var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.corporation != null)?_c('div',[_c('v-overlay',{attrs:{"value":_vm.corporationRentalStatusesLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-card',[_c('BaseModal',{on:{"cancel":_vm.close},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.corporation.corporation_name)+" "),(_vm.corporation.key_account_customer)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" star ")]):_vm._e()],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('p',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.$t('leasing.corporation_card_info'))+" ")]),_c('div',{staticClass:"section-textfield"},[_c('h4',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$t('leasing.corporation_info_mfiles') + ':')+" ")])]),_c('v-text-field',{staticClass:"form-field",attrs:{"value":_vm.corporation.corporation_name ? _vm.corporation.corporation_name : ' ',"label":_vm.$t('Name'),"readonly":""}}),_c('v-text-field',{staticClass:"form-field",attrs:{"value":_vm.corporation.business_id ? _vm.corporation.business_id : ' ',"label":_vm.$t('Business ID'),"readonly":""}}),_c('v-text-field',{staticClass:"form-field",attrs:{"value":_vm.corporation.tenant_status ? _vm.corporation.tenant_status : ' ',"label":_vm.$t('leasing.status'),"readonly":""}}),_c('v-text-field',{staticClass:"form-field",attrs:{"value":_vm.corporation.leasing_correspondent
                ? _vm.corporation.leasing_correspondent
                : ' ',"label":_vm.$t('Leasing correspondent'),"readonly":""}}),_c('div',{staticClass:"my-5"},[_c('div',{staticClass:"section-textfield"},[_c('h4',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$t('leasing.corporation_contact_details_mfiles') + ':')+" ")])]),_c('v-text-field',{staticClass:"form-field",attrs:{"value":_vm.corporation.corporation_contact
                  ? _vm.corporation.corporation_contact
                  : ' ',"label":_vm.$t('Corporation contact'),"readonly":""}})],1),_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t('leasing.corporation_extra'))+" ")]),_c('v-expansion-panel-content',[_c('MetadataForm',{attrs:{"metadata":_vm.getCorporationExtra(),"value":_vm.corporation}})],1)],1)],1),_vm._l((_vm.widgets),function(widget){return _c('div',{key:widget.id,staticClass:"mt-5",attrs:{"loading":false}},[(widget.id === 'leasing.corporation_notebook')?_c('Notes',{attrs:{"single-corporation":_vm.corporation,"maximized":widget.maximized}}):(widget.id === 'site.rental_contracts')?_c('v-expansion-panels',{model:{value:(_vm.contractExpand),callback:function ($$v) {_vm.contractExpand=$$v},expression:"contractExpand"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"py-1 pl-2"},[_vm._v(" "+_vm._s(_vm.$t('site.rental_contracts'))+" ")]),_c('v-expansion-panel-content',[_c('ContractDataForm',{attrs:{"data":_vm.getWidgetContracts(),"metadata":_vm.getContractMetadataForUnit(),"site-title":true}})],1)],1)],1):_c('DynamicWidget',{attrs:{"type":widget.type,"title":_vm.$t(widget.id),"data":{
                ...widget.data,
                items: _vm.getWidgetData(widget.id),
              },"loading":false,"maximized":widget.maximized}})],1)})]},proxy:true},{key:"footer",fn:function(){return undefined},proxy:true}],null,false,218577449)})],1)],1):_c('div',[_c('v-card',[_c('v-btn',{staticStyle:{"margin-top":"1em"},attrs:{"large":"","icon":"","right":"","absolute":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("close")]),_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(_vm.$t('Close')))])],1),_c('v-card-title',{staticClass:"text-h5 grey lighten-2 py-8",attrs:{"primary-title":""}}),_c('v-card-text',{staticClass:"empty-div"},[_c('v-progress-circular',{staticStyle:{"margin":"auto"},attrs:{"size":50,"color":"primary","indeterminate":""}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }