<template>
  <div
    v-if="editUser"
  >
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <AriaLive />
      <v-form
        ref="form"
        @submit.prevent="validate"
      >
        <BaseModal
          @cancel="emit"
        >
          <template #title>
            <v-container v-if="!editing">
              {{ $t('Create a new user') }}
            </v-container>
            <v-container v-else>
              {{ $t('Edit users of space') }}
            </v-container>
          </template>
          <template #content>
            <v-checkbox
              v-if="!editing && showSsoSelection"
              v-model="editUser.ssoAccount"
              class="infotext"
              :readonly="forceSSO"
              :label="$t('user.sso_account')"
            />
            <validation-provider
              v-slot="{ errors }"
              name="Name"
              rules="required"
            >
              <v-text-field
                v-model="editUser.name"
                :label="$t('Name')"
                :hint="$t('Required')"
                persistent-hint
                class="form-field"
                required
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="username"
              rules="required|max:120|allowedChars"
            >
              <v-text-field
                v-if="!editing"
                v-model="editUser.username"
                required
                :label="$t('Username')"
                :hint="$t('Required')"
                persistent-hint
                class="form-field"
                :error-messages="errors"
                @change="userSaved = false"
              />
              <v-text-field
                v-if="editing"
                v-model="editUser.username"
                readonly
                :label="$t('Username')"
                :hint="$t('Required')"
                persistent-hint
                required
                class="form-field"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="email"
              rules="required|email"
            >
              <v-text-field
                v-model="editUser.email"
                required
                :label="$t('Email')"
                :hint="$t('Required')"
                persistent-hint
                class="form-field"
                :error-messages="errors"
              />
            </validation-provider>
            <v-checkbox
              v-if="!editing && passwordChangeAllowed"
              v-model="editUser.sendInvitationEmail"
              :label="$t('Send invitation to email')"
            />
            <validation-provider
              v-if="!editing && !editUser.sendInvitationEmail && passwordChangeAllowed"
              v-slot="{ errors }"
              name="password"
              rules="required|min:12"
            >
              <v-text-field
                v-if="!editing"
                v-model="editUser.password"
                required
                :label="$t('Password')"
                :hint="$t('Required')"
                persistent-hint
                :type="'password'"
                autocomplete="new-password"
                number
                counter
                class="form-field"
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider
              v-if="editing && passwordChangeAllowed"
              ref="password"
              v-slot="{ errors }"
              name="password"
              rules="min:12"
            >
              <v-text-field
                v-if="editing"
                v-model="editUser.password"
                :label="$t('New password')"
                :type="'password'"
                autocomplete="new-password"
                number
                counter
                class="form-field"
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider
              v-if="editing && passwordChangeAllowed"
              v-slot="{ errors }"
              name="password1"
              rules="confirmed:password|min:12"
            >
              <v-text-field
                v-if="editing"
                v-model="editUser.password1"
                :label="$t('New password again')"
                :type="'password'"
                number
                counter
                class="form-field"
                :error-messages="errors"
              />
            </validation-provider>
            <v-text-field
              v-model="editUser.telephone"
              :label="$t('Telephone')"
              class="form-field"
            />

            <validation-provider
              v-slot="{ errors }"
              name="company"
              rules="required"
            >
              <v-text-field
                v-model="editUser.company"
                required
                :label="$t('Company')"
                :hint="$t('Required')"
                persistent-hint
                class="form-field"
                :error-messages="errors"
              />
            </validation-provider>
            <v-text-field
              v-model="editUser.address"
              :label="$t('Address')"
              class="form-field"
            />
            <v-text-field
              v-model="editUser.title"
              :label="$t('Title personnel')"
              class="form-field"
            />
            <br>
            <v-divider />
            <v-text-field
              v-model="validUntilDate"
              :label="$t('Valid until')"
              class="form-field"
              type="date"
            />
            <validation-provider
              v-slot="{ errors }"
              name="usergroups"
              rules="required"
            >
              <v-combobox
                v-model="selectedGroups"
                dense
                required
                :items="sortedGroupOptions"
                multiple
                chips
                item-text="groupName"
                return-object
                :no-data-text="'Ei käyttäjäryhmiä'"
                :label="$t('User groups')"
                :hint="$t('Required')"
                persistent-hint
                class="form-field"
                :error-messages="errors"
              />
            </validation-provider>
            <br>
            <v-divider />
            <v-text-field
              v-model="editUser.comments"
              :label="$t('Comments')"
              class="form-field"
            />
          </template>
          <template #footer>
            <v-btn
              type="submit"
              :disabled="
                invalid ||
                  inputCheck ||
                  indicatorRunning ||
                  (userSaved && !editing)
              "
              rounded
              depressed
              color="primary"
            >
              {{ $t('Save') }}
            </v-btn>
          </template>
        </BaseModal>
      </v-form>
    </validation-observer>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
    />
  </div>
</template>
<script>
import moment from 'moment'
import { required, confirmed, email, min, max } from 'vee-validate/dist/rules'
import Alert from '../components/Alert.vue'
import BaseModal from './general/BaseModal.vue'
import AriaLive from './AriaLive.vue'
import { extend, ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate'
import { i18n } from './../plugins/i18n.js'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: i18n.t('Required')
})

extend('confirmed', {
  ...confirmed,
  message: i18n.t('Passwords do not match')
})

extend('min', {
  ...min,
  message: i18n.t('Min 12 characters')
})

extend('max', {
  ...max,
  message: i18n.t('Max username length')
})

extend('email', {
  ...email,
  message: i18n.t('Email must be valid')
})

extend('allowedChars', {
   validate (value, args) {
     const allowedChars = /^[a-zA-Z0-9-._@+]*$/
     return allowedChars.test(String(value))
   },
   message: i18n.t('Allowed characters')
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Alert,
    BaseModal,
    AriaLive
  },
  props: {
    parentData: Boolean,
    user: Object,
    editing: Boolean,
    users: Array,
    invitation: Boolean,
    userOrganizations: {
      default: () => {
        return []
      },
      type: Array
    },
    allOrganizations: {
      default: () => {
        return []
      },
      type: Array
    },
    groupOptions: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  emits: ['close', 'childToParent', 'refresh', 'saved'],
  data () {
    return {
      childMessage: null,
      editable: false,
      editUser: null,
      currentVisibility: false,
      valid: true,
      selectedGroups: [],
      chips: true,
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      userSaved: false,
      validUntilDate: null
    }
  },
  computed: {
    customerId () {
      return this.$store.state.app.userInfo.customer_id
    },
    inputCheck () {
      if (this.editing) {
        if (this.editUser.password && !this.editUser.password1) {
          return true
        }
        if (this.editUser.password1 && !this.editUser.password) {
          return true
        }
      }
      return false
    },
    passwordChangeAllowed () {
      return !this.editUser.ssoAccount
    },
    isSuperAdmin () {
      return this.$store.state.app.superAdmin
    },
    forceSSO () {
      let forceSSO = false
      if (!this.isSuperAdmin) {
        forceSSO = this.userOrganizations.every(org => org.sso)
      }
      return forceSSO
    },
    showSsoSelection () {
      return this.userOrganizations.some(org => org.sso)
    },
    sortedGroupOptions () {
      // sort group options by organisations
      // first get set of unique ids
      const organizationIdSet = new Set()
      this.groupOptions.forEach(g => organizationIdSet.add(g.group.idUserOrganization))
      let sortedGroups = []
      organizationIdSet.forEach(id => {
        sortedGroups.push({ header: this.getOrganizationName(id) })
        sortedGroups = sortedGroups.concat(this.groupOptions.filter(g => g.group.idUserOrganization === id))
        sortedGroups.push({ divider: true })
      })
      return sortedGroups
    }
  },
  watch: {
    currentVisibility: function (value) {
      if (value === false) {
        this.$emit('close')
      }
    },
    visible: function (value) {
      this.currentVisibility = value
    },
    editUser: function (value) {
      if (this.editing) {
        this.selectedGroups = this.getGroupsForEditedUser()
        this.validUntilDate = moment(value.validUntil).utc(true).format('YYYY-MM-DD')
      }
    },
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    }
  },
  async mounted () {
    this.editUser = JSON.parse(JSON.stringify(this.user))
    this.editUser.ssoAccount = this.forceSSO
    this.selectedGroups = this.getGroupsForEditedUser()
    this.validUntilDate = this.editUser.validUntil ? moment(this.editUser.validUntil).utc(true).format('YYYY-MM-DD') : null
  },
  methods: {
    emit (event) {
      this.$emit('childToParent', false)
      this.$refs.observer.reset()
      this.$refs.form.reset()
      this.$emit('refresh')
    },
    close () {
      this.currentVisibility = false
      requestAnimationFrame(() => {
        this.$refs.observer.reset()
        this.$refs.form.reset()
      })
      this.$emit('close')
    },
    reset () {
      requestAnimationFrame(() => {
        this.$refs.observer.reset()
        this.$refs.form.reset()
      })
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
      this.$emit('refresh')
      this.$emit('close')
    },
    async validate () {
      if (this.$refs.form.validate()) {
        let userSaved = false
        let userGroupSaved = false
        const dateTime = this.validUntilDate ? this.validUntilDate : null

        const newUser = {
          name: this.editUser.name,
          username: this.editUser.username,
          password: this.editUser.password,
          telephone: this.editUser.telephone,
          company: this.editUser.company,
          address: this.editUser.address,
          title: this.editUser.title,
          comments: this.editUser.comments,
          validUntil: dateTime,
          email: this.editUser.email,
          customerId: parseInt(this.customerId),
          userGroups: this.selectedGroups,
          sendInvitationEmail: Boolean(this.editUser.sendInvitationEmail),
          ssoAccount: Boolean(this.editUser.ssoAccount)
        }

        if (!this.editing) {
          this.indicatorRunning = true
          if (this.forceSSO) {
            newUser.ssoAccount = true
          }
          if (newUser.ssoAccount) {
            newUser.sendInvitationEmail = false
          }
          try {
            const posted = await this.$rambollfmapi.accounts.post(newUser)
            this.editUser.password = ""
            if (posted.name === 'Error') {
              if (posted.response.data) {
                const data = posted.response.data
                if (
                  data.description === 'User with same username already exists'
                ) {
                  this.indicatorMessage = this.$t('error.existing_username')
                  this.operationResult = 'error'
                  this.showIndicator = true
                }
                if (
                  data.description ===
                  'Can only add users belonging to same customer account'
                ) {
                  this.indicatorMessage = this.$t('error.diff_customer')
                  this.operationResult = 'error'
                  this.showIndicator = true
                }
              }
            } else {
              this.reset()
              this.editUser.validUntil = null
              this.$emit('saved')
              this.indicatorRunning = false
            }
          } catch (error) {
            this.$log.error('error', error)
            // Generic message for when we get an error other then ones returned from API
            this.indicatorMessage = this.$t('error.unexpected')
            this.operationResult = 'error'
            this.showIndicator = true
          }
        } else {
          const data = []
          const newPassword = this.editUser.password1

          if (newPassword !== null) {
            this.editUser.password = newPassword
          }
          if (this.validUntilDate !== moment(this.editUser.validUntil).format('YYYY-MM-DD')) {
            this.editUser.validUntil = this.validUntilDate ? this.validUntilDate+'T00:00:00' : null
          }
          Object.entries(this.editUser).forEach(function (key, index, value) {
            if (
              key['0'] !== 'id' &&
              key['0'] !== 'customerId' &&
              key['0'] !== 'userGroups' &&
              key['0'] !== 'userGroup' &&
              key['0'] !== 'password1' &&
              key['0'] !== 'userGroupArr' &&
              key['0'] !== 'username'
            ) {
              if (key['1'] !== null || index !== 0) {
                data.push({
                  op: 'replace',
                  path: '/' + key['0'],
                  value: key['1']
                })
              }
            }
          })
          this.indicatorRunning = true

          try {
            const userResponse = await this.$rambollfmapi.accounts.patch(
              this.editUser.id,
              data
            )
            this.editUser.password = ""
            this.editUser.password1 = ""
            if (userResponse.name === 'Error') {
              if (userResponse.response.data) {
                const data = userResponse.response.data
                if (data.message === 'No User found with the given ID') {
                  this.indicatorMessage = this.$t('error.no_user_found')
                  this.operationResult = 'error'
                  this.showIndicator = true
                  userSaved = false
                }
              }
            } else {
              userSaved = true
            }

            const addedUsergroups = await this.$rambollfmapi.accounts.put(
              this.editUser.id,
              this.selectedGroups
            )
            if (addedUsergroups.name === 'Error') {
              this.indicatorMessage = this.$t('error.usergroup_add')
              this.operationResult = 'error'
              this.showIndicator = true
              userGroupSaved = false
            } else {
              userGroupSaved = true
            }
          } catch (error) {
            this.$log.error('error', error)
            this.indicatorMessage = this.$t('error.unexpected')
            this.operationResult = 'error'
            this.showIndicator = true

            userSaved = false
            userGroupSaved = false
          }
        }
        // Set Alert content based on operations results
        if (userSaved && userGroupSaved) {
          this.$store.dispatch('app/addAriaLive', this.$t('aria_live.new_user_added'))
          this.$emit('saved')
          this.$emit('childToParent', false)
          this.$emit('refresh')
          this.indicatorRunning = false
        }
      }
    },
    getGroupsForEditedUser () {
      if (this.editing) {
        this.selectedGroups = this.users.find(
          u => u.id === this.editUser.id
        ).userGroups
        for (var i = 0; i < this.selectedGroups.length; i++) {
            var filteredGroupOptions = this.groupOptions.find(go => go.groupId === this.selectedGroups[i].groupId)
            this.selectedGroups[i].group = filteredGroupOptions.group
        }
        return this.selectedGroups
      } else {
        return []
      }
    },
    getOrganizationName (orgId) {
      return this.allOrganizations.find(org => org.id === orgId)?.name ?? "-"
    }
  }
}
</script>
<style>
.fullscreen-settings {
  padding: 0px;
  height: 100%;
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  background-color: white;
}
.infotext {
  background: white !important;
  border: none !important;
}
</style>
