<template>
  <div>
    <v-dialog
      v-if="showCreateRentContractModal"
      v-model="showCreateRentContractModal"
      persistent
      width="90%"
    >
      <v-card>
        <CreateRentContractModal
          :contract-id="contractId"
          :out-rent="outRent"
          @handleSave="onCreateRentContractModalClosed()"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="unitModifyDialog"
      max-width="1200"
      persistent
    >
      <v-card>
        <UnitModifyForm
          :units="modificableUnits"
          :unit-usages="unitUsages"
          :unit-rent-states="unitRentStates"
          :party-links="unitPartyLinks"
          :dismantleable="selectedSpacesFromViewer.length > 0"
          :disabled="readonly"
          :renting="rentingTabActive"
          :apartments-active="showUnitProp('apartment').isShown"
          :unit-apartments="unitApartments"
          :unit-kitchens="unitKitchens"
          :unit-balconies="unitBalconies"
          :unit-additional-infos="unitAdditionalInfos"
          :unit-visibility-rights="unitVisibilityRights"
          :target-goal="targetGoal"
          :right-for-ti-expense="hasApplicationPermissionByName('ULKOINENVUOKRAUS')"
          @update="$emit('update')"
          @close="unitModifyDialog = false"
          @popup="onPopup"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="marketingModifyDialog"
      max-width="1200"
      persistent
    >
      <v-card>
        <MarketInfoModifyForm
          :units="modificableUnitsForMarketingInfo"
          :disabled="readonly"
          :unit-usages="marketingUnitUsages"
          :unit-extensibilities="unitExtensibilities"
          :unit-divisibilities="unitDivisibilities"
          :rental-attributes="rentalAttributes"
          :technical-attributes="technicalAttributes"
          :unit-condition="unitCondition"
          :move-readiness="moveReadiness"
          :water-meter="waterMeter"
          :electricity-meter="electricityMeter"
          :target-goal="targetGoal"
          @update="$emit('update')"
          @close="marketingModifyDialog = false"
          @popup="onPopup"
        />
      </v-card>
    </v-dialog>
    <!-- Commercial info update dialog -->
    <v-dialog
      v-if="hasApplicationPermissionByName('LEASING')"
      v-model="commercialModifyDialog"
      max-width="1200"
      persistent
    >
      <v-card>
        <CommercialInfoModifyForm
          :items="commercialInfoUnits"
          :header="$t('Unit')"
          :info-text="infoText()"
          :fields="commercialFields"
          @update="$emit('update')"
          @close="commercialModifyDialog = false"
          @popup="onPopup"
          @patch="onPatch"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="hasApplicationPermissionByName('VSOP_POHJAKUVA_VAJAAKAYTTO')"
      v-model="vacancyRateModifyDialog"
      max-width="700"
      persistent
    >
      <v-card>
        <GeneralModifyForm
          :items="vacancyRateAssumptionUnits"
          :header="$t('Unit')"
          :fields="vacancyRateAssumptionFields"
          title="Edit vacancy rate assumption"
          @update="$emit('update')"
          @close="vacancyRateModifyDialog = false"
          @popup="onPopup"
          @patch="onPatch"
        />
      </v-card>
    </v-dialog>
    <div v-if="hasApplicationPermissionByName('LEASING_PROSPEKTIT')">
      <v-dialog
        v-model="printSelectionDialogOpen"
        width="550"
        persistent
      >
        <v-card>
          <PrintSelectionForm
            :is-shown="printSelectionDialogOpen"
            :selected-units="layer === '1' ? selectedUnitForPrintingSpaces : selectedUnitsFromViewer"
            :selected-floor="selectedFloor ? selectedFloor.floor_name : ''"
            :total-area="totalArea"
            :selected-units-string="selectedUnitsString"
            @close="closePrintSelectionDialog"
            @print="openPrinting"
            @openAddUnits="openAddUnits"
          />
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="rentalProcessAttachDialogOpen"
        width="560"
        persistent
      >
        <v-card>
          <AttachUnitToRentalProcessForm
            :is-shown="rentalProcessAttachDialogOpen"
            :selected-units="selectedUnitsFromViewer"
            :total-area="totalArea"
            :current-site="currentSite"
            :selected-units-string="selectedUnitsString"
            :selected-process="selectedProcess"
            @close="closeAddUnitDialog"
            @showSuccessTooltip="toggleSuccessTooltip"
          />
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="rentalOfferOpen"
        persistent
        width="700"
      >
        <v-card>
          <RentalOfferDialog
            :units="selectedUnitsFromViewer"
            :units-string="selectedUnitsString"
            :total-area="totalArea"
            :prospect="selectedProcess"
            :is-shown="rentalOfferOpen"
            :current-site="currentSite"
            @close="closeRentalOffer"
            @save="saveRentalOffer"
          />
        </v-card>
      </v-dialog>
      <v-dialog
        :value="shownProspect != null && showProspectModal"
        persistent
        width="700"
      >
        <v-card>
          <ProspectModal
            :prospect="shownProspect"
            :is-shown="showProspectModal"
            :user-info="userInfo"
            @update="updateProspectData"
          />
        </v-card>
      </v-dialog>
      <v-dialog
        :value="shownCorporation && showCorporationModal"
        persistent
        width="700"
      >
        <v-card>
          <CorporationModal
            :corporation="shownCorporation"
            :rental-processes="rentalProcesses"
            :rental-statuses="allSitesRentalStatuses"
            :is-shown="shownCorporation != null"
          />
        </v-card>
      </v-dialog>
      <!-- Success tooltip for when units are added to a prospect. Should probably be moved to its own component -->
      <v-col id="success-card">
        <div
          v-if="showSuccess"
          class="success-tile"
          @click="showSuccess = false"
        >
          <h3>
            <v-icon
              class="success-tile-icon"
            >
              check
            </v-icon> {{ $t('leasing.units_added_success') }}
          </h3>
        </div>
      </v-col>
      <p
        v-if="selectedUnitsFromViewer.length === 0 && selectedTab !== 'carparks'"
        class="text-body-1 pt-3 px-2"
      >
        {{ $t('Select object') }}
      </p>
    </div>
    <!-- SINGLE UNIT BOX -->
    <v-expansion-panels
      v-if="selectedUnitsFromViewer.length"
      :value="selectedUnitsFromViewer.length ? [0] : null"
      class="my-2"
      multiple
    >
      <v-expansion-panel
        v-if="selectedUnitsFromViewer.length === 1"
      >
        <v-expansion-panel-header>
          <v-row
            align="center"
            justify="start"
            no-gutters
          >
            <v-icon>view_compact</v-icon>
            {{ $t('Selected unit') + ':' + ' ' }}
            {{
              selectedUnitsFromViewer[0].unit_code
                ? selectedUnitsFromViewer[0].unit_code
                : ""
            }}
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- UNIT BASIC INFORMATION BOX -->
          <UnitBasicInformation
            :layer="layer"
            :readonly="readonly"
            :unit-extra-informations="unitExtraInformations"
            :unit="selectedUnitsFromViewer[0]"
            :unit-visibility-rights="unitVisibilityRights"
            :show="show"
            :contract-visibility-rights="contractVisibilityRights"
            :contract-links="contractLinks"
            @openContract="openContract"
          />
          <UnitExtraInformation
            v-if="selectedTab === 'siteInformation'"
            :unit="selectedUnitsFromViewer[0]"
            :unit-visibility-rights="unitVisibilityRights"
            :unit-extra-informations="unitExtraInformations"
            :renting="selectedTab === 'renting'"
            :readonly="readonly"
            @update="updateUnit"
          />
          <!-- RENTING BOX -->
          <RentingBox
            v-if="selectedTab === 'renting'"
            :building-data="buildingData"
            :unit="selectedUnitsFromViewer[0]"
            :selected-units-from-viewer="selectedUnitsFromViewer"
            :unit-visibility-rights="unitVisibilityRights"
            :layer="layer"
            :renting="selectedTab === 'renting'"
            :contracts-loading="contractsLoading"
            :parties="parties"
          />

          <!-- MARKETING BOX -->
          <UnitMarketingBox
            v-if="selectedTab === 'marketing'"
            :selected-unit="selectedUnitsFromViewer[0]"
            :selected-units="selectedUnitsFromViewer"
            :floor-units="units"
            @update="updateUnit"
          />

          <!-- WEB VISIBILITY -->
          <UnitWebVisibilityField
            v-if="selectedTab === 'marketing'"
            :selected-unit="selectedUnitsFromViewer[0]"
            @update="updateUnit"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- UNIT BOX AS EXPAND -->
      <v-expansion-panel
        v-if="selectedUnitsFromViewer.length > 1"
        :value="show.unitInfo"
      >
        <v-expansion-panel-header>
          <v-row
            align="center"
            justify="start"
            no-gutters
          >
            <v-icon>view_compact</v-icon>
            {{ $t('Units') }}
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list
            dense
            class="info-box"
          >
            <v-list-item class="tile">
              <v-list-item-content
                class="tile-content"
              >
                {{ $t('Selection') }}
              </v-list-item-content>
              <v-list-item-content class="tile-content">
                {{
                  selectedUnitsFromViewer.length + ' Huoneistoa'
                }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="tile">
              <v-list-item-content class="tile-content">
                {{
                  $t('Unit area')
                }}
              </v-list-item-content>
              <v-list-item-content class="tile-content">
                {{
                  getValueWithUnit(calculatedUnitTotalArea, 'Area')
                }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- SELECTED UNITS -->
          <div
            v-for="unit in selectedUnitsFromViewer"
            :key="unit.id"
          >
            <v-expansion-panels
              class="my-2"
              @change="onChange"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ unit.name ? unit.name : unit.unit_code }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- UNIT BASIC INFORMATION BOX -->
                  <UnitBasicInformation
                    :layer="layer"
                    :readonly="readonly"
                    :unit-extra-informations="unitExtraInformations"
                    :unit="unit"
                    :unit-visibility-rights="unitVisibilityRights"
                    :show="show"
                    :contract-visibility-rights="contractVisibilityRights"
                    :contract-links="contractLinks"
                    @openContract="openContract"
                  />
                  <UnitExtraInformation
                    v-if="selectedTab === 'siteInformation'"
                    :unit="unit"
                    :readonly="readonly"
                    :unit-visibility-rights="unitVisibilityRights"
                    :unit-extra-informations="unitExtraInformations"
                    :renting="selectedTab === 'renting'"
                    @update="updateUnit"
                  />
                  <!-- RENTING BOX -->
                  <RentingBox
                    v-if="selectedTab === 'renting'"
                    :building-data="buildingData"
                    :unit="unit"
                    :selected-units-from-viewer="selectedUnitsFromViewer"
                    :unit-visibility-rights="unitVisibilityRights"
                    :layer="layer"
                    :renting="selectedTab === 'renting'"
                    :contracts-loading="contractsLoading"
                    :parties="parties"
                  />
                  <!-- MARKETING BOX -->
                  <UnitMarketingBox
                    v-if="selectedTab === 'marketing'"
                    :selected-unit="unit"
                    :selected-units="selectedUnitsFromViewer"
                    :floor-units="units"
                    @update="updateUnit"
                  />

                  <!-- WEB VISIBILITY -->
                  <UnitWebVisibilityField
                    v-if="selectedTab === 'marketing'"
                    :selected-unit="unit"
                    @update="updateUnit"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- EDIT MENU -->
      <v-row
        v-if="selectedUnitsFromViewer.length > 0 && selectedTab === 'siteInformation'"
        id="unitModifyButton"
        align="end"
        justify="end"
      >
        <v-menu
          v-if="someModifyRightOn"
          offset-y
        >
          <template
            #activator="{ on }"
          >
            <v-btn
              text
              rounded
              outlined
              align-end
              v-on="on"
            >
              <v-icon>edit</v-icon>
              {{ $t('Edit units...') }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="hasApplicationPermissionByName('HUONEISTO_MUOKKAUS')"
              @click="unitModifyDialog = true"
            >
              <v-list-item-content>
                <v-icon>edit</v-icon>
              </v-list-item-content>
              <v-list-item-title>{{ $t('Edit units') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="hasApplicationPermissionByName('VUOKRAUS_VLAINEN') && selectedUnitsFromViewer.length === 1"
              @click="$emit('tenantModify')"
            >
              <v-list-item-content>
                <v-icon>calendar_today</v-icon>
              </v-list-item-content>
              <v-list-item-title>{{ $t('Edit tenants') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-else-if="hasApplicationPermissionByName('VUOKRAUS_VLAINEN')">
          <v-btn
            v-if="selectedUnitsFromViewer.length === 1"
            text
            outlined
            align-end
            @click="$emit('tenantModify')"
          >
            <v-icon>visibility</v-icon>
            {{ $t('View tenants') }}
          </v-btn>
        </div>
      </v-row>
      <!-- MARKETING EDIT MENU -->
      <v-row
        v-if="selectedUnitsFromViewer.length > 0 && selectedTab === 'marketing'"
        id="marketingModifyButton"
        align="end"
        justify="end"
      >
        <v-menu
          v-if="!readonly && editableMarketingInfoUnits.length"
          offset-y
        >
          <template
            #activator="{ on }"
          >
            <v-btn
              text
              rounded
              outlined
              align-end
              v-on="on"
              @click="marketingModifyDialog = true"
            >
              <v-icon>edit</v-icon>
              {{ $t('Edit marketing information') }}
            </v-btn>
          </template>
        </v-menu>
      </v-row>
      <!-- Menu for renting actions -->
      <v-row
        v-if="selectedUnitsFromViewer.length > 0 && selectedTab === 'renting'"
        no-gutters
        justify="space-between"
      >
        <v-row no-gutters>
          <v-col cols="6">
            <v-btn
              v-if="hasApplicationPermissionByName('LEASING')"
              outlined
              rounded
              text
              @click="openRentalProcessAttachDialog"
            >
              {{ $t("leasing.add_to_process") }}
            </v-btn>
          </v-col>
          <v-col
            cols="6"
          >
            <v-btn
              v-if="hasApplicationPermissionByName('LEASING_PROSPEKTIT')"
              outlined
              rounded
              text
              right
              class="float-right"
              @click="openPrintSelectionDialog"
            >
              {{ $t("leasing.download_brochure") }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
          >
            <v-btn
              v-if="hasApplicationPermissionByName('LEASING') && !readonly"
              text
              rounded
              outlined
              class="float-right mr-4"
              @click="commercialModifyDialog = true"
            >
              <v-icon>edit</v-icon>
              {{ $t('Edit commercial information') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
          >
            <v-btn
              v-if="hasApplicationPermissionByName('VSOP_POHJAKUVA_VAJAAKAYTTO') && !readonly"
              text
              rounded
              outlined
              class="float-right mr-4"
              @click="vacancyRateModifyDialog = true"
            >
              <v-icon>edit</v-icon>
              {{ $t('Edit vacancy rate assumption') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import format from '../../helpers/format.js'
import UnitModifyForm from './UnitModifyForm.vue'
import MarketInfoModifyForm from '../../components/FacilityManagement/MarketInfoModifyForm.vue'
import UnitBasicInformation from './UnitBasicInformation.vue'
import UnitExtraInformation from './UnitExtraInformation.vue'
import AttachUnitToRentalProcessForm from '../../components/Leasing/AttachUnitToRentalProcessForm.vue'
import PrintSelectionForm from '../../components/Leasing/PrintSelectionForm.vue'
import RentalOfferDialog from '../../components/Leasing/RentalOfferDialog.vue'
import RentingBox from './RentingBox.vue'
import UnitMarketingBox from '../../components/FacilityManagement/UnitMarketingBox.vue'
import UnitWebVisibilityField from '../../components/FacilityManagement/UnitWebVisibilityField.vue'
import ProspectModal from '../../components/Leasing/ProspectModal.vue'
import CorporationModal from '../../components/Leasing/CorporationModal.vue'
import CommercialInfoModifyForm from './../../components/FacilityManagement/CommercialInfoModifyForm.vue'
import CreateRentContractModal from "../../components/Leasing/Modals/CreateRentContracts/CreateRentContractModal.vue"
import GeneralModifyForm from '../../components/FacilityManagement/GeneralModifyForm.vue'
import definition from '../../helpers/definition.js'
import clone from '../../helpers/clone.js'

export default {
  name: 'UnitBox',
  components: {
    AttachUnitToRentalProcessForm,
    PrintSelectionForm,
    RentingBox,
    RentalOfferDialog,
    UnitBasicInformation,
    UnitExtraInformation,
    UnitModifyForm,
    UnitMarketingBox,
    UnitWebVisibilityField,
    MarketInfoModifyForm,
    ProspectModal,
    CorporationModal,
    CommercialInfoModifyForm,
    CreateRentContractModal,
    GeneralModifyForm,
  },
  props: {
    buildingData: { type: Object, default: null },
    selectedTab : { type: String, default: null },
    parties: { type: Array, default: null },
    readonly: { type: Boolean, default: null },
    show: { type: Object, default: null },
    selectedSpacesFromViewer: { type: Array, default: null },
    selectedUnitForPrintingSpaces: { type: Array, default: null },
    selectedUnitsFromViewer: { type: Array, default: null },
    unitPartyLinks: { type: Array, default: null },
    unitVisibilityRights: { type: Object, default: null },
    contractVisibilityRights: { type: Object, default: null },
    renting: { type: Boolean, default: false },
    layer: { type: String, default: ''},
    metadata: { type: Object, default: null },
    selectedFloor: { type: Object, default: null },
    selectedProspect: { type: Object, default: null },
    selectedProcess: { type: Object, default: null },
    contracts: { type: Array, default: function () {return []}},
    contractLinks: { type: Array, default: function () {return []}},
    units: {
      type: Array,
      default: function () {
        return []
      }
    },
    spaces: { type: Array, default: function () {return []}}
  },
  emits: ['update', 'tenantModify', 'setProcess', 'print', 'loadParties'],
  data () {
    return {
      unitModifyDialog: false,
      marketingModifyDialog: false,
      unitRentStates: [],
      unitUsages: [],
      unitExtensibilities: [],
      unitDivisibilities: [],
      rentingTabActive: false,
      unitExtraInformations: [],
      savingFailed: false,
      unitApartments: [],
      unitKitchens: [],
      unitBalconies: [],
      unitAdditionalInfos: [],
      unitMarketingInfo: [],
      marketingUnitUsages: [],
      rentalAttributes: [],
      technicalAttributes: [],
      unitCondition: [],
      moveReadiness: [],
      waterMeter: [],
      electricityMeter: [],
      targetGoal: [],
      rentalProcessAttachDialogOpen: false,
      printSelectionDialogOpen: false,
      rentalOfferOpen: false,
      printType: null,
      showSuccess: false,
      contractsLoading: false,
      commercialModifyDialog: false,
      showCreateRentContractModal: false,
      vacancyRateModifyDialog: false,
      contractId: undefined,
      outRent: true,
      allSpaces: []
    }
  },
  computed: {
    ...mapGetters('app', [
      'definitionsByGroupLabel',
      'definitionById',
      'definitionLabelById',
      'hasApplicationPermissionByName',
    ]),
    ...mapGetters('sites', ['currentSite']),
    ...mapState('app', ['currentDate', 'userInfo', 'sites', 'settings']),
    ...mapState('leasing', ['showProspectModal', 'shownProspect', 'shownCorporation', 'showCorporationModal', 'rentalStatuses', 'prospects', 'allSitesRentalStatuses']),
    ...mapState('rentalProcess', ['rentalProcesses']),
    totalArea () {
      if (this.layer === '1' && this.selectedUnitForPrintingSpaces && this.selectedUnitForPrintingSpaces.length > 0) {
        return this.selectedUnitForPrintingSpaces[0].area
      }

      return this.selectedUnitsFromViewer.reduce((total, unit) => total + unit.area, 0)
    },
    selectedUnitsString () {
      const floorName = this.selectedFloor ? this.selectedFloor.floor_name : ''

      if (this.layer === '1' && this.selectedUnitForPrintingSpaces && this.selectedUnitForPrintingSpaces.length > 0) {
        return floorName + ": " + this.selectedUnitForPrintingSpaces[0].unit_code
      }

      return this.selectedUnitsFromViewer.reduce((total, unit, index) => {
        let string = total
        if (index !== 0) {
          string = string + ", "
        }
        return string + unit.unit_code
        }, floorName + ": ")
    },
    calculatedUnitTotalArea () {
        return this.selectedUnitsFromViewer
          .filter(u => u !== undefined)
          .map(u => Number(this.layer === '1' ? u.area_from_spaces : u.area))
          .reduce((acc, cur) => {
            return acc + cur
          }, 0)
    },
    modificableUnits () {
      return this.selectedUnitsFromViewer.map(unit => {
        return {
          id: unit.id,
          unit_code: unit.unit_code,
          usage: this.$t(unit.usage),
          unit_extensibility: unit.unit_extensibility,
          unit_divisibility: unit.unit_divisibility,
          unit_detail_info: unit.unit_detail_info,
          not_rentable: unit.not_rentable,
          market_rent: unit.market_rent,
          upkeep_rent: unit.upkeep_rent,
          marketing: unit.marketing,
          description: unit.unit_note,
          apartment: unit.apartment,
          kitchen: unit.kitchen,
          sauna: unit.sauna,
          balcony: unit.balcony,
          additional_info_1: unit.additional_info_1,
          additional_info_2: unit.additional_info_2,
          usage_types: unit.usage_types,
          renting_attributes: unit.renting_attributes,
          technical_attributes: unit.technical_attributes,
          unit_condition: unit.unit_condition,
          move_readiness: unit.move_readiness,
          advertising_phrase: unit.advertising_phrase,
          introduction: unit.introduction,
          video_link: unit.video_link,
          panorama_link: unit.panorama_link,
          clarified_ti_expense: unit.clarified_ti_expense,
          estimated_ti_expense: unit.estimated_ti_expense,
          water_meter: unit.water_meter,
          electricity_meter: unit.electricity_meter,
          other_utilities: unit.other_utilities,
          campaign_active: unit.campaign_active,
          campaign_last_date: unit.campaign_last_date,
          campaign_description: unit.campaign_description,
          unit_identifier: unit.unit_identifier,
          empty_space_contract_area: unit.empty_space_contract_area,
          rent_target: unit.rent_target,
          lease_plan: unit.lease_plan,
          list_rent_apartment: unit.list_rent_apartment,
          target_goal: unit.target_goal,
          vacancy_rate_assumption: unit.vacancy_rate_assumption,
        }
      })
    },
    commercialInfoUnits () {
      return this.modificableUnits.map(unit => {
        return {
          ...unit,
          item_code: unit.unit_code,
        }
      });
    },
    commercialFields () {
      return [
        {
          header: 'Market rent',
          fieldType: 'number_field',
          id: 'market_rent',
          value: 'market_rent',
          show: this.canEdit(this.unitVisibilityRights.market_rent),
          style: { 'min-width': '10em' },
        },
        {
          header: 'List rent apartment',
          fieldType: 'number_field',
          id: 'list_rent_apartment',
          value: 'list_rent_apartment',
          show: this.canEdit(this.unitVisibilityRights.list_rent_apartment),
          style: { 'min-width': '10em' },
        },
        {
          header: 'Rent target',
          fieldType: 'number_field',
          id: 'rent_target',
          value: 'rent_target',
          show: this.canEdit(this.unitVisibilityRights.rent_target),
          style: { 'min-width': '10em' },
          rules: [
            value => value >= 0 || this.$t('Value must be positive')
          ]
        },
        {
          header: 'Lease plan',
          fieldType: 'text_area',
          id: 'lease_plan',
          value: 'lease_plan',
          show: this.canEdit(this.unitVisibilityRights.lease_plan),
          style: { 'min-width': '22em' },
        },
        {
          header: 'Estimated ti expense',
          fieldType: 'number_field',
          id: 'estimated_ti_expense',
          value: 'estimated_ti_expense',
          show: this.canEdit(this.unitVisibilityRights.estimated_ti_expense),
          style: { 'min-width': '10em' },
          rules: [
            value => value >= 0 || this.$t('Value must be positive')
          ]
        },
        {
          header: 'Clarified ti expense',
          fieldType: 'number_field',
          id: 'clarified_ti_expense',
          value: 'clarified_ti_expense',
          show: true,
          style: { 'min-width': '10em' },
          rules: [
             value => value >= 0 || this.$t('Value must be positive')
          ]
        }
      ]
    },
    vacancyRateAssumptionUnits () {
      return this.modificableUnits.map(unit => {
        return {
          vacancy_rate_assumption: unit.vacancy_rate_assumption,
          id: unit.id,
          item_code: unit.unit_code,
        }
      });
    },
    vacancyRateAssumptionFields () {
      return [
        {
          header: 'Vacancy rate assumption',
          fieldType: 'number_field',
          id: 'vacancy_rate_assumption',
          value: 'vacancy_rate_assumption',
          show: true,
          style: { 'width': '30em' },
          rules: [
            value => value >= 0 && value <= 100 || this.$t('Value must be positive and less than 100')
          ]
        },
      ]
    },
    editableMarketingInfoUnits () {
      // subunits marketing info can't be edited
      return this.selectedUnitsFromViewer.filter(unit => unit.master_unit === null || unit.master_unit === unit.id)
    },
    modificableUnitsForMarketingInfo () {
      const editableUnits = this.editableMarketingInfoUnits
      return this.modificableUnits.filter(unit => editableUnits.find(e => e.id === unit.id))
    },
    someModifyRightOn ()
    {
      return !this.readonly &&
         (this.hasApplicationPermissionByName('HUONEISTO_MUOKKAUS') ||
          (this.hasApplicationPermissionByName('VUOKRAUS_VLAINEN') &&
           this.selectedUnitsFromViewer.length === 1));
    }
  },
  watch: {
    renting: {
      handler () {
        this.rentingTabActive = this.renting
      }
    },
    selectedUnitsFromViewer: {
      handler () {
        if(this.selectedUnitsFromViewer.length === 1) {
          this.fetchAllocatedAreas()
        }

        this.unitExtraInformations = []
        this.selectedUnitsFromViewer.map(unit => {
          this.unitExtraInformations = [...this.unitExtraInformations, { id: unit.id, extra_information: unit.unit_note ? unit.unit_note : '' , edited: false }]
        })
      }
    },
    buildingData : {
      handler () {
        this.contractsLoading = true
        this.getAllRentalStatuses([this.buildingData.id_building]).finally(() => this.contractsLoading = false)
      }
    }
  },
  mounted () {
    this.unitUsages = definition.translateAndSortLabels(clone.cloneDeep(this.definitionsByGroupLabel('unit.usage'))) // Fetch, copy and add translations to unit usage labels, then sort them alphabetically
    this.unitRentStates = this.definitionsByGroupLabel('unit.notrentable')
    this.unitRentStates.push({ id: 0, label: 'Rentable' })
    this.unitApartments = this.definitionsByGroupLabel('unit.apartmenttype')
    this.unitKitchens = this.definitionsByGroupLabel('unit.kitchentype')
    this.unitBalconies = this.definitionsByGroupLabel('unit.balcony')
    this.unitAdditionalInfos = this.definitionsByGroupLabel('unit.additional_info')
    this.marketingUnitUsages = this.definitionsByGroupLabel('unit.usage_types')
    this.unitExtensibilities = this.definitionsByGroupLabel('unit.extensibility')
    this.unitDivisibilities = this.definitionsByGroupLabel('unit.divisibility')
    this.rentalAttributes = this.definitionsByGroupLabel('unit.renting_attributes')
    this.technicalAttributes = this.definitionsByGroupLabel('unit.technical_attributes')
    this.unitCondition = this.definitionsByGroupLabel('unit.unit_condition')
    this.moveReadiness = this.definitionsByGroupLabel('unit.move_readiness')
    this.waterMeter = this.definitionsByGroupLabel('unit.meterstatus')
    this.electricityMeter = this.definitionsByGroupLabel('unit.meterstatus')
    this.targetGoal = this.definitionsByGroupLabel('unit.goal')
    this.contractsLoading = true
    this.getAllRentalStatuses([this.buildingData.id_building]).finally(() => this.contractsLoading = false)
    if (this.hasApplicationPermissionByName('LEASING')) {
      this.getProspects()
      this.getAllSitesRentalStatuses()
    }
    this.getSpacesBySite()
    this.fetchAllocatedAreas()
  },
  methods: {
    ...mapActions('leasing', ['getProspects', 'getAllRentalStatuses', 'getAllSitesRentalStatuses', 'postRentalOffer']),
    onChange (event) {
      if (event === undefined) // when panel is closed
        return

      this.fetchAllocatedAreas()
    },
    async fetchAllocatedAreas () {
       let unitIds = this.selectedUnitsFromViewer.map(unit => unit.id)
       let costcentersBySpaces = await this.$rambollfmapi.spaces.allocatedAreaByUnits(unitIds, this.currentDate)
       this.selectedUnitsFromViewer.forEach(unit => { unit.allocated_unit_area = 0 })
       costcentersBySpaces.forEach(costcenterBySpace => {
         let spacesOfId = this.allSpaces.filter(space => space.id == costcenterBySpace.idSpace)
         if (spacesOfId.length === 1) {
           let unitsOfId = this.selectedUnitsFromViewer.filter(unit => spacesOfId[0].id_unit === unit.id)
           if (unitsOfId.length === 1) {
             unitsOfId[0].allocated_unit_area += costcenterBySpace.allocatedUnitArea
           }
         }
       })
    },
    async getSpacesBySite (){
      let siteId = this.currentSite.id_site
      let spaces = await this.$rambollfmapi.spaces.siteSpaces(siteId)
      this.allSpaces = spaces
    },
    getValueWithUnit (value, formation) {
      return format.formatData(value, formation)
    },
    showUnitProp (prop) {
      const metadata = this.unitVisibilityRights[prop]
      if (metadata !== undefined) {
        const { isShown, category, order, format } = metadata
        return { isShown, category, order, format }
      } else {
        return { isShown: false, category: 'None', order: 999, format: 0 }
      }
    },
    canEdit (object) {
      return (
        object !== undefined && object.isShown && object.editType !== 'None'
      )
    },
    openPrinting (type, prospect = null) {
      this.printSelectionDialogOpen = false
      if (type === 'rental-offer') {
        this.$emit('setProcess', prospect)
        this.rentalOfferOpen = true
      }
      else {
        this.$emit('print', type)
        this.$emit('setProcess', prospect)
        this.printType = ''
      }
    },
    openRentalProcessAttachDialog () {
      this.rentalProcessAttachDialogOpen = true
    },
    openAddUnits (process, type) {
      this.$emit('setProcess', process)
      this.printType = type
      this.openRentalProcessAttachDialog()
    },
    openPrintSelectionDialog () {
      this.printSelectionDialogOpen = true
    },
    updateUnit () {
      this.$emit('update')
    },
    closePrintSelectionDialog () {
      this.printSelectionDialogOpen = false
    },
    closeRentalProcessAttachDialog () {
      this.rentalProcessAttachDialogOpen = false
    },
    closeAddUnitDialog (printing) {
      this.closeRentalProcessAttachDialog()
      if (this.selectedProcess) {
        if (printing) {
          this.openPrinting(this.printType, this.selectedProcess)
        }
        else {
          this.$emit('setProcess', null)
        }
      }
    },
    closeRentalOffer () {
      this.rentalOfferOpen = false
      this.$emit('setProcess', null)
    },
    toggleSuccessTooltip (newProcess) {
      if (this.selectedProcess) {
        this.$emit('setProcess', newProcess)
      }
      this.showSuccess = true
      setTimeout(() => this.showSuccess = false, 3000)
    },
    async saveRentalOffer () {
      // Get units for prospect printout saving
      const unitIds = this.selectedUnitsFromViewer.map(unit => unit.id)
      const unitProspectIds = []
      this.selectedProspect.sites.forEach(site => {
        site.units.forEach(unit => {
          if (unitIds.includes(unit.id_unit)) {
            unitProspectIds.push(unit.idProspectUnit)
          }
        })
      })
      const type = 2
      const data = {
        type: type,
        units: unitProspectIds,
        downloaded: this.currentDate,
        id_prospect: this.selectedProspect.id_prospect
      }
      await this.$rambollfmapi.leasing.prospects.printout(data)
      /*
        Rental offer saving disabled until knowit API is available
      */
      // Add printout id to rental offer and save rental offer
      // offer['id_prospect_printout'] = printout.idProspectPrintout
      // const returnOffer = await this.postRentalOffer(offer)
      this.closeRentalOffer()
      this.getProspects()
    },
    updateProspectData () {
      this.$emit('loadParties')
    },
    async onPatch (item, time, itemPatch) {
      await this.$rambollfmapi.units.patch(item, time, itemPatch);
      this.$emit('update')
    },
    onPopup (modifiedUnits, path, componentName){
      this.modifiedUnits = modifiedUnits

      const w = document.getElementById(componentName).clientWidth
      const h = document.getElementById(componentName).clientHeight + 1

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height

      const systemZoom = width / window.screen.availWidth
      const left = (width - w) / 2 / systemZoom
      const top = (height - h) / 2 / systemZoom

      const baseURL = window.location.href.replace(this.$route.path, '')
      window.addEventListener('message', this.receivePopupMessage)
      this.popupWindow = window.open(
        `${baseURL}/${path}/`,
        `myWindow${new Date()}`,
        `width=${w}, height=${h}, top=${top}, left=${left}`
      )
    },
    infoText () {
      const customer = this.settings.commercialInfoCustomer ?? ""
      switch (customer) {
        case "EQ":
          return this.$t('Commercial_data_modify_info_eq')
        default:
          return this.$t('Commercial_data_modify_info_basic')
      }
    },
    receivePopupMessage (event){
      if (event.data == 'READY_UNIT') {
        this.windowReady = true
        this.updateUnitPopupData(true)
      } else if (event.data == 'READY_MARKET') {
        this.windowReady = true
        this.updateMarketPopupData(true)
      } else if (event.data == 'READY_COMMERCIAL'){
        this.windowReady = true
        this.updateCommercialPopupData()
      } else if (event.data == 'UPDATE'){
        this.$emit('update')
      }
    },
    updateUnitPopupData (sendPreModifiedData) {
      let unitData = {
        unitRentStates: this.unitRentStates,
        unitUsages: this.unitUsages,
        unitApartments: this.unitApartments,
        unitKitchens: this.unitKitchens,
        unitBalconies: this.unitBalconies,
        unitAdditionalInfos: this.unitAdditionalInfos,
        unitMarketingInfo: this.unitMarketingInfo,
        modifiedUnits: sendPreModifiedData ? this.modifiedUnits : {},
        unitPartyLinks: this.unitPartyLinks,
        modificableUnits: this.modificableUnits,
        selectedSpacesFromViewer: this.selectedSpacesFromViewer,
        readonly: this.readonly,
        apartmentsActive: this.showUnitProp('apartment').isShown,
        unitVisibilityRights: this.unitVisibilityRights,
        rightForTIExpense: this.hasApplicationPermissionByName('ULKOINENVUOKRAUS'),
        targetGoal: this.targetGoal
      }

      this.popupWindow.postMessage(unitData)
    },
    updateMarketPopupData () {
      let marketInfoData = {
        units: this.modificableUnitsForMarketingInfo,
        readonly: this.readonly,
        marketingUnitUsages: this.marketingUnitUsages,
        rentalAttributes: this.rentalAttributes,
        technicalAttributes: this.technicalAttributes,
        unitCondition: this.unitCondition,
        moveReadiness: this.moveReadiness,
        waterMeter: this.waterMeter,
        electricityMeter: this.electricityMeter,
        targetGoal: this.targetGoal
      }

      this.popupWindow.postMessage(marketInfoData)
    },
    updateCommercialPopupData () {
      let commercialInfoData = {
        units: this.modificableUnits,
      }
      this.popupWindow.postMessage(commercialInfoData)
    },
    openContract (contract) {
      this.contractId = contract.id_contract
      this.isOutRent = contract.is_out_rent
      this.showCreateRentContractModal = true
    },
    onCreateRentContractModalClosed () {
      this.contractId = undefined
      this.showCreateRentContractModal = false
    },
  }
}
</script>
<style scoped>
#success-card {
  position: fixed;
  z-index: 299;
  bottom: 0.5em;
  left: 0;
  background-color: transparent;
}
.success-tile {
  background-color: #4caf50;
  border-top-right-radius: 5em;
  border-bottom-right-radius: 5em;
  margin-top: 0.5em;
  padding: 0.8em;
  color: white;
  display: block;
  padding-right: 1em;
  cursor: pointer;
}
.success-tile:hover {
  background-color: #4bce49;
}
.success-tile-content {
  display: inline-block;
}
.success-tile-icon {
  color: white;
}
</style>
