<template>
  <BaseModal
    @cancel="close()"
  >
    <template #title>
      <h2 class="text-h5">
        {{ $t('Vuokralaisen tiedot muissa sopimuksissa') }}
      </h2>
    </template>
    <template #content>
      <table
        class="v-data-table bordered"
        :style="{ width: '100%'}"
      >
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('Contract number') }}
            </th>
            <th class="text-left">
              {{ $t('Tenant') }}
            </th>
            <th class="text-left">
              {{ $t('create_rent_contract.tenant_identity_number') }}
            </th>
            <th class="text-left">
              {{ $t('create_rent_contract.description') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in idCheckResults"
            :key="index"
          >
            <td>
              {{ item.contractNumber }}
            </td>
            <td>
              {{ item.tenantName }}
            </td>
            <td>
              {{ item.tenantIdentityNumber }}
            </td>
            <td>
              {{ $t(item.tenantIdCheckMessage) }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </BaseModal>
</template>
<script>
import BaseModal from '../../../general/BaseModal.vue'

export default {
  name: 'IdCheckResultsModal',
  components: {
    BaseModal,
  },
  props: {
    idCheckResults: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.bordered {
  border-collapse: collapse;
  border: 1px solid var(--r-color-border);
}
</style>