<template>
  <v-row v-if="modifiedSpace !== null">
    <v-dialog
      v-if="selectedSpace"
      v-model="userDialog"
      class="user-edit-dialog"
      max-width="800"
    >
      <v-card>
        <v-btn
          large
          icon
          right
          absolute
          style="margin-top: 1em"
          @click="userDialog = false"
        >
          <v-icon>close</v-icon>
          <span class="d-sr-only">{{ $t('Close') }}</span>
        </v-btn>
        <v-card-title
          class="text-h5 grey lighten-2"
          primary-title
        >
          {{
            $t('Edit users of space')
          }}
        </v-card-title>
        <table
          class="v-datatable v-data-table v-table them--light"
          style="margin-top: 1em; margin-bottom: 1em"
        >
          <thead>
            <th
              class="column"
              style="padding: 0"
            />
            <th class="column">
              {{ $t('Space user') }}
            </th>
            <th class="column">
              {{ $t('Cost center') }}
            </th>
            <th class="column">
              {{ $t('Actions') }}
            </th>
          </thead>
          <tbody>
            <tr
              v-for="user in users"
              :key="user.id"
            >
              <th
                v-if="userRemoved(user)"
                class="error icon-column"
              >
                <v-icon>remove</v-icon>
              </th>
              <th v-else />
              <th>{{ user.party.name }}</th>
              <td>{{ user.party.cost_center }}</td>
              <td>
                <v-btn
                  v-if="!userRemoved(user)"
                  text
                  rounded
                  class="error"
                  @click="removeUser(user)"
                >
                  {{ $t('Delete') }}
                </v-btn>
                <v-btn
                  v-if="userRemoved(user)"
                  text
                  rounded
                  class="primary"
                  @click="removeUser(user)"
                >
                  {{ $t('Undo') }}
                </v-btn>
              </td>
            </tr>
            <!-- Users that should be added -->
            <tr
              v-for="user in addedUsers"
              :key="user.id"
            >
              <th class="primary">
                <v-icon>add</v-icon>
              </th>
              <th>{{ user.name }}</th>
              <td>{{ user.cost_center }}</td>
              <td>
                <v-btn
                  text
                  rounded
                  class="error"
                  @click="removeAddedUser(user)"
                >
                  {{
                    $t('Delete')
                  }}
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
        <br>
        <v-card-title class="text-h6 lighten-2">
          {{
            $t('Add new user to space')
          }}
        </v-card-title>
        <v-combobox
          v-model="selectedUser"
          :items="assignablePersons"
          clearable
          item-value="id"
          item-text="name"
        >
          <template #selection="data">
            {{ data.item.name }} - {{ data.item.cost_center }}
            <v-icon v-if="isAssigned(data.item)">
              assignment_ind
            </v-icon>
          </template>
          <template #item="data">
            {{ data.item.name }} - {{ data.item.cost_center }}
            <v-icon v-if="isAssigned(data.item)">
              assignment_ind
            </v-icon>
          </template>
        </v-combobox>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="selectedUser === null"
            rounded
            depressed
            color="primary"
            @click="addUser()"
          >
            {{ $t('Add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row
      v-if="modifiedSpace !== undefined"
      justify="space-between"
    >
      <v-row
        class="flex-column"
        justify="start"
      >
        <v-autocomplete
          v-if="
            hasInternalRentPermission
          "
          v-model="
            modifiedSpace.costcenterInsteadOfUser
          "
          :label="$t('Cost center')"
          :items="costcenters"
          :placeholder="space.costcenterInsteadOfUser"
          item-text="name"
          item-value="id"
          clearable
          @input="selectCostcenterInsteadOfUser(space)"
        />
        <v-row
          v-for="(userListItem, key) in getAllUsersForSpace(
            space
          )"
          :key="key"
        >
          <div v-if="userListItem.entries.length > 0">
            <b>{{ $t(userListItem.label) }}:</b>
            {{
              userListItem.entries.length > 3
                ? userListItem.entries.slice(0, 3).join(', ') +
                  '...'
                : userListItem.entries.join(', ')
            }}
            <br>
          </div>
          <br>
        </v-row>
      </v-row>
      <v-row
        v-if="!modifiedSpace.costcenterInsteadOfUser"
        class="flex-column"
        justify="end"
      >
        <v-icon
          @click="
            userDialog = true
            selectedSpace = space
          "
        >
          edit
        </v-icon>
      </v-row>
    </v-row>
  </v-row>
</template>

<script>
export default {
  name: 'SpaceUserModifyForm',
  props: {
    space: {
      type: Object,
      default: null
    },
    users: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: null
    },
    hasInternalRentPermission: {
      type: Boolean,
      default: false
    },
    links: {
      type: Array,
      default: () => []
    },
    people: {
      type: Array,
      default: () => []
    },
    costcenters: {
      type: Array,
      default: () => []
    },
    parties: {
      type: Array,
      default: () => []
    },
    linksInSomeSpace: {
      type: Array,
      default: () => []
    },
  },
  emits: ['change'],
  data () {
    return {
      deletedLinks: [],
      addedUsers: [],
      userDialog: false,
      selectedSpace: null,
      selectedUser: null
    }
  },
  computed: {
    modifiedSpace: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    assignablePersons () {
      const date = new Date(this.$store.state.app.currentDate).toISOString().substring(0, 10)
      const validOptions = []
      const persons = this.parties.filter(p => p.type === 1)

      // below inspections need undefined check alongside them so nothing breaks
       // even when inspected variables aren't added to party model data or database yet.

      // inspect if party start_date is defined and then check for end_date value.
      // if both values are present, check that start_date and end_date are valid.
       // if no end_date is defined, just check that start_date is valid.
      // if no start_date has been defined, we'll assume it's a party without date values
       // and add it to the returned data
      persons.forEach(p => {
        if (p.start_date !== undefined && p.start_date !== null) {

          const startDate = new Date(p.start_date).toISOString().substring(0, 10)

          if (p.end_date !== undefined && p.end_date !== null) {

            const endDate = new Date(p.end_date).toISOString().substring(0, 10)

            if (startDate <= date && endDate > date) {
              validOptions.push(p)
            }
          } else {
            if (startDate <= date) {
              validOptions.push(p)
            }
          }
        } else {
          validOptions.push(p)
        }
      })

      return validOptions
    }
  },
  methods: {
    update () {
      const newValues = {}
      newValues.spaceId = this.space.id
      newValues.addedUsers = JSON.parse(JSON.stringify(this.addedUsers))
      newValues.deletedLinks = JSON.parse(JSON.stringify(this.deletedLinks))
      this.$emit('change', newValues)
    },
    addUser () {
      if (this.selectedUser !== null && this.selectedUser !== undefined) {
        if (
          this.addedUsers.filter(
            u => u.id === this.selectedUser.id
          ).length > 0
        ) {
          // If user has already been added, do nothing
        } else {
          this.addedUsers.push(this.selectedUser)
        }
        this.$nextTick(() => {
          this.selectedUser = null
        })
      }
      this.update()
    },
    userRemoved (userLink) {
      if (this.deletedLinks.find(link => link.id === userLink.id)) {
        return true
      } else {
        return false
      }
    },
    removeUser (userLink) {
      if (this.deletedLinks.find(link => link.id === userLink.id)) {
        this.deletedLinks = this.deletedLinks.filter(
          link => link.id !== userLink.id
        )
      } else {
        this.deletedLinks.push(userLink)
      }
      this.update()
    },
    removeAddedUser (user) {
      this.addedUsers = this.addedUsers.filter(add => add.id !== user.id)
      this.update()
    },
    isAssigned (party) {
      return this.linksInSomeSpace.includes(party.id)
    },
    selectCostcenterInsteadOfUser (space) {
      if (this.modifiedSpace.costcenterInsteadOfUser) {
        // Clear away users that are marked as to be added.
        this.addedUsers = []
        this.update()
      }
      // Mark user links to delete.
      for (const user of this.getUsersForSpace(space)) {
        this.removeUser(user)
      }
    },
    getUsersForSpace (space) {
      const linksInSpace = this.links.filter(l => l.id_parent === space.id)
      const userList = linksInSpace.reduce((acc, cur) => {
        cur.party = this.people.find(p => p.id === cur.id_party)
        acc.push(cur)
        return acc
      }, [])
      userList.sort((a, b) => {
        if (a.party.name < b.party.name) return -1
        else return 1
      })
      return userList
    },
    getAllUsersForSpace (space) {
      const allUsers = {
        newUsers: {
          label: 'New users of space',
          entries: []
        },
        currentUsers: {
          label: 'Current users of space',
          entries: []
        },
        removedUsers: {
          label: 'Removed users of space',
          entries: []
        }
      }

      const linksInSpace = this.links.filter(l => l.id_parent === space.id)
      allUsers.currentUsers.entries = linksInSpace.reduce((acc, cur) => {
        const userName = this.people.find(p => p.id === cur.id_party).name
        if (this.userRemoved(cur)) {
          allUsers.removedUsers.entries.push(userName)
        }
        acc.push(userName)
        return acc
      }, [])

      allUsers.currentUsers.entries.sort()

      allUsers.newUsers.entries = this.addedUsers.map(
        user => user.name
      )
      return allUsers
    }
  }
}
</script>

<style scoped>
</style>