!<template>
  <div
    id="marketingInfo"
  >
    <ViewAndSelections
      parent-level="marketing"
      :contract-level="pageVersion"
      :loading-as-prop="loading"
      @close-filter="showColumnsFilterDialog"
    >
      <template #buttons>
        <BaseView>
          <template #selections>
            <v-select
              v-model="selectedMarketingLevel"
              :items="marketingLevels"
              item-text="text"
              item-value="value"
              return-object
              :disabled="loading"
              class="mb-2"
            />
          </template>
          <template #buttons>
            <v-btn
              small
              outlined
              rounded
              :disabled="loading"
              @click="showColumnsFilterDialog"
            >
              {{ $t('Choose visible columns') }}
            </v-btn>
            <v-btn
              small
              outlined
              rounded
              :loading="exporting.documentType == 'pdf'"
              :disabled="Object.keys(exporting).length > 0 || loading || viewData.length === 0"
              @click="exportAs('pdf')"
            >
              {{ $t('Export to PDF') }}
            </v-btn>
            <v-btn
              small
              outlined
              rounded
              :loading="exporting.documentType == 'csv'"
              :disabled="Object.keys(exporting).length > 0 || loading || viewData.length === 0"
              @click="exportAs('csv')"
            >
              {{ $t('Export to spreadsheet') }}
            </v-btn>
          </template>
        </BaseView>
      </template>
      <template
        #table="props"
      >
        <Table
          v-show="!loading"
          :rows="viewData"
          :headers="viewHeaders"
          :footers="createFooters"
          :hide="true"
          :show-filter="showColumnsFilter"
          :document-type="exportAsDocument"
          :pagination.sync="tablePagination"
          :show-save-selection="true"
          :selected-view-string="props.props.storedView ? props.props.storedView.name : null"
          :stored-views="props.props.storedViews.filter(sv => sv.level === pageVersion)"
          :show-confirm="props.props.confirmation"
          class="mx-4"
          @showFilterDialog="showColumnsFilterDialog"
          @exported="exporting = {}"
          @select_view="props.props.selectView"
          @save-selection="props.props.saveStoredView"
          @delete-view="props.props.deleteSelectedView"
        />
      </template>
    </ViewAndSelections>
  </div>
</template>

<script>
import Table from '../components/Table.vue'
import moment  from 'moment'
import { mapActions, mapState, mapGetters } from 'vuex'
import ViewAndSelections from '../components/Contracts/ViewAndSelections.vue'
import helpers from '../helpers'
import BaseView from '../components/general/BaseView.vue'

export default {
  name: 'MarketingInfo',
  components: {
    Table,
    BaseView,
    ViewAndSelections
  },
  metaInfo () {
    return {
      title: `${this.$t('Marketing information')} ·`,
    }
  },
  data () {
    return {
      loading: true,
      marketingLevels: [
        {text: this.$t('Rentable units marketing information'), value: 'units'},
        {text: this.$t('Site marketing information'), value: 'sites'}
      ],
      selectedMarketingLevel: {text: this.$t('marketing_info_units'), value: 'units'},
      pageVersion: "units",
      unitData: [],
      siteData: [],
      unitDataPromise: null,
      siteDataPromise: null,
      viewData: [],
      viewHeaders: [],
      showColumnsFilter: false,
      tablePagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['site_name', 'name'],
      },
      exportAsDocument: [],
      exporting: {},
    }
  },
  computed: {
    ...mapState('app', ['sites', 'currentDate']),
    ...mapState('leasing', ['prospects']),
    ...mapGetters('app', ['definitionsByGroupLabel', 'hasApplicationPermissionByName']),

    // We load needed definitions locally for improved performance
    unitDefinitions () {
      return this.definitionsByGroupLabel('unit.unit_condition')
        .concat(
          this.definitionsByGroupLabel('unit.move_readiness'),
          this.definitionsByGroupLabel('unit.meterstatus'),
          this.definitionsByGroupLabel('unit.technical_attributes'),
          this.definitionsByGroupLabel('unit.renting_attributes'),
          this.definitionsByGroupLabel('unit.usage_types'),
          this.definitionsByGroupLabel('webvisibility.status')
        )
    },
    siteDefinitions () {
      return this.definitionsByGroupLabel('site.purposeofuse')
        .concat(
          this.definitionsByGroupLabel('site.sitestatus'),
          this.definitionsByGroupLabel('building.energy'),
          this.definitionsByGroupLabel('site.solarenergy'),
          this.definitionsByGroupLabel('site.contracttype'),
          this.definitionsByGroupLabel('unit.usage')
        )
    }
  },
  watch: {
    sites: function (newValue) {
      this.loading = true
      if(newValue.length > 0) {
        this.getViewData(this.selectedMarketingLevel.value)
        this.loading = false
      }
    },
    // Get new data when current date changes
    currentDate: async function () {
      this.loading = true
      await this.loadData()
      if (this.sites.length !== 0) {
        await this.getViewData(this.selectedMarketingLevel.value)
        this.loading = false
      }
    },
    selectedMarketingLevel: function (newValue) {
      this.loading = true
      // set time out so loading text will display while new data is being formed (100ms is enough time for v-select animation to complete)
      setTimeout(() => {
        this.getHeaders(newValue.value)
        this.getViewData(newValue.value)
        this.loading = false
      }, 100)
    }
  },
  async mounted () {
    if (!this.hasApplicationPermissionByName('MARKKINOINTI')) {
      this.$router.push({ name: 'dashboard' })
    } else {
      this.getHeaders('units')
      this.loading = true
      this.getProspects()
      this.loadData()
      // If sites haven't loaded yet, don't get view data yet.
      // SIDE-EFFECT: If sites have loaded and they're empty array, loading icon will stay indefinitely (shouldn't be relevant, no data to show anyway)
      if (this.sites.length !== 0) {
        await this.getViewData('units')
        this.loading = false
      }
    }

  },
  methods: {
    ...mapActions('leasing', ['getProspects']),
    async loadData () {
      // We use promises so we don't have to wait for info for both levels to load before showing the other
      this.unitDataPromise = this.$rambollfmapi.units.marketingInformation(this.currentDate).then(res => {
        if (res.name == null) {
          this.unitData = res.map(unit => {
            return {
              ...unit,
              not_rentable: this.$t(unit.not_rentable),
              rental_status: this.$t(this.getUnitRentalStatus(unit)),
              master_unit_code: unit.master_unit ? this.getUnitCodeById(unit.master_unit, res) : this.$t('master_unit.not_merged'),
              sub_units_string: unit.sub_units.length > 0 ? this.getSubUnits(unit, res) : '',
              linked_photos: unit.linked_photos.map(photo => {
                return {
                  value: photo.filename,
                  type: 'Hyperlink',
                  url: photo.publicId ? 'https://api3.rambollfm.fi/v3.1/photos/public/' + photo.publicId : null
                }
              }),
              usage_types_string: this.changeDefinitionIdsToLabels(unit.usage_types, 'units'),
              renting_attributes_string: this.changeDefinitionIdsToLabels(unit.renting_attributes, 'units'),
              technical_attributes_string: this.changeDefinitionIdsToLabels(unit.technical_attributes, 'units'),
              unit_condition_string: this.getDefinitionLabelById(unit.unit_condition, 'units'),
              move_readiness_string: this.getDefinitionLabelById(unit.move_readiness, 'units'),
              water_meter_string: this.getDefinitionLabelById(unit.water_meter, 'units'),
              electricity_meter_string: this.getDefinitionLabelById(unit.electricity_meter, 'units'),
              reservation_status: this.getReservationStatus(unit),
              campaign_active_string: unit.campaign_active ?  this.$t('Yes') : this.$t('No'),
              web_visibility_status_string: this.getDefinitionLabelById(unit.web_visibility_status, 'units')
            }
          })
        }
      })
      this.siteDataPromise = this.$rambollfmapi.sites.marketingInformation(this.currentDate).then(res => {
        if (res.name == null) {
          this.siteData = res.map(site => {
            return {
              ...site,
              site_classification_string: this.getDefinitionLabelById(site.site_classification, 'sites'),
              site_status_string: this.getDefinitionLabelById(site.site_status, 'sites'),
              energy_class_string: this.getDefinitionLabelById(site.energy_class, 'sites'),
              solar_plant_string: this.getDefinitionLabelById(site.solar_plant, 'sites'),
              rent_type_string: this.getDefinitionLabelById(site.rent_type, 'sites'),
              lobby_service_string: site.lobby_service ?  this.$t('Yes') : this.$t('No'),
              lobby_service_automated_string: site.lobby_service_automated ?  this.$t('Yes') : this.$t('No'),
              information_point_string: site.information_point ? this.$t('Yes') : this.$t('No'),
              rentable_meeting_string: site.rentable_meeting ? this.$t('Yes') : this.$t('No'),
              auditorium_string: site.auditorium ? this.$t('Yes') : this.$t('No'),
              lunch_onsite_string: site.lunch_onsite ? this.$t('Yes') : this.$t('No'),
              catering_string : site.catering ? this.$t('Yes') : this.$t('No'),
              sauna_string : site.sauna ? this.$t('Yes') : this.$t('No'),
              gym_string : site.gym ? this.$t('Yes') : this.$t('No'),
              social_spaces_string : site.social_spaces ? this.$t('Yes') : this.$t('No'),
              common_spaces_string : site.common_spaces ? this.$t('Yes') : this.$t('No'),
              passenger_elevator_string : site.passenger_elevator ? this.$t('Yes') : this.$t('No'),
              freight_elevator_string : site.freight_elevator ? this.$t('Yes') : this.$t('No'),
              loading_platform_string : site.loading_platform ? this.$t('Yes') : this.$t('No'),
              site_cooling_string: site.site_cooling ? this.$t('Yes') : this.$t('No'),
              daycare_string : site.daycare ? this.$t('Yes') : this.$t('No'),
              hairdresser_string : site.hairdresser ? this.$t('Yes') : this.$t('No'),
              mostly_unobstructed_string : site.mostly_unobstructed ? this.$t('Yes') : this.$t('No'),
              telecommunications_string : site.telecommunications ? this.$t('Yes') : this.$t('No'),
              cycle_storage_string : site.cycle_storage ? this.$t('Yes') : this.$t('No'),
              campaign_active_string : site.campaign_active ? this.$t('Yes') : this.$t('No'),
              green_heating_string : site.green_heating == null ? '-' : site.green_heating ? this.$t('Yes') : this.$t('No'),
              green_electricity_string: site.green_electricity == null ? '-' : site.green_electricity ? this.$t('Yes') : this.$t('No'),
              geothermal_energy_string: site.geothermal_energy == null ? '-' : site.geothermal_energy ? this.$t('Yes') : this.$t('No'),
              rental_portal_main_image_string: site.rental_portal_main_image ? this.$t('Yes') : this.$t('No'),
              rental_portal_mood_image_string: site.rental_portal_mood_image ? this.$t('Yes') : this.$t('No')
            }
          })
        }
      })
    },
    async getViewData (type) {
      const siteIds = this.sites.map(site => site.id_site)
      if (type === 'units') {
        await this.unitDataPromise
        this.viewData = this.unitData.filter(unit => siteIds.includes(unit.id_site))
      }
      else {
        await this.siteDataPromise
        this.viewData = this.siteData.filter(site => siteIds.includes(site.id_site))
      }
    },
    getHeaders (marketingLevel) {
      // We manually determine
      if (marketingLevel === 'units') {
        this.pageVersion = "units"
        this.viewHeaders = [
          { value: 'site_name', text: 'Site name' },
          { value: 'unit_code_long', text: 'Unit code long' },
          { value: 'unit_code', text: 'Unit' },
          { value: 'area', text: 'Net floor area', format: 'Area' },
          { value: 'usage', text: 'Unit use', format: 'Translation' },
          { value: 'not_rentable', text: 'Unit rental status' },
          { value: 'rental_status', text: 'Rental Status' },
          { value: 'master_unit_code', text: 'Master unit' },
          { value: 'sub_units_string', text: 'Sub units' },
          { value: 'web_visibility_status_string', text: 'Web visibility status' },
          { value: 'advertising_phrase', text: 'Advertising phrase' },
          { value: 'introduction', text: 'Introduction' },
          { value: 'video_link', text: 'Video link' },
          { value: 'panorama_link', text: 'Panorama link' },
          { value: 'linked_photos', text: 'Linked photos' },
          { value: 'usage_types_string', text: "Usage types" },
          { value: 'renting_attributes_string', text: 'Renting attributes' },
          { value: 'technical_attributes_string', text: 'Technical attributes' },
          { value: 'unit_condition_string', text: 'Unit condition' },
          { value: 'move_readiness_string', text: 'Move readiness' },
          { value: 'water_meter_string', text: 'Water meter' },
          { value: 'electricity_meter_string',text: 'Electricity meter' },
          { value: 'other_utilities', text: 'Other utilities'},
          { value: 'campaign_active_string', text: 'Campaign active'},
          { value: 'campaign_last_date', text: 'Campaign last date', format: 'Date' },
          { value: 'campaign_description', text: 'Campaign description' },
          { value: 'reservation_status', text: 'Reservation status' }
        ]
      }
      else {
        this.pageVersion = "sites"
        this.viewHeaders = [
          { value: 'name', text: 'Site name' },
          { value: 'commercial_name', text: 'Commercial name' },
          { value: 'site_classification_string', text: 'Site classification' },
          { value: 'site_status_string', text: 'Site status' },
          { value:'site_state', text: 'Site state' },
          { value:'ownership_status', text: 'Ownership status' },
          { value:'lobby_service_string', text: 'Lobby service' },
          { value:'lobby_service_automated_string', text: 'Lobby service automated' },
          { value:'information_point_string', text: 'Information point' },
          { value:'rentable_meeting_string', text: 'Rentable meeting' },
          { value:'auditorium_string', text: 'Auditorium' },
          { value:'lunch_onsite_string', text: 'Lunch onsite' },
          { value:'catering_string', text: 'Catering' },
          { value:'sauna_string', text: 'Sauna' },
          { value:'gym_string', text: 'Gym' },
          { value:'social_spaces_string', text: 'Social spaces' },
          { value:'common_spaces_string', text: 'Common spaces' },
          { value:'passenger_elevator_string', text: 'Passenger elevator' },
          { value:'freight_elevator_string', text: 'Freight elevator' },
          { value:'loading_platform_string', text: 'Loading platform' },
          { value:'site_cooling_string', text: 'Site cooling' },
          { value:'daycare_string', text: 'Daycare' },
          { value:'hairdresser_string', text: 'Hairdresser' },
          { value:'mostly_unobstructed_string', text: 'Mostly unobstructed' },
          { value:'telecommunications_string', text: 'Telecommunications' },
          { value:'cycle_storage_string', text: 'Cycle storage' },
          { value:'site_headline', text: 'Site headline' },
          { value:'marketing_phrase', text: 'Marketing phrase' },
          { value:'site_desc', text: 'Site desc' },
          { value:'consumer_website', text: 'Consumer website' },
          { value:'facebook_site', text: 'Facebook site' },
          { value:'other_website', text: 'Other website' },
          { value:'campaign_active_string', text: 'Campaign active' },
          { value:'campaign_last_date', text: 'Campaign last date', format: 'Date' },
          { value:'campaign_description', text: 'Campaign description' },
          { value:'energy_certificate_last_date', text: 'Energy certificate last date', format: 'Date' },
          { value:'energy_class_string', text: 'Energy class' },
          { value:'green_heating_string', text: 'Green heating' },
          { value:'green_electricity_string', text: 'Green electricity' },
          { value:'solar_plant_string', text: 'Solar plant' },
          { value:'geothermal_energy_string', text: 'Geothermal energy', },
          { value:'rent_priority', text: 'Rent priority' },
          { value:'wwwClass', text: 'WwwClass' },
          { value:'rent_type_string', text: 'Rental contract type' },
          { value:'new_lease', text: 'New lease' },
          { value:'upkeep_rent', text: 'Upkeep rent' },
          { value:'time_to_train_station', text: 'Time to train station', format: 'Double' },
          { value:'time_to_metro_station', text: 'Time to metro station', format: 'Double' },
          { value:'time_to_tram_station', text: 'Time to tram station', format: 'Double' },
          { value:'time_to_bus_stop', text: 'Time to buss stop', format: 'Double' },
          { value:'time_to_airport', text: 'Time to airport', format: 'Double' },
          { value:'rental_portal_main_image_string', text: 'photos.photoInRentalPortalMainImage'},
          { value:'rental_portal_mood_image_string', text: 'photos.photoInRentalPortalMoodImage'}
        ]
      }
    },
    showColumnsFilterDialog () {
      this.showColumnsFilter = !this.showColumnsFilter
    },
    createFooters () {
     return []
    },
    getUnitRentalStatus (unit) {
      moment.locale('fi')
      const targetDate = moment(unit.contract_end_date)

      const threeMonthsToGo = moment(this.currentDate)
        .add(3, 'months')
        .calendar()
      const sixMonthsToGo = moment(this.currentDate)
        .add(6, 'months')
        .calendar()
      const nineMonthsToGo = moment(this.currentDate)
        .add(9, 'months')
        .calendar()
      const twelveMonthsToGo = moment(this.currentDate)
        .add(12, 'months')
        .calendar()

      if (!unit.tenant && (unit.not_rentable === 'rentable' || unit.not_rentable === 'rentable.koy')) {
        return this.$t('Empty unit')
      } else if (
        (unit.tenant && !unit.contract_end_date) ||
        (unit.tenant &&
          unit.contract_end_date &&
          targetDate.isAfter(moment(twelveMonthsToGo, 'DD.MM.YYYY'), 'day'))
      ) {
        return this.$t('Rented') + ' (' + this.$t('Valid over 12 months') + ')'
      } else if (
        unit.tenant &&
        unit.contract_end_date &&
        targetDate.isAfter(moment(nineMonthsToGo, 'DD.MM.YYYY'), 'day')
      ) {
        return this.$t('Rented') + ' (' + this.$t('Valid over 9 months') + ')'
      } else if (
        unit.tenant &&
        unit.contract_end_date &&
        targetDate.isAfter(moment(sixMonthsToGo, 'DD.MM.YYYY'), 'day')
      ) {
        return this.$t('Rented') + ' (' + this.$t('Valid over 6 months') + ')'
      } else if (
        unit.tenant &&
        unit.contract_end_date &&
        targetDate.isAfter(moment(threeMonthsToGo, 'DD.MM.YYYY'), 'day')
      ) {
        return this.$t('Rented') + ' (' + this.$t('Valid over 3 months') + ')'
      } else if (
        unit.tenant &&
        unit.contract_end_date &&
        targetDate.isBefore(moment(threeMonthsToGo, 'DD.MM.YYYY'), 'day')
      ) {
        return (
          this.$t('Rented') + ' (' + this.$t('Valid less than 3 months') + ')'
        )
      } else {
        return null
      }
    },
    getUnitCodeById (unitId, data) {
      let unit = data.find(item => item.id === unitId)
      if (unit) {
        return unit.unit_code
      }
      else {
        return ''
      }
    },
    getSubUnits (unit, data) {
      return unit.sub_units?.map(id => this.getUnitCodeById(id, data)).sort((a, b) => {
          if (a < b) {
            return -1
          }
          if (a > b) {
            return 1
          }
          return 0
        }).join(", ")
    },
    getDefinitionLabelById (key, type) {
      let definitions
      if (type === 'units') {
        definitions = this.unitDefinitions
      }
      else if (type === 'sites') {
        definitions = this.siteDefinitions
      }
      else {
        return this.$t('Undefined')
      }
      const def = definitions.find(item => item.id === key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? this.$t(def.label) : this.$t('Undefined')
    },
    changeDefinitionIdsToLabels (ids, type) {
      if (ids != null) {
        return ids.reduce((acc, curr) => acc += this.getDefinitionLabelById(curr, type) + ', ', '').slice(0, -2)
      }
    },
    getReservationStatus (unit) {
      const reserved = unit.reservation_end_date && moment(this.currentDate).isBefore(unit.reservation_end_date)
      const prevented = unit.preventation_end_date && moment(this.currentDate).isBefore(unit.preventation_end_date)
      const contractInAllocation = this.contractInAllocation(unit)
      const forcedPublishEnd = moment(unit.forced_publish_date).subtract(180, 'days')
      const publishingForced = unit.contract_end_date && unit.forced_publish_date && unit.contract_end_date === unit.forced_publish_date && moment(this.currentDate).isBefore(forcedPublishEnd)
      if (reserved) {
        return this.$t('Reserved\n') + ' (' + this.$t('reservation ends') + ' ' + helpers.humanize.date(unit.reservation_end_date) + ')'
      } else if (prevented) {
        return this.$t('Visibility prevented')
      } else if (contractInAllocation) {
        return this.$t('Contract in allocation') + ' (' + this.$t('ends after allocation') + ')'
      } else if (publishingForced) {
        return this.$t('Forced publish') + ' (' + this.$t('publishing ends') + ' ' + forcedPublishEnd.format('DD.MM.YYYY') + ')'
      } else {
        return this.$t('No reservations')
      }
    },
    exportAs (documentType) {
      this.exporting = {}
      this.exporting.documentType = documentType

      this.exportAsDocument = []
      this.exportAsDocument.push(documentType)
      this.exportAsDocument.push('market_info')
    },
    contractInAllocation (unit){
      const awaitingAllocationForSiteAndUnit = this.prospects.filter(prospect => prospect.status === 'AwaitingAllocation' && prospect.sites.filter(site => site.id_site === unit.id_site && site.units.filter(siteUnit => siteUnit.id_unit === unit.id).length).length)
      return Boolean(awaitingAllocationForSiteAndUnit.length)
    }
  }
}
</script>

<style scoped>
</style>