<template>
  <div>
    <AriaLive />
    <v-toolbar
      flat
      tile
    >
      <v-toolbar-title>
        <h1>{{ $t('Users') }}</h1>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          icon
          @click="
            dialog = true
            editedItem = newUser
            editing = false
          "
        >
          <v-icon>person_add</v-icon>
          <span class="d-sr-only">{{ $t('AddUser') }}</span>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-col
      v-if="hierarchyLoading"
      style="text-align: center"
    >
      <v-progress-circular
        :width="7"
        :size="80"
        indeterminate
        color="primary"
      />
      <h4>{{ $t('Loading. Please wait...') }}</h4>
    </v-col>
    <v-col
      v-else
      class="body-text"
    >
      <Table
        :rows="rows"
        :headers="headers"
        :show-controls="enable"
        :pagination="tablePagination"
        :show-rows-per-page-selection="true"
        @event_child="eventChild"
      />
      <v-dialog
        v-if="dialog"
        v-model="dialog"
        persistent
        width="550"
      >
        <v-card>
          <UserForm
            :parent-data="dialog"
            :user="editedItem"
            :editing="editing"
            :users="userData"
            :user-organizations="userOrganizations"
            :all-organizations="allOrganizations"
            :group-options="groupOptions"
            @childToParent="onChildClick"
            @refresh="refresh()"
            @saved="
              showIndicator = true
              operationResult = 'success'
            "
          />
        </v-card>
      </v-dialog>
    </v-col>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
    />
  </div>
</template>
<script>
import UserForm from '../components/UserForm.vue'
import Table from '../components/Table.vue'
import Alert from '../components/Alert.vue'
import helpers from '../helpers'
import AriaLive from '../components/AriaLive.vue'

export default {
  components: {
    UserForm: UserForm,
    Table,
    Alert: Alert,
    AriaLive
  },
  emits: ['close'],
  metaInfo () {
    return {
      title: `${this.$t('Users')} · ${this.$t('Settings')} ·`,
    }
  },
  data () {
    return {
      enable: true,
      filterValues: {},
      showVisibleColumnsDialog: false,
      rows: [],
      headers: [],
      userData: [],
      dialog: false,
      editedItem: null,
      editing: true,
      newUser: {
        username: null,
        password: null,
        name: '',
        telephone: null,
        company: null,
        address: null,
        title: null,
        comments: null,
        validUntil: '',
        email: null,
        userGroup: null,
        ssoAccount: false
      },
      vacanciesHeaders: [
        { text: this.$t('Name'), value: 'name' },
        { text: this.$t('Username'), value: 'username' },
        { text: this.$t('Email'), value: 'email' },
        { text: this.$t('Company'), value: 'company' },
        { text: this.$t('Valid until'), value: 'validUntil', format: 'Date' },
        { text: this.$t('Last login'), value: 'lastLogin', format: 'Time' },
        { text: this.$t('User group'), value: 'userGroupArr' }
      ],
      userArray: [],
      currentVisibility: false,
      valid: true,
      showIndicator: false,
      hierarchyLoading: true,
      operationResult: '',
      indicatorMessage: '',
      allOrganizations: [],
      userOrganizations: [],
      groupOptions: [],
      tablePagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['name'],
      },
    }
  },
  computed: {
    isLoading () {
      if (this.rows.length > 0) {
        return false
      }
      return true
    },
    filteredList () {
      return this.postList.filter(post => {
        return post.title.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  watch: {
    currentVisibility: function (value) {
      if (value === false) {
        this.$emit('close')
      }
    },
    visible: function (value) {
      this.currentVisibility = value
    },
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
    operationResult: function (value) {
      if (value === 'error') {
        this.indicatorMessage = this.$t('Unsuccesfull save')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_unsuccesfull'))
      }
      if (value === 'success') {
        this.indicatorMessage = this.$t('Succesfull save')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.new_user_added'))
      }
    }
  },
  async mounted () {
    this.userOrganizations = await this.$rambollfmapi.userOrganizations.user()
    this.allOrganizations = await this.$rambollfmapi.userOrganizations.list()
    this.getUsers()
    this.groupOptions = await this.$rambollfmapi.permissions.groups().list()
  },
  methods: {
    eventChild: function (item, method) {
      if (method === 'edit') {
        this.editItem(item)
      }
      if (method === 'delete') {
        this.deleteItem(item)
      }
    },
    deleteItem (item) {
      if (confirm('Are you sure you want to delete this item?')) {
        this.$rambollfmapi.accounts
          .delete(item.id)
          .then(response => {
            this.getUsers()
          })
          .catch(error => {
            this.$log.error(error)
          })
      }
    },
    hideIndicator () {
      this.showIndicator = false
    },
    onChildClick (value) {
      this.dialog = value
    },
    close () {
      this.currentVisibility = false
      this.editing = false
      this.$emit('close')
      this.getUsers()
    },
    editItem (item) {
      this.editedIndex = this.userData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem = helpers.format.unescapeHtmlFromObjectStrings(this.editedItem)
      this.editing = true
      this.dialog = true
    },
    refresh () {
      this.currentVisibility = false
      this.editing = false
      this.getUsers()
    },
    async getUsers () {
      this.hierarchyLoading = true
      this.userData = await this.$rambollfmapi.accounts.list()

      this.userArray = this.userData.map((value) => {
        value = helpers.format.escapeHtmlFromObjectStrings(value)
        value.userGroupArr = this.getuserGroupArr(value.userGroups)
        return value
      })
      this.rows = this.userArray
      this.headers = this.vacanciesHeaders
      this.hierarchyLoading = false
    },
    getuserGroupArr (data) {
      var userGroupArr = []
      for (var i = 0; i < data.length; i++) {
        var userGroup = data[i].groupName + ' [' + data[i].organisationName + ']'
        userGroupArr.push(helpers.format.escapeHtml(userGroup))
      }
      return userGroupArr
    }
  }
}
</script>
<style>
.fullscreen-settings {
  padding: 0px;
  height: 100%;
  width: 100%;
  right: 0;
  top: -111px;
  position: absolute;
  background-color: white;
}

.layout-white {
  background-color: white;
}

.form-fields {
  margin-top: 20px !important;
}

.activeButton {
  background: var(--c-color-accent) !important;
  color: white !important;
}
</style>
