<template>
  <td class="column">
    <template v-if="field.value === 'id_purpose'">
      <v-autocomplete
        v-model="model"
        clearable
        :items="selectablePurposes"
        item-text="title"
        item-value="id"
      />
    </template>

    <template v-else-if="field.value === 'clarified_purpose_of_use'">
      <v-text-field
        v-model="model"
        clearable
        type="text"
      />
    </template>

    <template v-else-if="field.value === 'purpose_zone'">
      <PurposeZoneInput
        v-model="model"
        :placeholder="placeholder"
      />
    </template>

    <template v-else-if="field.value === 'floor_material'">
      <v-select
        v-model="model"
        :items="floorMaterials"
        item-text="label"
        item-value="id"
      />
    </template>

    <template
      v-else-if="
        field.value === 'cleaning_area' || field.value === 'cleaning_area_low'
      "
    >
      <v-autocomplete
        v-model="model"
        clearable
        :items="cleaningAreas"
        item-text="description"
        item-value="name"
      />
    </template>

    <template v-else-if="field.value === 'fire_area'">
      <v-combobox
        v-model="model"
        clearable
        :items="fireAreas"
        item-text="name"
        item-value="name"
        @input.native="nativeInputCombo"
      />
    </template>

    <template v-else-if="field.value === 'movement_area'">
      <v-select
        v-model="model"
        :items="movementAreas"
        item-text="label"
        item-value="id"
      />
    </template>

    <template v-else-if="field.value === 'cleaning_frequency'">
      <v-select
        v-model="model"
        clearable
        :items="cleaningFrequencies"
        item-text="label"
        item-value="id"
      />
    </template>

    <template v-else-if="field.value === 'additional_cost'">
      <v-text-field
        v-model="model"
        type="number"
        clearable
        :placeholder="'-'"
      />
    </template>

    <template v-else-if="field.value === 'cleaning_details'">
      <v-textarea
        v-model="cleaningDetails"
        clearable
        counter
        maxlength="800"
        rows="4"
        type="text"
      />
    </template>

    <template
      v-else-if="field.value === 'shares' || field.value === 'user_cost_center'"
    >
      <v-row
        align="center"
        no-gutters
      >
        <CostcenterSharesField
          v-model="model"
          :costcenters="costcenters"
          @input="model = $event"
        />
      </v-row>
    </template>

    <template
      v-else-if="
        field.value === 'number_of_workstations' ||
          field.value === 'number_of_personnel' ||
          field.value === 'area'
      "
    >
      <v-text-field
        v-model="model"
        clearable
        type="number"
      />
    </template>

    <template
      v-else-if="
        field.value === 'meter_number' || field.value === 'classification'
      "
    >
      <v-text-field
        v-model="model"
        clearable
      />
    </template>

    <template v-else-if="field.value === 'space_name'">
      <v-text-field
        v-model="model"
        clearable
        type="text"
        :placeholder="
          spacePlaceholder !== null
            ? spacePlaceholder
            : getPlaceholderValue(placeholder)
        "
        @focus="clearField(placeholder)"
      />
    </template>

    <template v-else-if="field.value === 'name'">
      <v-text-field
        v-model="model"
        clearable
        type="text"
        :rules="[v => !!v || $t('Required')]"
        :placeholder="
          spacePlaceholder !== null
            ? spacePlaceholder
            : getPlaceholderValue(placeholder)
        "
      />
    </template>
    <template v-else-if="field.value === 'capacity_quantity'">
      <v-text-field
        v-model="model"
        clearable
        type="text"
        :placeholder="
          spacePlaceholder !== null
            ? spacePlaceholder
            : getPlaceholderValue(placeholder)
        "
      />
    </template>
    <template v-else-if="field.value === 'capacity_unit'">
      <v-select
        v-model="model"
        :items="capacityUnits"
        item-text="label"
        item-value="id"
        :placeholder="getDefinitionLabel(placeholder)"
      />
    </template>

    <template v-else-if="field.value === 'contract_number_sap'">
      <v-text-field
        v-model="model"
        type="text"
        :placeholder="getPlaceholderValue(placeholder)"
      />
    </template>

    <template v-else-if="field.value === 'shared_space_code'">
      <v-select
        v-model="model"
        :items="sharedSpaceCodes"
        item-text="label"
        item-value="id"
        :placeholder="getDefinitionLabel(placeholder)"
      />
    </template>

    <template
      v-else-if="
        field.value === 'space_category'
      "
    >
      <v-autocomplete
        v-model="model"
        clearable
        :items="spaceCategories"
        item-text="label"
        item-value="id"
        :placeholder="getDefinitionLabel(placeholder)"
      />
    </template>

    <template
      v-else-if="
        field.value === 'department'
      "
    >
      <v-autocomplete
        v-model="model"
        clearable
        :items="departments"
        item-text="label"
        item-value="id"
        :placeholder="getDefinitionLabel(placeholder)"
      />
    </template>

    <template
      v-else-if="
        field.value === 'item_area'
      "
    >
      <v-autocomplete
        v-model="model"
        clearable
        :items="itemAreas"
        item-text="label"
        item-value="id"
        :placeholder="getDefinitionLabel(placeholder)"
      />
    </template>

    <template v-else>
      {{ field.value }}
    </template>
  </td>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PurposeZoneInput from './PurposeZoneInput.vue'
import CostcenterSharesField from './CostcenterSharesField.vue'

export default {
  name: 'SpaceModifyField',
  components: {
    PurposeZoneInput,
    CostcenterSharesField,
  },
  props: {
    value: {
      type: [Object, Array, Number, String],
      default: undefined
    },
    field: {
      type: Object,
      default: null
    },
    placeholder: {
      type: [Object, Array, String, Number],
      default: null
    },
    purposesOfUse: {
      type: Array,
      default: () => []
    },
    cleaningAreas: {
      type: Array,
      default: () => []
    },
    fireAreas: {
      type: Array,
      default: () => []
    },
    costcenters: {
      type: Array,
      default: () => []
    },
    spaceCategories: {
      type: Array,
      default: () => []
    },
    departments: {
      type: Array,
      default: () => []
    },
    itemAreas: {
      type: Array,
      default: () => []
    },
    data: null
  },
  emits: ['input'],
  data () {
    return {
      spacePlaceholder: null,
      cleaningDetails: null
    }
  },
  computed: {
    ...mapGetters('app', ['definitionLabelById', 'definitionsByGroupLabel']),
    ...mapState('app', ['purposeZones']),
    model: {
      get () {
        return this.data
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    selectablePurposes () {
      return this.purposesOfUse.slice().filter(x => x.selectable)
    },
    placeholderValue () {
      if (this.placeholder === null || this.placeholder === undefined) {
        return '-'
      }
      return String(this.placeholder)
    },
    floorMaterials () {
      const values = this.definitionsByGroupLabel('space.floormaterial')
      return [{ id: null, label: this.$t('Undefined') }, ...values]
    },
    movementAreas () {
      const values = this.definitionsByGroupLabel('space.movementarea')
      return values
    },
    cleaningFrequencies () {
      const values = this.definitionsByGroupLabel('space.cleaning_frequency')
      return values
    },
    sharedSpaceCodes () {
      const values = this.definitionsByGroupLabel('space.sharedspacecode')
      return values
    },
    capacityUnits () {
      const values = this.definitionsByGroupLabel('space.capacityunits')
      return [{ id: null, label: this.$t('Undefined') }, ...values]
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (val) {
        if (this.field.value === 'cleaning_details') {
          this.cleaningDetails = val
        } else {
          this.model = val
        }
      }
    },
    cleaningDetails: {
      handler (val) {
        this.model = val
      }
    },
     data: {
      deep: true,
      immediate: true,
      handler (val) {
        if (this.field.value === 'cleaning_details') {
          this.cleaningDetails = val
        } else {
          this.model = val
        }
      }
    },
  },
  mounted () {
    if (this.field.value === 'cleaning_details') {
      this.cleaningDetails = this.placeholder
    }
  },
  methods: {
    nativeInputCombo (nativeEvent)
    {
      this.$emit('input', nativeEvent.target.value)
    },
    selectablePurposeZones (level) {
      if (level !== null) {
        return this.purposeZones.filter(pz => pz.level === level)
      }
      return this.purposeZones
    },
    getPlaceholderValue (value) {
      if (value === null || value === undefined) {
        return '-'
      } else if (!isNaN(value)) {
        return String(value)
      }
      return value
    },
    getDefinitionLabel (id) {
      // const found = this.floorMaterials.find(x => x.id === id)
      const found = this.definitionLabelById(id)
      return found
    },
    getPurposeName (id) {
      const found = this.purposesOfUse.find(x => x.id === id)
      if (found) {
        return found.title
      }
      return ''
    },
    getPurposeZoneName (id) {
      const found = this.purposeZones.find(pz => pz.id === id)
      if (found) {
        return found.name
      }
      return ''
    },
    clearField (field) {
      this.spacePlaceholder = ''
      this.model = []
    }
  }
}
</script>

<style scoped>
</style>
