<template>
  <div>
    <!-- Modals -->
    <v-dialog
      :value="shownProspect != null && showProspectModal"
      persistent
      width="700"
    >
      <v-card>
        <ProspectModal
          :prospect="shownProspect"
          :is-shown="showProspectModal"
          :user-info="userInfo"
          @update="updateLeasingWidgetData()"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      :value="shownCorporation && showCorporationModal"
      persistent
      width="700"
    >
      <v-card>
        <CorporationModal
          :corporation="shownCorporation"
          :rental-processes="rentalProcesses"
          :rental-statuses="allSitesRentalStatuses"
          :is-shown="showCorporationModal"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="renegotiations && hasApplicationPermissionByName('LEASING')"
      :value="renegotiations != null"
      persistent
      width="550"
    >
      <v-card>
        <renegotiations-modal-process
          :contract="renegotiations"
          :is-shown="renegotiations !== null"
          :user-info="userInfo"
          @update="updateLeasingWidgetData()"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-else-if="!!renegotiations"
      :value="renegotiations != null"
      persistent
      width="550"
    >
      <v-card>
        <RenegotiationsModal
          :contract="renegotiations"
          :is-shown="renegotiations !== null"
          :user-info="userInfo"
          @update="updateLeasingWidgetData()"
        />
      </v-card>
    </v-dialog>
    <div
      id="site-renting"
      class="mt-4 mx-4"
    >
      <sortable-widget-layout :widgets="widgets" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import SortableWidgetLayout from '../components/widgets/SortableWidgetLayout.vue'
import {
  Services,
  TrafficCommunication,
  EmptyRentalSpaces,
  UnitMarketRentStatus,
  CarParksAndParkingZonesWithRent,
  InformationForRentingFromMFilesCostcenter,
} from '../widgets/site'
import ProspectModal from '../components/Leasing/ProspectModal.vue'
import CorporationModal from '../components/Leasing/CorporationModal.vue'
import LeasingWidgets from '../widgets/leasing'
import RenegotiationsModal from '../components/Leasing/RenegotiationsModal.vue'
import RenegotiationsModalProcess from '../components/Leasing/RenegotiationsModalProcess.vue'

export default {
  name: 'Renting',
  components: {
    SortableWidgetLayout,
    ProspectModal,
    CorporationModal,
    RenegotiationsModal,
    RenegotiationsModalProcess
  },
  metaInfo () {
    return {
      title: `${this.$t('Renting')} ·`,
    }
  },
  data () {
    return {
      // Widgets
      widgets: [
        ...LeasingWidgets.SitesRentalContracts(),
        TrafficCommunication(),
        Services(),
        EmptyRentalSpaces(),
        UnitMarketRentStatus(),
        CarParksAndParkingZonesWithRent(),
        InformationForRentingFromMFilesCostcenter()
      ],
    }
  },
  computed: {
    ...mapState('sites', ['currentSiteId', 'loadedReports', 'loadedBuildings']),
    ...mapState('app', ['currentDate', 'userInfo', 'sites']),
    ...mapGetters('sites', [
      'reportForCurrentSite',
      'currentSite',
      'rentingForCurrentSite',
    ]),
    ...mapState('leasing', [
      'shownProspect',
      'showProspectModal',
      'shownCorporation',
      'showCorporationModal',
      'allSitesRentalStatuses',
      'prospectEvents',
      'renegotiations',
      'siteLeasingWidgetDataLoading'
    ]),
    ...mapState('rentalProcess', ['rentalProcesses']),
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapGetters('leasing', ['leasingWidgetDataForCurrentSite']),
  },
  watch: {
    currentDate: function (newDate) {
      const date = this.$store.state.app.currentDate
      if (
        new Date(newDate).toISOString().substr(0, 10) !==
        date.toISOString().substr(0, 10)
      ) {
        this.initChanges()
        this.updateWidgetData()
      }
    },
    loadedBuildings: {
      handler: async function () {
        // Make a copy of the currentSite
        this.$log.info('Current site changed. updating site info...')
        await this.initView()
      },
      deep: true,
    },
    loadedReports: function () {
      this.updateWidgetData()
    },
    siteLeasingWidgetDataLoading: function (newValue) {
      if (!newValue) {
        this.updateWidgetData()
      }
    },
  },
  async mounted () {
    if (!this.hasApplicationPermissionByName('KOHDE_VUOKRAUSLEHTI')) {
      this.$router.push({ name: 'sites.info' })
    }
    await this.initView()
  },
  methods: {
    ...mapActions('sites', ['loadSiteRentalReports']),
    ...mapActions('leasing', ['LoadLeasingSiteWidgetData', 'getAllProspectEvents']),
    ...mapActions('rentalProcess', ['getRentalProcess']),
    async initView () {
      if (this.currentSite) {
        this.loadSiteRentalReports({
          siteId: this.currentSiteId,
          widgets: this.widgets,
        })
        await this.getAllProspectEvents()
        this.getRentalProcess({})
        this.updateWidgetData()
        this.$store.commit('app/setSortableWidgets', false)
      }
    },
    updateWidgetData () {
      this.widgets.forEach((w) => {
        if (w.data.report !== undefined) {
          // if report is defined in widget, use the data from store
          w.data.items = this.reportForCurrentSite(w.data.report)
          if (w.data.items === null || w.data.items === undefined) {
            w.data.items = []
          } else {
            w.loading = false
          }
        } else if (w.data.source !== undefined && w.data.source !== null) {
          // if source is defined in widget, use the data from component
          w.data.items = this[w.data.source]
          w.loading = false
        } else if (w.id.startsWith('site.leasing.')) {
          w.data.items = this.leasingWidgetDataForCurrentSite(w.id)
          if (w.data.items === null || w.data.items === undefined) {
            w.data.items = []
            w.loading = true
          } else {
            w.loading = false
          }
        }

        // Set metadata
        if (w.data.metadataSource !== null) {
          w.data.metadata = this[w.data.metadataSource]
        } else if (
          w.data.metadataLabels !== null &&
          w.data.metadataLabels !== undefined
        ) {
          w.data.metadata = {}
          w.data.metadataLabels.forEach((label) => {
            w.data.metadata[label.value] = {
              isShown: true,
              category: w.data.category ? w.data.category : 'None',
              isDefinition: label.isDefinition ? label.isDefinition : false,
              format: label.format ? label.format : 'None',
            }
          })
        }
      })
    },
    async updateLeasingWidgetData () {
      // const leasingWidgets = this.widgets.filter(widget => widget.id.startsWith('site.leasing.'))
      // leasingWidgets.forEach(widget => widget.loading = true)
      await this.LoadLeasingSiteWidgetData()
    },
  },
}
</script>
<style scoped>
#site-renting {
  padding: 0.5em;
  padding-top: 0;
}

#site-renting > .col {
  padding: 0.5em;
  padding-top: 0;
}

#site-renting > .col > div:not(:first-child) > .v-expansion-panel {
  margin-top: 0.5em;
}

#site-renting .subpanel {
  margin: 0;
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: auto;
}

#site-renting .subpanel:not(:first-child) {
  margin-top: 0.5em;
}

#site-renting .subpanel:last-child {
  margin-bottom: 0.5em;
}

#site-renting .widget {
  padding-top: 1em;
}
</style>
