var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"column"},[(_vm.field.value === 'id_purpose')?[_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.selectablePurposes,"item-text":"title","item-value":"id"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'clarified_purpose_of_use')?[_c('v-text-field',{attrs:{"clearable":"","type":"text"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'purpose_zone')?[_c('PurposeZoneInput',{attrs:{"placeholder":_vm.placeholder},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'floor_material')?[_c('v-select',{attrs:{"items":_vm.floorMaterials,"item-text":"label","item-value":"id"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(
      _vm.field.value === 'cleaning_area' || _vm.field.value === 'cleaning_area_low'
    )?[_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.cleaningAreas,"item-text":"description","item-value":"name"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'fire_area')?[_c('v-combobox',{attrs:{"clearable":"","items":_vm.fireAreas,"item-text":"name","item-value":"name"},nativeOn:{"input":function($event){return _vm.nativeInputCombo.apply(null, arguments)}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'movement_area')?[_c('v-select',{attrs:{"items":_vm.movementAreas,"item-text":"label","item-value":"id"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'cleaning_frequency')?[_c('v-select',{attrs:{"clearable":"","items":_vm.cleaningFrequencies,"item-text":"label","item-value":"id"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'additional_cost')?[_c('v-text-field',{attrs:{"type":"number","clearable":"","placeholder":'-'},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'cleaning_details')?[_c('v-textarea',{attrs:{"clearable":"","counter":"","maxlength":"800","rows":"4","type":"text"},model:{value:(_vm.cleaningDetails),callback:function ($$v) {_vm.cleaningDetails=$$v},expression:"cleaningDetails"}})]:(_vm.field.value === 'shares' || _vm.field.value === 'user_cost_center')?[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('CostcenterSharesField',{attrs:{"costcenters":_vm.costcenters},on:{"input":function($event){_vm.model = $event}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)]:(
      _vm.field.value === 'number_of_workstations' ||
        _vm.field.value === 'number_of_personnel' ||
        _vm.field.value === 'area'
    )?[_c('v-text-field',{attrs:{"clearable":"","type":"number"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(
      _vm.field.value === 'meter_number' || _vm.field.value === 'classification'
    )?[_c('v-text-field',{attrs:{"clearable":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'space_name')?[_c('v-text-field',{attrs:{"clearable":"","type":"text","placeholder":_vm.spacePlaceholder !== null
          ? _vm.spacePlaceholder
          : _vm.getPlaceholderValue(_vm.placeholder)},on:{"focus":function($event){return _vm.clearField(_vm.placeholder)}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'name')?[_c('v-text-field',{attrs:{"clearable":"","type":"text","rules":[v => !!v || _vm.$t('Required')],"placeholder":_vm.spacePlaceholder !== null
          ? _vm.spacePlaceholder
          : _vm.getPlaceholderValue(_vm.placeholder)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'capacity_quantity')?[_c('v-text-field',{attrs:{"clearable":"","type":"text","placeholder":_vm.spacePlaceholder !== null
          ? _vm.spacePlaceholder
          : _vm.getPlaceholderValue(_vm.placeholder)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'capacity_unit')?[_c('v-select',{attrs:{"items":_vm.capacityUnits,"item-text":"label","item-value":"id","placeholder":_vm.getDefinitionLabel(_vm.placeholder)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'contract_number_sap')?[_c('v-text-field',{attrs:{"type":"text","placeholder":_vm.getPlaceholderValue(_vm.placeholder)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(_vm.field.value === 'shared_space_code')?[_c('v-select',{attrs:{"items":_vm.sharedSpaceCodes,"item-text":"label","item-value":"id","placeholder":_vm.getDefinitionLabel(_vm.placeholder)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(
      _vm.field.value === 'space_category'
    )?[_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.spaceCategories,"item-text":"label","item-value":"id","placeholder":_vm.getDefinitionLabel(_vm.placeholder)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(
      _vm.field.value === 'department'
    )?[_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.departments,"item-text":"label","item-value":"id","placeholder":_vm.getDefinitionLabel(_vm.placeholder)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:(
      _vm.field.value === 'item_area'
    )?[_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.itemAreas,"item-text":"label","item-value":"id","placeholder":_vm.getDefinitionLabel(_vm.placeholder)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:[_vm._v(" "+_vm._s(_vm.field.value)+" ")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }