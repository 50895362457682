import RambollFMAPI from '../api/rambollfm.js'
import loglevel from 'loglevel'
import { i18n } from '../plugins/i18n.js'
import { getProspectType, getProspectStage } from '../helpers/leasing/prospect.js'
import humanize from '../helpers/humanize.js'
import { fetchLeasingReports } from './requests/fetchReport.js'
import { transformToProspect } from '../helpers/leasing/rentalprocess.js'
import moment from 'moment'

function getDefinitionLabelById (rootGetters, key) {
  const def = rootGetters['app/definitionById'](key)
  if (typeof def === 'undefined' || def === null) {
    return i18n.t('Undefined')
  }
  return def.label !== null ? def.label : i18n.t('Undefined')
}
// function getDefinitionsByGroupLabel (rootGetters, label) {
//   const defs = rootGetters['app/definitionsByGroupLabel'](label)
//   return defs
// }

function computeProspect (prospect, rootGetters, parties, municipalities, sites, leasingUsers, events) {
  // Party name
  const foundParty = parties.find(party => party.id == prospect.id_osapuoli)
  let partyName
  if (foundParty) {
    partyName = foundParty.name
  }
  else {
    partyName = ""
  }
  //  Sites need to be added as subItems
  let subItems = []
  prospect.sites.forEach(item => {
    const foundSite = sites.find(sitesItem => sitesItem.id_site == item.id_site)
    if (foundSite) subItems.push({site: foundSite.name, unit_area_preference: 0, id_site: item.id_site})
  })
  // String of sites
  const reducedSites = subItems.reduce((acc, value, index) =>
    {
      let next = acc;
      if (index !== 0) next += ', '
      next += value.site
      return next
    },
  '')

  let singleSite = ''
  let idSite = null
  if (subItems.length === 1) {
    singleSite = reducedSites
    idSite = subItems[0].id_site
  }
  else if (subItems.length > 1) {
    singleSite = i18n.t('prospect.several')
  }

  // String of municipalities
  const cityString = prospect.cities.reduce((acc, curr, index) => {
    const city = municipalities.find(item => item.id === curr).municipality
    if (index !== 0) return acc + ', ' + city
    else return acc + city;
  }, '')

  // Unit usage string
  const unitUsageString = prospect.unit_type_preference.reduce((acc, curr, index) => {
    const usage = getDefinitionLabelById(rootGetters, curr)
    if (index !== 0) return acc + ', ' + usage
    else return acc + usage;
  }, '')

  // Prospect type (renegotiations/real estate development/new contract)
  let prospectType = 'spa'
  let prospectTypeText = i18n.t('leasing.new_negotiation')
  if (prospect.renegotiations && prospect.rental_contract_validity === 'määräaikainen') {
    prospectType = 'autorenew'
    prospectTypeText = i18n.t('leasing.renegotiations' )
  }
  else if (prospect.renegotiations && prospect.rental_contract_validity === 'toistaiseksi voimassaoleva') {
    prospectType = 'icecream'
    prospectTypeText = i18n.t('leasing.renewal' )
  }
  else if (prospect.real_estate_development) {
    prospectType = 'construction'
    prospectTypeText = i18n.t('prospect.real_estate_development')
  }


  // Prospect stage and stage date
  // Prospect stage and stage date
  const stageObject = getProspectStage(prospect, events)
  // If state is passed, add reason
  let stage = i18n.t(stageObject.stage)
  let stageDate = humanize.date(stageObject.stageDate)
  if (stageObject.stage === 'Passed') {
    stage = stage +  ': ' + i18n.t(getDefinitionLabelById(rootGetters, prospect.status_description))
  }

  // Prospect responsibles
  let firstResponsibleName = ''
  let secondaryResponsibleName = ''
  const firstResponsible = leasingUsers.find(item => item.id_user === prospect.owner)
  if (firstResponsible) {
    firstResponsibleName = firstResponsible.name
  }
  const secondaryResponsible = leasingUsers.find(item => item.id_user === prospect.secondary_owner)
  if (secondaryResponsible) {
    secondaryResponsibleName = secondaryResponsible.name
  }

  const returnObject =  {
    id_prospect: prospect.id_prospect,
    prospect_description: prospect.prospect_description,
    party: partyName,
    site: singleSite,
    sitesString: "",
    unit_area_preference: prospect.unit_area_preference,
    city_preference: cityString,
    real_estate_development: prospect.real_estate_development ? i18n.t('Yes') : i18n.t('No'),
    unit_type: unitUsageString,
    prospect_status: prospect.status,
    prospect_fullscreen_status: i18n.t(prospect.status),
    showToggle: subItems.length > 1,
    created: prospect.created,
    last_modified: prospect.last_modified,
    prospect_type: prospectType,
    prospect_type_text: prospectTypeText,
    id_site: idSite,
    stage: stage,
    stageChanged: stageDate,
    success_likelihood: prospect.success_likelihood,
    rental_contract_end_date: prospect.rental_contract_end_date,
    first_responsible: firstResponsibleName,
    secondary_responsible: secondaryResponsibleName
  }

  if (subItems.length > 1) {
    returnObject['subItems'] = subItems
    returnObject['sitesString'] = reducedSites
  }
  else {
    returnObject['sitesString'] = reducedSites
  }
  return returnObject
}

function prospectGeneral (prospects, rootGetters, sites, parties, municipalities, currentUser, leasingUsers, siteId, prospectEvents = []) {

  const filteredProspects = prospects.filter(prospect => {

    if(!prospect.sites){
      return false
    }

    let siteIds = prospect.sites.map(site => site.id_site)


    if (siteIds.includes(siteId)) {
      return true
    }
    else {
      return false
    }
  })


  const prospectWidgetData = filteredProspects.map(prospect => {
    const events = prospectEvents.filter(event => event.id_prospect === prospect.id_prospect)
    return computeProspect(prospect, rootGetters, parties, municipalities, sites, leasingUsers, events)
  })

  prospectWidgetData.sort((prev, next) =>  new Date(next.last_modified) - new Date(prev.last_modified))

  return prospectWidgetData
}

function contractData (rentalStatuses, prospects, ignoredProspects, permanent = false, rootGetters) {
  const renegotiationsProspects = prospects.concat(ignoredProspects).filter(prospect => prospect.renegotiations === true)
  const rows = []

  let filteredStatuses = []

  if (permanent) {
    filteredStatuses = rentalStatuses.filter(status =>
      status.validity === 'toistaiseksi voimassaoleva' && status.contractFirstPossibleEndDate !== null
    )

  }
  else {
    filteredStatuses = rentalStatuses.filter(status =>
      status.validity === 'määräaikainen' && status.curPartyEndDate !== null
    )
  }
  filteredStatuses.forEach(d => {

    const getDate = (dateString) => {
      if (dateString) {
        return moment(dateString).format('DD.MM.YYYY')
      }
      return null
    }
    if (d.contractNumber) {
      const index = rows.findIndex(row => row.contractId === d.contractId)
      if (index >= 0) {
        rows[index].totalArea += d.area
        rows[index].units.push({id: d.unitId, unit_code: d.unitName})
        rows[index].contractTotalMarketRent += (d.market_rent ?? 0) * (d.area ?? 0)
        rows[index].unitNames.push(d.unitName)
        if (rows[index].vacancyRateAssumption !== d.vacancy_rate_assumption) {
          rows[index].vacancyRateAssumption = i18n.t('Multiple')
        }
        if (rows[index].vacancyRateAssumptionUpdated !== getDate(d.vacancy_rate_assumption_date)) {
          rows[index].vacancyRateAssumptionUpdated = i18n.t('Multiple')
        }
      } else {
        const newRow = {
          tenant: d.curPartyName,
          siteName: d.siteName,
          unitNames: [d.unitName],
          site_identifier: d.site_identifier,
          totalArea: d.area,
          endDate: d.curPartyEndDate,
          contractNumber: d.contractNumber,
          id_site: d.siteId,
          units: [{id: d.unitId, unit_code: d.unitName}],
          id_tenant: d.curPartyId,
          unitUsage: d.unitUsage,
          tenant_corporation: d.tenant_corporation,
          no_renegotiations: d.no_renegotiations,
          contractId: d.contractId,
          validity: d.validity,
          contractFirstPossibleEndDate: d.contractFirstPossibleEndDate,
          marketRent: d.market_rent,
          contractTotalMarketRent: (d.market_rent ?? 0) * (d.area ?? 0),
          unitId: d.unitId,
          curUnitPartyId: d.curUnitPartyId,
          vacancyRateAssumption: d.vacancy_rate_assumption,
          vacancyRateAssumptionUpdated: getDate(d.vacancy_rate_assumption_date),
        }

        const negotiationStatus = getDefinitionLabelById(rootGetters, d.defNegotiationStatus)

        if (d.id_prospect_renegotiations != null) {
          const foundProspect = renegotiationsProspects.find(item => item.id_prospect === d.id_prospect_renegotiations)
          if (foundProspect) {
            newRow.id_prospect = foundProspect.id_prospect
            newRow.id_process = foundProspect.id_process
            newRow.prospect_description = foundProspect.prospect_description
            newRow.success_likelihood = foundProspect.success_likelihood
            if (permanent) {
              newRow.renewal = foundProspect.prospect_description
            }
            else {
              newRow.renegotiations = foundProspect.prospect_description
            }
            if (foundProspect.status === 'Inactive') {
              newRow.prospect_status = foundProspect.status
            } else {
              newRow.prospect_status = foundProspect.stage ?? foundProspect.status
            }
          }
        }
        else if (negotiationStatus !== i18n.t('Undefined')) {
          newRow.prospect_status = negotiationStatus
        }
        else {
          newRow.prospect_status = 'ProspectPending'
        }
        newRow.totalArea = d.area
        newRow.prospect_fullscreen_status = i18n.t(newRow.prospect_status)
        rows.push(newRow)
      }
    }
  })

  if (permanent) {
    rows.sort((a,b) => new Date(a.contractFirstPossibleEndDate) - new Date(b.contractFirstPossibleEndDate))
  }
  else {
    rows.sort((a,b) => new Date(a.endDate) - new Date(b.endDate))
  }

  return rows
}

export default {
  namespaced: true,
  state: {
    rentalStatuses: [],
    currentRentalStatusesLoading: false,
    allSitesRentalStatuses: [],
    allSitesRentalStatusesLoading: false,
    futureRentalStatuses: [],
    futureRentalStatusesLoading: false,
    pastRentalStatuses: [],
    pastRentalStatusesLoading: false,
    filteredRentalStatuses: [],
    prospects: [],
    prospectsLoading: false,
    prospectNotes: [],
    prospectNotesLoading: false,
    leasingWidgetData: [],
    apiEndpoint: null,
    apiToken: null,
    endTime: undefined,
    startTime: undefined,
    anotherEndTime: undefined,
    anotherStartTime: undefined,
    showProspectModal: false,
    shownProspect: null,
    leasingUsers: [],
    leasingUsersLoading: false,
    renegotiations: null,
    userNotifications: [],
    userNotificationsLoading: false,
    showCorporationModal: false,
    corporationLoading: false,
    shownCorporation: null,
    leasingWidgetFilters: [],
    siteLeasingWidgetData: {},
    siteLeasingWidgetDataLoading: false,
    municipalities: [],
    municipalitiesLoading: false,
    parties: [],
    allBusinessParties: [],
    partiesLoading: false,
    situationWidgetsLoading: false,
    leaseGoals: null,
    leaseGoalsLoading: false,
    corporations: [],
    corporationsLoading: false,
    responsibleSites: [],
    responsibleSitesLoading: false,
    newContracts: [],
    newContractsLoading: false,
    inspectionRentalStatuses: [],
    inspectionRentalStatusesLoading: false,
    closedContracts: [],
    closedContractsLoading: false,
    quarterlyUsage: [],
    quarterlyUsageLoading: false,
    prospectEvents: [],
    prospectEventsLoading: false,
    corporationRentalStatusesLoading: false,
    corporationRentalStatuses: [], // All the contracts of single tenant will be stored here.,
    ignoredProspects: [],
    ignoredProspectsLoading: false,
    futureUsages: [],
    futureUsagesLoading: false,
    leasingSummaries: [],
    leasingSummariesLoading: false,
    situationAndTargetData: {
      endingContracts: [],
      endingCarparkContracts: [],
      freeUnitsStart: [],
      freeUnitsEnd: []
    },
    situationAndTargetLoading: false,
    capitalOccupancy: [],
    capitalOccupancyLoading: false,
    occupancyRateProgress: [],
    occupancyRateProgressLoading: false
  },
  getters: {
    dataForLeasingWidget: (state) => (widgetName) => {
      const found = state.leasingWidgetData.find(row => row.name === widgetName)
      if (found) {
        return found.data
      }
      return null
    },
    rentalStatuses: (state) => {
      return state.rentalStatuses
    },
    futureRentalStatuses: (state) => {
      return state.futureRentalStatuses
    },
    pastRentalStatuses: (state) => {
      return state.pastRentalStatuses
    },
    showProspectModal: (state) => {
      return state.showProspectModal
    },
    shownProspect: (state) => {
      return state.shownProspect
    },
    leasingUsers: (state) => {
      return state.leasingUsers
    },
    userNotificationsWithLinks: (state, getters) => {
      let filteredNotifications = [...state.userNotifications].filter(notification => notification.id_prospect === null || getters.filteredProspects.find(prospect => prospect.id_prospect === notification.id_prospect) != null)
      let notificationsWithLinks = filteredNotifications.map(notification => {
        let notificationWithLink = {...notification}
        if (notification.id_prospect) {
          const foundProspect = getters.filteredProspects.find(prospect => prospect.id_prospect === notification.id_prospect)
          if (foundProspect) {
            notificationWithLink.prospect_description = foundProspect.prospect_description
            notificationWithLink.prospect_type = getProspectType(foundProspect)
          }
        }
        return notificationWithLink
      })
      return notificationsWithLinks.sort((prev, next) => new Date(next.modified_date) - new Date(prev.modified_date))
    },
    filteredProspects: (state, getters, rootState, rootGetters) => {
      let allProspects = state.prospects
      // Show only user prospects
      let filteredProspects = allProspects.filter(prospect => (prospect.owner === rootState.app.userInfo.id || prospect.secondary_owner === rootState.app.userInfo.id))
      // Filter out prospects with sites the current user doesn't have access to
      filteredProspects = filteredProspects.filter(prospect => {
        for (let i = 0; i < prospect.sites.length; i++) {
          if (rootState.app.sites.find(sitesItem => sitesItem.id_site == prospect.sites[i].id_site) == null) {
            return false
          }
        }
        return true
      })

      // Also check if site filters are active and filter out prospects with no sites if this is the case
      if (rootGetters['app/siteFiltersByTypes']('all').length > 0) {
        filteredProspects = filteredProspects.filter(prospect => prospect.sites.length !== 0)
      }

      return filteredProspects
    },
    leasingWidgetDataForCurrentSite: (state, getters, rootState) => (widgetId) => {
      if (rootState.sites.currentSiteId !== undefined) {
        if (!Array.isArray(state.siteLeasingWidgetData[rootState.sites.currentSiteId])) {
            return null
          }
        const found = state.siteLeasingWidgetData[rootState.sites.currentSiteId].find(row => row.name === widgetId)

        if (found) {
          return found.data
        }
      }
      return null
    },
    prospectRentalStatus: (state) => (prospect) => {
      let rentalStatuses = []
      rentalStatuses.push(...state.pastRentalStatuses.filter(rs => rs.contractNumber === prospect.allocated_contract_number || rs.id_prospect_renegotiations === prospect.id_prospect))
      rentalStatuses.push(...state.futureRentalStatuses.filter(rs => rs.contractNumber === prospect.allocated_contract_number || rs.id_prospect_renegotiations === prospect.id_prospect))
      rentalStatuses.push(...state.allSitesRentalStatuses.filter(rs => rs.contractNumber === prospect.allocated_contract_number || rs.id_prospect_renegotiations === prospect.id_prospect))
      return rentalStatuses
    }
  },
  mutations: {
    setRentalStatuses (state, rentalStatuses) {
      state.rentalStatuses = rentalStatuses
    },
    setAllSitesRentalstatuses (state, rentalStatuses) {
      state.allSitesRentalStatuses = rentalStatuses
    },
    setFutureRentalStatuses (state, rentalStatuses) {
      state.futureRentalStatuses = rentalStatuses
    },
    setPastRentalStatuses (state, rentalStatuses) {
      state.pastRentalStatuses = rentalStatuses
    },
    setFilteredRentalStatuses (state, rentalStatuses) {
      let filteredResult = rentalStatuses
      if (state.startTime) {
        filteredResult = rentalStatuses.filter(status => {
          if (status.contractId && status.contractStartDate) {
            const matchTime = new Date(status.contractStartDate).getTime() >= state.startTime && new Date(status.contractStartDate).getTime() <= state.endTime
            let matchAnotherTime = false
            if (state.anotherStartTime) {
              matchAnotherTime = new Date(status.contractStartDate).getTime() >= state.AnotherStartTime && new Date(status.contractStartDate).getTime() <= state.AnotherEndTime
            }
            return matchTime || matchAnotherTime
          } else {
            return false
          }
        })
      }

      state.filteredRentalStatuses = filteredResult
    },
    setProspects (state, prospects) {
      state.prospects = prospects
    },
    setProspectNotes (state, prospectNotes) {
      state.prospectNotes = prospectNotes
    },
    addNewProspect (state, prospect) {
      state.prospects.push(prospect)
    },
    addNewProspectNote (state, prospectNote) {
      state.prospectNotes.push(prospectNote)
    },
    setUpdatedNote (state, prospectNote) {
      const index = state.prospectNotes.findIndex(item => item.id_prospect_note === prospectNote.id_prospect_note)
      state.prospectNotes.splice(index, 1, prospectNote)
    },
    deleteProspectNote (state, id) {
      state.prospectNotes = state.prospectNotes.filter(item => item.id_prospect_note !== id)
    },
    setEditedProspect (state, editedProspect) {
      const index = state.prospects.findIndex(prospect => prospect.id_prospect === editedProspect.id_prospect)
      state.prospects.splice(index, 1, editedProspect)
    },
    setLeasingUsers (state, users) {
      users.sort((previous, next) => previous.name.localeCompare(next.name))
      state.leasingUsers = users
    },

    setLeasingWidgetData (state, {name, data, headers}) {
      let names = name
      if (!Array.isArray(name)) {
        names = [name]
      }

      names.forEach(name => {
        const existing = state.leasingWidgetData.findIndex(r => r.name === name)
        if (existing >= 0) {
          state.leasingWidgetData.splice(existing, 1)
        }

        state.leasingWidgetData.push({ name: name, data: data,  headers: headers })
      })
    },
    setShowProspectModal (state, data) {
      state.shownProspect =  state.prospects.concat(state.ignoredProspects).find(prospect => prospect.id_prospect === data )
      if (data != null) {
        if (state.showCorporationModal) {
          state.showCorporationModal = false
        }
        state.showProspectModal = true
      }
      else {
        if (state.shownCorporation) {
          state.showCorporationModal = true
        }
        state.showProspectModal = false
      }
    },
    setProspectModalVisibility (state, data) {
      state.showProspectModal = data
    },
    setUpdatedProspect (state, data) {
      const { prospect } = data
      if (prospect.ignore === false) {
        const index = state.prospects.findIndex(item => item.id_prospect === prospect.id_prospect)
        state.prospects.splice(index, 1, prospect)
      }
      else {
        const newIndex = state.ignoredProspects.findIndex(item => item.id_prospect === prospect.id_prospect)
        if (newIndex < 0) {
          state.ignoredProspects.push(prospect)
          const oldIndex = state.prospects.findIndex(item => item.id_prospect === prospect.id_prospect)
          state.prospects.splice(oldIndex, 1)
        }
        else {
          state.ignoredProspects.splice(newIndex, 1, prospect)
        }
      }
      state.shownProspect = prospect
    },

    setRenegotiations (state, newData) {
      state.renegotiations = newData
    },
    updateContractData (state, params) {
      const { contractId, prospect } = params
      let contracts = state.allSitesRentalStatuses.filter(rs => rs.contractId === contractId)
      contracts.forEach(contract => contract.id_prospect_renegotiations = prospect.id_prospect)
      let inspectionContracts = state.inspectionRentalStatuses.filter(rs => rs.contractId === contractId)
      inspectionContracts.forEach(contract => contract.id_prospect_renegotiations = prospect.id_prospect)
    },
    setUserNotifications (state, notifications) {
      state.userNotifications = notifications
    },
    deleteUserNotification (state, params) {
      const { notificationId, siteId, unitId, eventType } = params
      if(notificationId) {
        state.userNotifications = state.userNotifications.filter(item => item.id !== notificationId)
      }
      if(siteId && unitId) {
        state.userNotifications = state.userNotifications.filter(item => item.id_site !== siteId && item.id_unit !== unitId || item.id_site === siteId && item.id_unit !== unitId && item.event_type !== eventType || item.id_site === siteId && item.id_unit !== unitId && item.event_type === eventType)
      }
    },
    setShownCorporation (state, data) {
      if (data != null) {
        if (state.showProspectModal) {
          state.showProspectModal = false
        }
        state.showCorporationModal = true
      }
      else {
        if (state.shownProspect) {
          state.showProspectModal = true
        }
        state.showCorporationModal = false
      }
      state.shownCorporation = data
    },
    setCorporationModalVisibility (state, data) {
      state.showCorporationModal = data
    },
    setCorporationLoading (state, data) {
      state.corporationLoading = data
    },
    setWidgetFilterData (state, {name, data}) {
      let names = name
      if (!Array.isArray(name)) {
        names = [name]
      }

      names.forEach(name => {
        const existing = state.leasingWidgetFilters.findIndex(r => r.name === name)
        if (existing >= 0) {
          state.leasingWidgetFilters.splice(existing, 1)
        }
        state.leasingWidgetFilters.push({ name: name, data: data })
      })
    },
    setUpdatedSuccessLikelihood (state, data) {
      if (data.ignore === false) {
        const index = state.prospects.findIndex(item => item.id_prospect === data.id_prospect)
        state.prospects.splice(index, 1, data)
      }
      else {
        const ignoredIndex = state.ignoredProspects.findIndex(item => item.id_prospect === data.id_prospect)
        state.ignoredProspects.splice(ignoredIndex, 1, data)
      }

      if (state.shownProspect) {
        state.shownProspect = data
      }

    },

    setSiteLeasingWidgetData (state, { name, data, siteId }) {
      let names = name
      if (!Array.isArray(name)) {
        names = [name]
      }

      names.forEach(name => {

        if (!Array.isArray(state.siteLeasingWidgetData[siteId])) {
          state.siteLeasingWidgetData[siteId] = []
        }

        const existing = state.siteLeasingWidgetData[siteId].findIndex(r => r.name === name)
        if (existing >= 0) {
          state.siteLeasingWidgetData[siteId].splice(existing, 1)
        }

        state.siteLeasingWidgetData[siteId].push({ name: name, data: data })
      })
    },
    setMunicipalities (state, data) {
      state.municipalities = data
    },
    setParties (state, data) {
      state.allBusinessParties = data
      state.parties = data.filter(party => party.tenant_corporation === true)
    },
    deleteProspect (state, prospectId) {
      let index = state.prospects.findIndex(item => item.id_prospect === prospectId)
      state.prospects.splice(index, 1)
      if (index < 0) {
        index = state.ignoredProspects.findIndex(item => item.id_prospect === prospectId)
        state.ignoredProspects.splice(index, 1)
      }
    },
    setSiteLeasingWidgetDataLoading (state, data) {
      state.siteLeasingWidgetDataLoading = data
    },
    setSituationWidgetsLoading (state, data) {
      state.situationWidgetsLoading = data
    },
    setLeaseGoals (state, data) {
      state.leaseGoals = data
    },
    setCorporations (state, data) {
      state.corporations = data
    },
    updateProspectModifiedDate (state, data) {
      const { prospectId, modifiedDate } = data
      const index = state.prospects.findIndex(item => item.id_prospect === prospectId)
      if (index > -1) {
        const foundProspect = state.prospects[index]
        foundProspect.last_modified = modifiedDate
        state.prospects.splice(index, 1, foundProspect)
      }

    },
    setResponsibleSites (state, data) {
      state.responsibleSites = data
    },
    setNewContracts (state, data) {
      state.newContracts = data
    },
    setInspectionRentalStatuses (state, data) {
      state.inspectionRentalStatuses = data
    },
    setClosedContracts (state, data) {
      state.closedContracts = data
    },
    setQuarterlyUsage (state, data) {
      state.quarterlyUsage = data
    },
    setProspectEvents (state, data) {
      state.prospectEvents = data
    },
    changeProspectsLoading (state, data) {
      state.prospectsLoading = data
    },
    changeProspectNotesLoading (state, data) {
      state.prospectNotesLoading = data
    },
    changeLeasingUsersLoading (state, data) {
      state.leasingUsersLoading = data
    },
    changeMunicipalitiesLoading (state, data) {
      state.municipalitiesLoading = data
    },
    changePartiesLoading (state, data) {
      state.partiesLoading = data
    },
    changeAllSitesRentalStatusesLoading (state, data) {
      state.allSitesRentalStatusesLoading = data
    },
    changeUserNotificationsLoading (state, data) {
      state.userNotificationsLoading = data
    },
    changeFutureRentalStatusesLoading (state, data) {
      state.futureRentalStatusesLoading = data
    },
    changePastRentalStatusesLoading (state, data) {
      state.pastRentalStatusesLoading = data
    },
    changeLeaseGoalsLoading (state, data) {
      state.leaseGoalsLoading = data
    },
    changeCorporationsLoading (state, data) {
      state.corporationsLoading = data
    },
    changeResponsibleSitesLoading (state, data) {
      state.responsibleSitesLoading = data
    },
    changeNewContractsLoading (state, data) {
      state.newContractsLoading = data
    },
    changeInspectionRentalStatusesLoading (state, data) {
      state.inspectionRentalStatusesLoading = data
    },
    changeClosedContractsLoading (state, data) {
      state.closedContractsLoading = data
    },
    changeQuarterlyUsageLoading (state, data) {
      state.quarterlyUsageLoading = data
    },
    changeProspectEventsLoading (state, data) {
      state.prospectEventsLoading = data
    },
    setCorporationRentalStatusesLoading (state, data){
      state.corporationRentalStatusesLoading = data
    },
    setCorporationRentalStatuses (state, data){
      state.corporationRentalStatuses = data
    },
    updateProspectEvents (state, { events, prospectId}) {
      const filteredEvents = state.prospectEvents.filter(event => event.id_prospect !== prospectId)
      state.prospectEvents = filteredEvents.concat(events);
    },
    setProspectsWithIgnored (state, data) {
      state.ignoredProspects = data
    },
    changeIgnoredProspectsLoading (state, data) {
      state.ignoredProspectsLoading = data
    },
    changeFutureUsagesLoading (state, data) {
      state.futureUsagesLoading = data
    },
    setFutureUsages (state, data) {
      state.futureUsages = data
    },
    changeCurrentRentalStatusesLoading (state, data) {
      state.currentRentalStatusesLoading = data
    },
    setLeasingSummaries (state, data) {
      state.leasingSummaries = data
    },
    changeLeasingSummariesLoading (state, data) {
      state.leasingSummariesLoading = data
    },
    setSituationAndTargetData (state, data) {
      state.situationAndTargetData = data
    },
    changeSituationAndTargetLoading (state, data) {
      state.situationAndTargetLoading = data
    },
    updateRentalStatus (state, data){
      // There is currently quite many lists of rentalstatuses and we have to check each one of them to make sure that the data is in sync.
      // We should look into reducing the amount of rental status lists. Or then we could also provide the information about which list to update??
      // But then again the other lists are not synced to the proper status and this will reflect accross the system and introduce bugs
      // until there is a refetch.
      const { contractNumber, patchObj } = data
      let rentalStatusesChanged = false
      const rentalStatuses = state.rentalStatuses.map(rs => {

        if(rs.contractNumber === contractNumber){
          rentalStatusesChanged = true
          return {
            ...rs,
            ...patchObj
          }
        }
        return rs
      })

      if(rentalStatusesChanged){
        state.rentalStatuses = rentalStatuses
      }

      let pastRentalStatusesChanged = false

      const pastRentalStatuses = state.pastRentalStatuses.map(rs => {

        if(rs.contractNumber === contractNumber){
          pastRentalStatusesChanged = true
          return {
            ...rs,
            ...patchObj
          }
        }
        return rs
      })

      if(pastRentalStatusesChanged){
        state.pastRentalStatuses = pastRentalStatuses
      }

      let newContractsChanged = false
      const newContracts = state.newContracts.map(rs => {

        if(rs.contractNumber === contractNumber){
          newContractsChanged = true
          return {
            ...rs,
            ...patchObj
          }
        }
        return rs
      })

      if(newContractsChanged){
        state.newContracts = newContracts
      }

      let inspectionRentalStatusesChanged = false
      const inspectionRentalStatuses = state.inspectionRentalStatuses.map(rs => {

        if(rs.contractNumber === contractNumber) {
          inspectionRentalStatusesChanged = true
          return {
            ...rs,
            ...patchObj
          }
        }
        return rs
      })

      if(inspectionRentalStatusesChanged){
        state.inspectionRentalStatuses = inspectionRentalStatuses
      }

    },
    setCapitalOccupancy (state, data) {
      state.capitalOccupancy = data
    },
    setCapitalOccupancyLoading (state, data) {
      state.capitalOccupancyLoading = data
    },
    setOccupancyRateProgressLoading (state, data) {
      state.occupancyRateProgressLoading = data
    },
    setOccupancyRateProgress (state, data) {
      state.occupancyRateProgress = data
    }
  },
  actions: {
    async getRentalStatuses ({ commit, rootState, state }, siteIds) {
      const time = rootState.app.currentDate
      commit('changeCurrentRentalStatusesLoading', true)
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const buildingIds = rootState.app.buildings.filter(b => siteIds.includes(b.id_site)).map(b => b.id_building)
      const rentalStatuses = await api.reports.post('rentalstatus', buildingIds, time)
      commit('setRentalStatuses', rentalStatuses)
      commit('changeCurrentRentalStatusesLoading', false)
    },
    async getAllSitesRentalStatuses ({ commit, rootState, state }, params) {
      let time = rootState.app.currentDate
      if (params && params.time) {
        time = params.time
      }
      commit('changeAllSitesRentalStatusesLoading', true)
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      let buildings = []
      if (rootState.app.buildings.length === 0 ) {
        buildings = await api.buildings.list()
      }
      else {
        buildings = rootState.app.buildings
      }
      const buildingIds = buildings.map(b => b.id_building)
      if (buildingIds.length > 0) {
        const rentalStatuses = await api.reports.post('rentalstatus', buildingIds, time)
        commit('setAllSitesRentalstatuses', rentalStatuses)
      }
      commit('changeAllSitesRentalStatusesLoading', false)
    },
    async getFutureRentalStatuses ({ commit, rootState, state }, params) {
      const { siteIds } = params
      let time = rootState.app.currentDate
      if (params && params.time) {
        time = params.time
      }
      commit('changeFutureRentalStatusesLoading', true)
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      let buildings = []
      if (rootState.app.buildings.length === 0 ) {
        buildings = await api.buildings.list()
      }
      else {
        buildings = rootState.app.buildings
      }
      const buildingIds = buildings.filter(b => siteIds.includes(b.id_site)).map(b => b.id_building)
      const rentalStatuses = await api.reports.post('futurerentalstatus', buildingIds, time)
      commit('setFutureRentalStatuses', rentalStatuses)
      commit('changeFutureRentalStatusesLoading', false)
    },
    async getAllFutureRentalStatuses ({ commit, rootState, state }, siteIds) {
      const time = rootState.app.currentDate
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const buildingIds = rootState.app.buildings.filter(b => siteIds.includes(b.id_site)).map(b => b.id_building)
      const rentalStatuses = await api.reports.post('allfuturerentalstatusversions', buildingIds, time)
      commit('setFutureRentalStatuses', rentalStatuses)
      return rentalStatuses
    },
    async getPastRentalStatuses ({ commit, rootState, state }, params) {
      const { siteIds } = params
      commit('changePastRentalStatusesLoading', true)
      const time = rootState.app.currentDate
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const buildingIds = rootState.app.buildings.filter(b => siteIds.includes(b.id_site)).map(b => b.id_building)
      const rentalStatuses = await api.reports.post('pastrentalstatus', buildingIds, time)
      commit('setPastRentalStatuses', rentalStatuses)
      commit('changePastRentalStatusesLoading', false)
    },
    async getAllRentalStatuses ({ commit, rootState, state }, buildingIds) {
      const time =  rootState.app.currentDate
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const rentalStatuses = await api.reports.post('rentalstatus', buildingIds, time)
      commit('setRentalStatuses', rentalStatuses)
      const futureRentalStatuses = await api.reports.post('futurerentalstatus', buildingIds, time)
      commit('setFutureRentalStatuses', futureRentalStatuses)
      const pastRentalStatuses = await api.reports.post('pastrentalstatus', buildingIds, time)
      commit('setPastRentalStatuses', pastRentalStatuses)
    },
    async getNewContracts ({ commit, rootState, state}, time) {
      if (time == null) {
        time = rootState.app.currentDate
      }
      commit('changeNewContractsLoading', true)
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const newContracts = await api.leasing.newContracts.get(time)
      commit('setNewContracts', newContracts)
      commit('changeNewContractsLoading', false)
    },
    async getInspectionRentalStatuses ({commit, rootState, state}, time) {
      commit('changeInspectionRentalStatusesLoading', true)
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      let buildings = []
      if (rootState.app.buildings.length === 0 ) {
        buildings = await api.buildings.list()
      }
      else {
        buildings = rootState.app.buildings
      }
      const buildingIds = buildings.map(b => b.id_building)
      if (buildingIds.length > 0) {
        const rentalStatuses = await api.reports.post('rentalstatus', buildingIds, time)
        commit('setInspectionRentalStatuses', rentalStatuses)
      }
      commit('changeInspectionRentalStatusesLoading', false)
    },
    async getClosedContracts ({ commit, rootState, state}) {
      commit('changeClosedContractsLoading', true)
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const closedContracts = await api.leasing.prospects.closedContracts()
      commit('setClosedContracts', closedContracts)
      commit('changeClosedContractsLoading', false)
    },
    async updateUnitRentalStatuses ({ commit, rootState, state, dispatch }, data) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      await api.units.rentalStatus().put(data, true)
      const siteIds = rootState.app.sites.map(site => site.id_site)
      await dispatch('getRentalStatuses', siteIds)
    },
    async getRentalStatusesSignedAfter ({commit, rootState, state}, params) {
      const { prospectId, time } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const rentalStatuses = api.leasing.prospects.allocationContracts(prospectId, time)
      return rentalStatuses;
    },
    async saveNewProspect ({commit, state}, data) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.leasing.prospects.post(data)
      if (state.parties.findIndex(party => party.id === result.id_osapuoli) < 0) {
        const parties  = await api.parties.list({ query: { party_type: 0, visibility_status: true } })
        commit('setParties', parties)
      }
      commit('addNewProspect', result)
      const events = await api.leasing.prospects.events(result.id_prospect)
      commit('updateProspectEvents', {prospectId: result.id_prospect, events: events})
      return result
    },
    async saveNewProspectNote ({commit, state}, data) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.leasing.prospectNotes.post(data)
      if (result.id_prospect != null) {
        commit('updateProspectModifiedDate', {prospectId: result.id_prospect, modifiedDate: result.modified_date, ignore: result.ignore})
      }
      commit('addNewProspectNote', result)
    },
    async getProspects ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      commit('changeProspectsLoading', true)
      const result = await api.leasing.prospects.get()
      if (Array.isArray(result)) commit('setProspects', result)
      commit('changeProspectsLoading', false)
    },
    async updateProspect ({commit, state, rootState}, data) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const prospect = await api.leasing.prospects.put(data)
      // If new party was created, update party data
      if (state.parties.findIndex(party => party.id === prospect.id_osapuoli) < 0) {
        const parties  = await api.parties.list({ query: { party_type: 0, visibility_status: true } })
        commit('setParties', parties)
      }
      commit('setUpdatedProspect', {prospect, rootState})
      const events = await api.leasing.prospects.events(prospect.id_prospect)
      commit('updateProspectEvents', {prospectId: prospect.id_prospect, events: events})
      return prospect
    },
    async removeProspect ({commit, state, rootState}, prospectId) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.leasing.prospects.delete(prospectId)
      if (typeof result === 'number') {
        commit('deleteProspect', result)
      }
      return result
    },
    async getProspectNotes ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      commit('changeProspectNotesLoading', true)
      const result = await api.leasing.prospectNotes.get()
      if (Array.isArray(result)) commit('setProspectNotes', result)
      commit('changeProspectNotesLoading', false)
    },
    async updateProspectNote ({commit, state}, data) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.leasing.prospectNotes.put(data)
      commit('setUpdatedNote', result)
      return result
    },
    async deleteProspectNote ({commit, state}, id) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.leasing.prospectNotes.delete(id)
      if (result.status === 204) {
        commit('deleteProspectNote', id)
      }
    },
    async addUnitToProspect ({commit, state}, params) {
      const { prospectId, data } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.leasing.prospects.postUnits(prospectId, data)
      commit('setEditedProspect', result)
      return result
    },
    async getLeasingUsers ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      commit('changeLeasingUsersLoading', true)
      const result = await api.leasing.leasingUsers.get()
      commit('setLeasingUsers', result)
      commit('changeLeasingUsersLoading', false)
    },
    async getMunicipalities ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      commit('changeMunicipalitiesLoading', true)
      const result = await api.municipalities.list()
      commit('setMunicipalities', result)
      commit('changeMunicipalitiesLoading', false)
    },
    async getParties ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      commit('changePartiesLoading', true)
      const parties  = await api.parties.list({ query: { party_type: 0, visibility_status: true } })
      commit('setParties', parties)
      commit('changePartiesLoading', false)
    },
    changeProspectModalState ({commit}, data) {
      commit('setShowProspectModal', data)
    },
    async changeProspectStatus ({commit, state, rootState}, params) {
      const { prospectId, data } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const prospect = await api.leasing.prospects.changeState(prospectId, data)
      commit('setUpdatedProspect', {prospect, rootState})
      const events = await api.leasing.prospects.events(prospect.id_prospect)
      commit('updateProspectEvents', {prospectId: prospect.id_prospect, events: events})
      return prospect
    },
    changeRenegotiationsModalState ({commit}, params) {
      commit('setRenegotiations', params)
    },
    updateContractData ({commit, rootState}, params) {
      commit('updateContractData', params )
    },
    async passUnits ({state}, data) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.leasing.prospects.units.pass(data)
      return result
    },
    async getUserNotifications ({commit, state}, params) {
      commit('changeUserNotificationsLoading', true)
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.leasing.notifications.get()
      commit('setUserNotifications', result)
      commit('changeUserNotificationsLoading', false)
    },
    async deleteUserNotification ({commit, state}, params) {
      const { notificationId, siteId, unitId } = params
      if(notificationId) {
        const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
        const result = await api.leasing.notifications.delete(notificationId)
        if (result.status === 204) {
          commit('deleteUserNotification', params)
        }
      }
      if(siteId && unitId) {
        commit('deleteUserNotification', params)
      }
    },
    async getCorporations ({commit, state}, params) {
      commit('changeCorporationsLoading', true)
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.parties.tenantCorporation.list()
      commit('setCorporations', result)
      commit('changeCorporationsLoading', false)
    },
    async getCorporationExtraById ({commit, state}, params) {
      commit('setCorporationLoading', true)
      if (state.shownCorporation == null) {
        commit('setCorporationModalVisibility', true)
      }
      const { id } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.parties.tenantCorporation.tenantCorporationById(id)
      commit('setShownCorporation', result)
      commit('setCorporationLoading', false)
      return result
    },
    setShownCorporation ({commit}, data) {
      commit('setShownCorporation', data)
    },
    setProspectModalVisibility ({commit}, data) {
      commit('setProspectModalVisiblity', data)
    },
    setCorporationModalVisibility ({commit}, data) {
      commit('setCorporationModalVisibility', data)
    },
    async changeProspectSuccessLikelihood ({commit, state}, params) {
      const { prospectId, data } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.leasing.prospects.changeSuccessLikelihood(prospectId, { success_likelihood: data})
      commit('setUpdatedSuccessLikelihood', result)
      const events = await api.leasing.prospects.events(result.id_prospect)
      commit('updateProspectEvents', {prospectId: result.id_prospect, events: events})
      return result
    },

    async LoadLeasingSiteWidgetData ({commit, state, rootState, rootGetters}) {
      let { siteId } = rootState.sites
      const { events } = state
      const { sites } = rootState.app


      if (siteId === undefined || siteId === null) {
        siteId = rootState.sites.currentSiteId
      }

      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const time = rootState.app.currentDate
      const currentUser = rootState.app.userInfo.id

      const userWidgets = rootState.app.userWidgets.filter(w => w.name.startsWith('site.leasing.'))
      // userWidgets.forEach(widget => {
      //   commit('setSiteLeasingWidgetData', {
      //     name: widget.name,
      //     data: null,
      //     siteId: siteId
      //   })
      // })


      // Only load leasing widget data for site if user has the right permissions
      if (this.getters['app/hasApplicationPermissionByName']('LEASING')) {

        commit('setSiteLeasingWidgetDataLoading', true)

        let rentalStatuses = []
        let municipalities = []
        let leasingUsers = []
        let rentalProcesses = []

        if (userWidgets.length > 0) {
          const buildingIds = rootState.sites.loadedBuildings.filter(b => b.id_site === siteId).map(b => b.id_building)
          // const buildings = await api.buildings.list({ query: { siteId, time: time } })
          // const buildingIds = buildings.map(b => b.id_building)
          rentalStatuses = await api.reports.post('rentalstatus', buildingIds, time)

          if(this.getters['app/hasApplicationPermissionByName']('LEASING')){
            const processes =  await api.leasing.rentalProcesses.get()
            rentalProcesses = [...processes.map(transformToProspect)]
          }

          let allBuildings = []
          if (rootState.app.buildings.length === 0 ) {
            allBuildings = await api.buildings.list()
          }
          else {
            allBuildings = rootState.app.buildings
          }
          const allBuildingIds = allBuildings.map(b => b.id_building)
          if (allBuildingIds.length > 0) {
            api.reports.post('rentalstatus', allBuildingIds, time)
              .then(res => commit('setAllSitesRentalstatuses', res))

          }
          // Corporations, municipalities and leasing users data needed for prospects-widgets
          if (userWidgets.findIndex(widget => widget.name.indexOf('prospects') !== -1) !== -1) {
            const parties = await api.parties.list({ query: { party_type: 0, visibility_status: true } })
            commit('setParties', parties)

            municipalities = await api.municipalities.list()
            commit('setMunicipalities', municipalities)

            leasingUsers = await api.leasing.leasingUsers.get()
            commit('setLeasingUsers', leasingUsers)
          }

        }


        userWidgets.forEach(widget => {
          if (widget.name === 'site.leasing.ending_contracts') {
            const endingContracts = contractData(rentalStatuses, rentalProcesses, [], false, rootGetters)
            commit('setSiteLeasingWidgetData', {
              name: widget.name,
              data: endingContracts,
              siteId: siteId
            })
          }
          else if (widget.name === 'site.leasing.permanent_contracts') {
            const endingContracts = contractData(rentalStatuses, rentalProcesses, [], true, rootGetters)
            commit('setSiteLeasingWidgetData', {
              name: widget.name,
              data: endingContracts,
              siteId: siteId
            })
          }
        })

        commit('setSiteLeasingWidgetDataLoading', false)
      }
      // Without proper permissions, set all widgets' data to empty
      else {
        userWidgets.forEach(widget => {
            commit('setSiteLeasingWidgetData', {
              name: widget.name,
              data: [],
              siteId: siteId
            })
        })
      }

    },
    async postRentalOffer ({commit, state}, data) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.leasing.rentalOffer.post(data)
      return result
    },
    patchRentalStatus ({commit, state, rootState, dispatch}, data){

      const { patch, unitId, curUnitPartyId, contractNumber } = data
      const { apiEndpoint, apiToken } = state

      const api = new RambollFMAPI(apiEndpoint, apiToken)

      api.units
        .partyLinks(unitId)
        .rentalContracts()
        .patch(curUnitPartyId, patch )

      const patchObj = patch.reduce((acc, op) => {
        acc[op.path] = op.value
        return acc
      }, {})

      commit('updateRentalStatus', { contractNumber, patchObj })
      if (rootState.sites.currentSiteId !== undefined) {
        dispatch('LoadLeasingSiteWidgetData')
      }
    },
    async getLeaseGoals ({commit, rootState, state}, params) {
      commit('changeLeaseGoalsLoading', true)
      const { siteIds, time } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const buildingIds = rootState.app.buildings.filter(b => siteIds.includes(b.id_site)).map(b => b.id_building)
      const goals = await api.reports.post('getleasegoals', buildingIds, time)
      commit('setLeaseGoals', goals)
      commit('changeLeaseGoalsLoading', false)
      return goals
    },
    async getResponsibleSites ({commit, rootState, state}) {
      commit('changeResponsibleSitesLoading', true)
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const goals = await api.leasing.responsibleSites.get()
      commit('setResponsibleSites', goals)
      commit('changeResponsibleSitesLoading', false)
      return goals
    },
    async getQuarterlyUsage ({commit, rootState, state}, time) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      commit('changeQuarterlyUsageLoading', true)
      if (time == null) {
        time = rootState.app.currentDate
      }
      let buildings = []
      if (rootState.app.buildings.length === 0 ) {
        buildings = await api.buildings.list()
      }
      else {
        buildings = rootState.app.buildings
      }
      const buildingIds = buildings.map(b => b.id_building)
      const response = await api.reports.post('quarterlyoccupancyrates', buildingIds, time)
      // const response = await api.leasing.quarterlyUsage.get(time)
      commit('setQuarterlyUsage', response)
      commit('changeQuarterlyUsageLoading', false)
    },
    async getProspectEvents ({ state }, params) {
      const { prospectId } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const events = await api.leasing.prospects.events(prospectId)
      return events
    },
    async getAllProspectEvents ({commit, rootState, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      commit('changeProspectEventsLoading', true)
      const events = await api.leasing.prospects.allEvents()
      commit('setProspectEvents', events)
      commit('changeProspectEventsLoading', false)
      return events
    },
    async getCorporationRentalStatuses ({ commit, rootState, state}, {corporationId, time}){
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      commit("setCorporationRentalStatusesLoading", true)

      const rentalStatuses = await api.parties.tenantCorporation.contracts(corporationId, time)
      commit("setCorporationRentalStatuses", rentalStatuses)

      commit("setCorporationRentalStatusesLoading", false)
      return rentalStatuses
    },
    async getIgnoredProspects ({commit, state}) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      commit('changeIgnoredProspectsLoading', true)
      const result = await api.leasing.prospects.get({ignored: true})
      if (Array.isArray(result)) commit('setProspectsWithIgnored', result)
      commit('changeIgnoredProspectsLoading', false)
    },
    async getFutureUsages ({commit, rootState, state}, time) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      commit('changeFutureUsagesLoading', true)
      if (time == null) {
        time = rootState.app.currentDate
      }
      let buildings = []
      if (rootState.app.buildings.length === 0 ) {
        buildings = await api.buildings.list()
      }
      else {
        buildings = rootState.app.buildings
      }
      const buildingIds = buildings.map(b => b.id_building)
      const response = await api.reports.post('futureoccupancyrates', buildingIds, time)
      commit('setFutureUsages', response)
      commit('changeFutureUsagesLoading', false)
    },
    async getLeasingSummaries ({commit, rootState, state}) {
      const time = rootState.app.currentDate
      commit('changeLeasingSummariesLoading', true)
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const buildingIds = rootState.app.buildings.map(b => b.id_building)
      const result = await api.reports.post('siteleasingsummaries', buildingIds, time)
      commit('setLeasingSummaries', result)
      commit('changeLeasingSummariesLoading', false)
    },
    async getSituationAndTargetData ({commit, state, rootState}, params) {
      commit('changeSituationAndTargetLoading', true)
      const { siteIds, startDate, endDate } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const buildingIds = rootState.app.buildings.filter(b => siteIds.includes(b.id_site)).map(b => b.id_building)
      const promises = []
      promises.push(api.reports.post('endingrentalstatus', buildingIds, startDate))
      promises.push(api.reports.post('endingcarparkcontracts', buildingIds, startDate))
      if (endDate) {
        promises.push(api.reports.post('freeunitsrentalstatuses', buildingIds, startDate))
        promises.push(api.reports.post('freeunitsrentalstatuses', buildingIds, endDate))
      }
      const situationAndTargetData = {
        endingContracts: [],
        endingCarparkContracts: [],
        freeUnitsStart: [],
        freeUnitsEnd: []
      }
      Promise.all(promises)
        .then(responses => {
          situationAndTargetData.endingContracts = responses[0]
          situationAndTargetData.endingCarparkContracts = responses[1]
          if (endDate) {
            situationAndTargetData.freeUnitsStart = responses[2]
            situationAndTargetData.freeUnitsEnd = responses[3]
          }
        })
        .finally(() => {
          commit('setSituationAndTargetData', situationAndTargetData)
          commit('changeSituationAndTargetLoading', false)
        })
    },
    async getCapitalOccupancy ({commit, rootState}, params) {
      const { time } = params
      commit('setCapitalOccupancyLoading', true)
      const response =  await fetchLeasingReports(rootState, ['OccupancyRatesTotal'], time)
      commit('setCapitalOccupancy', response['OccupancyRatesTotal']);
      commit('setCapitalOccupancyLoading', false)
    },

    async getOccupancyRateProgress ({commit, rootState}, params) {
      const { time } = params
      commit('setOccupancyRateProgressLoading', true)
      const response =  await fetchLeasingReports(rootState, ['OccupancyRateProgress'], time)
      commit('setOccupancyRateProgress', response['OccupancyRateProgress']);
      commit('setOccupancyRateProgressLoading', false)
    }
  }
}
