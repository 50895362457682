<template>
  <div id="reports">
    <v-dialog
      v-model="showCreateRentContractModal"
      persistent
      width="90%"
    >
      <v-card>
        <CreateRentContractModal
          v-if="showCreateRentContractModal"
          :contract-id="editedContractId"
          :out-rent="tabLocation == 'contractlayers.renting_out'"
          :contract-numbers="contractNumbers"
          :contract-template="contractTemplate"
          @contractIdUpdated="onContractIdUpdated"
          @handleSave="onCreateRentContractModalClosed"
          @changedDraft="
            showIndicator = true
            operationResult = 'changedDraft'
          "
          @savedDraft="
            showIndicator = true
            operationResult = 'savedDraft'
          "
        />
      </v-card>
    </v-dialog>
    <SkipTo
      :label="$t('skip-to-contracts-content')"
      to="#contracts-content"
    />
    <nav :aria-label="$t('SubMenu')">
      <v-tabs
        class="pl-5"
        show-arrows
      >
        <v-tab
          v-if="hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS')"
          :to="{ name: 'contractlayers.renting_out' }"
          exact
          @click="updateTabLocation(); outRentChanged();"
        >
          {{ $t('Renting out') }}
        </v-tab>
        <v-tab
          v-if="hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')"
          :to="{ name: 'contractlayers.renting_in' }"
          exact
          @click="updateTabLocation(); outRentChanged();"
        >
          {{ $t('Renting in') }}
        </v-tab>
        <v-tab
          v-if="hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')"
          :to="{ name: 'contractlayers.dashboard' }"
          exact
          @click="updateTabLocation();"
        >
          {{ $t('Dashboard') }}
        </v-tab>
      </v-tabs>
      <v-divider />
    </nav>
    <BaseView v-if="tabLocation != 'contractlayers.dashboard'">
      <template #selections>
        <h1 class="d-sr-only">
          {{ $t('Contracts') }}
        </h1>
        <v-select
          v-model="contractLevel"
          :label="$t('Contract level')"
          :items="contractLevels"
          item-text="text"
          item-value="value"
          class="mb-2"
          style="width: 10%"
        />
        <v-combobox
          v-if="contractLevel === 'contract' || contractLevel === 'contract_rows'"
          v-model="selectedContractStates"
          :items="translatedContractStates"
          :label="$t('Contract state')"
          item-text="name"
          item-value="id"
          multiple
          clearable
          class="ml-2 mb-2 combobox"
          style="width: 10%"
        >
          <template #selection="{ item, index }">
            <span class="mr-1 stateText">
              {{ item.name + (index === selectedContractStates.length - 1 ? "" : ",") }}
            </span>
          </template>
        </v-combobox>
      </template>
      <template #buttons>
        <v-btn
          :disabled="loading"
          small
          outlined
          rounded
          @click="showDefaultSearchDialog"
        >
          {{ $t('Save default search') }}
        </v-btn>
        <v-btn
          :disabled="loading"
          small
          outlined
          rounded
          @click="showColumnsFilterDialog"
        >
          {{ $t('Choose visible columns') }}
        </v-btn>
        <v-btn
          :loading="exporting.documentType == 'pdf'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          small
          outlined
          rounded
          @click="exportAs('pdf')"
        >
          {{ $t('Export to PDF') }}
        </v-btn>
        <v-btn
          :loading="exporting.documentType == 'csv'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          small
          outlined
          rounded
          @click="exportAs('csv')"
        >
          {{ $t('Export to spreadsheet') }}
        </v-btn>
        <v-menu
          v-if="isUpdatingAllowed && templates.length > 1"
          :disabled="loading"
          offset-y
          small
          outlined
          class="pa-0 ma-0"
        >
          <template #activator="{ on, attrs }">
            <div class="ma-0 pa-0">
              <v-list
                small
                class="ma-0 pa-0"
                rounded
              >
                <v-list-item
                  small
                  rounded
                  class="pa-0 ma-0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-title class="pa-0 ma-0">
                    <span class="v-btn v-btn--outlined v-btn--rounded v-btn__content v-size--small">
                      {{ $t('create_rent_contract.create_new_contract') }} {{ "(" + (tabLocation == 'contractlayers.renting_out' ? $t('Renting out') : $t('Renting in')) + ")" }}
                      <v-icon>keyboard_arrow_down</v-icon>
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </template>
          <v-list>
            <v-list-item
              v-for="(template, index) in templates"
              :key="index"
              class="v-btn v-btn__content v-size--small pa-0 ma-0"
              @click="createContractWithTemplate(template)"
            >
              <v-list-item-title class="v-btn v-btn__content v-size--small ma-0">
                {{ $t(template.code) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-else-if="isUpdatingAllowed"
          id="create_contract"
          :disabled="loading"
          small
          outlined
          rounded
          @click="createContractWithTemplate(templates[0])"
        >
          {{ $t('create_rent_contract.create_new_contract') }} {{ "(" + (tabLocation == 'contractlayers.renting_out' ? $t('Renting out') : $t('Renting in')) + ")" }}
        </v-btn>
      </template>
      <template #main>
        <div
          v-if="tabLocation != 'contractlayers.dashboard'"
          class="viewSelection"
        >
          <v-select
            v-model="selectedView"
            :label="$t('Stored views')"
            :items="availableStoredViews"
            item-text="name"
            item-value="id"
            clearable
            :disabled="availableStoredViews.length === 0"
            class="mb-8"
          >
            <template
              #selection="data"
            >
              {{ data.item.name }}
            </template>
            <template
              #item="data"
            >
              {{ getViewShortName(data.item.name) }}
              <v-spacer />
              <v-icon
                v-if="data.item.private || hasApplicationPermissionByName('VAKIOHAKU_LUONTI')"
                :style="{ 'margin-right': '0.4em' }"
                @click="deleteSelectedView(data.item.id)"
              >
                delete
              </v-icon>
            </template>
          </v-select>
        </div>
        <Table
          v-show="!loading && tabLocation != 'contractlayers.dashboard'"
          id="contracts-content"
          :rows="viewData"
          :headers="viewHeaders"
          :footers="createFooters"
          :footer-definitions="footerDefinitions"
          :hide="true"
          :show-rows-per-page-selection="showPagination"
          :show-filter="showColumnsFilter"
          :show-default-search="showDefaultSearch"
          :document-type="exportAsDocument"
          :pagination="getPagination"
          :emit-pagination="contractLevel === 'contract_payments' || contractLevel === 'contract' || contractLevel === 'contract_rows'"
          :row-count="getTableRowCount"
          :rows-loading="rowsLoading"
          :show-edit-control="isUpdatingAllowed && contractLevel === 'contract'"
          :out-rent="tabLocation == 'contractlayers.renting_out'"
          :selected-contract-states="selectedContractStates.map(state => state.id)"
          :get-export-data="contractLevel === 'contract' ? getContractExport : contractLevel === 'contract_payments' ? getContractPaymentsExport : getContractRowsExportData"
          :show-save-selection="true"
          :selected-view-string="storedView ? storedView.name : null"
          :stored-views="storedViews.filter(sv => sv.level === contractLevel)"
          :show-confirm="confirmation"
          :multi-select-keys="multiSelectKeys"
          @showFilterDialog="showColumnsFilterDialog"
          @showDefaultSearchDialog="showDefaultSearchDialog"
          @exported="exporting = {}"
          @updatePagination="updatePagination"
          @filterSearch="filterSearch"
          @edit-row="onEditRow"
          @remove-row="onRemoveRow"
          @triggerCustomEvents="triggerCustomEvents"
          @select_view="selectView"
          @save-selection="saveStoredView"
          @delete-view="deleteSelectedView"
        />
      </template>
    </BaseView>
    <ContractlayersDashboard
      v-if="tabLocation == 'contractlayers.dashboard'"
      :contract-states="contractStates"
    />
    <v-col
      v-if="loading"
      style="text-align: center; padding: 100px"
    >
      <div
        class="text-h5"
        style="margin-bottom: 2em"
      >
        {{ $t('Gathering information. Wait a second...') }}
      </div>
      <v-progress-circular
        size="64"
        indeterminate
        color="primary"
      />
    </v-col>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
      :y="yPosition"
    />
  </div>
</template>

<script>
import Alert from '../components/Alert.vue'
import Table from '../components/Table.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import BaseView from '../components/general/BaseView.vue'
import SkipTo from '../components/general/SkipTo.vue'
import CreateRentContractModal from '../components/Leasing/Modals/CreateRentContracts/CreateRentContractModal.vue'
import ContractlayersDashboard from '../components/ContractlayersDashboard.vue'
import moment from 'moment'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Contraclayers',
  components: {
    Alert,
    BaseView,
    SkipTo,
    Table,
    CreateRentContractModal,
    ContractlayersDashboard
    },
  props: {
    siteId: { type: Number, default: undefined }
  },
  metaInfo () {
    return {
      title: `${this.$t('Contracts')} ·`,
    }
  },
  data () {
    return {
      templates: [],
      category: 'None',
      showColumnsFilter: false,
      exportAsDocument: [],
      tablePagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['possessor'],
      },
      contractPagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['contract_number'],
        page: 1,
        search: {}
      },
      contractPaymentsPagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['contract_number'],
        page: 1,
        search: []
      },
      contractRowsPagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['contract_number'],
        page: 1,
        search: []
      },
      loading: true,
      contractLevel: '',
      contractLevels: [],
      contracts: [],
      contractPayments: [],
      contractRows: [],
      contractsCount: 0,
      contractPaymentsCount: 0,
      contractRowsCount: 0,
      contractPaymentsDataLoading: false,
      contractRowsDataLoading: false,
      contractDataLoading: false,
      abortController: undefined,
      searchValues: {},
      showCreateRentContractModal: false,
      editedContractId: undefined,
      selectedContractStates: [],
      translatedContractStates: [],
      contractStates: [],
      rentPaymentDomains: [],
      exporting: {},
      storedView: null,
      confirmation: false,
      selectedView: null,
      showDefaultSearch: false,
      contractTemplate: undefined,
      multiSelectKeys: { multiSelectOptionTypes: {}},
      tabLocation: null,

      // Alert component variables
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      yPosition: 'top',
    }
  },
  computed: {
    ...mapState('app', ['sites', 'contractMetadata', 'contractData', 'currentDate', 'buildings', 'siteIds', 'settings', 'storedViews']),
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionsByGroupId', 'definitionById', 'definitionByLabel', 'hasApplicationPermissionByName']),
    ...mapGetters('rentContracts', ['getContractTemplatesByOutRent']),
    isUpdatingAllowed () {
      return this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_MUOKKAUS')
    },
    rowData () {
      switch (this.contractLevel) {
        case 'contract': {
          let contractData = [...this.contracts]
          return contractData.map(data => {
            return {
              ...data,
              contract_type: this.$t(data.contract_type_label),
              contract_type_label: this.$t(data.contract_type_label),
              contract_method: data.contract_method_label,
              //contract_status: this.getDefinitionLabelById(data.contract_status),
              //renting_type: this.getDefinitionLabelById(data.renting_type),
              is_fixed_term: data.is_fixed_term ? this.$t('Yes') : data.is_fixed_term === null ? this.$t('Undefined') : this.$t('No'),
              is_taxable: data.is_taxable ? this.$t('Yes') : data.is_taxable === null ? this.$t('Undefined') : this.$t('No'),
              rent_review_type: data.rent_review_type_label,
              is_non_billable: data.is_non_billable ? this.$t('Yes') : data.is_non_billable === null ? this.$t('Undefined') : this.$t('No'),
              customer_stays_in_permises: data.customer_stays_in_premises ? this.$t('Yes') : data.customer_stays_in_premises === null ? this.$t('Undefined') : this.$t('No'),
              tenant: data.tenant === 'PrivatePerson' ? this.$t('PrivatePerson') : data.tenant ?? this.$t('Undefined'),
              rental_target_tenant: data.rental_target_tenant ?? this.$t('Undefined'),
              renter: data.renter ?? this.$t('Undefined'),
              asset_type_id: data.asset_type_label,
              address: data.address.filter(item => item).join(", "),
              target_name: data.target_name.filter(item => item).join(", "),
              target_code: data.target_code.filter(item => item).join(", "),
              is_draft: data.is_draft ? this.$t('Yes') : this.$t('No'),
              contract_state: data.contract_state ? this.$t(data.contract_state) : this.$t('Undefined'),
              validity: data.is_fixed_term ? this.$t('Fixed term') : this.$t('Permanent'),
              is_vat: data.is_vat  === null ? this.$t(' ') : data.is_vat ? this.$t('Taxable') : this.$t('Exempt from taxation'),
              removeAble: data.is_draft && (data.invoice_count == 0) && this.isUpdatingAllowed,
              post_number: data.post_number.filter(item => item).join(", "),
              automatic_billing_reasoning: data.automatic_billing_reasoning,
              automatic_billing_allowed: data.automatic_billing_allowed ? this.$t('Yes') : this.$t('No'),
              hallintamuoto: this.$t(data.hallintamuoto),
            }
          })
        }
        case 'contract_payments': {
          let contractPaymentsData = [...this.contractPayments]
          return contractPaymentsData.map(data => {
            return {
              ...data,
              agreed_area: data.agreed_area  !== null ? data.agreed_area  : this.$t('Undefined'),
              start_date: data.start_date  !== null ? data.start_date : this.$t(' '),
              end_date: data.end_date  !== null ? data.end_date : this.$t(' '),
              payment_date: data.payment_date  !== null ? data.payment_date  : this.$t(' '),
              latest_review_date: data.latest_review_date  !== null ?  data.latest_review_date : this.$t('Undefined'),
              invoicing_basis: data.invoicing_basis  ? this.$t(data.invoicing_basis) : this.$t('Undefined'),
              payment_type: data.payment_type ? this.$t(data.payment_type) : this.$t('Undefined'),
              def_basis: data.def_basis  !== null ?  data.def_basis  : this.$t('Undefined'),
              payment_basis: data.payment_basis  ? this.$t(data.payment_basis) : this.$t('Undefined'),
              is_discount_payment: data.is_discount_payment === null ? this.$t('No') : data.is_discount_payment ? this.$t('Yes') : this.$t('No'),
              unitnames: data.unitnames  !== null ? data.unitnames : this.$t(' '),
              rent_review_type: data.rent_review_type ? this.$t(data.rent_review_type) : this.$t('Undefined'),
              tenant: data.tenant  !== null ?  data.tenant : this.$t('Undefined'),
              payment_domain: data.payment_domain !== null ? this.$t(data.payment_domain) : this.$t('Undefined'),
              is_square_based: data.is_square_based  === null ? this.$t('No') : data.is_square_based ? this.$t('Yes') : this.$t('No'),
              site: data.site  !== null ? data.site  : this.$t('Undefined'),
              latest_billing_date: data.latest_billing_date !== null ? data.latest_billing_date : this.$t(' '),
              target: data.target === 'Whole contract payment  ' ? this.$t('Whole contract payment') : data.target
            }
          })
        }
        case 'contract_rows': {
          let contractRowsData = [...this.contractRows]
          return contractRowsData.map(data => {
            return {
              ...data,
              agreed_area: data.agreed_area  !== null ? data.agreed_area  : this.$t('Undefined'),
              start_date: data.start_date  !== null ? data.start_date : this.$t(' '),
              end_date: data.end_date  !== null ? data.end_date : this.$t(' '),
              payment_date: data.payment_date  !== null ? data.payment_date  : this.$t(' '),
              latest_review_date: data.latest_review_date  !== null ?  data.latest_review_date : this.$t('Undefined'),
              tenant: data.tenant  !== null ?  data.tenant : this.$t('Undefined'),
              site: data.site  !== null ? data.site  : this.$t('Undefined'),
              contract_status: this.$t(data.contract_status),
              contract_type: this.$t(data.contract_type),
              unit_status: data.unit_status !== null ? this.$t(data.unit_status) : null
            }
          })
        }
        case 'usage_fee': {
          let usageFeeData = [...this.contractData.usageFee]
          return usageFeeData.map(data => {
            return {
            ...data,
            is_nonrecurring: data.is_nonrecurring ? this.$t('Yes') : data.is_nonrecurring === null ? this.$t('Undefined') : this.$t('No')
            }
          })
        }
        default: {
          return []
        }
      }
    },
    contractNumbers () {
      return this.contracts.map((contract) => contract.contract_number);
    },
    rowsLoading () {
      return this.contractPaymentsDataLoading || this.contractDataLoading || this.contractRowsDataLoading
    },
    createFooters () {
      return []
    },
    footerDefinitions () {
      return []
    },
    getHeaders () {
      switch (this.contractLevel) {
        case 'contract':
          return [
          /*{ value: 'target_info', text: this.$t('Target info') },*/

          { text: 'Contract number', value: 'contract_number' , event: 'showCreateRentContractModal' },
          ...this.buildHeaders(this.contractMetadata.contract, true)
        ]
        case 'usage_fee':
          return [
          ...this.buildHeaders(this.contractMetadata.usageFee)
        ]
        case 'contract_payments':
          return [
          {
            text: this.$t('Contract number'),
            value: 'contract_number',
            event: 'showCreateRentContractModal',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Contract sites'),
            value: 'site',
            editable: false,
            type: undefined,
            event: 'showSite'
          },
          {
            text: this.$t('Site identifier'),
            value: 'site_identifier',
            editable: false,
            type: undefined,
          },
          {
            text: this.$t('Tenant'),
            value: 'tenant',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Payment type'),
            value: 'payment_type',
            format: 'Array',
            editable: false,
            type: undefined,
            isMultiSelect: true,
            optionsTranslationEntries: true,
            definitionGroupLabel: 'rent_payments.type'
          },
          {
            text: this.$t('Start date'),
            value: 'start_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('End date'),
            value: 'end_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Allocation'),
            value: 'target',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Billing date'),
            value: 'payment_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Payment domain'),
            value: 'payment_domain',
            format: 'Array',
            editable: false,
            type: undefined,
            isMultiSelect: true
          },
          {
            text: this.$t('Payment basis'),
            value: 'payment_basis',
            format: 'Array',
            editable: false,
            type: undefined,
            isMultiSelect: true,
            optionsTranslationEntries: true,
            definitionGroupLabel: 'rent_payments.basis'
          },
          {
            text: this.$t('create_rent_contract.payment_legend'),
            value: 'legend',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Invoicing basis'),
            value: 'invoicing_basis',
            format: 'Array',
            editable: false,
            type: undefined,
            isMultiSelect: true,
            optionsTranslationEntries: true,
            definitionGroupLabel: 'rent_payments.invoicing_basis'
          },
          {
            text: this.$t('InvoicingBasisPcs'),
            value: 'pcs_count',
            format: "Number",
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Agreed area'),
            value: 'agreed_area',
            format: 'Area',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('create_rent_contract.payment_pcs_based'),
            value: 'is_square_based',
            format: 'Array',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('create_rent_contract.contract_payment_discount'),
            value: 'is_discount_payment',
            format: 'Array',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Net square rent'),
            value: 'net_square_rent',
            format: 'Currency',
            editable: false,
            type: undefined,
            sortable: false,
          },
          {
            text: this.$t('create_rent_contract.payment_net_sum'),
            value: 'net_sum',
            format: 'Currency',
            editable: false,
            type: undefined,
            sortable: false
          },
          {
            text: this.$t('Tax'),
            value: 'tax',
            format: 'Percentage',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Gross square rent'),
            value: 'gross_square_rent',
            format: 'Currency',
            editable: false,
            type: undefined,
            sortable: false
          },
          {
            text: this.$t('create_rent_contract.gross_sum'),
            value: 'gross_sum',
            format: 'Currency',
            editable: false,
            type: undefined,
            sortable: false
          },
          {
            text: this.$t('Rent review type'),
            value: 'rent_review_type',
            format: 'Array',
            editable: false,
            type: undefined,
            isMultiSelect: true,
            optionsTranslationEntries: true,
            definitionGroupLabel: 'rent_payments.rent_review'
          },
          {
            text: this.$t('create_rent_contract.contract_payment_latest_review_date'),
            value: 'latest_review_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Last created invoice date'),
            value: 'latest_billing_date',
            format: 'MonthAndYear',
            editable: false,
            type: undefined
          },
        ]
        case 'contract_rows':
          return [
          {
            text: this.$t('Contract number'),
            value: 'contract_number',
            event: 'showCreateRentContractModal',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Tenant'),
            value: 'tenant_name',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Contract status'),
            value: 'contract_status',
            editable: false,
            type: undefined,
            sortable: false,
          },
          {
            text: this.$t('contract.start_date'),
            value: 'contract_start_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('contract.end_date'),
            value: 'contract_end_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Site identifier'),
            value: 'target_code',
            editable: false,
            type: undefined,
          },
          {
            text: this.$t('Contract sites'),
            value: 'site_name',
            editable: false,
            type: undefined,
            event: 'showSite',
            link: { target: 'target_id', path: 'sites.info' },
          },
          {
            text: this.$t('Building name'),
            value: 'building_name',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Building code'),
            value: 'building_code',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Address'),
            value: 'address',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Governance entity'),
            value: 'governance_entity',
            editable: false,
            type: undefined,
            definitionGroupLabel: 'building.governanceentity',
            isMultiSelect: true
          },
          {
            text: this.$t('Unit'),
            value: 'unit_code',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Structure'),
            value: 'structure_name',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Carpark'),
            value: 'parking_space_name',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Unit status'),
            value: 'unit_status',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Premise type'),
            value: 'unit_premise_type',
            editable: false,
            type: undefined,
            definitionGroupLabel: 'unit.usage',
            isMultiSelect: true
          },
          {
            text: this.$t('Target goal'),
            value: 'unit_target',
            editable: false,
            type: undefined,
            definitionGroupLabel: 'unit.goal',
            isMultiSelect: true
          },
          {
            text: this.$t('Cost center'),
            value: 'costcenter',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('contract_row.start_date'),
            value: 'start_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('contract_row.end_date'),
            value: 'end_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Net floor area'),
            value: 'unit_area',
            editable: false,
            type: undefined,
            format: 'Area'
          },
          {
            text: this.$t('Agreed area'),
            value: 'agreed_area',
            editable: false,
            type: undefined,
            format: 'Area'
          },
          {
            text: this.$t('Net sum'),
            value: 'net_sum',
            editable: false,
            type: undefined,
            format: 'Euro'
          },
          {
            text: this.$t('Contract type'),
            value: 'contract_type',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Clientele'),
            value: 'clientele',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Landlord'),
            value: 'renter_name',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Last created invoice date'),
            value: 'last_created_invoice_date',
            editable: false,
            type: undefined,
            format: 'MonthAndYear'
          }
        ]
        default: {
          return []
        }
      }
    },
    viewHeaders () {
      let headers = this.getHeaders
      this.resetMultiSelectKeys()
      headers.forEach(h => {
        if (h.isMultiSelect === true)
        {
          if (h.definitionGroupId !== null && h.definitionGroupId !== undefined &&
              h.value !== 'contract_type_label')
          {
            this.multiSelectKeys[h.value] = []
            this.definitionsByGroupId(h.definitionGroupId).forEach(
              definitionOfGroup => {
                let labelText = definitionOfGroup.label
                if (h.optionsTranslationEntries === true)
                {
                  labelText = this.$t(labelText)
                }
                this.multiSelectKeys[h.value].push({ text: labelText, value: definitionOfGroup.id })
              }
            )
            this.multiSelectKeys[h.value].push({ text: '-', value: 'NULL' })
          }
          else if (h.definitionGroupLabel !== undefined)
          {
            this.multiSelectKeys[h.value] = []
            this.definitionsByGroupLabel(h.definitionGroupLabel).forEach(
              definitionOfGroup => {
                let labelText = definitionOfGroup.label
                if (h.optionsTranslationEntries === true)
                {
                  labelText = this.$t(labelText)
                }
                this.multiSelectKeys[h.value].push({ text: labelText, value: definitionOfGroup.id })
              }
            )
            this.multiSelectKeys[h.value].push({ text: '-', value: 'NULL' })
          }
          else if (h.value === "payment_domain")
          {
            this.multiSelectKeys[h.value] = []
            this.rentPaymentDomains.forEach(paymentDomain => {
              this.multiSelectKeys[h.value].push({ text: this.$t(paymentDomain.name) + " " + this.$t("VAT") + " " + paymentDomain.vatPercent + "%", value: paymentDomain.id })
            })
            this.multiSelectKeys[h.value].push({ text: '-', value: 'NULL' })
          }
          this.multiSelectKeys['multiSelectOptionTypes'][h.value] = 1
        }
      })
      return headers
    },
    viewData () {
      return this.rowData
    },
    showPagination () {
      return true
    },
    getPagination () {
      if (this.contractLevel === 'contract_payments') {
        return this.contractPaymentsPagination
      }

      if (this.contractLevel === 'contract') {
        return this.contractPagination
      }

      if (this.contractLevel === 'contract_rows') {
        return this.contractRowsPagination
      }

      return this.tablePagination
    },
    availableStoredViews () {
      return this.storedViews.filter(sv => sv.level === this.contractLevel)
    },
    getContractExport () {
      if(this.contractLevel === 'contract'){
        return this.getContractExportData
      }
      else return null
    },
    getContractPaymentsExport () {
      if(this.contractLevel === 'contract_payments'){
        return this.getContractPaymentsExportData
      }
      else return null
    },
    getContractRowsExport () {
      if(this.contractLevel === 'contract_rows'){
        return this.getContractRowsExportData
      }
      else return null
    },
    getTableRowCount () {
      switch (this.contractLevel) {
        case 'contract':
          return this.contractsCount
        case 'contract_payments':
          return this.contractPaymentsCount
        case 'contract_rows':
          return this.contractRowsCount
        default:
          return 0
      }
    }
  },
  watch: {
    currentDate: async function () {
      await this.loadData()
    },
    contractLevel: async function (level) {
      await this.loadData()

      this.loading = true
      await this.getLevelData(level)
      this.loading = false
      this.selectedView = null
    },
    contractPaymentsPagination: {
      async handler () {
        await this.getContractPaymentsData()
      },
      deep: true
    },
    contractRowsPagination: {
      async handler () {
        await this.getContractRowsData()
      },
      deep: true
    },
    contractPagination: {
      async handler () {
          await this.getContractData()
      },
      deep: true
    },
    selectedContractStates: async function () {
      await this.loadData()
    },
    showIndicator: function (value) {
      if (value === true) {
        this.indicatorRunning = true
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
    operationResult: function (value) {
      if (value === 'error') {
        this.indicatorMessage = this.$t('Unsuccesfull save')
      }
      if (value === 'warning') {
        this.indicatorMessage = this.$t('Warning') + ': ' + name + ' ' + this.$t('will be overwritten')
      }
      if (value === 'success') {
        this.indicatorMessage = this.$t('Succesfull save')
      }
      if (value === 'changedDraft') {
        this.indicatorMessage = this.$t('Changed to draft')
      }
      if (value === 'savedDraft') {
        this.indicatorMessage = this.$t('Saved as draft')
      }
      if (value === 'delete') {
        this.indicatorMessage = this.$t('Succesfull delete')
      }
    },
    selectedView: function (view) {
      this.storedView = view ? this.storedViews.filter(sv => sv.level === this.contractLevel && sv.id === view)[0] : null
    }
  },
  mounted: async function () {
    this.updateTabLocation()
    var promises = []
    if (!this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS') && !this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')) {
      if(this.siteId === undefined)
      {
        this.$router.push({ name: 'dashboard' })
      }
      else
      {
        this.$router.push({ name: 'sites.info' })
      }
      promises.push(this.getStoredViews('contract'))
      await Promise.all(promises)
    } else {
      promises.push(this.getSettings())
      promises.push(this.getContractConditionsTemplates())
      promises.push(this.getAllRentPaymentDomains())
      promises.push(this.getContractMetadata())
      promises.push(this.getAllContractStates())
      promises.push(this.getStoredViews('contract'))
      await Promise.all(promises)
      this.formContractLevels()
    }
    await this.$store.dispatch('rentContracts/initContractTemplates')
    this.templates = this.getContractTemplatesByOutRent(this.tabLocation == 'contractlayers.renting_out')
    this.loading = false
  },
  methods: {
    ...mapActions('app', ['getUsageFeeData', 'getContractMetadata', 'getContractConditionsTemplates', 'getSettings', 'getStoredViews']),
    ...mapActions('dashboard', ['updateUnitInformation']),
    ...mapActions("calculator", ["resetCalculatorData"]),
    resetMultiSelectKeys () {
      this.multiSelectKeys = { multiSelectOptionTypes: {}}
    },
    async createContractWithTemplate (template) {
      this.contractTemplate = template
      this.showCreateRentContractModal = true;
    },
    async getLevelData (level) {
      let sortBy = ['contract_number']

      if (level === 'usage_fee') {
        if (!(this.contractData.usageFee.length > 0)) {
          await this.getUsageFeeData()
        }
      }
      this.tablePagination = {
        page: 1,
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy,
      }
    },
    onContractIdUpdated (contractId) {
      this.editedContractId = contractId
    },
    async loadData () {
      this.contractDataLoading = false
      this.contractPaymentsDataLoading = false
      this.contractRowsDataLoading = false

      if (this.contractLevel === 'contract'){
        await this.getContractData()
        await this.getContractTotalCount()
      }
      if (this.contractLevel === 'contract_payments') {
        await this.getContractPaymentsData()
        await this.getContractPaymentsTotalCount()
      }
      if (this.contractLevel === 'contract_rows') {
        await this.getContractRowsData()
        await this.getContractRowsTotalCount()
      }
    },
    async getContractPaymentsData () {
      this.contractPaymentsDataLoading = true
      const pagination = this.contractPaymentsPagination
      const descending = pagination.sortDesc ? pagination.sortDesc[0] : false
      const sortBy = pagination.sortBy ? pagination.sortBy[0] : null

      // Cancel the previous in progress request before proceeding
      if (this.abortController) {
        await this.abortController.abort()
      }
      this.abortController = new AbortController()
      const signal = this.abortController.signal
      const queryBody = {
        offset: (pagination.page - 1) * pagination.itemsPerPage,
        fetch: pagination.itemsPerPage,
        descending,
        time: this.currentDate,
        sortby: sortBy,
        search: this.searchValues,
        outRent: this.tabLocation == 'contractlayers.renting_out'
      }
      const resultRows = await this.$rambollfmapi.contracts.contractPayments.reports.get(queryBody, signal)

      // Update the contract payments if the request has not been canceled
      if (resultRows?.length >= 0) {
        this.contractPayments = resultRows
        this.contractPaymentsDataLoading = false
      }
    },
    async getContractData () {
      this.contractDataLoading = true
      const pagination = this.contractPagination
      const descending = pagination.sortDesc ? pagination.sortDesc[0] : false
      const sortBy = pagination.sortBy ? pagination.sortBy[0] : null

      // Cancel the previous in progress request before proceeding
      if (this.abortController) {
        await this.abortController.abort()
      }
      this.abortController = new AbortController()
      const signal = this.abortController.signal
      const queryBody = {
        offset: (pagination.page - 1) * pagination.itemsPerPage,
        fetch: pagination.itemsPerPage,
        descending,
        time: this.currentDate,
        sortby: sortBy,
        search: this.searchValues,
        outRent: this.tabLocation == 'contractlayers.renting_out',
        contractStates: this.selectedContractStates.map(state => state.id),
      }
      const resultRows = await this.$rambollfmapi.contracts.contract.contractreports.get(queryBody, signal)
      // Update the contracts if the request has not been canceled
      if (resultRows?.length >= 0) {
        this.contracts = resultRows
        this.contractDataLoading = false
      }
    },
    async getContractRowsData () {
      this.contractRowsDataLoading = true
      const pagination = this.contractRowsPagination
      const descending = pagination.sortDesc ? pagination.sortDesc[0] : false
      const sortBy = pagination.sortBy ? pagination.sortBy[0] : null

      // Cancel the previous in progress request before proceeding
      if (this.abortController) {
        await this.abortController.abort()
      }
      this.abortController = new AbortController()
      const signal = this.abortController.signal
      const queryBody = {
        offset: (pagination.page - 1) * pagination.itemsPerPage,
        fetch: pagination.itemsPerPage,
        descending,
        time: this.currentDate,
        sortby: sortBy,
        search: this.searchValues,
        outRent: this.tabLocation == 'contractlayers.renting_out',
        withPayments: true,
        contractStates: this.selectedContractStates.map(state => state.id),
      }
      const resultRows = await this.$rambollfmapi.contracts.contractRow.reports.get(queryBody, signal)

      // Update the contract payments if the request has not been canceled
      if (resultRows?.length >= 0) {
        this.contractRows = resultRows
        this.contractRowsDataLoading = false
      }
    },
    async outRentChanged () {
      await this.loadData()
      this.templates = this.getContractTemplatesByOutRent(this.tabLocation == 'contractlayers.renting_out')
    },
    onCreateRentContractModalClosed (addContract, closeModal) {
      if (addContract) {
        this.contractsCount += 1
      }

      this.getContractData()
      if (closeModal || closeModal === undefined) {
        this.editedContractId = undefined
        this.showCreateRentContractModal = false
      }
      this.resetCalculatorData()
    },
    getFieldText (field) {
      // guess the name
      let name = field.split('_').join(' ')

      if (field == 'tags') {
        name = 'Category'
      }

      return name.charAt(0).toUpperCase() + name.slice(1)
    },
    getFieldValue (field) {
      switch (field) {
        default:
          return field
      }
    },
    async formContractLevels () {
      this.contractLevels.push({
        text: this.$t('Contract level'),
        value: 'contract'
      })
      if (this.contractLevel === '') {
        this.contractLevel = 'contract'
      }
      this.contractLevels.push({
        text: this.$t('Contract payments level'),
        value: 'contract_payments'
      })
      if (this.settings.canShowContractRowLayer === true) {
        this.contractLevels.push({
          text: this.$t('Contract row level'),
          value: 'contract_rows'
        })
      }
    },
    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? this.$t(def.label) : this.$t('Undefined')
    },
    showColumnsFilterDialog () {
      this.showColumnsFilter = !this.showColumnsFilter
    },
    showDefaultSearchDialog () {
      this.showDefaultSearch = !this.showDefaultSearch
      this.confirmation = false
    },
    closeViewAndSelectionDialogs () {
      if (this.showDefaultSearch) {
        this.showDefaultSearchDialog()
      }
      if (this.showColumnsFilter) {
        this.showColumnsFilterDialog()
      }
    },
    exportAs (documentType) {
      this.exporting = {}
      this.exporting.documentType = documentType

      this.exportAsDocument = []
      this.exportAsDocument.push(documentType)
      this.exportAsDocument.push('contract')
    },
    onEditRow (row) {
      this.editedContractId = row.id
      this.showCreateRentContractModal = true
    },
    async onRemoveRow (row) {
      if (confirm(this.$t('Confirm remove contract draft'))) {
        await this.$rambollfmapi.contracts.contract.deleteContract(row.id)
        this.getContractData()
      }
    },
    calculateCanShowPortfolio (metadata) {
      let canShowPortfolio = false

      if("status_portfolio" in metadata){
        if (this.settings != undefined) {
          if (this.settings.length === 0) {
            canShowPortfolio = false;
          }
          else {
            canShowPortfolio = this.tabLocation == 'contractlayers.renting_out' == true
          ? this.settings.outrentCanConnectContractToPortfolio
          : this.settings.inrentCanConnectContractToPortfolio;
          }
        }
        return canShowPortfolio
      }
    },
    buildHeaders (metadata, fromContract = false) {
      if (metadata === null) {
        return []
      }

      let canShowPortfolio = this.calculateCanShowPortfolio(metadata)

      return Object.keys(metadata)
        // filter out metadata.contract_number because we want to initialize the header for contract_number elsewhere for clickable link
        .filter(key => key !== 'contract_number')
        .map(key => {
          return { ...metadata[key], name: key }
        })
        // filter with some hardcoded values
        .filter(v => {
          if (fromContract) {
            if ((v.name === 'status_portfolio')) {
              return canShowPortfolio
            }
          }
          return v.isShown
        })
        .filter(v => v.category === this.category)
        .sort((a, b) => (a.order < b.order ? -1 : 1))
        .map(key => {
          let header = {
            text: this.getFieldText(key.name),
            value: this.getFieldValue(key.name),
            format: key.format,
            sortable: fromContract && (key.name === 'payments_net_sum' || key.name === 'contract_state') ? false : key.sortable,
            isMultiSelect: key.definitionGroupId !== 0 && key.definitionGroupId !== null && key.definitionGroupId !== undefined && key.name !== 'contract_type_label',
            definitionGroupId: key.definitionGroupId,
            type: this.getFieldType(key),
          }
          if (header.text === 'Automatic billing reasoning') {
            header.hideOverflow = true
          }
          return header
        })
    },
    updatePagination (pag) {
      if (this.contractLevel === 'contract') {
        Object.assign(this.contractPagination, pag)
      }
      if (this.contractLevel === 'contract_payments') {
        Object.assign(this.contractPaymentsPagination, pag)
      }
      if (this.contractLevel === 'contract_rows') {
        Object.assign(this.contractRowsPagination, pag)
      }
    },
    async filterSearch (query) {
      if (this.contractLevel === 'contract') {
        let queryStr = JSON.stringify(query)
        if (queryStr != JSON.stringify(this.contractPagination.search)) {
          this.contractPagination.search = queryStr
          this.contractPagination.page = 1
          this.searchValues = queryStr
          await this.getContractTotalCount()
        }
      }
      if (this.contractLevel === 'contract_payments') {
        let queryStr = JSON.stringify(query)
        if (queryStr != JSON.stringify(this.contractPaymentsPagination.search)) {
          this.contractPaymentsPagination.search = JSON.stringify(query)
          this.contractPaymentsPagination.page = 1
          this.searchValues = JSON.stringify(query)
          await this.getContractPaymentsTotalCount()
        }
      }
      if (this.contractLevel === 'contract_rows') {
        let queryStr = JSON.stringify(query)
        if (queryStr != JSON.stringify(this.contractRowsPagination.search)) {
          this.contractRowsPagination.search = JSON.stringify(query)
          this.contractRowsPagination.page = 1
          this.searchValues = JSON.stringify(query)
          await this.getContractRowsTotalCount()
        }
      }
    },
    async getContractTotalCount () {
      const stateIds = this.selectedContractStates.map(state => state.id)
      const result = await this.$rambollfmapi.contracts.contract.contractreports.totalCount(this.currentDate, this.searchValues, this.tabLocation == 'contractlayers.renting_out', stateIds)
      this.contractsCount = isNaN(result) ? 0 : result
    },
    async getContractPaymentsTotalCount () {
      const result = await this.$rambollfmapi.contracts.contractPayments.reports.totalCount(this.currentDate, this.searchValues, this.tabLocation == 'contractlayers.renting_out')
      this.contractPaymentsCount = isNaN(result) ? 0 : result
    },
    async getContractRowsTotalCount () {
      const stateIds = this.selectedContractStates.map(state => state.id)
      const result = await this.$rambollfmapi.contracts.contractRow.reports.totalCount(this.currentDate, this.searchValues, this.tabLocation == 'contractlayers.renting_out', stateIds)
      this.contractRowsCount = isNaN(result) ? 0 : result
    },
    async getAllContractStates () {
      await this.getSettings()
      const states = await this.$rambollfmapi.contracts.contract.getAllContractStates()
      var filteredStates = states
      if (this.settings.canShowContractSigningStatuses == false) {
        filteredStates = states.filter(obj => ![
          'ContractStateReadyForSigning',
          'ContractStateSigningRequestSent',
          'ContractStateSigningRequestExpired',
          'ContractStateNoElectornigSigning',
          'ContractStateRequestFailed'
        ].includes(obj.name));
      }
      let ContractStateExpiredId = filteredStates.filter(state => state.name == "ContractStateExpired").map(state => state.id);
      this.contractStates = filteredStates
      this.translatedContractStates = filteredStates.map(state => ({ id: state.id, name: this.$t(state.name) }))
      this.selectedContractStates = this.translatedContractStates
      this.selectedContractStates = this.translatedContractStates.filter(state => !ContractStateExpiredId.includes(state.id));
    },
    async getAllRentPaymentDomains () {
      const result = await this.$rambollfmapi.contracts.contract.getRentPaymentDomains()
      this.rentPaymentDomains = result
    },
    openContractModal (row) {
      this.editedContractId = row.id
      this.showCreateRentContractModal = true
    },
    showSite (row) {
      let siteIdArr = row.siteids.split(',')
      if (siteIdArr.length == 1) {
        window.open("../sites/" + siteIdArr[0].trim(), "_blank")
      }
    },
    triggerCustomEvents (event) {
      if (event.eventName === 'showCreateRentContractModal') {
        this.openContractModal(event.row)
      }
      if (event.eventName === 'showSite') {
        this.showSite(event.row)
      }
    },
    async getContractExportData () {
        const data = await this.$rambollfmapi.contracts.contract.contractreports.get({
            time: this.currentDate,
            offset: 0,
            fetch: 1000000,
            descending: this.tablePagination.sortDesc[0],
            sortby: this.tablePagination.sortBy[0],
            search: this.searchValues,
            contractStates: this.selectedContractStates.map(state => state.id),
            outRent: this.tabLocation == 'contractlayers.renting_out'
          })

        return data
    },
    async getContractPaymentsExportData () {
        const data = await this.$rambollfmapi.contracts.contractPayments.reports.get({
            time: this.currentDate,
            offset: 0,
            fetch: 1000000,
            descending: this.tablePagination.sortDesc[0],
            sortby: this.tablePagination.sortBy[0],
            search: this.searchValues,
            outRent: this.tabLocation == 'contractlayers.renting_out'
          })

        return data
    },
    async getContractRowsExportData () {
        const data = await this.$rambollfmapi.contracts.contractRow.reports.get({
            time: this.currentDate,
            offset: 0,
            fetch: 1000000,
            descending: this.tablePagination.sortDesc[0],
            sortby: this.tablePagination.sortBy[0],
            search: this.searchValues,
            outRent: this.tabLocation == 'contractlayers.renting_out',
            withPayments: true,
            contractStates: this.selectedContractStates.map(state => state.id),
          })

        return data
    },
    selectView (view) {
      if (view) {
        this.selectedView = this.availableStoredViews.find(v => v.name === view.name).id
      } else {
        this.selectedView = null
      }
    },
    async saveStoredView (name, selectedHeaders, confirm, privacy = true) {
      this.confirmation = false
      const exists = this.availableStoredViews.includes(name)
      if (exists && !confirm) {
          this.confirmation = true
          this.operationResult = 'warning'
          this.storedView.stored_fields = selectedHeaders.map(h => {
          return {
              field: h,
              id_storedview: this.storedView.id
          }
        })
      } else {
          try {
            var rename = false
            if (exists) {
              // Modify existing
              await this.$rambollfmapi.storedviews.put(this.storedView.id, selectedHeaders)
            } else {
              // Create new
              const data = {
                  parent_level: 'contract',
                  level: this.contractLevel,
                  name: name,
                  privacy: privacy
              }

              await this.$rambollfmapi.storedviews.post(data, selectedHeaders).then(res => {
                if (res == 'Name already exist') {
                  rename = true
                }
              })
            }
            if (rename == false) {
              this.closeViewAndSelectionDialogs()
              this.operationResult = 'success'
              this.indicatorMessage = this.$t('Save success')
            } else {
              this.operationResult = 'warning'
              this.indicatorMessage = this.$t('Name already in use')
            }
          } catch (error) {
            this.operationResult = 'error'
            this.indicatorMessage = this.$t('Error while saving')
          }
      }
      this.showIndicator = true
      this.operationResult = 'success'
      if (this.operationResult === 'success') {
        await this.getStoredViews('contract')
        this.selectedView = this.availableStoredViews.find(view => view.name === name).id
      }
    },
    async deleteSelectedView (id) {
      const view = this.availableStoredViews.find(x => x.id === id)
      if (confirm(this.$t('storedview.deletion') + ' ' + view.name + '?' + '\n' + (view.private ? this.$t('storedview.deletion.private') : this.$t('storedview.deletion.public')))) {
        try {
            await this.$rambollfmapi.storedviews.delete(id)
            this.operationResult = 'delete'
            this.selectedView = null
        } catch (error) {
            this.operationResult = 'error'
        }
        await this.getStoredViews(this.parentLevel)
        this.showIndicator = true
      }
    },
    getViewShortName (s) {
      const breakpoint = this.$vuetify.breakpoint
      var max = 0
      if (breakpoint.xl) {
        max = 40
      } else if (breakpoint.lg) {
        max = 20
      } else if (breakpoint.md) {
        max = 10
      } else if (breakpoint.sm) {
        max = 5
      } else if (breakpoint.xs) {
        max = 2
      }

      if (s.length > max) {
        return s.substring(0, max) + '...'
      }
      return s
    },
    getFieldType (field) {
      if (!field) return null;

      let type = null
      switch (field.name) {
        case "tags":
          type = "chipField"
          break;
      }
      return type
    },
    updateTabLocation () {
      // Initialize first tab to be open
      if (this.$router.history.current.name === 'contractlayers') {
        if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS')) {
            this.$router.push({ name: 'contractlayers.renting_out'})
        } else if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')) {
            this.$router.push({ name: 'contractlayers.renting_in'})
        } else {
          this.$router.push({ name: 'contractlayers.dashboard'})
        }
      }

      if (this.tabLocation === 'contractlayers.renting_out' && !this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS')) {
        if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')) {
          this.$router.push({ name: 'contractlayers.renting_in'})
        } else {
          this.$router.push({ name: 'contractlayers.dashboard'})
        }
      } else if (this.tabLocation === 'contractlayers.renting_in' && !this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')) {
        if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS')) {
          this.$router.push({ name: 'contractlayers.renting_out'})
        } else {
          this.$router.push({ name: 'contractlayers.dashboard'})
        }
      }

      this.tabLocation = this.$router.history.current.name
    }
  }
}
</script>
<style scoped>
.combobox >>> .v-select__selections {
  overflow: hidden;
  flex-wrap: nowrap;
}
.stateText {
  overflow: hidden;
  white-space: nowrap;
  min-width: fit-content;
}
.storedViewSelection {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: clip;
  max-width: 100%;
  background-color: "blue";
}
.viewSelection {
  width: 25%;
}

@media screen and (max-width: 600px) {
  .viewSelection {
    width: 100%;
    padding-right: 5px;
  }
}
</style>