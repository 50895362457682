import { i18n } from '../plugins/i18n.js'

export function DisassembledAndRemovedWidget () {
  return {
    id: 'dashboard.disassembled_and_removed_reporting',
    type: 'ObjectTable',
    loading: true,
    data: {
      headers: [
        { text: i18n.t('Site identifier'), value: 'SiteIdentifier' },
        { text: i18n.t('Site name without link'), value: 'SiteName' },
        { text: i18n.t('Official name'), value: 'OfficialName' },
        { text: i18n.t('Estate indetifier'), value: 'EstateIdentifier'},
        { text: i18n.t('Address'), value: 'Address'},
        { text: i18n.t('Building code'), value: 'BuildingIdentifier'},
        { text: i18n.t('Permanent building identifier'), value: 'PermanentBuildingIdentifier'},
        { text: i18n.t('Year of manufacture'), value: 'YearOfManufacture'},
        { text: i18n.t('Buildingclass'), value: 'BuildingClassification'},
        { text: i18n.t('Action'), value: 'Type' },
        { text: i18n.t('hierarchy_removal.level'), value: 'Level', format: 'Translation' },
        { text: i18n.t('hierarchy_removal.identifier'), value: 'Identifier' },
        { text: i18n.t('Net floor area'), value: 'UnitArea' },
        { text: i18n.t('hierarchy_removal.reason'), value: 'Reason' },
        { text: i18n.t('End date'), value: 'DateOfRemoval', format: 'Date' },
        { text: i18n.t('Income'), value: "Income" },
        { text: i18n.t('Expenditure'), value: "Expenditure" },
        { text: i18n.t('Margin'), value: "Margin" },
        { text: i18n.t('technicalValues.conditionPercentage'), value: "ConditionPercentage", format: 'Percentage'},
        { text: i18n.t('technicalValues.technicalValue'), value: "TechnicalValue", format: 'Euro'},
        { text: i18n.t('technicalValues.repairDebt75'), value: "RepairDebt75", format: 'Euro'},
        { text: i18n.t('hierarchy_removal.unit_count'), value: 'UnitCount', format: 'Amount' }
      ],
      filter: {
        header: ['Level'],
        type: ['equal', 'notEqual'],
        options: [
          { text: 'All', value: [null]},
          { text: 'Site', value: ['Site'] },
          { text: 'Estate', value: ['Estate'] },
          { text: 'Building', value:['Building'] },
          { text: 'Unit', value: ['Unit'] }
        ],
      },
      sortBy: "DateOfRemoval",
      sortDesc: true,
      items: [],
      ignoreSubitemsInTable: true,
    }
  }
}