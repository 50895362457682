<template>
  <v-row
    class="ma-1"
    justify="end"
  >
    <v-col>
      <v-row
        v-for="(share, index) in limitedShares"
        :key="index"
        class="mb-1"
      >
        <v-autocomplete
          v-model="share.idCostcenter"
          :items="costcenters"
          item-text="name"
          item-value="id"
        />
        <v-text-field
          v-model="share.share"
          class="share-amount-input"
          type="number"
          clearable
          :placeholder="'-'"
        />
        <v-btn
          icon
          @click="clearIndex(index)"
        >
          <v-icon>clear</v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-btn
          v-if="sharesOverLimit > 0"
          class="pa-0"
          plain
          @click="toggled = !toggled"
        >
          <v-icon v-if="!toggled">
            arrow_drop_down
          </v-icon>
          <v-icon v-else>
            arrow_drop_up
          </v-icon>
          <!-- <span v-if="!toggled">Näytä piilotetut {{ sharesOverLimit }} kustannuspaikkaa</span> -->
          <span v-if="!toggled">
            {{ $t('CostcenterSharesField.show_rows', { n: sharesOverLimit }) }}
          </span>
          <span v-else>
            {{ $t('CostcenterSharesField.hide_rows', { n: sharesOverLimit }) }}
          </span>
        </v-btn>
        <v-spacer />
        <v-btn
          plain
          @click="addCostcenterRow()"
        >
          <v-icon>add</v-icon>
          <span>{{ $t('CostcenterSharesField.add_row') }}</span>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'CostcenterSharesField',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    costcenters: {
      type: Array,
      default: () => []
    },
    visibleLimit: {
      type: Number,
      default: () => 3
    }
  },
  data () {
    return {
      toggled: false,
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    limitedShares () {
      if (this.toggled) {
        return this.model
      } else {
        return this.model.slice(0, this.visibleLimit)
      }
    },
    sharesOverLimit () {
      if (this.model.length < this.visibleLimit) {
        return 0
      } else {
        return this.model.length - this.visibleLimit
      }
    }
  },
  methods: {
    addCostcenterRow () {
      this.model.push({
        idCostcenter: 0,
        share: null
      })
      // We do not want the new row to appear unnoticed, so we make sure to show all costcenters if it would go out of view.
      if (this.sharesOverLimit > 0) {
        this.toggled = true
      }
    },
    clearIndex (index) {
      this.model.splice(index, 1)
    },
  }
}
</script>
<style scoped>
.share-amount-input {
  width: 1em;
}
</style>