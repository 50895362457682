import { i18n } from '../plugins/i18n.js'

export function SalesWidget () {
  return {
    id: 'dashboard.sale_reporting',
    type: 'ObjectTable',
    loading: true,
    data: {
      headers: [
        { text: i18n.t('Site identifier'), value: 'SiteIdentifier' },
        { text: i18n.t('Site name without link'), value: 'SiteName',  customWidth: '150px' },
        { text: i18n.t('Official name'), value: 'OfficialName' },
        { text: i18n.t('Estate indetifier'), value: 'EstateIdentifier'},
        { text: i18n.t('Address'), value: 'Address'},
        { text: i18n.t('Building code'), value: 'BuildingIdentifier'},
        { text: i18n.t('Permanent building identifier'), value: 'PermanentBuildingIdentifier'},
        { text: i18n.t('Year of manufacture'), value: 'YearOfManufacture'},
        { text: i18n.t('Buildingclass'), value: 'BuildingClassification'},
        { text: i18n.t('Unit code'), value: 'UnitCode' },
        { text: i18n.t('Usage'), value: 'PurposeOfUse', customWidth: '175px'},
        { text: i18n.t('Net floor area'), value: 'UnitArea' },
        { text: i18n.t('DebtfreeSellingPrice'), value: 'DebtfreeSellingPrice', format: 'Euro' },
        { text: i18n.t('Sell date'), value: 'DateOfRemoval', format: 'Date' },
        { text: i18n.t('SalePrice'), value: 'Price', format: 'Euro', customWidth: '150px'},
        { text: i18n.t('SaleType'), value: 'SaleType' },
        { text: i18n.t('LoanPortion'), value: 'LoanPortion', format: 'Euro' },
        { text: i18n.t('Buyer'), value: 'Buyer'},
        { text: i18n.t('Seller'), value: 'Seller'},
        { text: i18n.t('Stocknumbers'), value: 'Stocknumbers'},
        { text: i18n.t('AdditionalInfo'), value: 'AdditionalInformation', hideOverflow: true },
        { text: i18n.t('Income'), value: "Income"},
        { text: i18n.t('Expenditure'), value: "Expenditure"},
        { text: i18n.t('Margin'), value: "Margin"},
        { text: i18n.t('technicalValues.conditionPercentage'), value: "ConditionPercentage", format: 'Percentage'},
        { text: i18n.t('technicalValues.technicalValue'), value: "TechnicalValue", format: 'Euro'},
        { text: i18n.t('technicalValues.repairDebt75'), value: "RepairDebt75", format: 'Euro'},
        { text: i18n.t('WholeSiteSold'), value: 'WholeSiteSold', format: 'YesNoUnknown'},
        { text: i18n.t('SoldUnits'), value: 'UnitCount', format: 'Amount' },
        { text: i18n.t('SoldBuildings'), value: 'BuildingCount', format: 'Amount' },
        { text: i18n.t('SoldStructures'), value: 'StructureCount', format: 'Amount' },
      ],
      filter: {
        header: ['Level'],
        type: ['equal', 'notEqual'],
        options: [
          { text: 'All', value: [null]},
          { text: 'Site', value: ['Site'] },
          { text: 'Estate', value: ['Estate'] },
          { text: 'Building', value:['Building'] },
          { text: 'Unit', value: ['Unit'] }
        ],
      },
      items: [],
      sortBy: "DateOfRemoval",
      sortDesc: true,
      ignoreSubitemsInTable: true,
    }
  }
}

