!<template>
  <v-list class="mx-4 my-1 py-0">
    <div
      v-for="item in sortedData"
      :key="item.contractNumber + '-' + item.curPartyName + '-' + item.siteName + '-' + item.contractId"
    >
      <v-list-group
        no-action
        color=""
        class="row-with-border"
      >
        <template
          #activator
        >
          <v-list-item-content class="title-tile">
            <span v-if="siteTitle">{{ item.contractNumber }} {{ item.siteName }}</span>
            <span v-else>{{ item.contractNumber }} {{ item.curPartyName }}</span>
          </v-list-item-content>
          <v-list-item-content class="pl-2 text-center">
            <v-chip
              class="chip"
              :class="item.className"
            >
              {{ item.status }}
            </v-chip>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(value, valueName) in metadata"
          :key="valueName"
          class="px-0"
          :class="{ 'bottom-gap': value.groupLast }"
        >
          <v-list-item-content
            class="ma-0 pa-0"
          >
            <v-row>
              <v-col
                cols="6"
                class="pa-4 borders-left background-grey"
              >
                <span>{{ $t(value.name) }}</span>
              </v-col>
              <v-col
                cols="6"
                class="pa-4 borders-right small-text font-weight-regular background-grey"
              >
                <span class="">{{ getValueWithUnits(item[valueName], value.format) }}</span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </div>
  </v-list>
</template>

<script>
import format from '../../helpers/format.js'
import { mapState } from 'vuex'

export default {
  name: 'ContractDataForm',
  props: {
    data: { type: Array, default: () => []},
    metadata: {type: Object, default: null},
    siteTitle: {type: Boolean, default: false}
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('app', ['currentDate']),
    // Let's sort the data so valid contracts show up first
    sortedData () {
      return this.data.map(contract => {
        const endDate = new Date(contract.curPartyEndDate).getTime()
        const startDate = new Date(contract.curPartyStartDate).getTime()
        const now = new Date(this.currentDate).getTime()
        const sixMonthsFromNow = new Date(this.currentDate).getTime() + 180 * 24*60*60*1000

        let className;
        let status;
        let order = 5;

        if (endDate !== 0 && endDate < now) {
          className =  'concluded'
          status = this.$t('leasing.contract_concluded'),
          order = 4
        }
        else if (endDate !== 0 && endDate < sixMonthsFromNow) {
          className = 'concluding'
          status = this.$t('leasing.contract_concluding')
          order = 2
        }
        else if (startDate > now) {
          className = 'starting'
          status = this.$t('leasing.contract_starting')
          order = 3
        }
        else {
          className = 'valid'
          status = this.$t('leasing.valid')
          order = 1
        }

        return {
          ...contract,
          className,
          status,
          order
        }
      }).sort((a, b) => a.order - b.order)
    }
  },
  methods: {
    getValueWithUnits (value, formatting) {
      return format.formatData(value, formatting)
    }
  }
}
</script>

<style scoped>
.bottom-gap {
  margin-bottom: 4px !important;
}
.small-text {
  font-size: 13px;
  display: flex;
  align-items: center;
}
.row-with-border ::v-deep .v-list-group__header {
  padding-left: 10px !important;
  border: 2px solid #ebeef8 !important;
  border-top: 2px solid #ebeef8 !important;
  border-bottom: 2px solid #ebeef8 !important;
}
.borders-left {
  border-left: 2px solid #ebeef8 !important;
  border-right: 1px solid #ebeef8 !important;
  border-top: 2px solid #ebeef8 !important;
  border-bottom: 2px solid #ebeef8 !important;
}
.borders-right {
  border-left: 1px solid #ebeef8 !important;
  border-right: 2px solid #ebeef8 !important;
  border-top: 2px solid #ebeef8 !important;
  border-bottom: 2px solid #ebeef8 !important;
}
.background-grey {
  background: #d5e9f6 !important;
}
.title-tile {
  min-width: 51%;
  border-right: 2px solid #ebeef8 !important;
}
.chip {
  max-width: 100px;
  justify-content: center;
  color: white;
}
.starting {
  background-color: #fb8c00 !important;
}
.concluded {
  background-color: #ff5252 !important;
}
.valid {
  background-color: #47ccaa !important;
}
.concluding {
  background-color: #f1da0e !important;
}
</style>