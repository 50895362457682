<template>
  <div :style="`border: 5px solid ${borderColor}`">
    <v-dialog
      v-if="rentalProcessCopy"
      v-model="showMFilesAddingDialog"
      persistent
      max-width="620"
    >
      <v-card>
        <AddMFilesIdsDialog
          :rental-process-id="rentalProcess.id"
          :visible="showMFilesAddingDialog"
          @updateStage="updateStage"
          @close="showMFilesAddingDialog = false"
        />
      </v-card>
    </v-dialog>
    <v-overlay
      absolute
      :value="rentalProcessLoading"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-dialog
      v-model="showInactivationDialog"
      max-width="550"
      persistent
    >
      <v-card>
        <InactivationModal
          :inactivation-reasons="inactivationReasons"
          :show-info="false"
          title="leasing.passivate_rentalprocess"
          @close="showInactivationDialog = false"
          @passivate="passivate($event)"
        />
      </v-card>
    </v-dialog>
    <rental-process-history-modal
      :rental-process="showProcessHistory"
      @cancel="showProcessHistory = null"
    />
    <BaseModal
      v-if="rentalProcess"
      @cancel="close"
    >
      <template #title>
        <RentalProcessTitle
          :rental-process="rentalProcess"
        />
      </template>
      <template
        #content
      >
        <div class="d-flex align-center">
          <RentalProcessStatus
            :rental-process="rentalProcess"
            @updateStage="updateStage"
          />
          <v-btn
            fab
            x-small
            class="blue-button"
            elevation="0"
            @click="showProcessHistory = rentalProcess"
          >
            <v-icon size="24px">
              history
            </v-icon>
          </v-btn>
          <v-tooltip
            top
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  :disabled="!hasApplicationPermissionByName('PROSPEKTI_POISTO')"
                  icon
                  x-large
                  @click="deleteItem"
                >
                  <v-icon>
                    delete
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ $t('prospect.delete_info') }}</span>
          </v-tooltip>
        </div>
        <v-container
          v-if="rentalProcessCopy"
          fluid
          class="pa-2 full-width"
        >
          <v-row class="px-2">
            <EditCorporation
              :fields="corporationFields"
              :values="corporationValues"
              :title="$t('Tenant corporation')"
              :loading="corporationsLoading"
              @save="updateCorporation"
            />
          </v-row>
          <v-row class="px-2">
            <EditField
              :fields="responsibleFields"
              :values="responsibleValues"
              :title="$t('leasing.responsibles')"
              :loading="usersLoading"
              @save="patchResponsibles"
            />
          </v-row>
          <v-row class="px-2">
            <LeadEdit
              :item="leadInfo"
              @save="patchLeadInfo"
            />
          </v-row>
          <v-row class="px-2">
            <CurrencyEdit
              v-if="userCurrencies.length > 1"
              :selected-currency="selectedCurrency"
            />
          </v-row>
          <v-row class="px-2">
            <MultipleEditField
              :fields="contactPersonFields"
              :title="$t('leasing.customer_contacts')"
              :items="rentalProcessCopy.contact_persons"
              :max-instances="3"
              @save="updateContacts"
            />
          </v-row>
          <v-row class="px-2">
            <MultipleEditField
              :fields="contactPersonFields"
              :items="rentalProcessCopy.asset_managers"
              :title="$t('Asset manager')"
              :edit-disabled="true"
              :edit-disabled-info="$t('rental_process.asset_manager_source')"
            />
          </v-row>
          <v-row>
            <v-col
              md="6"
              sm="12"
            >
              <v-expansion-panels
                :value="stageNumber >= 4 ? 1 : 0"
                class="my-2"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('leasing.customer_need_proposal') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <FieldGroup
                      :item="rentalProcessCopy"
                      :metadata="customerNeedMetadata"
                      @changeValue="changeValue"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels
                v-if="stageNumber >= 3"
                :value="stageNumber >= 4 ? 1 : 0"
                class="my-2"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('rental_process.exact_customer_needs') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <FieldGroup
                      :item="rentalProcessCopy"
                      :metadata="exactCustomerNeedsMetadata"
                      :selected-currency="selectedCurrency"
                      @changeValue="changeValue"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels
                v-if="stageNumber >= 4"
                :value="stageNumber >= 6 ? 1 : 0"
                class="my-2"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('rental_process.profitability_and_proposal') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <FieldGroup
                      :item="rentalProcessCopy"
                      :metadata="profitabilityAndProposalMetadata"
                      :selected-currency="selectedCurrency"
                      @changeValue="changeValue"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <dynamic-widget
                v-if="rentrollWidget.data.items && rentrollWidget.data.items.length > 0"
                :type="rentrollWidget.type"
                :title="$t('Contracts')"
                :data="rentrollWidget.data"
                :loading="rentrollLoading"
                :maximized="rentrollWidget.maximized"
              />
            </v-col>
            <v-col
              md="6"
              sm="12"
            >
              <v-expansion-panels
                :value="stageNumber >= 5 ? 1 : 0"
                class="my-2"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('leasing.concept') }} / {{ $t('leasing.customer_needs') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <MetadataForm
                      v-model="rentalProcessCopy"
                      :metadata="customerNeedDetailedMetadata"
                      :multiline="true"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <AddSiteForm
                :selected-sites="rentalProcessCopy.sites"
                :panel-closed="stageNumber >= 6"
                :selected-currency="selectedCurrency"
                @add="addSite"
                @edit="editSite"
                @delete="deleteSite"
              />
              <v-expansion-panels
                v-if="stageNumber >= 4"
                :value="stageNumber >= 6 ? 1 : 0"
                class="my-2"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('rental_process.estimate') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <FieldGroup
                      :item="rentalProcessCopy"
                      :metadata="predictionMetadata"
                      :selected-currency="selectedCurrency"
                      @changeValue="changeValue"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels
                v-if="stageNumber >= 6"
                :value="stageNumber >= 8 ? 1 : 0"
                class="my-2"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('rental_process.identifiers') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <EditMFilesIds
                      v-if="settings.hasMFilesIntegration"
                      :fields="mFilesIdFields"
                      :values="mFilesIdValues"
                      :title="$t('leasing.editIds')"
                      @save="updateIdentifiers"
                    />
                    <v-col
                      cols="12"
                      class="px-2"
                    >
                      <v-autocomplete
                        v-model="rentalProcessCopy.contract_info"
                        :items="currentContractNumbers"
                        multiple
                        item-text="contractNumber"
                        dense
                        chips
                        deletable-chips
                        :label="$t('Contract numbers')"
                        return-object
                        :loading="contractNumbersLoading"
                      >
                        <template #item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.contractNumber }}</v-list-item-title>
                            <v-list-item-subtitle>{{ getDateText(item.contractSigningDate) }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels
                v-if="stageNumber >= 7"
                :value="0"
                class="my-2"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('rental_process.reporting') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-for="(item, index) in rentalProcessCopy.contract_info"
                      :key="index"
                    >
                      <hr v-if="index > 0">
                      <div class="mt-2 ml-2">
                        {{ $t('Contract number') }}: {{ item.contractNumber }}
                      </div>
                      <FieldGroup
                        :item="item"
                        :index="index"
                        :metadata="rentalReportingMetadata"
                        :selected-currency="selectedCurrency"
                        @changeValue="changeContractInfoValue"
                      />
                    </div>
                    <div
                      v-if="!rentalProcessCopy.contract_info.length"
                      class="mt-2 ml-2 text-center"
                      style="font-weight: normal;"
                    >
                      <span>{{ $t('No data available') }}</span>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <Notes
                :single-process="rentalProcessCopy"
                :info="('leasing.rental_process_notes_info')"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template #hideCancel>
        <p />
      </template>
      <template #footer>
        <v-col>
          <div
            v-if="errorMessages.length > 0"
          >
            <CdsAlert
              type="danger"
              :title="$t('Following mandatory contract fields should be filled')"
              size="medium"
              dismissible
            >
              <ul>
                <li
                  v-for="message in errorMessages"
                  :key="message"
                >
                  {{ message }}
                </li>
              </ul>
            </CdsAlert>
          </div>
          <rental-process-button-row
            :inactive="inactive"
            :inactive-reason="inactiveReason"
            :closed="closed"
            :data-changed="dataChanged"
            :save-loading="rentalProcessLoading"
            :stage="rentalProcess.stage"
            :stage-number="stageNumber"
            @updateStage="updateStage"
            @save="save"
            @passivate="showInactivationDialog = true"
            @activate="activate"
          />
        </v-col>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "../general/BaseModal.vue";
import RentalProcessStatus from './RentalProcessStatus.vue'
import RentalProcessTitle from './RentalProcessTitle.vue'
import FieldGroup from './FieldGroup.vue'
import EditMFilesIds from './EditMFilesIds.vue'
import MetadataForm from '../MetadataForm.vue'
import AddSiteForm from './AddSiteForm.vue'
import Notes from './Notes.vue'
import InactivationModal from './InactivationModal.vue'
import AddMFilesIdsDialog from './AddMFilesIdsDialog.vue'
import EditField from './EditField.vue'
import EditCorporation from './EditCorporation.vue'
import LeadEdit from './LeadEdit.vue'
import CurrencyEdit from './CurrencyEdit.vue'
import { rentalProcessStageColors } from '../../constants/colors.js';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import lodash from 'lodash'
import RentalProcessHistoryModal from './Modals/RentalProcessHistoryModal.vue';
import RentalProcessButtonRow from './RentalProcessButtonRow.vue';
import MultipleEditField from './MultipleEditField.vue'
import moment from 'moment'
import DynamicWidget from '../DynamicWidget.vue';
import { RentRollWidget } from '../../widgets/leasing/contracts/rentrollWidget.js'
import CdsAlert from '../CircleDesignSystem/CdsAlert.vue'

export default {
  name: "RentalProcessModal",
  components: {
    BaseModal,
    RentalProcessTitle,
    RentalProcessStatus,
    FieldGroup,
    MetadataForm,
    AddSiteForm,
    Notes,
    InactivationModal,
    AddMFilesIdsDialog,
    EditField,
    EditMFilesIds,
    LeadEdit,
    RentalProcessHistoryModal,
    RentalProcessButtonRow,
    MultipleEditField,
    DynamicWidget,
    EditCorporation,
    CurrencyEdit,
    CdsAlert,
  },
  props: {
    rentalProcess: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  data () {
    return {
      // Rentroll widget
      rentrollWidget: RentRollWidget(),

      // Copy of rental process object
      rentalProcessCopy: null,

      // Dialog states
      showInactivationDialog: false,

      // Fetched values
      locationsList: [],
      cities: [],
      users: [],
      tenantCorporations: [],

      // Fetching states
      citiesLoading: false,
      usersLoading: false,
      rentrollLoading: false,
      corporationsLoading: false,
      contractNumbersLoading: false,

      // History modal
      showProcessHistory: null,

      // Victory confirmation / add M-Files ids dialog
      showMFilesAddingDialog: false,

      errorMessages: [],
      currentContractNumbers: null,
    }
  },
  computed: {
    ...mapState('app', ['availableSites', 'currentDate']),
    ...mapState('rentalProcess', ['rentalProcessLoading', 'locations']),
    ...mapGetters('app', ['definitionsByGroupLabel', 'hasApplicationPermissionByName', "definitionById", 'settings', 'currencies']),

    inactiveReason () {

      if(this.rentalProcess?.status_description){
        return this.definitionById(this.rentalProcess.status_description)?.label
      }

      return ""

    },

    // Rental process inactive status
    inactive () {
      return this.rentalProcess.status === 'Inactive'
    },
    closed () {
      return this.rentalProcess.status === 'Closed'
    },
    // Metadata for widgets
    customerNeedMetadata () {
    const key = this.locationsList.length !== 0 ? 'locations' : 'cities'
    return {
        customer_need: {
          name: 'leasing_describe_needs',
          editType: 'TextField',
          cols: 8,
          maxlength: 80
        },
        area_preference: {
          name: 'prospect.area_requirement',
          editType: 'Area',
          suffix: 'm\u00B2',
          cols: 4
        },
        [key]: {
          name: key === 'locations' ? 'Location' : 'prospect.location_municipalities',
          editType: 'Multiselect',
          items: key === 'locations' ? this.locationsList : this.cities,
          cols: 4
        },
        usage_types: {
          name: 'Usage types',
          editType: 'Multiselect',
          items: this.unitUsageTypes,
          cols: 4
        },
        estimated_completion: {
          name: 'leasing.contract_estimated_start',
          editType: 'Date',
          cols: 4,
          info: 'leasing.estimated_completion_info'
        },
        customer_rent_level: {
          name: 'leasing.customer_need_rent_level',
          editType: 'TextField',
          cols: 6,
          maxlength: 80
        },
        customer_rent_period: {
          name: 'leasing.customer_need_rent_period',
          editType: 'TextField',
          cols: 6,
          maxlength: 80
        }
      }
    },
    profitabilityAndProposalMetadata () {
      return {
        profit_analysis_done: {
          name: 'rental_process.profit_analysis_done',
          editType: 'Checkbox',
          cols: 6,
          child: {
            key: 'profit_analysis_date',
            name: 'rental_process.date_done',
            editType: 'Date',
          },
        },
        written_proposal_done: {
          name: 'rental_process.written_proposal_done',
          editType: 'Checkbox',
          cols: 6,
          child: {
            key: 'written_proposal_date',
            name: 'rental_process.date_done',
            editType: 'Date',
          },
        },
        proposal_details: {
          name: 'rental_process.proposal_details',
          editType: 'TextArea',
          maxlength: 4000,
          rows: 2,
          cols: 12,
        },
        def_contract_model: {
          name: 'rental_process.contract_model',
          editType: 'Select',
          items: this.contractModels,
          cols: 6,
        },
        def_contract_type: {
          name: 'rental_process.contract_type',
          editType: 'Select',
          items: this.contractTypes,
          cols: 6,
        },
        maturity: {
          name: 'rental_process.maturity',
          editType: 'Select',
          items: this.maturities,
          cols: 6,
        },
        options: {
          name: 'rental_process.options',
          editType: 'TextField',
          maxLength: 80,
          cols: 6,
        },
        rent_level: {
          name: 'rental_process.rent_level',
          editType: 'Currency',
          suffix: '/m\u00B2',
          cols: 6,
        },
        rent_free: {
          name: 'rental_process.rent_free',
          editType: 'TextField',
          maxLength: 80,
          cols: 6,
        },
        rent_review_base_index: {
          name: 'rental_process.rent_review_base_index',
          editType: 'TextField',
          maxLength: 80,
          cols: 6,
        },
        rent_security: {
          name: 'rental_process.rent_security',
          editType: 'TextField',
          maxLength: 80,
          cols: 6,
        },
        rent_details: {
          name: 'rental_process.rent_details',
          editType: 'TextField',
          maxLength: 300,
          cols: 12,
        },
        investments: {
          name: 'rental_process.investments',
          editType: 'TextArea',
          maxLength: 4000,
          rows: 2,
          cols: 12,
        },
      }
    },
    rentalReportingMetadata () {
      const fieldsReadonly = !this.canEditContractInfo
      return {
        contractType: {
          name: 'rental_process.contract_type',
          editType: 'Select',
          items: this.contractTypes,
          readonly: fieldsReadonly,
          cols: 12,
        },
        contractSigningDate: {
          name: 'rental_process.contract_signing_date',
          editType: 'Date',
          readonly: fieldsReadonly,
          cols: 6,
        },
        reportableArea: {
          name: 'rental_process.reportable_squares',
          editType: 'Area',
          suffix: 'm\u00B2',
          readonly: fieldsReadonly,
          cols: 6,
        },
        capitalRent: {
          name: 'CapitalRent',
          editType: 'Currency',
          readonly: fieldsReadonly,
          cols: 6,
        },
        contractArea: {
          name: 'rental_process.contract_squares',
          editType: 'Area',
          suffix: 'm\u00B2',
          readonly: true,
          cols: 6,
        },
      }
    },
    customerNeedDetailedMetadata () {
      return {
        customer_need_detailed: {
          category: 'None',
          maxlength: 4000,
          editType: 'TextArea',
          format: 'None',
          isShown: true,
          type: 'String',
          singleLine: true
        },
      }
    },
    mFilesIdFields () {
      const fields = []
      this.rentalProcessCopy.mfiles_ids.forEach(o =>  {
        fields.push(
          {
            id: o.identifier,
            label: o.is_main_contract ? 'leasing.main_contract_id' : 'leasing.other_contract_id',
            is_main_contract: o.is_main_contract,
            editType: 'Number',
          })
      });
      return fields
    },
    mFilesIdValues () {
      return this.rentalProcessCopy.mfiles_ids.reduce((acc, cur) => {
        acc[cur.identifier] = cur.mfiles_id

        return acc
      }, {})
    },

    corporationFields () {
      return [
        {
          id: 'id_corporation',
          label: 'Tenant corporation',
          editType: 'Select',
          items: this.tenantCorporations,
        },
        {
          id: 'tag',
          label: 'leasing.new_rental_tenant',
          editType: 'CheckBoxAndText',
          maxLength: 50,
          info:
          {
            text: 'leasing.rental_tag_info',
          },
          checkbox:
          {
            value: this.rentalProcessCopy.nameless_search,
            label: 'leasing.nameless_search',
          }
        },
      ]
    },
    corporationValues () {
      return {
        id_corporation: this.rentalProcessCopy.id_corporation,
        tag: this.rentalProcessCopy.tag
      }
    },
    responsibleFields () {
      return [
        {
          id: 'owner',
          label: 'prospect.primary_responsible',
          editType: 'Select',
          items: this.users,
        },
        {
          id: 'secondaryOwner',
          label: 'prospect.secondary_responsible',
          editType: 'Select',
          items: this.users,
        },
      ]
    },

    responsibleValues () {
      return {
        owner: this.rentalProcessCopy.owner,
        secondaryOwner: this.rentalProcessCopy.secondaryOwner
      }
    },

    predictionMetadata () {
      return {
        estimated_signature_date: {
          name: 'rental_process.estimated_signature_date',
          editType: 'Date',
          dateType: 'month',
          status: this.estimatedSignatureStatus,
          cols: 4
        },
        estimated_modification_cost: {
          name: 'rental_process.estimated_modification_cost',
          editType: 'Currency',
          cols: 4
        },
        estimated_value_impact: {
          name: 'rental_process.estimated_value_impact',
          editType: 'Currency',
          cols: 4
        }
      }
    },

    exactCustomerNeedsMetadata () {
      return {
        rent_level_capital: {
          name: 'rental_process.rent_level_capital',
          editType: 'RangeSlider',
          lowerValue: 'rent_level_capital_min',
          upperValue: 'rent_level_capital_max',
          suffix: `${this.selectedCurrency.symbol}/m\u00B2/${this.$t('Mth')}`,
          cols: 6,
          min: 10,
          max: 40,
          step: 1,
          overflow: true
        },
        carpark_need: {
          name: 'rental_process.carpark_need',
          editType: 'RangeSlider',
          lowerValue: 'carpark_need_min',
          upperValue: 'carpark_need_max',
          suffix: this.$t('pcs'),
          cols: 6,
          min: 0,
          max: 50,
          step: 5,
          overflow: true
        },
        contract_duration: {
          name: 'rental_process.contract_duration',
          editType: 'RangeSlider',
          lowerValue: 'contract_duration_min',
          upperValue: 'contract_duration_max',
          suffix: this.$t('years'),
          cols: 6,
          min: 0,
          max: 15,
          step: 1
        },
        separate_storage: {
          name: 'rental_process.separate_storage',
          editType: 'RangeSlider',
          lowerValue: 'separate_storage_min',
          upperValue: 'separate_storage_max',
          suffix: 'm\u00B2',
          cols: 6,
          min: 0,
          max: 100,
          step: 10,
          overflow: true
        },
        def_payer_of_investment: {
          name: 'rental_process.payer_of_investment',
          editType: 'Select',
          items: this.investmentPayers,
          cols: 6
        },
        investment_needs: {
          name: 'rental_process.investment_needs',
          editType: 'Currency',
          suffix: `, ${this.$t('VAT')} 0%`,
          cols: 6
        },
        def_rent_security_ability: {
          name: 'rental_process.rent_security_ability',
          editType: 'Select',
          items: this.securityAbilities,
          cols: 6
        },
        specific_conditions: {
          name: 'rental_process.specific_conditions',
          editType: 'TextField',
          maxLength: 100,
          cols: 6
        }
      }
    },

    contactPersonFields () {
      return [
        {
          id: 'name',
          label: 'Name',
          editType: 'Text',
          maxLength: 240,
          title: true
        },
        {
          id: 'phoneNumber',
          label: 'Phone number',
          editType: 'Text',
          maxLength: 50
        },
        {
          id: 'email',
          label: 'Email',
          editType: 'Text',
          maxLength: 60
        },
        {
          id: 'label',
          label: 'leasing.label',
          editType: 'Text',
          maxLength: 100
        },
        {
          id: 'info',
          label: 'Comments',
          editType: 'Text',
          maxLength: 1200
        },
      ]
    },
    assetManagerValues () {
      return this.rentalProcessCopy.asset_manager
    },

    leadInfo () {
      const info = {
        lead_origin: null,
        lead_information: '',
        lead_mediator: null
      }

      if (this.rentalProcessCopy) {
        info.lead_origin = this.rentalProcessCopy.lead_origin
        info.lead_information = this.rentalProcessCopy.lead_information
        info.lead_mediator = this.rentalProcessCopy.lead_mediator
      }

      return info
    },



    // Styles
    borderColor () {
      if (this.inactive) {
        return rentalProcessStageColors.Passed
      }
      return rentalProcessStageColors[this.rentalProcess?.stage]
    },

    // Definitions
    unitUsageTypes () {
      return this.definitionsByGroupLabel('unit.usage_types').map(def => ({label: def.label, id: def.id}))
    },
    inactivationReasons () {
      return this.definitionsByGroupLabel('rentalprocess.abandonedreason')
    },
    contractModels () {
      return this.definitionsByGroupLabel('rentalprocess.contract_model')
    },
    contractTypes () {
      return this.definitionsByGroupLabel('rentalprocess.contract_type')
    },
    investmentPayers () {
       return this.definitionsByGroupLabel('rentalprocess.payer_of_investment')
    },
    securityAbilities () {
       return this.definitionsByGroupLabel('rentalprocess.rent_security_ability')
    },
    canEditContractInfo () {
      return this.hasApplicationPermissionByName("LEASING_VUOKRAUSRAPORTOINTI")
    },

    // Custom lists
    maturities () {
      const years = new Array(20).fill(1).map((item, index) => ({id: (index + 1), label: `${(index + 1).toString()  } ${  this.$t('years')}`}))
      years.push({id: 0, label: this.$t('Permanent')})
      return years;
    },

    // Detecting if any values have changed
    dataChanged () {
      return !lodash.isEqual(this.rentalProcessCopy, this.rentalProcess)
    },

    // Stage numbers to make it easier to show widgets for specific stages
    stageNumber () {
      if (this.rentalProcess?.def_stage) {
        return parseInt(this.definitionById(this.rentalProcess.def_stage)?.label.charAt(0))
      }
      return -1
    },

    estimatedSignatureStatus () {
      const signatureDate = this.rentalProcessCopy?.estimated_signature_date
      if (signatureDate) {

        const date = moment(signatureDate)
        const now =  moment(this.currentDate)
        const monthFromNow = moment(this.currentDate).add(30, 'days')
        const threeMonthsFromNow = moment(this.currentDate).add(90, 'days')

        if (date.isBefore(now)) {
          return { icon: 'error', color: 'error'}
        }
        else if (date.isBefore(monthFromNow)) {
          return { icon: 'error', color: 'warning'}
        }
        else if (date.isBefore(threeMonthsFromNow)) {
          return { icon: 'error', color: 'yellow'}
        }

      }
      return null
    },
    selectedCurrency () {
      return this.currencies.find(c => c.id === this.rentalProcessCopy.currency_id)
    },
    userCurrencies () {
      return this.settings.availableCurrencies.map(ac => { return this.currencies.find(c => c.code === ac) })
    },
  },
  watch: {
    visible: {
      handler: function (value) {
        if (value) {
          this.open()
        }
      }
    },
  },
  mounted () {
    this.open()
  },
  methods: {
    ...mapActions('rentalProcess', [
      'updateRentalProcess',
      'deleteRentalProcess',
      'patchRentalProcess',
      'updateContactPersons',
      'deleteContactPersons',
      'updateMFilesIds',
    ]),
    ...mapMutations('rentalProcess', ['setShownRentalProcess']),
    ...mapActions('app', ['showConfirmDialog']),
    getDateText (date) {
      return date ? this.$t('Signed') + ' ' + moment(date).format('DD.MM.YYYY') : ''
    },
    async open () {
      const siteIds = this.availableSites.map(site => site.id_site)
      if (this.rentalProcess.sites.some(site => !siteIds.includes(site.id_site)))
      {
        alert(this.$t('leasing.missing_site'))
        this.close()
      }
      this.getCities()
      this.getLeasingUsers()
      this.getTenantCorporations()
      this.getRentroll()
      this.rentalProcessCopy = lodash.cloneDeep(this.rentalProcess)
      this.getContractNumbers()
    },
    async getContractNumbers () {
      this.contractNumbersLoading = true
      try {
        this.currentContractNumbers = this.settings.hasMFilesIntegration
          ? await this.$rambollfmapi.leasing.rentalProcesses.contractNumbers.get(this.currentDate)
            .then(contracts => {
              this.rentalProcessCopy.contract_info.forEach(contractInfo => {
                if (!contracts.find(contract => contract.contractNumber === contractInfo.contractNumber)) {
                  contracts.push(contractInfo)
                }
              })
              return contracts
            })
          : await this.$rambollfmapi.contracts.contract.getAllContractIds(false, false, false)
            .then(contracts =>
              contracts.map(contract => ({
                idContract: contract.id,
                contractNumber: contract.contractNumber
              }))
          )
      } catch (err) {
        this.currentContractNumbers = []
      }
      this.contractNumbersLoading = false
    },
    async close () {
      if (this.dataChanged) {
        const ok = await this.showConfirmDialog({
          title: 'leasing.confirm',
          positive: 'Save',
          negative: 'Discard',
        })
        if (ok === undefined) {
          return
        }
        else if (ok === true && !await this.save()) {
          return
        }
      }
      this.$emit('close')
      setTimeout(() => this.setShownRentalProcess(null), 500)
    },
    update () {
      this.errorMessages = []
      this.rentalProcessCopy = lodash.cloneDeep(this.rentalProcess)
    },
    async save () {
      if (!await this.validateStage(this.stageNumber)) {
        return false
      }
      await this.updateRentalProcess({rentalProcess: this.rentalProcessCopy})
      this.update()
      return true
    },
    async deleteItem () {
      const ok = await this.showConfirmDialog({
        title: 'leasing.rental_process_delete_confirm',
        negative: 'Yes',
      })
      if (ok === false) {
        this.deleteRentalProcess({ id: this.rentalProcessCopy.id })
      }
    },
    async patch (patchObject) {
      await this.patchRentalProcess({ id: this.rentalProcessCopy.id, patch: patchObject })
      this.update()
    },
    changeValue ({ value, key }) {
      this.rentalProcessCopy[key] = value
    },
    changeContractInfoValue ({ value, key, index }) {
      if (index !== -1) {
        this.rentalProcessCopy.contract_info[index][key] = value;
      }
    },
    async getCities () {
      this.citiesLoading = true
      if (this.locations.length !== 0) {
        this.locationsList = this.locations
        .map(location => ({label: location.text, id:location.id}))
      } else {
        const data = await this.$rambollfmapi.municipalities.list()
        this.cities = data
        .sort((prev, next) => prev.municipality.localeCompare(next.municipality))
        .map(city => ({label: city.municipality, id: city.id}))
      }
      this.citiesLoading = false
    },

    addSite (site) {
      this.rentalProcessCopy.sites.push(site)
    },
    async deleteSite (index) {
      const ok = await this.showConfirmDialog({
        title: 'rental_process.delete_site',
        negative: 'Yes',
      })
      if (ok === false) {
        this.rentalProcessCopy.sites.splice(index, 1);
      }
    },
    editSite ({index, site}) {
      this.rentalProcessCopy.sites.splice(index, 1, site);
    },
    async passivate (reason) {
      this.showInactivationDialog = false
      // We reset any other values
      this.rentalProcessCopy = lodash.cloneDeep(this.rentalProcess)
      this.rentalProcessCopy.status_description = reason
      this.rentalProcessCopy.status = 'Inactive'
      await this.updateRentalProcess({rentalProcess: this.rentalProcessCopy})
      await this.deleteContactPersons({rentalProcessId: this.rentalProcessCopy.id})
      this.update()
    },
    async getTenantCorporations () {
    this.corporationsLoading = true
    const data = await this.$rambollfmapi.parties.list({ query: { party_type: 0, visibility_status: true } })
    this.tenantCorporations = data.filter(party => party.tenant_corporation === true).map(corp => ({id: corp.id, label: corp.name}))
    this.corporationsLoading = false
    },
    async getLeasingUsers () {
      this.usersLoading = true
      const leasingUsers =  await this.$rambollfmapi.leasing.leasingUsers.get()
      this.users = leasingUsers.map(user => ({id: user.id_user, label: user.name}))
      this.usersLoading = false
    },
    async getRentroll () {
      this.rentrollLoading = true

      try{
        this.rentrollWidget.data.items = await this.$rambollfmapi.leasing.rentalProcesses.rentroll.get(this.rentalProcess.id, this.currentDate)
      } catch (err) {
        this.rentrollWidget.data.items = []
      }

      this.rentrollLoading = false
    },

    async updateCorporation (copyFields) {
      copyFields.forEach(field => {
        this.changeValue({value: field.value, key: field.id})
      })
    },
    patchResponsibles (copyFields) {
      const item = Object.fromEntries(this.responsibleFields.map(field => [field.id, field.value]))
      const copyItem = Object.fromEntries(copyFields.map(field => [field.id, field.value]))
      const patchObject = this.$jsonpatch.compare(item, copyItem)
      this.patch(patchObject)
    },
    async updateIdentifiers (copyFields) {
      const copyItems = copyFields.map(field => ({ identifier: null, mfiles_id: parseInt(field.value), is_main_contract: field.is_main_contract}))
      await this.updateMFilesIds({id: this.rentalProcessCopy.id, mFilesIds: copyItems})
      this.update()
    },
    async updateContacts (items) {
      const copyItems = items.map(item => ({...item}))
      await this.updateContactPersons({id: this.rentalProcessCopy.id, persons: copyItems})
      this.update()
    },
    async patchLeadInfo (copyItem) {
      const patchObject = this.$jsonpatch.compare(this.leadInfo, copyItem)
      this.patch(patchObject)
    },
    async activate (){

      const patchObject = [
        {
          op: 'replace',
          path: '/status',
          value: 'Active'
        }
      ]

      await this.patchRentalProcess({ id: this.rentalProcessCopy.id, patch: patchObject })
      this.update()
    },
    async updateStage (stage) {
      const targetStage = parseInt(stage.label.charAt(0))
      //We validate stage that is one smaller than targetStage to avoid errors
      const stageNumber = targetStage - 1
      if (!await this.validateStage(stageNumber)) {
        return
      }
      const patchObject = []
      if (stage?.openAddMFilesIds === true && this.rentalProcessCopy.mfiles_ids?.length < 1) {
        this.showMFilesAddingDialog = true
        return
      } else if (stage?.openAddMFilesIds === false) {
        this.showMFilesAddingDialog = false
      }

      if (stage?.label === '8. Closed') {
        patchObject.push(
          {
            op: 'replace',
            path: '/status',
            value: 'Closed'
          }
        )
      }
      if(this.closed){
        patchObject.push(
          {
            op: 'replace',
            path: '/status',
            value: 'Active'
          }
        )
      }

      patchObject.push(
        {
          op: 'replace',
          path: '/def_stage',
          value: stage.id
        }
      )

      await this.updateRentalProcess({rentalProcess: this.rentalProcessCopy})
      await this.patchRentalProcess({ id: this.rentalProcessCopy.id, patch: patchObject })
      this.update()
    },
    async validateStage (stageNumber) {
      // Check if stageNumber is a number
      this.errorMessages = []

      if (isNaN(stageNumber)) {
        this.errorMessages.push(this.$t('error.unexpected'))
        throw new Error(`stageNumber must be a number. stageNumber is ${stageNumber}`)
      }
      let errors = []
      // Define the validation rules for each stage
      const validationRules = {
        2: () => {
          if (!this.rentalProcessCopy.customer_need) {
            errors.push(this.$t('leasing.customer_need_error'))
          }
          if (!this.rentalProcessCopy.area_preference) {
            errors.push(this.$t('leasing.area_preference_error'))
          }
          if (this.rentalProcessCopy.locations?.length < 1 && this.rentalProcessCopy.cities?.length < 1) {
            errors.push(this.$t('leasing.locations_error'))
          }
          if (this.rentalProcessCopy.usage_types?.length < 1) {
            errors.push(this.$t('leasing.usage_types_error'))
          }
          if (!this.rentalProcessCopy.estimated_completion) {
            errors.push(this.$t('leasing.estimated_completion_error'))
          }
        },
        4: () => {
          let isUnitSelected = false
          this.rentalProcessCopy?.sites.forEach(site => {
            if (site.units.length > 0) {
              isUnitSelected = true
            }
          })

          if (isUnitSelected === false) {
            errors.push(this.$t('leasing.units_error'));
          }
        },
        5: () => {
          if (!this.rentalProcessCopy.estimated_signature_date) {
            errors.push(this.$t('leasing.estimated_signature_date_error'));
          }
          const cost = this.rentalProcessCopy.estimated_modification_cost;
          if (cost === null || cost === '' || isNaN(Number(cost))) {
            errors.push(this.$t('leasing.estimated_modification_cost_error'));
          }
        },
        6: () => {
          if (this.rentalProcessCopy.contract_info.length < 1) {
            errors.push(this.$t('leasing.contract_numbers_error'))
          }
        },
        7: async () => {
          if (this.rentalProcessCopy.contract_info.length < 1) {
            return
          }
          const contractNumbers = this.rentalProcessCopy.contract_info.map(contract => contract.contractNumber)
          // check that there are no duplicate contract numbers
          if (new Set(contractNumbers).size !== contractNumbers.length) {
            errors.push(this.$t('leasing.duplicate_contract_numbers'))
          }

          const numbersString = contractNumbers.join("&contractNumbers=")
          const notFound = await this.$rambollfmapi.leasing.rentalProcesses.contractNumbers.check(numbersString)

          if (notFound.length > 0) {
            errors.push(`${this.$t('leasing.not_found_contract_numbers')} ${notFound.join(", ")}`)
          }
        }
      }
      // Validate each stage up to the given stageNumber
      for (let stage = 2; stage <= stageNumber; stage++) {
        if (validationRules[stage]) {
          await validationRules[stage].apply()
        }
      }

      if (errors.length > 0) {
        errors.forEach(error => this.errorMessages.push(error))
        return false
      }
      return true
    },
  }
};
</script>

<style scoped>
</style>
