<template>
  <v-dialog
    v-model="endDialog"
    persistent
    max-width="550"
  >
    <AriaLive />
    <BaseModal
      @cancel="cancel()"
    >
      <template #title>
        {{ hierarchyData.deleteTitle }}
      </template>
      <template #content>
        <v-form
          v-if="level != 'unit'"
          ref="form"
          lazy-validation
        >
          <h3 class="text-subtitle-2 mt-6">
            {{ $t("Select reason for depreciation") }}
          </h3>
          <v-radio-group
            v-model="reason"
            :rules="[v => !!v || $t('Required')]"
            row
            class="ml-2 mt-2"
          >
            <v-radio
              :label="$t('Sale')"
              value="sale"
            />
            <v-radio
              v-if="!hierarchyData.hideDemolition"
              :label="$t('Demolition')"
              value="demolition"
              class="ml-6"
            />
            <v-radio
              :label="$t('Other depreciation')"
              value="otherDepreciation"
              class="ml-6"
            />
          </v-radio-group>
          <div
            v-if="reason === 'otherDepreciation'"
            :key="'otherMenu'"
          >
            <v-menu
              class="form-field"
              offset-y
              :close-on-content-click="false"
              min-width="0"
            >
              <template
                #activator="{ on }"
              >
                <v-text-field
                  :value="formatDate(endDate)"
                  :label="$t('End date')"
                  :hint="$t('Required')"
                  persistent-hint
                  required
                  prepend-icon="calendar_today"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                scrollable
                first-day-of-week="1"
                :locale="$i18n.locale"
              />
            </v-menu>
            <v-text-field
              v-model="explanation"
              :label="$t('explanation for ending')"
              class="mt-8 mb-4"
            />
          </div>
          <div
            v-if="reason === 'demolition'"
            :key="'demoMenu'"
          >
            <v-menu
              class="form-field"
              offset-y
              :close-on-content-click="false"
              min-width="0"
            >
              <template
                #activator="{ on }"
              >
                <v-text-field
                  :value="formatDate(endDate)"
                  :label="$t('End date')"
                  :hint="$t('Required')"
                  persistent-hint
                  required
                  prepend-icon="calendar_today"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                scrollable
                first-day-of-week="1"
                :locale="$i18n.locale"
              />
            </v-menu>
            <v-text-field
              v-model="explanation"
              :label="$t('explanation for ending')"
              :hint="$t('Required')"
              persistent-hint
              required
              class="mt-8 mb-8"
            />
          </div>
          <div
            v-if="reason === 'sale'"
            :key="'saleMenu'"
          >
            <h3 class="text-subtitle-2 mb-2">
              {{ $t("SaleInformation") }}
            </h3>
            <v-col
              md="12"
              class="pt-2 column d-flex"
            >
              <v-col
                md="6"
                class="pr-1"
              >
                <v-menu
                  class="form-field"
                  offset-y
                  :close-on-content-click="false"
                  min-width="0"
                >
                  <template
                    #activator="{ on }"
                  >
                    <v-text-field
                      :value="formatDate(endDate)"
                      :label="$t('Sell date')"
                      :hint="$t('Required')"
                      persistent-hint
                      required
                      class="mb-4 mt-0"
                      prepend-icon="calendar_today"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    :locale="$i18n.locale"
                  />
                </v-menu>
              </v-col>
              <v-col
                md="6"
                class="pr-1"
              >
                <v-menu
                  class="form-field"
                  offset-y
                  :close-on-content-click="false"
                  min-width="0"
                >
                  <template
                    #activator="{ on }"
                  >
                    <v-text-field
                      :value="formatDate(saleinfo.SaleBookingDate)"
                      :label="$t('SaleBookingDate')"
                      class="mb-4 mt-0"
                      prepend-icon="calendar_today"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="saleinfo.SaleBookingDate"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    :locale="$i18n.locale"
                  />
                </v-menu>
              </v-col>
            </v-col>
            <v-col
              md="12"
              class="pt-2 column d-flex"
            >
              <v-col
                md="6"
                class="pr-1"
              >
                <v-menu
                  class="form-field"
                  offset-y
                  :close-on-content-click="false"
                  min-width="0"
                >
                  <template
                    #activator="{ on }"
                  >
                    <v-text-field
                      :value="formatDate(saleinfo.OwnershipTransferDate)"
                      :label="$t('OwnershipTransferDate')"
                      :hint="$t('Required')"
                      persistent-hint
                      required
                      class="mb-4 mt-0"
                      prepend-icon="calendar_today"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="saleinfo.OwnershipTransferDate"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    :locale="$i18n.locale"
                  />
                </v-menu>
              </v-col>
              <v-col
                md="6"
                class="pr-1"
              >
                <v-select
                  v-model="saleinfo.SaleType"
                  clearable
                  class="mb-4 mt-0"
                  :items="saleTypes"
                  item-text="label"
                  item-value="id"
                  :label="$t('SaleType')"
                />
              </v-col>
            </v-col>
            <v-col
              md="12"
              class="pt-5 column d-flex"
            >
              <v-col
                md="4"
                class="pr-1"
              >
                <v-text-field
                  v-model="saleinfo.Price"
                  :label="$t('SalePrice')"
                  :rules="[isNumeric]"
                  @blur="convertInputValue('Price')"
                />
              </v-col>
              <v-col
                md="4"
                class="pr-1"
              >
                <v-text-field
                  v-model="saleinfo.LoanPortion"
                  :label="$t('LoanPortion')"
                  :rules="[isNumeric]"
                  @blur="convertInputValue('LoanPortion')"
                />
              </v-col>
              <v-col
                md="4"
                class="pr-1"
              >
                <v-text-field
                  :disabled="true"
                  :label="$t('DebtfreeSellingPrice')"
                  :value="DebtfreeSellingPrice"
                />
              </v-col>
            </v-col>
            <h3 class="text-subtitle-2 mt-8">
              {{ $t("BuyerInformation") }}
            </h3>
            <v-row
              align="center"
            >
              <v-col cols="6">
                <v-text-field
                  v-model="saleinfo.Buyer"
                  :label="$t('Buyer')"
                  :hint="$t('Required')"
                  persistent-hint
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="saleinfo.IsBuyerPrivatePerson"
                  :label="$t('Buyer is private person')"
                  class="form-fields mx-2 px-2"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="saleinfo.BuyerAddress"
              :label="$t('BuyerAddress')"
              class="mt-4 mb-4"
            />
            <v-col
              class="column d-flex"
              md="12"
            >
              <v-col
                md="6"
                class="pr-1"
              >
                <v-text-field
                  v-model="saleinfo.BuyerPostalCode"
                  :label="$t('BuyerPostalCode')"
                  class="mb-4 mt-0"
                />
              </v-col>
              <v-col
                md="6"
                class="pl-1"
              >
                <v-text-field
                  v-model="saleinfo.BuyerPostOffice"
                  :label="$t('BuyerPostOffice')"
                  class="mb-4 mt-0"
                />
              </v-col>
            </v-col>              <h3 class="text-subtitle-2 my-4">
              {{ $t("Additional information") }}
            </h3>
            <v-text-field
              v-model="saleinfo.AdditionalInformation"
              :label="$t('AdditionalInfo')"
              class="mb-4 mt-0"
            />
          </div>
        </v-form>
        <v-form
          v-if="level == 'unit'"
          ref="form"
          lazy-validation
        >
          <div v-if="hierarchyData.showItemSelection">
            <h3 class="text-subtitle-2 mt-6">
              {{ $t("Select reason for depreciation") }}
            </h3>
            <v-radio-group
              v-model="reason"
              :rules="[v => !!v || $t('Required')]"
              row
              class="ml-2 mt-2"
            >
              <v-radio
                :label="$t('Sale')"
                value="sale"
              />
              <v-radio
                v-if="!hierarchyData.hideDemolition"
                :label="$t('Demolition')"
                value="demolition"
                class="ml-6"
              />
              <v-radio
                :label="$t('Other depreciation')"
                value="otherDepreciation"
                class="ml-6"
              />
            </v-radio-group>
          </div>
          <div
            v-if="reason === 'otherDepreciation'"
            :key="'otherMenu'"
          >
            <v-menu
              class="form-field"
              offset-y
              :close-on-content-click="false"
              min-width="0"
            >
              <template
                #activator="{ on }"
              >
                <v-text-field
                  :value="formatDate(endDate)"
                  :label="$t('End date')"
                  :hint="$t('Required')"
                  persistent-hint
                  required
                  prepend-icon="calendar_today"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                scrollable
                first-day-of-week="1"
                :locale="$i18n.locale"
              />
            </v-menu>
          </div>
          <div
            v-if="reason === 'demolition'"
            :key="'demoMenu'"
          >
            <v-menu
              class="form-field"
              offset-y
              :close-on-content-click="false"
              min-width="0"
            >
              <template
                #activator="{ on }"
              >
                <v-text-field
                  :value="formatDate(endDate)"
                  :label="$t('End date')"
                  :hint="$t('Required')"
                  persistent-hint
                  required
                  prepend-icon="calendar_today"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                scrollable
                first-day-of-week="1"
                :locale="$i18n.locale"
              />
            </v-menu>
          </div>
          <v-select
            v-if="endDate != undefined && reason != 'sale'"
            v-model="removeItem.ids"
            :rules="[v => !!v.length || $t('Required')]"
            :label="hierarchyData.itemTitle"
            persistent-hint
            :item-text="hierarchyData.metaDataTitleProperty"
            item-value="id"
            :items="metadata"
            :item-disabled="(item) => isItemDisabled.includes(item.id)"
            multiple
            required
            class="mt-8 mb-8"
          />
          <v-text-field
            v-if="endDate != undefined && reason != 'sale'"
            v-model="explanation"
            :label="$t('explanation for ending')"
            :hint="$t('Required')"
            persistent-hint
            required
            class="mt-8 mb-8"
          />
          <div
            v-if="reason === 'sale'"
            :key="'saleMenu'"
          >
            <h3 class="text-subtitle-2 mb-2">
              {{ $t("SaleInformation") }}
            </h3>
            <v-menu
              class="form-field"
              offset-y
              :close-on-content-click="false"
              min-width="0"
            >
              <template
                #activator="{ on }"
              >
                <v-text-field
                  :value="formatDate(endDate)"
                  :label="$t('Sell date')"
                  :hint="$t('Required')"
                  persistent-hint
                  required
                  class="mb-4 mt-0"
                  prepend-icon="calendar_today"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                scrollable
                first-day-of-week="1"
                :locale="$i18n.locale"
              />
            </v-menu>
            <v-select
              v-if="endDate != undefined && reason == 'sale'"
              v-model="removeItem.ids"
              :rules="[v => !!v.length || $t('Required')]"
              :label="hierarchyData.itemTitle"
              persistent-hint
              :item-text="hierarchyData.metaDataTitleProperty"
              item-value="id"
              :items="metadata"
              :item-disabled="(item) => isItemDisabled.includes(item.id)"
              multiple
              required
              class="mt-8 mb-8"
            />
            <CdsAlert
              v-if="showErrorIndicator && reason == 'sale'"
              :title="$t('unitUnableToDeleteTitle')"
              type="danger"
              :description="$t(errorMessage)"
            />
            <v-menu
              v-if="endDate != undefined"
              class="form-field"
              offset-y
              :close-on-content-click="false"
              min-width="0"
            >
              <template
                #activator="{ on }"
              >
                <v-text-field
                  :value="formatDate(saleinfo.SaleBookingDate)"
                  :label="$t('SaleBookingDate')"
                  class="mb-4 mt-0"
                  prepend-icon="calendar_today"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="saleinfo.SaleBookingDate"
                no-title
                scrollable
                first-day-of-week="1"
                :locale="$i18n.locale"
              />
            </v-menu>
            <div
              v-if="endDate != undefined"
            >
              <v-col
                md="12"
                class="pt-2 column d-flex"
              >
                <v-col
                  md="6"
                  class="pr-1"
                />
                <v-col
                  md="6"
                  class="pr-1"
                />
              </v-col>
              <v-col
                md="12"
                class="pt-2 column d-flex"
              >
                <v-col
                  md="6"
                  class="pr-1"
                >
                  <v-menu
                    class="form-field"
                    offset-y
                    :close-on-content-click="false"
                    min-width="0"
                  >
                    <template
                      #activator="{ on }"
                    >
                      <v-text-field
                        :value="formatDate(saleinfo.OwnershipTransferDate)"
                        :label="$t('OwnershipTransferDate')"
                        :hint="$t('Required')"
                        persistent-hint
                        required
                        class="mb-4 mt-0"
                        prepend-icon="calendar_today"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="saleinfo.OwnershipTransferDate"
                      no-title
                      scrollable
                      first-day-of-week="1"
                      :locale="$i18n.locale"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  md="6"
                  class="pr-1"
                >
                  <v-select
                    v-model="saleinfo.SaleType"
                    clearable
                    class="mb-4 mt-0"
                    :items="saleTypes"
                    item-text="label"
                    item-value="id"
                    :label="$t('SaleType')"
                  />
                </v-col>
              </v-col>
              <v-col
                md="12"
                class="pt-5 column d-flex"
              >
                <v-col
                  md="4"
                  class="pr-1"
                >
                  <v-text-field
                    v-model="saleinfo.Price"
                    :label="$t('SalePrice')"
                    :rules="[isNumeric]"
                    @blur="convertInputValue('Price')"
                  />
                </v-col>
                <v-col
                  md="4"
                  class="pr-1"
                >
                  <v-text-field
                    v-model="saleinfo.LoanPortion"
                    :label="$t('LoanPortion')"
                    :rules="[isNumeric]"
                    @blur="convertInputValue('LoanPortion')"
                  />
                </v-col>
                <v-col
                  md="4"
                  class="pr-1"
                >
                  <v-text-field
                    :disabled="true"
                    :label="$t('DebtfreeSellingPrice')"
                    :value="DebtfreeSellingPrice"
                  />
                </v-col>
              </v-col>
              <h3 class="text-subtitle-2 mt-8">
                {{ $t("BuyerInformation") }}
              </h3>
              <v-row
                align="center"
              >
                <v-col cols="6">
                  <v-text-field
                    v-model="saleinfo.Buyer"
                    :label="$t('Buyer')"
                    :hint="$t('Required')"
                    persistent-hint
                    required
                  />
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                    v-model="saleinfo.IsBuyerPrivatePerson"
                    :label="$t('Buyer is private person')"
                    class="form-fields mx-2 px-2"
                  />
                </v-col>
              </v-row>
              <v-text-field
                v-model="saleinfo.BuyerAddress"
                :label="$t('BuyerAddress')"
                class="mt-4 mb-4"
              />
              <v-col
                class="column d-flex"
                md="12"
              >
                <v-col
                  md="6"
                  class="pr-1"
                >
                  <v-text-field
                    v-model="saleinfo.BuyerPostalCode"
                    :label="$t('BuyerPostalCode')"
                    class="mb-4 mt-0"
                  />
                </v-col>
                <v-col
                  md="6"
                  class="pl-1"
                >
                  <v-text-field
                    v-model="saleinfo.BuyerPostOffice"
                    :label="$t('BuyerPostOffice')"
                    class="mb-4 mt-0"
                  />
                </v-col>
              </v-col>
              <h3 class="text-subtitle-2 my-4">
                {{ $t("Additional information") }}
              </h3>
              <v-text-field
                v-model="saleinfo.AdditionalInformation"
                :label="$t('AdditionalInfo')"
                class="mb-4 mt-0"
              />
            </div>
          </div>
        </v-form>
        <CdsAlert
          v-if="showErrorIndicator && level != 'unit'"
          type="danger"
          :title="$t('unableToDeleteTitle')"
          :description="$t(errorMessage)"
        />
        <CdsAlert
          v-if="showErrorIndicator && reason != 'sale' && level == 'unit'"
          type="danger"
          :title="$t('unitUnableToDeleteTitle')"
          :description="$t(errorMessage)"
        />
        <CdsAlert
          v-if="level != 'unit'"
          type="primary"
          :description="$t(hierarchyData.lowLevelWarning )"
        />
      </template>
      <template #footer>
        <v-btn
          v-if="level == 'unit' && reason != 'sale'"
          :disabled="disableSaveButton"
          depressed
          rounded
          color="primary"
          @click="deleteItems()"
        >
          <span v-if="!saving">{{ $t('Delete') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
        <v-btn
          v-if="level == 'unit' && reason == 'sale'"
          :disabled="disableSaveButton"
          depressed
          rounded
          color="primary"
          @click="sellUnits()"
        >
          <span v-if="!saving">{{ $t('Delete') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
        <v-btn
          v-if="level != 'unit'"
          :disabled="!dateValid || !fieldsValid"
          depressed
          rounded
          color="primary"
          @click="deleteItem()"
        >
          <span v-if="!saving">{{ $t('Delete') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
    <template #activator="{ on }">
      <v-btn
        v-if="disabled === false"
        :aria-label="hierarchyData.deleteButton"
        small
        outlined
        rounded
        class="ma-0 mt-2"
        v-on="on"
      >
        {{ hierarchyData.deleteButton }}
      </v-btn>
    </template>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import BaseModal from './general/BaseModal.vue'
import AriaLive from './AriaLive.vue'
import moment from 'moment'
import CdsAlert from '../components/CircleDesignSystem/CdsAlert.vue'

export default {
  components: {
    BaseModal,
    AriaLive,
    CdsAlert
  },
props: {
    metadata: {
      type: Array,
      default: function () {
        return []
      },
    },
    level: {
      type: String,
      default: ""
    },
    idFromParent: {
      type: Number,
      default: undefined
    }
},
  emits: ['saved'],
  data () {
    return {
      reason: null,
      disabled: true,
      saving: false,
      dateValid: true,
      endDialog: false,
      endDate: undefined,
      showErrorIndicator: false,
      indicatorMessage: '',
      errorMessage: '',
      removeItem: {
        id: null,
      },
      explanation: "",
      saleinfo: this.getDefaultSaleObject(),
      saleTypes: [],
      hierarchyData: this.getHierarchyData(),
      isItemDisabled: [],
      disableSaveButton: true
    }
  },

  computed: {
    ...mapState('app', ['currentDate']),
    ...mapGetters('app', ['hasApplicationPermissionByName', 'definitionsByGroupLabel']),
    siteId () {
      return Number(this.$route.params.id_site)
    },
    selectedDate () {
      let date = new Date(this.currentDate)
      date = new Date(date.setUTCHours(0,0,0,0)).toISOString().split('.')[0]
      return date
    },
    fieldsValid () {
      if(this.removeItem.id === null)
      {
        return false
      }
      switch(this.reason)
      {
        case "otherDepreciation":
          return this.endDate != undefined
        case "demolition":
          if(this.endDate != undefined && this.explanation != "")
          {
            return true
          }
          return false
        case "sale": {
          if(!this.endDate ||
          !this.saleinfo.OwnershipTransferDate ||
          !this.saleinfo.Buyer)
          {
            return false
          }
          var loan = this.saleinfo.LoanPortion
          var price = this.saleinfo.Price
          //Important to check for true since "isNumeric" returns truthy value in all cases
          if(this.isNumeric(loan) !== true || this.isNumeric(price) !== true)
          {
            return false
          }
          return true
        }
        default:
          return false
      }
    },
    DebtfreeSellingPrice ()
    {
      var loan = this.saleinfo.LoanPortion
      var price = this.saleinfo.Price

      if (loan !== undefined) {
        loan = parseFloat(loan.replace(',', '.'));
      }
      if (price !== undefined) {
        price = parseFloat(price.replace(',', '.'));
      }
      if(isNaN(loan) && isNaN(price))
      {
        return undefined
      }
      else if(isNaN(loan))
      {
        return price
      }
      else if (isNaN(price))
      {
        return loan
      }
      return price + loan
    }
  },
  watch: {
    'removeItem.id': function (selectedItemId) {
      if(this.endDialog === false){
        this.removeItem.id = this.idFromParent ? this.idFromParent : null
        return
      }
      if (selectedItemId !== '') {
        if(this.level != 'unit'){
          this.endDate = null
        }
        this.showErrorIndicator = false
        this.indicatorMessage = ''
        this.errorMessage = ''
      }
    },
    'removeItem.ids': function (selectedItemIds) {
      if(selectedItemIds.length > 0)
      {
        this.disableSaveButton = false
      }
      if(selectedItemIds.length === 0)
      {
        this.disableSaveButton = true
      }
    },
    reason () {
      this.endDate = undefined
      this.indicatorMessage = ''
      this.errorMessage = ''
      this.showErrorIndicator = false
      if (!this.removeItem.id) {
        this.indicatorMessage = this.hierarchyData.selectTitle
      }
    },
    endDate () {
      if(!this.endDate){
        this.disableSaveButton = true
        this.removeItem.ids = []
      }else{
        this.errorMessage = ''
        this.DateValidations()
      }
    },
    idFromParent (newId) {
      this.removeItem.id = newId
    }
  },
  async mounted () {
    this.removeItem.id = this.idFromParent
    if (this.hasApplicationPermissionByName('KOHDEHIERARKIA_POISTO')) {
      this.disabled = false
      this.saleTypes = this.definitionsByGroupLabel('saletype')
    }
  },
  methods: {
    cancel () {
      this.endDialog = false
      this.clearForm ()

      this.$refs.form.reset()
    },
    clearForm () {
      this.$refs.form.resetValidation()
      this.removeItem.id = this.idFromParent ? this.idFromParent : ''
      this.reason = null
      this.showErrorIndicator = false
      this.endDate = undefined
      this.indicatorMessage = ''
      this.errorMessage = ''
      this.explanation = ''
      this.saleinfo = this.getDefaultSaleObject()
      this.disableSaveButton = true
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : null
    },
    async DateValidations () {
      if(this.level == 'unit')
      {
        if (!this.endDate) {
            return
        }
        const endingCheckFunction = await this.hierarchyData.checkEnding();
        var removeBlockerCheck = await endingCheckFunction(this.siteId, this.endDate)
        this.dateValid = true
        this.showErrorIndicator = false

        const activeContracts = removeBlockerCheck
        if (Array.isArray(activeContracts)) {
          activeContracts.forEach(unfinishedContracts => {
            const contractNumber = unfinishedContracts?.error?.contractNumber;
            const hierarchyLevelId = unfinishedContracts?.error?.hierarchyLevelId;
            let unitCode = this.metadata.find(item => item.id === hierarchyLevelId)?.unit_code
            if (contractNumber && unitCode != undefined) {
              this.showErrorIndicator = true
              if (!this.errorMessage.includes(this.$t('Cannot be removed because of existing contracts'))) {
                this.errorMessage += this.$t('Cannot be removed because of existing contracts') + '\n'
              }
              this.errorMessage += ' (' + unitCode + ') ' + ' ' + contractNumber + (', ') + '\n'
              return
            }
          });
        }

        let alreadyDeleted = removeBlockerCheck;
        if (Array.isArray(alreadyDeleted)) {
          alreadyDeleted.forEach(deletedItem => {
            const alreadyDeletedDate = deletedItem?.error?.dateOfDeletion;
            const hierarchyLevel = deletedItem?.error?.hierarchyLevel;
            const hierarchyLevelId = deletedItem?.error?.hierarchyLevelId;
            let unitCode = this.metadata.find(item => item.id === hierarchyLevelId)?.unit_code
            if (alreadyDeletedDate && hierarchyLevel && unitCode != undefined) {
              this.showErrorIndicator = true;
              if (!this.errorMessage.includes(this.$t('unit.marked_deleted'))) {
                this.errorMessage += this.$t('unit.marked_deleted') + '\n'
              }
              this.errorMessage += ' (' + unitCode + ') ' + ' ' + moment(alreadyDeletedDate).format('DD.MM.YYYY HH:mm:ss') + '\n'
              return
            }
          });
        }

        if (Array.isArray(removeBlockerCheck)) {
          const hierarchyLevelIds = removeBlockerCheck.map(item => item?.error?.hierarchyLevelId);
          this.isItemDisabled = hierarchyLevelIds;
        }

        this.metadata.forEach(item => {
          if (item.end_date < this.endDate) {
            let unitCode = item.unit_code
            this.isItemDisabled.push(item.id)
            this.showErrorIndicator = true
            if (!this.errorMessage.includes(this.$t('unit.version_ends'))) {
                this.errorMessage += this.$t('unit.version_ends') + '\n'
              }
            this.errorMessage += ' (' + unitCode + ') ' + ' ' + moment(item.end_date).format('DD.MM.YYYY HH:mm:ss') + '\n';
          }
        });
      }
      else{
        if (!this.removeItem.id || !this.endDate) {
          return
        }
        const endingCheckFunction = await this.hierarchyData.checkEnding();
        removeBlockerCheck = await endingCheckFunction(this.removeItem.id, this.endDate)

        this.dateValid = true
        this.showErrorIndicator = false
        const activeContracts = removeBlockerCheck?.error?.unfinishedContracts
        if (activeContracts && activeContracts.length > 0) {
          this.dateValid = false
          this.errorMessage = this.$t('Cannot be removed because of existing contracts') + ' ' + activeContracts.join(', ')
          this.showErrorIndicator = true
          return
        }

        const alreadyDeleted = removeBlockerCheck?.error?.dateOfDeletion
        if (alreadyDeleted) {
          this.dateValid = false
          this.errorMessage = this.$t('Unable to delete') + ', ' + this.$t(removeBlockerCheck.error.hierarchyLevel) + ' ' + this.$t('hierarchy.marked_deleted') + ' ' + moment(alreadyDeleted).format('DD.MM.YYYY HH:mm:ss')
          this.showErrorIndicator = true
          return
        }
      }
    },
    deleteItem ()
    {
      if (this.saving) {
        return
      }
      if (this.$refs.form.validate()) {
      this.saving = true
      var deleteObject = this.generateDeleteObject()
      const deleteItemFunction = this.hierarchyData.deleteItem();
       deleteItemFunction (this.removeItem.id, this.endDate, deleteObject)
          .then(res => {
            if (res.message) {
              this.errorMessage = this.$t("Error while saving")
              this.$store.dispatch('app/addAriaLive', this.$t('Error while saving'))
              this.showErrorIndicator = true
            } else {
              this.endDialog = false
              this.clearForm()
              this.$store.dispatch('app/addAriaLive', this.$t('Depreciation was successful'))
              this.$emit('saved', true)
            }
              this.saving = false
          })
          .catch(function (error) {
            this.$log.error(error)
          })
      }
    },
    deleteItems ()
    {
      if (this.saving) {
        return
      }
      if (this.$refs.form.validate()) {
        this.saving = true
        var deleteObject = this.generateDeleteObject()
        const deleteItemFunction = this.hierarchyData.deleteItem();
        const promises = this.removeItem.ids.map(id =>
          deleteItemFunction(id, this.endDate, deleteObject)
        );
        Promise.all(promises)
          .then(results => {
        const hasError = results.some(res => res.message);
        if (hasError) {
          this.errorMessage = this.$t("Error while saving")
          this.$store.dispatch('app/addAriaLive', this.$t('Error while saving'))
          this.showErrorIndicator = true
        } else {
          this.endDialog = false
          this.clearForm()
          this.$store.dispatch('app/addAriaLive', this.$t('Depreciation was successful'))
          this.$emit('saved', true)
        }
        this.saving = false
          })
          .catch(function (error) {
            this.$log.error(error)
            this.saving = false
          });
      }
    },
    sellUnits ()
    {
      if (this.saving) {
        return
      }
      if (this.$refs.form.validate()) {
        this.saving = true
        var deleteObject = this.generateDeleteObject()
        deleteObject.UnitIds = this.removeItem.ids
        const sellItemFunction = this.hierarchyData.sellUnits();
        sellItemFunction (deleteObject)
          .then(async res => {
            if (res.message) {
              this.errorMessage = this.$t("Error while saving")
              this.$store.dispatch('app/addAriaLive', this.$t('Error while saving'))
              this.showErrorIndicator = true
            } else {
              this.endDialog = false
              this.clearForm()
              this.$store.dispatch('app/addAriaLive', this.$t('Depreciation was successful'))
              this.$emit('saved', true)
            }
              this.saving = false
          })
          .catch(function (error) {
            this.$log.error(error)
          })
      }
    },
    findLastDate (datesArray) {
      if (!Array.isArray(datesArray) || datesArray.length === 0) {
        return null;
      }

      const dateObjects = datesArray.map(dateString => new Date(dateString));
      let highestDate = dateObjects[0];

      for (const date of dateObjects) {
        if (date > highestDate) {
          highestDate = date;
        }
      }
      return highestDate;
    },
    generateDeleteObject ()
    {
      switch(this.reason)
      {
        case "otherDepreciation":
          return { Reason: this.explanation }
        case "demolition":
          return { Reason: this.explanation, Type: "Purku"}
        case "sale":
          this.saleinfo.DebtfreeSellingPrice = this.DebtfreeSellingPrice
          this.saleinfo.SaleDate = this.endDate
          return this.saleinfo
        default:
          return {}
      }
    },
    getDefaultSaleObject ()
    {
      return {
            SaleBookingDate: undefined,
            OwnershipTransferDate: undefined,
            SaleType: undefined,
            Price: undefined,
            LoanPortion: undefined,
            Buyer: "",
            BuyerAddress: undefined,
            BuyerPostalCode: undefined,
            BuyerPostOffice: undefined,
            AdditionalInformation: undefined,
            Type: "Myynti",
            IsBuyerPrivatePerson: false,
            UnitIds: [],
            SaleDate: undefined,
      }
    },
    isNumeric (value) {
      if (!value) return true; // Allow empty input
      return /^(\d+(\.\d{1,2})?|\d+(\,\d{1,2})?)$|^\d+$/.test(value) || this.$t("Give value as number");
    },
    getHierarchyData ()
    {
      switch(this.level)
      {
        case "site":
        return {
          deleteTitle: this.$t('SiteRemovalTitle'),
          deleteButton: this.$t('Delete site'),
          itemTitle: this.$t("Site"),
          lowLevelWarning: this.$t("RemovingSiteRemovesLowerLevels"),
          checkEnding: async () => {
            return this.$rambollfmapi.sites.checkIfEndingPossible
          },
          deleteItem: () => {
            return this.$rambollfmapi.sites.endSite
          },
          showItemSelection: false,
          hideDemolition: true
        }
        case "estate":
        return {
          deleteTitle: this.$t('EstateRemovalTitle'),
          deleteButton: this.$t('Delete estate'),
          itemTitle: this.$t("Estate"),
          lowLevelWarning: this.$t("RemovingEstateRemovesLowerLevels"),
          checkEnding: async () => {
            return this.$rambollfmapi.estates.checkIfEndingPossible
          },
          deleteItem: () => {
            return this.$rambollfmapi.estates.endEstate
          },
          showItemSelection: false
        }
        case "building":
        return {
          deleteTitle: this.$t('BuildingRemovalTitle'),
          deleteButton: this.$t('Delete building'),
          itemTitle: this.$t("Building"),
          lowLevelWarning: this.$t("RemovingBuildingRemovesLowerLevels"),
          checkEnding: async () => {
            return this.$rambollfmapi.buildings.checkIfEndingPossible
          },
          deleteItem: () => {
            return this.$rambollfmapi.buildings.endBuilding
          },
          showItemSelection: false
        }
        case "unit":
        return {
          deleteTitle: this.$t('UnitRemovalTitle'),
          deleteButton: this.$t('Delete units'),
          selectTitle: this.$t("Select units"),
          itemTitle: this.$t("Units"),
          metaDataTitleProperty: "unit_code",
          checkEnding: async () => {
            return this.$rambollfmapi.units.checkIfEndingPossible
          },
          deleteItem: () => {
            return this.$rambollfmapi.units.endUnit
          },
          sellUnits: ()  => {
            return this.$rambollfmapi.units.sellUnits
          },
          showItemSelection: true
        }
      }
    },
    convertInputValue (fieldName) {
      let value = this.saleinfo[fieldName];
      if (value) {
        this.saleinfo[fieldName] = value.replace(',', '.');
      }
    },
  }
}
</script>
<style scoped>
</style>
