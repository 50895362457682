<template>
  <div>
    <v-dialog
      v-model="showTableDataDialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <div>
          <v-btn
            large
            icon
            absolute
            right
            style="margin-top: 0.8em"
            @click="closeViewData"
          >
            <v-icon>close</v-icon>
            <span class="d-sr-only">{{ $t('Close') }}</span>
          </v-btn>
          <v-card-title
            primary-title
            style="width:80%"
          >
            <h1>
              {{ tableData.title }}
            </h1>
          </v-card-title>
        </div>
        <Table
          :rows="tableData.rows"
          :headers="tableData.headers"
          :footers="createFooters(tableData.headers, tableData.rows)"
          :footer-definitions="tableData.footers"
          :pagination.sync="viewDataOptions"
          :custom-formatters="customFormatters"
          :show-edit-control="data.showEditControl"
          @edit-row="$emit('editRow', $event)"
          @getProspects="getAllProspectData"
          @triggerCustomEvents="$emit('triggerCustomEvents', $event)"
        />
      </v-card>
    </v-dialog>
    <v-data-table
      :header-props="dataTableHeaderDefinitions"
      :headers="
        data.headers.map((h) => {
          return {
            text: $t(h.text),
            value: h.value,
            type: h.type,
            chooses: h.chooses,
            width: h.customWidth,
            sort: h.sort,
          }
        })
      "
      :items="arrayOfItems"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: [],
      }"
      :no-data-text="dataMissing"
      class="elevation-0"
      mobile-breakpoint="0"
    >
      <template
        #item="props"
      >
        <template
          v-for="item in getSubItems(props.item)"
        >
          <tr
            v-if="isExpanded(props.item, item)"
            :key="item.id + (item.parentId ? item.parentId : 'parent')"
            :style="getStyleForLayer(item.layer)"
          >
            <template v-for="header in data.headers">
              <td
                v-if="(header.type && header.type === 'dropdown')"
                :key="header.value"
              >
                <v-select
                  v-model="item[header.value]"
                  clearable
                  :items="header.chooses()"
                  :label="header.label"
                  @change="selectChanged(header.value, item)"
                />
              </td>
              <!-- Columns for leasing related widgets in their own component because of large amount of custom logic -->
              <LeasingColumns
                v-else-if="header.value === 'prospect_description' ||
                  header.value === 'process_title' ||
                  header.value === 'renegotiations' ||
                  (header.value === 'prospect_status' && item.layer === 0) ||
                  header.value === 'units_passed' || header.value === 'corporation_name' || header.value === 'tenant' ||
                  (header.value === 'endDate' && title === $t('leasing.ending_contracts_fixed_term')) ||
                  (header.value === 'endDate' && title === $t('leasing.ending_contracts_permanent')) ||
                  (header.value === 'success_likelihood' && item.layer === 0) ||
                  header.value === 'renewal' ||
                  header.value === 'rental_type_translated' ||
                  header.value === 'rentalSituation' ||
                  header.value === 'termination_reason' ||
                  header.value === 'rentingProcessTitle'"
                :key="header.value"
                :item="item"
                :props="props"
                :header="header"
                :title="title"
                :items="data.items"
                @changePproperty="$emit('changePproperty', {})"
                @getProspects="getAllProspectData"
              />
              <td
                v-else-if="header.value === 'sites' && header.multiple"
                :key="header.value"
                class="min-width"
              >
                <template
                  v-for="(site, index) in item[header.value]"
                >
                  <span
                    :key="index"
                    :class="{ linked: site.id_site != null }"
                    @click="linkTo(site, 'sites.info')"
                  >
                    {{ site.name }}
                    <br>
                  </span>
                </template>
              </td>
              <td
                v-else-if="(header.value === 'property_name') && (item.intended_use === 'Kiinteistö') && (props.item.id_site)"
                :key="'property' +header.value"
                :class="{ 'col-align-right': isRightAligned(header.format) }"
                class="linked"
              >
                {{ formatData(props.item, header.value, header.format) }}
              </td>
              <td
                v-else-if="(header.value === 'site' || header.value === 'site_name')"
                :key="'site' + header.value"
                :class="{
                  'col-align-right': isRightAligned(header.format),
                  'linked': item.site !== $t('prospect.several')
                }"
                @click="item.site !== $t('prospect.several') && linkTo(item, 'sites.info')"
              >
                {{ formatData(item, header.value, header.format, true) }}
              </td>
              <td
                v-else-if="header.value === 'unit_name' || header.value === 'unitName' "
                :key="'unit' + header.value"
                :class="getLinkStyle(props.item)"
                @click="linkTo(props.item, 'sites.facilitymanagement')"
              >
                {{ formatData(item, header.value, header.format, true) }}
              </td>
              <td
                v-else-if="header.value === 'units' && item.showToggle"
                :key="header.value"
                :class="getLinkStyle(props.item)"
                @click="expand(item)"
              >
                {{ item.units }}
              </td>
              <td
                v-else-if="header.value === 'units'"
                :key="header.value"
                :class="getLinkStyle(props.item)"
                @click="linkTo(props.item, 'sites.facilitymanagement')"
              >
                {{ item.units }}
              </td>
              <td
                v-else-if="header.value === 'auditDirection'"
                :key="header.value"
              >
                <v-icon
                  v-if="props.item[header.value] === $t('HANDOVER')"
                  class="handoverDirection"
                >
                  arrow_circle_left
                </v-icon>
                <v-icon
                  v-else
                >
                  arrow_circle_right
                </v-icon>
                {{ props.item[header.value] }}
              </td>
              <td
                v-else-if="header.value === 'auditStatus'"
                :key="header.value"
              >
                {{ props.item[header.value] }}
                <v-icon
                  v-if="props.item[header.value] === $t('COMPLETED')"
                  class="statusCompleted"
                >
                  check_circle
                </v-icon>
                <v-icon
                  v-else-if="props.item[header.value] === $t('INPROGRESS')"
                  class="statusInProgress"
                >
                  error
                </v-icon>
              </td>
              <td
                v-else-if="(header.value === 'administrative_property' || header.value === 'siteName')"
                :key="'other' + header.value"
                class="linked"
                @click="linkTo(props.item, 'sites.info')"
              >
                {{ formatData(props.item, header.value, header.format, true) }}
              </td>
              <ObjectTableModifyField
                v-else-if="header.modify && item[header.value] !== undefined"
                :key="'modifiedfield' + header.value"
                :class="{ 'col-align-right': isRightAligned(header.format) }"
                :value="formatData(item, header.value, header.format).toString()"
                :type="header.editType"
                :boolean-props="header.booleanProps"
                :disabled="disabledField(item, header.value)"
                @change="dataModified(item, props.item, header.value, $event)"
              />
              <td
                v-else-if="header.drag"
                :key="'drag' + header.value"
                @dragover="dragover($event)"
                @dragleave="dragleave($event)"
                @drop="drop(item)"
              >
                <v-btn
                  v-if="item[header.value]"
                  :draggable="true"
                  large
                  text
                  rounded
                  class="secondary dragbtn"
                  @dragstart="startDrag(props.item, header.value)"
                  @click="clickAction(item, header.value)"
                >
                  {{ formatData(item, header.value, header.format) }}
                </v-btn>
              </td>
              <td
                v-else-if="header.status && item[header.value] !== undefined"
                :key="'notundefined' + header.value"
              >
                <v-row
                  no-gutters
                  class="flex-nowrap"
                >
                  <v-col>
                    <StatusIcon
                      :field="
                        getObjectWithUnit(item, header.value)
                      "
                      :data="
                        formatData(item, header.value, header.format)
                      "
                    />
                  </v-col>
                  <v-col
                    :key="header.value"
                    class="d-flex align-center justify-end text-no-wrap"
                  >
                    {{ formatData(item, header.value, header.format) }}
                  </v-col>
                </v-row>
              </td>
              <td
                v-else-if="header.value === 'customComponent'"
                :key="'customComponent' + header.value"
              >
                <slot
                  v-if="item.showCustomComponent !== false"
                  :item="item"
                  name="customComponent"
                />
              </td>
              <td
                v-else-if="header.value !== 'toggle' && !!header.event"
                :key="header.value"
              >
                <span v-if="!!header.event && !!item[header.value]">
                  <button
                    :class="{ 'linked': !!header.event && !!item[header.value] }"
                    @click="$emit('triggerCustomEvents', { eventName: header.event, row: item })"
                  >
                    {{ formatData(item, header.value, header.format) }}
                  </button>
                </span>
                <span v-else>
                  {{ formatData(item, header.value, header.format) }}
                </span>
              </td>
              <HideOverflowColumn
                v-else-if="header.hideOverflow"
                :key="'hideOverflow' + header.value"
                :header="header"
                :item="item"
              />
              <td
                v-else-if="header.value !== 'toggle'"
                :key="'nottoggle' + header.value"
                :class="defaultCellClass(header)"
              >
                {{ formatData(item, header.value, header.format) }}
              </td>

              <td
                v-else
                :key="'else' + header.value"
                class="no-padding"
              >
                <v-btn
                  v-if="item.showToggle === true"
                  icon
                  @click="expand(item)"
                >
                  <span v-if="item.expanded">
                    <v-icon>keyboard_arrow_up</v-icon>
                    <span class="d-sr-only">{{ `${$t('Show less')}` }}</span>
                  </span>
                  <span v-else>
                    <v-icon>keyboard_arrow_down</v-icon>
                    <span class="d-sr-only">{{ `${$t('Show more')}` }}</span>
                  </span>
                </v-btn>
              </td>
            </template>
          </tr>
        </template>
      </template>
      <template
        #footer.page-text="item"
      >
        <ImportButton
          v-if="importing"
          :headers="data.headers"
          @dataUpdate="$emit('dataUpdated', $event)"
        />&nbsp;
        {{ loadingData ? $t('Gathering information. Wait a second...') : '' }}
        <v-btn
          v-if="importing || exportCsv"
          :loading="loadingData"
          small
          outlined
          rounded
          @click="beginExport"
        >
          {{ $t('Export to spreadsheet') }}
        </v-btn>&nbsp;
        <v-btn
          v-if="!data.noBrowse"
          small
          outlined
          rounded
          @click="beginViewData"
        >
          {{ $t('Browse') }}
        </v-btn>
        {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
        {{ item.itemsLength.toLocaleString('fi-FI') }}
      </template>
      <template slot="body.append">
        <tr>
          <td
            v-for="(footer, idx) in data.footers"
            :key="idx"
            :class="{ 'col-align-right': isRightAligned(footer.format) }"
          >
            {{
              getFooterValue(
                arrayOfItems,
                footer.value,
                footer.text,
                footer.average,
                footer.format,
                footer.unique
              )
            }}
          </td>
        </tr>
      </template>
      <!-- Slot for filtering the contents. Filter options can be added in the widget object (see widgets/leasing f.ex.) -->
      <template
        v-if="data.filter"
        #footer
      >
        <div
          v-if="$vuetify.breakpoint.mobile"
        >
          <v-menu
            offset-x
            :close-on-content-click="!data.filter.multiple"
          >
            <template #activator="{ on, attrs }">
              <v-card
                elevation="0"
                class="filterBox"
              >
                <v-btn
                  v-bind="attrs"
                  outlined
                  x-small
                  fab
                  rounded
                  class="filterButton"
                  v-on="on"
                >
                  <v-icon>
                    filter_list
                  </v-icon>
                  <span
                    class="text-subtitle-2"
                  >{{ hasMultipleFilters ? $t('Multiple') : displayText }}</span>
                </v-btn>
              </v-card>
            </template>
            <v-list>
              <v-list-item-group
                v-model="filters"
                active-class="active"
                return-object
                :multiple="data.filter.multiple || false"
              >
                <v-list-item
                  v-for="(listItem, index) in data.filter.options"
                  :key="Array.isArray(listItem.value) ? listItem.value.join() + 'idx' + index : listItem.value + 'idx' + index"
                >
                  <template #default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(listItem.text) }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="data.filter.multiple">
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                      />
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
        <div
          v-else
          style="margin: 0px 0px -45px 15px"
        >
          <v-menu
            offset-x
            :close-on-content-click="!data.filter.multiple"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                outlined
                x-small
                fab
                rounded
                v-on="on"
              >
                <v-icon>
                  filter_list
                </v-icon>
                <span class="d-sr-only">: {{ $t('Filters') + filterTexts }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group
                v-model="filters"
                active-class="active"
                return-object
                :multiple="data.filter.multiple || false"
              >
                <v-list-item
                  v-for="(listItem, index) in data.filter.options"
                  :key="index"
                >
                  <template #default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(listItem.text) }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="data.filter.multiple">
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                      />
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-tooltip
            v-if="hasMultipleFilters"
            top
          >
            <template #activator="{ on }">
              <span v-on="on">...</span>
            </template>
            <span class="text-subtitle-1">{{ filterTexts }}</span>
          </v-tooltip>
          <span
            v-else
            class="text-subtitle-1"
          >{{ displayText }}</span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import helpers from '../../helpers'
import moment from 'moment'
import Table from '../Table.vue'
import ImportButton from '../../components/ImportButton.vue'
import ObjectTableModifyField from '../ObjectTableModifyField.vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import StatusIcon from '../../components/StatusIcon.vue'
import LeasingColumns from '../../components/Leasing/LeasingColumns.vue'
import HideOverflowColumn from '../../components/general/HideOverflowColumn.vue'
import { currencySumFields } from '../../helpers/table.js'

export default {
  components: {
    Table,
    ImportButton,
    ObjectTableModifyField,
    StatusIcon,
    LeasingColumns,
    HideOverflowColumn,
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: function () {
        return ''
      }
    },
    customFormatters: {
      type: Object,
      default: function () {
        return {}
      }
    },
    widgetId: {
      type: String,
      default: null
    },
    shouldRequestAllData: {
      type: Boolean,
      default: false
    },
    showEditControl: {
      type: Boolean,
      default: false
    }
  },
  emits: ['editRow', 'triggerCustomEvents', 'changePproperty', 'dataUpdated', 'getSentInvoices', 'drag', 'openDialog'],
  data () {
    return {
      showTableDataDialog: false,
      tableData: {
        title: '',
        headers: [],
        rows: [],
        footers: []
      },
      options: {
        page: 1,
        itemsPerPage: this.data.itemsPerPage ? this.data.itemsPerPage : 5,
        sortBy: [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      },
      viewDataOptions: {
        page: 1,
        itemsPerPage: this.data.itemsPerPage ? this.data.itemsPerPage : 100,
        sortBy: [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      },
      importing: this.data.importing ? this.data.importing : false,
      exportCsv: this.data.exportCsv ? this.data.exportCsv : false,
      subItemsOnly: this.data.subItemsOnly ? this.data.subItemsOnly : false,
      arrayOfItems: [],
      filters: [],
      draggedItem: null,
      loadingData: false,
      allLoaded: false,
      selectedId: null,
      shouldSkipReFetch: true,
      dataMissing: '',
    }
  },
  computed: {
    ...mapGetters('app', ['dataTableHeaderDefinitions', 'currencies']),
    ...mapState('app', ['sites']),
    ...mapState('leasing', ['prospects', 'prospectsLoading']),
      siteIds () {
        return this.sites.map(site => site.id_site)
      },
    items: function () {
      const items = []
      this.data.items.map((item, idx) => {
      // Filter data if filtering is selected
      if (this.filters.length > 0 && !this.filters.some(filter => !helpers.tableFilter.filterRow(filter, this.data.filter, item))) {
        return
      }

        let keys = Object.keys(item)
        let thisItem = {}

        if (!keys.includes("subItems")) {
          keys = this.data.headers.map(header => header.value)
          thisItem = {...item}
        }

        thisItem.id = idx
        thisItem.expanded = this.selectedId === idx
        thisItem.layer = 0
        thisItem.subItems = []

        keys.forEach(key => {
          if (key === 'subItems' && item[key].length > 0) {
            thisItem = this.parseSubItems(item[key], thisItem, idx, 1)
          }
          // Here used to be implementation which transformed each list in object to strings...
          else if (Array.isArray(item[key])) {
           // No need to duplicate things
            const list = [... new Set(item[key]) ]
            let itemStr = ''
            // If there is more than 4 items, just show the first 4 items and add ... as the last item
            if (list.length > 4) {
              itemStr = [...list.slice(0, 4).map(v => this.getValueWithUnit(v, key))].join(', ') + '...'
            } else {
              itemStr = [...list]
                .map(v => this.getValueWithUnit(v, key))
                .filter(v => v !== null && v !== '')
                .join(', ')
            }
            thisItem[key] = itemStr
          } else {
            thisItem[key] = item[key]
          }
        })

        // fetch opened row data
        if (
          !this.shouldSkipReFetch &&
          this.selectedId != null &&
          thisItem.expanded &&
          !(thisItem.subItems?.[0]?.length > 0)
        ) {
          const toggleHeader = this.data.headers.filter(h => h.value === 'toggle')?.[0]
          if (toggleHeader && toggleHeader.event) {
            this.$emit('triggerCustomEvents', { eventName: toggleHeader.event, row: thisItem })
          }
        }

        items.push(thisItem)
      })
      return items
    },
    dwgsAvailable () {
      return this.$store.state.app.dwgsAvailable
    },
    filterTexts () {
      return this.filters.map(index => this.$t(this.data.filter.options[index].text)).join(', ');
    },
    hasMultipleFilters () {
      return this.filters.length > 1;
    },
    displayText () {
      return this.filters.length === 1 ? this.filterTexts : this.$t('All');
    },
  },
  watch: {
    filters (value) {
      if (!Array.isArray(value)) {
        this.filters = [value];
      }
    },
    items: {
      handler: function (items) {
        this.allLoaded = false;
        this.arrayOfItems = items
        this.updateTableData(items, this.data)
    },
    deep: true
    },
    'data.items': {
      handler: function () {
        // enable refetching expanded row data if it's missing
        this.shouldSkipReFetch = false
      },
    },
	'data.filter.defaultFilter': {
      handler: function (newValue){
        if (newValue) {
          this.filters.push(this.data.filter.options.findIndex(option => option.text === newValue.text))
        }
      }
    },
  },
  mounted () {
    this.arrayOfItems = this.items
    // If default filter is given, set it on mounted
    if (this.data.filter && this.data.filter.defaultFilter != null) {
      this.filters.push(this.data.filter.options.findIndex(option => option.text === this.data.filter.defaultFilter.text))
    }

    const date = this.$store.state.app.currentDate
    const today = new Date()

    if (!moment(date, 'day').isSame(today, 'day')) {
      this.dataMissing = this.$t('No data available or not time related')
    } else {
      this.dataMissing = this.$t('No data available')
    }
  },
  methods: {
    ...mapActions('leasing', [
      'getProspects',
      'getLeasingUsers',
      'getFutureRentalStatuses',
      'getProspectNotes',
      'getMunicipalities',
      'getParties',
      'getResponsibleSites',
      'getClosedContracts',
      'getAllProspectEvents',
      'getIgnoredProspects',
      'getPastRentalStatuses',
      'getRentalStatuses',
      ]),
      getValidDate (val) {
      const date = moment(val.split('T')[0], "YYYY-MM-DD")
      return date.isValid() ? date.format("D.M.YYYY") : undefined
    },
    async getSentInvoicesForCsv (headers, items) {
      this.loadingData = true
      if (this.allLoaded === false) {
        this.$emit("getSentInvoices",{ getAll: true , exportAsCsv: () => this.exportAsCsv(headers, this.data.items)})
      }
      else this.exportAsCsv(headers, items)
    },
    async linkTo (destination, path) {
      let target = undefined
      if(destination.id_site !== undefined){
        target = destination.id_site
      }
      if(target !== undefined){
        if(path === 'sites.info') {
          this.$router.push({
          name: 'sites.info',
          params: { id_site: target}
          })
        }
        if(path === 'sites.facilitymanagement')
        {
          let unitparam = undefined
          // dashboard link
          if(destination.unitIdentifier !== undefined ){
            unitparam = destination.unitIdentifier
          }
          // site link
          else if (destination.unitId !== undefined){
            unitparam = destination.unitId
          }
          let plan = await this.$rambollfmapi.sites.get(target)
          if(plan.has_dwg === true)
          {
            this.$router.push({
            name: 'sites.facilitymanagement',
            params: { id_site: target},
            query: { suite_id: unitparam}
          })}
          else
          {
            this.$router.push({
            name: 'sites.info',
            params: { id_site: target}
          })
          }
        }
      }
    },
    isRightAligned (format) {
      return helpers.format.alignRight(format)
    },
    getValueWithUnit (value, header) {
      return helpers.format.valueWithUnit(value, header)
    },
    // link exceptions
    getLinkStyle (value)
    {
      if(value.priority !== undefined)
        return null
      else
        return 'linked'
    },
    getObjectWithUnit (data, name) {
      if(this.data.fieldsStatusIcons && this.data.fieldsStatusIcons.hasOwnProperty(name)) {
        return this.data.fieldsStatusIcons[name](data)
      } else {
        var object = { name, data: data[name] }
        return object
      }
    },
    getFooterValue (data, headerName, text, average, format, unique) {
      if (typeof text !== 'undefined') {
        return text
      }
      if (typeof headerName === 'undefined' || typeof data === 'undefined') {
        return null
      }
      if(unique){
        const values = data.map(item => item[headerName])
        const uniqueValues = [...new Set(values)]
        const sum = uniqueValues.reduce((total, cur) => Number(total) + (isNaN(cur) ? 1 : Number(cur)), 0)
        return helpers.format.formatData(sum, format)
      }
      // If there are multiple currencies in the data, selectedCurrency value will be 'multiple' and sum will not be calculated, instead '-' will be shown in the footer
      const rowCurrency = helpers.table.defineRowCurrency(data, this.currencies)

      let value = currencySumFields.includes(headerName) && rowCurrency === 'multiple' ? 0 : data
        .map(i => {
          const toNumber = Number(i[headerName])
          return i[headerName] !== undefined ? isNaN(toNumber) ? 1 : toNumber : 0
        })
        .reduce((acc, cur) => Number(acc) + Number(cur), 0)
      if (headerName === 'general_rating') {
        var totalCount = 0
        var averageRating = 0
        var sitesWithAudit = 0
        for (let i = 0; i < data.length; i++) {
          var singleRate = data[i].general_rating
          if (singleRate !== null) {
            totalCount += singleRate
            sitesWithAudit += 1
          }
        }
        averageRating = totalCount / sitesWithAudit
        if (isNaN(averageRating)) {
          averageRating = 0
          }
        value = helpers.format.formatData(averageRating, headerName)
        return value
      }
      if (typeof average !== 'undefined' && average === true) {
        value = (parseFloat(value) / data.length).toFixed(2)
      }
      if (typeof format !== 'undefined') {

        value = helpers.format.formatData(value, format, rowCurrency)
      }
      return value
    },
    viewData (title, widgetData, data) {
      this.allLoaded = true
      let headers = widgetData.browseHeaders ? widgetData.browseHeaders : widgetData.headers.filter(h => h.value !== 'toggle')
      let footers = widgetData.browseFooters ? widgetData.browseFooters : widgetData.footers
      var arr = []
      if (widgetData.ignoreSubitemsInTable) {
        data.forEach(item => arr.push(item))
      }
      else {
        data.forEach(item => {
          this.getSubItems(item, this.subItemsOnly).forEach(sub => {
            arr.push(sub)
          })
        })
      }

      this.tableData = {
        title: title,
        headers: headers.map((h, idx) => {
          if (idx === 0) {
            h.width = '30%'
          }
          if (h.editType) {
            h.format = h.editType
          }
          return h
        }),
        rows: arr,
        footers: footers
      }
      this.viewDataOptions = {
        sortDesc: this.options.sortDesc,
        sortBy: this.options.sortBy,
        itemsPerPage: -1
      }
      this.showTableDataDialog = true
    },
    closeViewData () {
      this.showTableDataDialog = false
      this.tableData = {
        title: '',
        headers: [],
        rows: [],
        footers: []
      }
      this.data.headers.map(h => {
        if(h.editType) {
          delete h.format
        }
      })

    },
    createFooters (headers, rows) {
      return helpers.table.createFooters(headers, rows, this.data.browseFooters, this.data.footers)
    },
    getHumanReadableDate (date) {
      return helpers.humanize.date(date, 'date')
    },
    exportAsCsv (originalHeaders, originalData) {
      this.allLoaded = true;
      const headers = [...JSON.parse(JSON.stringify(originalHeaders))]
      const data = this.subItemsOnly
        ? [...JSON.parse(JSON.stringify(originalData))].map((m)=> ( [...m.subItems] )).flat()
        : [...JSON.parse(JSON.stringify(originalData))]

      const csvHeaders = headers.map(h => this.$t(h.text))
      const csvData = data.map(d => {
        const row = []
        headers.forEach(h => {
          let val = d[h.value]
          val = val === undefined ? '' : val
          val = this.getValueWithUnitForCsvExport(val, h.value, false)
          row.push(val)
        })
        return row
      })
      helpers.csv.export(csvHeaders, csvData)
      this.loadingData = false
    },
    getValueWithUnitForCsvExport (value, header, encode) {
      if (Array.isArray(value)) {
        return this.getTruncatedStringFromArray(value, header, encode)
      }

      if (header.includes('date')) {
        return helpers.format.valueWithUnit(value, header, encode)
      }

      if (value === null) {
        return ''
      }

      if (!isNaN(value)) {
        return value.toString().replace('.', ',')
      }

      return value
    },
    getTruncatedStringFromArray (value, header, encode) {
      // No need to duplicate things
      const list = new Set(value)
      let itemStr = ''
      // If there is more than 4 items, just show the first 4 items and add ... as the last item
      if (list.length > 4) {
        itemStr = [
          ...list
            .slice(0, 4)
            .map(v => this.getValueWithUnit(v, header, encode)),
          '...'
        ].join(', ')
      } else {
        itemStr = [...list]
          .map(v => this.getValueWithUnit(v, header, encode))
          .filter(v => v !== null && v !== '')
          .join(', ')
      }

      return itemStr
    },
    getSubItems (item, ignoreParentItems = false) {
      var subArrays = []
      if (!ignoreParentItems) {
        subArrays.push(item)
      }
      if (item.subItems?.length > 0) {
        item.subItems.forEach(arr => {
          subArrays = subArrays.concat(arr)
        })
      }

      subArrays.sort((a, b) => a.id.toString() > b.id.toString() ? 1 : -1)
      return subArrays
    },
    getStyleForLayer (layer) {
      if (layer === 0) {
        return null
      } else if (layer === 1) {
        return "background-color: #f0f9ff;"
      } else if (layer === 2) {
        return "background-color: #FCE4D6;"
      }
      // undefined color
      else return "background-color: red;"
    },
    isExpanded (props, item) {
      if (item.layer === 0) {
        return true
      } else if (item.layer === 1) {
        return props.expanded
      } else {
        return props.subItems[item.layer - 2].find(parent => parent.id === item.parentId).expanded
      }
    },
    parseSubItems (subItems, item, parentId, round) {
      subItems.forEach((obj, index) => {
        obj.parentId = parentId
        obj.id = obj.parentId + '_' + index
        obj.expanded = false
        obj.layer = round
        if (typeof item.subItems[round - 1] === 'undefined') {
          item.subItems[round - 1] = []
        }
        item.subItems[round - 1].push(obj)
        Object.keys(obj).forEach(key => {
          if (key === 'subItems' && obj[key].length > 0) {
            this.parseSubItems(obj[key], item, obj.id, round + 1)
          }
        })
      })
      return item
    },
    async UpdateSentInvoices (item, id, dataItem) {
      // THIS IS MUTATING PROPS, SHOULD BE FIXED
      const bundles = await this.$rambollfmapi.reports.list(["GetSentInvoices"],[],null,[],[],item)
      this.selectedId = id;
      dataItem.netSum = bundles.GetSentInvoices[0].netSum
      dataItem.state = this.$t('invoice.' + bundles.GetSentInvoices[0].state)
      dataItem.subItems = bundles.GetSentInvoices[0].invoices.map(inv => ({
                ...inv,
                state: bundles.GetSentInvoices[0].state ? this.$t('invoice.' + bundles.GetSentInvoices[0].state) : '',
                stateTimestamp: bundles.GetSentInvoices[0].stateTimestamp ? helpers.humanize.date(bundles.GetSentInvoices[0].stateTimestamp + 'Z', 'time') : "",
                invoiceCount: 1,
                billingDate: inv.billingDate ? this.getValidDate(inv.billingDate) : "",
                type: inv.type ? this.$t('invoice.' + inv.type) : '',
              }));
    },
    expand (item) {
      if (item.layer === 0) {
        var isAnyRowExpanded = false
        this.arrayOfItems.map(async i => {
          if (i.id === item.id) {
            const toggleHeader = this.data.headers.filter(h => h.value === 'toggle')?.[0]
            if (item.bundle && i.expanded === false && i.subItems[0].length < 2 && i.invoiceCount !== 1) {
              await this.UpdateSentInvoices(item.bundle, i.id, this.data.items[i.id])
            } else if (
              // when expanding a row fetch the selected row's subrows
              i.expanded == false &&
              toggleHeader &&
              toggleHeader.event
            ) {
              this.selectedId = i.id
              this.$emit('triggerCustomEvents', { eventName: toggleHeader.event, row: i })
            }
            i.expanded = !i.expanded
            isAnyRowExpanded = i.expanded
          } else {
            i.expanded = false
          }
          if (i.subItems?.length > 0) {
            i.subItems.forEach(sub => {
              sub.map(s => { s.expanded = false })
            })
          }
        })
        if (!isAnyRowExpanded) {
          this.selectedId = null
        }
        this.shouldSkipReFetch = true
      } else {
        this.arrayOfItems.find(i => i.id === item.parentId).subItems.forEach(subLayer => { subLayer.map(i => {
            if (i.id === item.id) {
              i.expanded = !i.expanded
            } else {
              i.expanded = false
            }
          })
        })
        // Hack to update parent... is there better way?
        this.arrayOfItems.find(i => i.id === item.parentId).expanded = false
        this.arrayOfItems.find(i => i.id === item.parentId).expanded = true
      }
    },
    selectChanged (property, item){
      this.$emit("changePproperty", {title: this.title, property: property, item: item} )
    },
    formatData (row, header, format, isLink = false) {
      if (this.customFormatters && this.customFormatters.hasOwnProperty(header)) {
        return this.customFormatters[header](row, this)
      }
      const value = isLink ? helpers.format.escapeHtml(row[header]) : row[header]
      return helpers.format.formatData(value, format, this.currencies.find(c => c.id === row.currency_id)?.symbol)
    },
    dataModified (item, parent, header, value) {

      let level2 = null

      if(parent.subItems && parent.subItems.length > 0){
        level2 = parent.subItems[0].find(ch => ch.id == item.parentId)
      }

      this.$emit(
        'dataUpdated',
        {
          id: this.title,
          item: item,
          level1: parent,
          level2,
          header: header,
          value: value
        })
      this.items[item.id][header] = value
      if (header === 'reportable_ending') {
        this.items[item.id].reportable_carparks_count = value === true ? item.carparkCount : 0
        this.items[item.id].reportable_area = value === true ? item.reportable_area : 0
      }

    },
    dragover (event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('drag-bg')) {
        event.currentTarget.classList.add('drag-bg')
      }
    },
    dragleave (event) {
      event.currentTarget.classList.remove('drag-bg')
    },
    async drop (target) {
      this.$emit('drag', { action: 'drop', title: this.title, object: target})
      this.draggedItem = null
    },
    startDrag (item, header) {
      this.draggedItem = { item: item, header: header}
      this.$emit('drag', { action: 'start', title: this.title, object: this.draggedItem})
    },
    disabledField (rowData, header) {
      if(this.data.disablingFields && this.data.disablingFields.hasOwnProperty(header)) {
        return this.data.disablingFields[header](rowData)
      }
      return false
    },
    updateTableData (data, widgetData) {
      var arr = []
      if (widgetData.ignoreSubitemsInTable)
      {
        data.forEach(item => arr.push(item))
      }
      else {
        data.forEach(item => {
          this.getSubItems(item, this.subItemsOnly).forEach(sub => {
            arr.push(sub)
          })
        })
      }

      // if newly fetched data does not include all data, we should not update tableData
      if (this.showTableDataDialog && this.shouldRequestAllData && !this.allLoaded) {
        return
      } else {
        const newTableData = {
          ...this.tableData,
          rows: arr
        }
        this.tableData = newTableData
      }
    },
    clickAction (item, header) {
      if(header === 'allocation_code') {
        const allocationCode = item.allocation_code
        this.$emit('openDialog', { allocationCode, header })
      }
    },
    async getAllProspectData (callback) {
      await this.getProspectNotes(),
      await this.getLeasingUsers(),
      await this.getMunicipalities(),
      await this.getParties(),
      await this.getAllProspectEvents(),
      await this.getProspects(),
      this.getIgnoredProspects(),
      this.getResponsibleSites(),
      this.getClosedContracts(),
      this.getRentalStatuses(this.siteIds),
      this.getFutureRentalStatuses({siteIds: this.siteIds}),
      this.getPastRentalStatuses({siteIds: this.siteIds})
      callback()
    },
    defaultCellClass (header) {
      return {
        'col-align-right': this.isRightAligned(header.format)
      }
    },
    beginExport () {
      if (this.title === this.$t('billing.sent_invoices')) {
        this.getSentInvoicesForCsv(this.data.headers, this.data.items)
      }
      else if (this.shouldRequestAllData && this.allLoaded === false) {
        this.loadingData = true
        this.$emit('getAllData', {
          id: this.widgetId,
          callback: () => this.exportAsCsv(this.data.headers, this.data.items)
        })
      } else {
        this.exportAsCsv(this.data.headers, this.data.items)
      }
    },
    beginViewData () {
      if (this.shouldRequestAllData && this.allLoaded === false) {
        this.$emit('getAllData', {
          id: this.widgetId,
          callback: () => this.viewData(this.title, this.data, this.items)
        })
      } else {
        this.viewData(this.title, this.data, this.items)
      }
    }
  }
}
</script>
<style scoped>
.icon_padding {
  padding-right: 0.5em;
}
.dragbtn {
  border: 1px solid;
}
.handoverDirection {
  color: rgb(113, 188, 241);
}
.statusCompleted {
  color: rgb(76, 175, 80);
  padding-left: 0.5em;
}
.statusInProgress {
  color: rgb(255, 173, 119);
  padding-left: 0.5em;
}

@media screen and (max-width: 760px) {
  .filterButton {
    margin: 0px;
    border-radius: 2em;
    width: 100%;
    justify-content: start;
  }
  .filterBox {
    margin: 1em 1.5em;
    display: flex;
    justify-content: center;
    opacity: 0.87;
  }
}
</style>
