<template>
  <CdsAlert
    v-if="idCheckProcess"
    class="ml-6 mb-0"
    :type="idCheckIsLoading || isCheckValid ? 'primary' : 'danger'"
    :icon="isCheckValid ? 'check' : 'warning_amber'"
    size="small"
    :loading="idCheckIsLoading"
    :description="$t(idCheckIsLoading ? 'create_rent_contract.isCheckingIdNumber' : idCheckDescription)"
  >
    <template #action>
      <v-dialog
        v-if="!idCheckIsLoading && idCheckResults.length > 0"
        v-model="idCheckResultsModal"
        width="700"
      >
        <template #activator="{ on, attrs }">
          <CdsButton
            :type="isCheckValid ? 'secondary' : 'danger'"
            size="small"
            class="ma-0"
            v-bind="attrs"
            v-on="on"
            @click="idCheckResultsModal = true"
          >
            {{ $t('Extra info') }}
          </CdsButton>
        </template>
        <IdCheckResultsModal
          :id-check-results="idCheckResults"
          @close="idCheckResultsModal = false"
        />
      </v-dialog>
    </template>
  </CdsAlert>
</template>
<script>
import IdCheckResultsModal from './IdCheckResultsModal.vue'
import CdsAlert from '../../../CircleDesignSystem/CdsAlert.vue'
import CdsButton from '../../../CircleDesignSystem/CdsButton.vue'

export default {
  name: 'IdCheckResults',
  components: {
    IdCheckResultsModal,
    CdsAlert,
    CdsButton,
  },
  props: {
    selectedTenantId: {
      type: Number,
      default: 0
    },
    selectedTenantIdentityNumber: {
      type: String,
      default: ''
    },
    isValidIdentityNumber: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      idCheckResultsModal: false,
      idCheckProcess: false,
      idCheckIsLoading: false,
      idCheckResults: []
    }
  },
  computed: {
    isCheckValid () {
      if (this.idCheckResults.length === 0) {
        return true
      }
      return this.idCheckResults.every(result => result.isValid)
    },
    idCheckDescription () {
      if (this.idCheckIsLoading) {
        return
      }
      if (this.idCheckResults.length === 0) {
        return 'create_rent_contract.valid_identity_number'
      }
      // Remove duplicates and map to description
      return this.idCheckResults
        .filter((result, index, self) => index === self.findIndex(t => (t.tenantIdCheckMessage === result.tenantIdCheckMessage)))
        .map(result => this.$t(result.tenantIdCheckMessage)).join(', ')
    }
  },
  watch: {
    selectedTenantIdentityNumber () {
      this.checkIdentityNumber()
    },
    selectedTenantId () {
      this.checkIdentityNumber()
    },
  },
  methods: {
    async checkIdentityNumber () {
      this.idCheckProcess = false
      this.idCheckIsLoading = false
      this.idCheckResults = []

      // Check if tenantIdentityNumber and tenantId are set
      if (!this.selectedTenantId || !this.selectedTenantIdentityNumber) return

      // Check if tenantIdentityNumber is valid
      if (!this.isValidIdentityNumber) return

      this.idCheckProcess = true
      this.idCheckIsLoading = true
      this.idCheckResults = await this.$rambollfmapi.contracts.contract.checkIdentityNumber(this.selectedTenantId, this.selectedTenantIdentityNumber)

      this.idCheckIsLoading = false
    },
  }
}
</script>
<style lang="scss" scoped>

</style>