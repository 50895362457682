import RambollFMAPI from '../api/rambollfm.js'
import loglevel from 'loglevel'
import moment from 'moment'
import store from '../store/store.js'
import { i18n } from '../plugins/i18n.js'
import helpers from '../helpers'
import { getApartmentsForSites } from '../helpers/apartments.js'
import lodash from 'lodash'
import { carparks, siteCarparks } from '../helpers/mappers/carparks.js'

function RentableArea (rootGetters, rentalStatuses, sites, buildings) {
  // Attach site information to rentalStatuses
  const rentalStatusesWithSites = rentalStatuses.map(r => {
    r.site = sites.find(s => s.id_site === r.siteId)
    r.building = buildings.find(
      b => b.building_code === r.buildingCode
    )
    return r
  })

  // Mutate rentalstatuses into object with purpose of use as a key
  return rentalStatusesWithSites.reduce((acc, cur) => {
    if (typeof cur.building === 'object') {
      let use = cur.building.building_usage

      if (use === null) {
        use = 'Unknown'
      } else {
        use = GetDefinitionLabelById(rootGetters, cur.building.building_usage)
      }

      if (typeof acc[use] === 'undefined') {
        acc[use] = 0
      }

      acc[use] += Number(cur.area)
    } else {
      loglevel.info('Rentalstatus contains a building that is not available!')
    }
    return acc
  }, {})
}
function buildingsByUsage (rootGetters, buildings) {
  return buildings.map(building => { return { building_usage: GetDefinitionLabelById(rootGetters, building.building_usage) } })
}

function buildingsByClassification (rootGetters, buildings) {
  return buildings.map(building => { return { building_class_id: GetBuildingClassificationById(rootGetters, building.building_class_id) } })
}

function Contracts (rentalStatus) {
  const allTenants = rentalStatus.filter(rs => rs.curPartyId > 0)
    .map(rs => {
      return {
        site_name: rs.siteName,
        unit_name: rs.unitName,
        unit_area: rs.area,
        unit_class: i18n.t(rs.unitUsage),
        current_tenant_name: rs.curPartyName,
        start_date: (rs.curPartyStartDate),
        end_date: (rs.curPartyEndDate),
        siteIdentifier : rs.siteId,
        unitIdentifier : rs.unitId
        }
    })
  // Group by Site and current tenant
  const groupedTenants = allTenants.reduce((acc, cur) => {
    const key = cur.site_name + cur.current_tenant_name
    if (typeof acc[key] === 'undefined') {
      acc[key] = {
        site_name: cur.site_name,
        unit_name: [],
        unit_area: 0,
        unit_class: [],
        current_tenant_name: cur.current_tenant_name,
        start_date: [],
        end_date: [],
        siteIdentifier: cur.siteIdentifier,
        unitIdentifier: cur.unitIdentifier
      }
    }

    acc[key].unit_name.push(cur.unit_name)
    acc[key].unit_class.push(cur.unit_class)
    acc[key].unit_area += cur.unit_area
    acc[key].start_date.push(cur.start_date)
    acc[key].end_date.push(cur.end_date)
    
    return acc
  }, {})

  return Object.keys(groupedTenants).map(k => {
    const val = groupedTenants[k]
    val.unit_name = [...new Set(val.unit_name)] // .join(', ')
    val.unit_class = [...new Set(val.unit_class)] // .join(', ')
    val.current_tenant_start_date = [
      ...new Set(val.current_tenant_start_date)
    ] // .join(', ')
    return val
  })
}
function Utilization (rentalStatuses, sites, rentalDefs) {
  const totalAreaBySite = {}
  const estateAreaBySite = {}
  const leasedAreaBySite = {}
  const freeAreaBySite = {}
  const nameBySite = {}

  const estateDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') > -1).map(x => x.id)
  const rentableDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') === -1).map(x => x.id)

  rentalStatuses.forEach(rs => {
    const site = rs.siteId

    if (typeof totalAreaBySite[site] === 'undefined') {
      totalAreaBySite[site] = 0
    }
    if (typeof estateAreaBySite[site] === 'undefined') {
      estateAreaBySite[site] = 0
    }
    if (typeof leasedAreaBySite[site] === 'undefined') {
      leasedAreaBySite[site] = 0
    }
    if (typeof freeAreaBySite[site] === 'undefined') {
      freeAreaBySite[site] = 0
    }

    nameBySite[site] = rs.siteName

    totalAreaBySite[site] += rs.area

    if (rs.curPartyId > 0) {
      leasedAreaBySite[site] += rs.area
    } else if (estateDefs.includes(rs.unitStatus)) {
      estateAreaBySite[site] += rs.area
    } else if (rentableDefs.includes(rs.unitStatus)) {
      freeAreaBySite[site] += rs.area
    }
  })

  return [...new Set(rentalStatuses.map(rs => rs.siteId))].map(rs => {
    const leasableArea = totalAreaBySite[rs] - estateAreaBySite[rs]
    return {
      site_name: nameBySite[rs],
      total_area: totalAreaBySite[rs],
      leasable_area: leasableArea,
      estate_area: estateAreaBySite[rs],
      leased_area: leasedAreaBySite[rs],
      free_area: freeAreaBySite[rs],
      total_are_minus_estate_area: totalAreaBySite[rs] - estateAreaBySite[rs],
      utilization_percentage: Number(
        (leasedAreaBySite[rs] /
          (totalAreaBySite[rs] - estateAreaBySite[rs])) * 100).toFixed(2),
      trend6m: 0,
      vacancy_rate_percentage: Number((freeAreaBySite[rs] / (totalAreaBySite[rs] - estateAreaBySite[rs])) * 100).toFixed(2),
      siteIdentifier : rs
    }
  })
}
function UtilizationForUnits (rentalStatuses, sites, rentalDefs) {
  const rentableDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') === -1).map(x => x.id)
  return rentalStatuses.map(rs => {
    var rentableSiteArea = 0
    rentalStatuses.filter(status => status.siteId === rs.siteId && rentableDefs.includes(status.unitStatus)).forEach(status => {
      rentableSiteArea += status.area
    })
    return {
      site_name: rs.siteName,
      unit_name: rs.unitName,
      id: rs.unitId,
      total_area: rs.area,
      leasable_area: rentableDefs.includes(rs.unitStatus) ? rs.area : 0,
      leased_area: rs.curPartyId > 0 ? rs.area : 0,
      free_area: rentableDefs.includes(rs.unitStatus) && !(rs.curPartyId > 0) ? rs.area : 0,
      vacancy_rate_percentage: rentableDefs.includes(rs.unitStatus) && !(rs.curPartyId > 0) ? Number((rs.area / rentableSiteArea) * 100) : 0,
      rentable_site_area: rentableSiteArea,
      showChild: (rentableDefs.includes(rs.unitStatus) && !(rs.curPartyId > 0)),
      siteIdentifier: rs.siteId,
      unitIdentifier: rs.unitId
    }
  })
}
function UnitVacanciesByUsage (rentalStatuses, rentalDefs) {
  const estateDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') > -1).map(x => x.id)
  const rentableDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') === -1).map(x => x.id)

  const unitVacancies = Object.values(rentalStatuses
    .reduce((acc, status) => {
      const usage = status.unitUsage
      if (acc[usage] === undefined) {
        acc[usage] = {
          usage: usage,
          totalArea: 0,
          estateArea: 0,
          leasedArea: 0,
          freeArea: 0
        }
      }

      acc[usage].totalArea += status.area
      if (status.curPartyId) {
        acc[usage].leasedArea += status.area
      } else if (estateDefs.includes(status.unitStatus)) {
        acc[usage].estateArea += status.area
      } else if (rentableDefs.includes(status.unitStatus)) {
        acc[usage].freeArea += status.area
      }

      return acc
    }, {}))

  return unitVacancies.map(rs => {
    return {
      usage: rs.usage === null ? 'Other' : rs.usage,
      total_area: rs.totalArea,
      estate_area: rs.estateArea,
      leased_area: rs.leasedArea,
      free_area: rs.freeArea,
      total_area_minus_estate_area: rs.totalArea - rs.estateArea,
      vacancy_percentage: 100.00 - Number(
        (rs.leasedArea /
          (rs.totalArea - rs.estateArea)) * 100).toFixed(2),
      trend6m: 0,
      siteIdentifier: rs.siteId,
      unitIdentifier: rs.unitId
    }
  })
}
function VatResponsibility (rentalStatuses, sites, rentalDefs) {
  const rentableAreaBySite = {}
  const vatAreaBySite = {}
  const notVatAreaBySite = {}

  const rentableDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') === -1).map(x => x.id)
  rentalStatuses.filter(rs => rentableDefs.includes(rs.unitStatus) && rs.vat !== null).forEach(rs => {
    const site = rs.siteId
    if (typeof rentableAreaBySite[site] === 'undefined') {
      rentableAreaBySite[site] = 0
    }
    if (typeof vatAreaBySite[site] === 'undefined') {
      vatAreaBySite[site] = 0
    }
    if (typeof notVatAreaBySite[site] === 'undefined') {
      notVatAreaBySite[site] = 0
    }

    rentableAreaBySite[site] += rs.area
    if (rs.vat === 1) {
      vatAreaBySite[site] += rs.area
    } else if (rs.vat === 0) {
      notVatAreaBySite[site] += rs.area
    }
  })

  return [...new Set(rentalStatuses.map(us => us.siteId))].map(us => {
    return {
      site_name: rentalStatuses.find(rs => rs.siteId === us).siteName,
      rentable_area: rentableAreaBySite[us],
      facility_area_vat: vatAreaBySite[us],
      facility_area_not_vat: notVatAreaBySite[us],
      percentage_vat: Number((vatAreaBySite[us] / rentableAreaBySite[us]) * 100),
      percentage_not_vat: Number((notVatAreaBySite[us] / rentableAreaBySite[us]) * 100),
      siteIdentifier: us
    }
  })
}

function PersonnelWithoutAssignment (personnelAssignments) {
  if (personnelAssignments.length === 0) {
    return null
  }
  const init = {
    Assigned: 0,
    Unassigned: 0
  }
  
  // personnelAssignments list has duplicate rows for those that have been assigned to several spaces.
  // Flushing out duplicates with Map.
  const uniquePersonnel = [...new Map(personnelAssignments.map(item =>
    [item['id_party'], item])).values()];

  return uniquePersonnel.reduce((acc, cur) => {
    if (cur.type === 1) {
      if (cur.space_id === null) {
        acc.Unassigned += 1
      } else {
        acc.Assigned += 1  
      }   
    }
      return acc
  }, init)
}

function PropertyTaxesList (propertyTaxes) {
  var result = propertyTaxes.reduce((acc, cur) => {
    const key = cur.id_site + '_' + cur.id_estate
    if (typeof acc[key] === 'undefined') {
      acc[key] = {}
    }
    if (cur.intended_use === 'Kiinteistö') {
      acc[key].administrative_property = cur.administrative_property
      acc[key].property_id = cur.property_id
      acc[key].intended_use = cur.intended_use
      acc[key].property_name = cur.property_name
      acc[key].vtj_pbe = cur.vtj_pbe
      acc[key].current_tax_amount = cur.current_tax_amount
      acc[key].past_tax_amount = cur.past_tax_amount
      acc[key].change_percentace = cur.change_percentace
      acc[key].property_number = cur.property_number
      acc[key].building_part_number = cur.building_part_number
      acc[key].acreage = cur.acreage
      acc[key].completion_date = cur.completion_date
      acc[key].municipality = cur.municipality
      acc[key].municipality_number = cur.municipality_number
      acc[key].region = cur.region
      acc[key].status = cur.status
      acc[key].property_tax_id = cur.id
    } else if (cur.intended_use === 'Maapohja' || cur.intended_use === 'Rakennus') {
      // first level of children
      if (typeof acc[key].subItems === 'undefined') {
        acc[key].subItems = []
      }
      if (typeof acc[key].subItems[cur.id_building] === 'undefined') {
        acc[key].subItems[cur.id_building] = {}
      }
      acc[key].subItems[cur.id_building].administrative_property = cur.administrative_property
      acc[key].subItems[cur.id_building].property_id = cur.property_id
      acc[key].subItems[cur.id_building].intended_use = cur.intended_use
      acc[key].subItems[cur.id_building].property_name = cur.property_name
      acc[key].subItems[cur.id_building].vtj_pbe = cur.vtj_pbe
      acc[key].subItems[cur.id_building].current_tax_amount = cur.current_tax_amount
      acc[key].subItems[cur.id_building].past_tax_amount = cur.past_tax_amount
      acc[key].subItems[cur.id_building].change_percentace = cur.change_percentace
      acc[key].subItems[cur.id_building].property_number = cur.property_number
      acc[key].subItems[cur.id_building].building_part_number = cur.building_part_number
      acc[key].subItems[cur.id_building].acreage = cur.acreage
      acc[key].subItems[cur.id_building].completion_date = cur.completion_date
      acc[key].subItems[cur.id_building].municipality = cur.municipality
      acc[key].subItems[cur.id_building].municipality_number = cur.municipality_number
      acc[key].subItems[cur.id_building].region = cur.region
      acc[key].subItems[cur.id_building].status = cur.status
      acc[key].subItems[cur.id_building].property_tax_id = cur.id

      acc[key].showToggle = true
    } else if (cur.intended_use === 'Rakennusosa') {
      // second level of children
      if (typeof acc[key].subItems === 'undefined') {
        acc[key].subItems = []
      }
      if (typeof acc[key].subItems[cur.id_building] === 'undefined') {
        acc[key].subItems[cur.id_building] = {}
      }
      if (typeof acc[key].subItems[cur.id_building].subItems === 'undefined') {
        acc[key].subItems[cur.id_building].subItems = []
      }
      if (typeof acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding] === 'undefined') {
        acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding] = {}
      }
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].administrative_property = cur.administrative_property
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].property_id = cur.property_id
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].intended_use = cur.intended_use
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].property_name = cur.property_name
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].vtj_pbe = cur.vtj_pbe
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].current_tax_amount = cur.current_tax_amount
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].past_tax_amount = cur.past_tax_amount
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].change_percentace = cur.change_percentace
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].property_number = cur.property_number
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].building_part_number = cur.building_part_number
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].acreage = cur.acreage
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].completion_date = cur.completion_date
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].municipality = cur.municipality
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].municipality_number = cur.municipality_number
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].region = cur.region
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].status = cur.status
      acc[key].subItems[cur.id_building].subItems[cur.id_partialbuilding].property_tax_id = cur.id

      acc[key].subItems[cur.id_building].showToggle = true
    }
    return acc
  }, {})

  // Convert items and subitems to arrays
  return Object.keys(result).map(lvl1 => {
    if (typeof result[lvl1].subItems !== 'undefined') {
      result[lvl1].subItems = Object.keys(result[lvl1].subItems).map(lvl2 => {
        if (typeof result[lvl1].subItems[lvl2].subItems !== 'undefined') {
          result[lvl1].subItems[lvl2].subItems = Object.keys(result[lvl1].subItems[lvl2].subItems).map(lvl3 => {
            const lvl3Val = result[lvl1].subItems[lvl2].subItems[lvl3]
            return lvl3Val
          })
        }
        const lvl2Val = result[lvl1].subItems[lvl2]
        return lvl2Val
      })
    }
    return result[lvl1]
  })
}

function getReserveSituationGraves (reserveSituationGraves) {

  const init = {
    'Vacant graves amount': reserveSituationGraves.vacant_graves_count,
    'Precursory reserved graves count': reserveSituationGraves.precursory_reserved_graves_count,
    'Released one year count': reserveSituationGraves.released_one_year_count,
    'Released two year count': reserveSituationGraves.released_two_year_count
  }
  
  return init
}

function SpacesByPurposeOfUse (buildingUsages) {
  const grouped = buildingUsages.reduce((acc, cur) => {
    const key = cur.building_code
    if (typeof acc[key] === 'undefined') {
      acc[key] = []
    }
    acc[key].push(cur)
    return acc
  }, {})

  return Object.keys(grouped).map(buildingCode => {
    const allUsages = grouped[buildingCode]
    const usages = allUsages.reduce((acc, cur) => {
      const key = cur.building_code

      if (typeof acc[key] === 'undefined') {
        acc[key] = 0
      }

      acc[key] += Number(cur.ala)
      return acc
    }, {})

    usages.building_name = allUsages[0].building_name
    usages.total_area = allUsages
      .map(u => u.area)
      .filter(u => u !== undefined && u !== null)
      .reduce((acc, cur) => acc + cur, 0)
    usages.space_use = allUsages
      .map(u => u.space_use)
      .filter(u => u !== undefined && u !== null)
      .reduce((acc, cur) => acc + ', ' + cur)

    return usages
  })
}

// function GetSiteNameById (sites, id) {
//   const map = sites.reduce((acc, cur) => {
//     acc[cur.id_site] = cur.name
//     return acc
//   }, {})
//   return map[id]
// }

function GetDefinitionLabelById (rootGetters, key) {
  const def = rootGetters['app/definitionById'](key)
  if (typeof def === 'undefined' || def === null) {
    return i18n.t('Undefined')
  }
  return def.label !== null ? def.label : i18n.t('Undefined')
}

function GetBuildingClassificationById (rootGetters, key) {
  const buildingClassification = rootGetters['app/buildingClassificationById'](key)
    if (typeof buildingClassification === 'undefined' || buildingClassification === null) {
        return i18n.t('Undefined')
    }
    return buildingClassification.nimi !== null ? buildingClassification.nimi : i18n.t('Undefined')  
}

function GetAddresses (Buildings) {
  const addresses = Buildings.map(building => {
    if (typeof building.address === 'string' && building.address.trim() !== '') {
      return building.address.trim();
    }
    return null;
  });

  const filteredAddresses = addresses.filter(address => address !== null);
  const uniqueAddresses = new Set(filteredAddresses);

  if (uniqueAddresses.size === 1) {
    return uniqueAddresses.values().next().value;
  } else {
    const joinedAddresses = filteredAddresses.join(", ");
    return joinedAddresses;
  }
}

function GetOwners (Buildings) {
  const owners = Buildings.map(building => {
    if (typeof building.owner === 'string' && building.owner.trim() !== '') {
      return building.owner.trim();
    }
    return null;
  });

  const filteredOwners = owners.filter(owner => owner !== null);
  const uniqueOwners = new Set(filteredOwners);

  if (uniqueOwners.size === 1) {
    return uniqueOwners.values().next().value;
  } else {
    const joinedOwners = filteredOwners.join(", ");
    return joinedOwners;
  }
}

function GetRentalRate (site) {
  const rentedFloorArea = parseFloat(site.rented_floor_area)
  const netFloorArea = parseFloat(site.net_floor_area)

  if (!isNaN(rentedFloorArea) && !isNaN(netFloorArea) && netFloorArea !== 0) {
    const occupancyRate = ((rentedFloorArea / netFloorArea) * 100).toFixed(2)
    return occupancyRate
  } else {
    return ''
  }
}

function GetPartiesForSite (site,siteParties) {
  const partiesForSite = siteParties.filter(siteParty => siteParty.idKohde === site.id_site)
  return partiesForSite
}

function SetupPartyResponsibilityDefinitions (rootGetters) {
  const siteResponsibilityDefs = GetDefinitionGroup(rootGetters, 'site.responsibility')
  const roleDefs = GetDefinitionGroup(rootGetters, 'role')

  const combinedDefs = [...siteResponsibilityDefs, ...roleDefs]

  const sortedDefs = combinedDefs.sort((a, b) => {
    const labelA = i18n.t(a.label)
    const labelB = i18n.t(b.label)
    
    return labelA < labelB ? -1 : 1
  });
  return sortedDefs
}

function getBuildingPermissionValidity (report) {
  return report
    .map((building) => {
      return {
        ...building,
        internalBuildingCode: building.internal_building_code,
        buildingCode: building.building_code,
        buildingName: building.building_name,
        buildingId: building.id_building,
        temporaryBuildingPermitDescription: building.temporary_permit_desc,
        temporaryBuildingPermitDate: building.temporary_permit_validity,
        identifierSite: building.site_identifier,
        siteName: building.site_name,
        completionYear: building.completed_year,
        buildingUnitArea: building.building_unit_area,
        siteId: building.id_site,
        accessLevel: building.access_level,
        editableRow: building.access_level === 1
      }
    })
}

function getUnitPermissionValidity (report) {
  return report
    .map((unit) => {
      return {
        ...unit,

        unitName: unit.unit_name,
        internalBuildingCode: unit.internal_building_code,
        buildingName: unit.building_name,
        unitId: unit.id_unit,
        temporaryUnitPermitDescription: unit.temporary_permit_desc,
        temporaryUnitPermitDate: unit.temporary_permit_validity,
        identifierSite: unit.site_identifier,
        siteName: unit.site_name,          
        unitArea: unit.unit_area,
        siteId: unit.id_site,
        accessLevel: unit.access_level,
        editableRow: unit.access_level === 1
      }
    })
}

function GetMaintenance (siteData, sortedDefs) {
  const filteredDefs = sortedDefs.filter(def => def.label === i18n.t('Building Manager') || def.label === i18n.t('Technical supervisor'));
  const filteredSiteData = siteData.filter(data => {
    return filteredDefs.some(def => def.id === data.defRooli)
  });

  let combinedMaintenanceName = ''

  if (filteredSiteData.length > 0) {
    const filteredName = filteredSiteData
      .filter(data => data && data.nimi !== undefined)
      .map(data => data.nimi)
      combinedMaintenanceName = filteredName.join(', ')
  }
  
  return combinedMaintenanceName
}

function GetAdministrationContact (siteData,sortedDefs) {
  const filteredDefs = sortedDefs.filter(def => def.label === i18n.t('AdministrationContact'))

  const filteredSiteData = siteData.filter(data => {
    return filteredDefs.some(def => def.id === data.defRooli)
  });
  
  let combinedMaintenanceName = ''
  if (filteredSiteData.length > 1) {
    const filteredName = filteredSiteData
      .filter(data => data && data.nimi !== undefined)
      .map(data => data.nimi)
      combinedMaintenanceName = filteredName.join(', ')
  } else if (filteredSiteData.length === 1 && filteredSiteData[0] && filteredSiteData[0].nimi !== undefined) {
    combinedMaintenanceName = filteredSiteData[0].nimi
  }

  return combinedMaintenanceName
}

function GetMarketingContact (siteData,sortedDefs) {
  const filteredDefs = sortedDefs.filter(def => def.label === i18n.t('marketing.contact'))

  const filteredSiteData = siteData.filter(data => {
    return filteredDefs.some(def => def.id === data.defRooli)
  });
  
  let combinedMaintenanceName = ''
  if (filteredSiteData.length > 1) {
    const filteredName = filteredSiteData
      .filter(data => data && data.nimi !== undefined)
      .map(data => data.nimi)
      combinedMaintenanceName = filteredName.join(', ')
  } else if (filteredSiteData.length === 1 && filteredSiteData[0] && filteredSiteData[0].nimi !== undefined) {
    combinedMaintenanceName = filteredSiteData[0].nimi
  }
  return  combinedMaintenanceName
}

function GetDefinitionGroup (rootGetters, key) {
  const defs = rootGetters['app/definitionsByGroupLabel'](key)
  if (typeof defs === 'undefined' || defs === null) {
    return []
  }
  return defs
}

function GetMapLayerFromBuildings (buildings, sites) {
  if (buildings === undefined) {
    return [
      {
        name: 'Main map',
        title: 'Main map',
        type: 'OSM',
        opacity: 1
      }]
  }
  const buildingsWithCoordinates = buildings.filter(b => {
    if (
      b.coordinate_x &&
      b.coordinate_y &&
      sites.find(s => s.id_site === b.id_site)
    ) {
      return b
    }
  })

  loglevel.debug('buildings with coordinates ', buildingsWithCoordinates)

  return [
    {
      name: 'Main map',
      title: 'Main map',
      type: 'OSM',
      opacity: 1
    },
    {
      name: 'Buildings',
      title: 'Buildings',
      type: 'Points',
      opacity: 1,
      points: buildingsWithCoordinates.map(b => {
        return {
          x: b.coordinate_x,
          y: b.coordinate_y,
          projection: b.coordinate_system,
          data: b,
          name: b.address,
          description: b.building_identifier + ' ' + b.post_office
        }
      })
    }]

  /* this.map.mapLayers = this.map.mapLayers.filter(
        l => l.name === 'Buildings'
      )
      this.map.mapLayers.push(layer) */
}

function GetMapLayerFromCemeteries (cemeteries, sites) {
  if (cemeteries === undefined || cemeteries === null) {
    return [
      {
        name: 'Main map',
        title: 'Main map',
        type: 'OSM',
        opacity: 1
      }]
  }
  const cemeteriesWithCoordinates = cemeteries.filter(g => {
    if (
      g.xkoord !== null &&
      g.ykoord !== null &&
      sites.find(s => s.id_site === g.idSite)
    ) {
      g.id_site = g.idSite
      return g
    }
  })

  loglevel.debug('Cemeteries with coordinates ', cemeteriesWithCoordinates)

  let cemeteryObject =
  {
    name: 'Cemeteries',
    title: 'Cemeteries',
    type: 'Points',
    opacity: 1,
    points: cemeteriesWithCoordinates.map(g => {
      return {
        x: g.xkoord,
        y: g.ykoord,
        projection: 'EPSG:3879',
        data: g,
        name: g.name
      }
    })
  }
  return cemeteryObject
}

function RentalUnits (rentalStatus, rentalDefs, sites) {
  const rentableDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') === -1).map(x => x.id)

  var totalRentableAreaByUnit = 0
  rentalStatus.forEach(s => {
    totalRentableAreaByUnit += s.area
  })

  return rentalStatus.filter(vd => rentableDefs.includes(vd.unitStatus))
    .map(rs => {
      var vatArea = 0
      var notVatArea = 0
      var resp = ''
      if (rs.vat === 1) {
        vatArea = rs.area
        resp = 'Kyllä'
      } else if (rs.vat === 0) {
        resp = 'Ei'
        notVatArea = rs.area
      }
      return {
        site_name: sites.find(s => s.id_site === rs.siteId).name,
        building_name: rs.buildingName,
        unit_name: rs.unitName,
        unit_class: rs.unitUsage,
        tenant: rs.curPartyName,
        start_date: rs.curPartyStartDate,
        vat_responsibility: resp,
        unit_area_vat: vatArea,
        unit_area_not_vat: notVatArea,
        percentage_vat: Number((vatArea / totalRentableAreaByUnit) * 100),
        percentage_not_vat: Number((notVatArea / totalRentableAreaByUnit) * 100),
        total_rentable_area_by_unit: totalRentableAreaByUnit,
        siteIdentifier : rs.siteId,
        unitIdentifier : rs.unitIdentifier,
        unitId: rs.unitId
      }
    })
}

function LtmpYearOverview (fimxdata, sites, currentDate) {
  const today = new Date()
  
  if (!fimxdata || !Array.isArray(fimxdata)) {
    return []
  } else if (!moment(currentDate, 'day').isSame(today, 'day')) {
    return []
  }

  for(const cost of fimxdata) {
    const site = sites.find(x => x.site_identifier === cost.customerNumber)
    cost.yearlySums.forEach(sum => sum.area = site.net_floor_area)
  }

  // Objects in array have yearlySums-array inside them. Concatenate the values to
  // form a single array with all value.
  const sums = [].concat.apply([], fimxdata.map(x => x.yearlySums))
  const data = []
  const currentYear = currentDate.getFullYear()

  for(let year = currentYear; year < (currentYear + 10); year++) {
    const sumsForYear = sums.filter(x => x.year === year)
    const dataForYear = sumsForYear.reduce((acc, cur) => {
      acc.maintenanceInProcess += cur.maintenanceInProcess
      acc.maintenanceSuggestion += cur.maintenanceSuggestion
      acc.maintenanceCompleted += cur.maintenanceCompleted
      acc.investmentInProcess += cur.investmentInProcess
      acc.investmentSuggestion += cur.investmentSuggestion
      acc.investmentCompleted += cur.investmentCompleted
      acc.energyInvestmentInProcess += cur.energyInvestmentInProcess
      acc.energyInvestmentSuggestion += cur.energyInvestmentSuggestion
      acc.energyInvestmentCompleted += cur.energyInvestmentCompleted
      acc.totalMaintenance += cur.totalMaintenance
      acc.totalInvestment += cur.totalInvestment
      acc.totalEnergyInvestment += cur.totalEnergyInvestment
      acc.area += cur.area
      return acc
    }, {
      year: year,
      maintenanceInProcess: 0,
      maintenanceSuggestion: 0,
      maintenanceCompleted: 0,
      investmentInProcess: 0,
      investmentSuggestion: 0,
      investmentCompleted: 0,
      energyInvestmentInProcess: 0,
      energyInvestmentSuggestion: 0,
      energyInvestmentCompleted: 0,
      totalMaintenance: 0,
      totalInvestment: 0,
      totalEnergyInvestment: 0,
      area: 0
    })
    data.push(dataForYear)
  }
  return data
}

function LtmpTasksPerSiteYearOverview (fimxdata, sites, currentDate) {
  const today = new Date()

  if (!fimxdata || !Array.isArray(fimxdata)) {
    return []
  } else if (!moment(currentDate, 'day').isSame(today, 'day')) {
    return []
  }

  const currentYear = currentDate.getFullYear()
  const tenYearsFromCurrentYear = currentYear + 10
    const filteredData = fimxdata.map(x => { 
      const filteredYears = x.yearlySums.filter(yearSum => yearSum.year >= currentYear && yearSum.year < tenYearsFromCurrentYear)
      return { customerNumber: x.customerNumber, yearlySums: filteredYears }
    })

  const data = filteredData.map(x => {
    const siteData = sites.find(s => s.site_identifier === x.customerNumber)
    
    const siteYearDatas = x.yearlySums.reduce((total, yearSum) => {
      return {
        ...total,
        [i18n.t('Total (for period)')]:  total[i18n.t('Total (for period)')] + yearSum.totalEnergyInvestment + yearSum.totalInvestment + yearSum.totalMaintenance,
        [i18n.t('Total suggestion (for period)')]: total[i18n.t('Total suggestion (for period)')] + yearSum.investmentSuggestion + yearSum.energyInvestmentSuggestion + yearSum.maintenanceSuggestion,
        [i18n.t('Total in process (for period)')]: total[i18n.t('Total in process (for period)')] + yearSum.energyInvestmentInProcess + yearSum.investmentInProcess + yearSum.maintenanceInProcess,
        [i18n.t('Total completed (for period)')]: total[i18n.t('Total completed (for period)')] + yearSum.energyInvestmentCompleted + yearSum.investmentCompleted + yearSum.maintenanceCompleted,
        [String(yearSum.year) + ' ' + i18n.t('Energy investment total')] : yearSum.totalEnergyInvestment,
        [String(yearSum.year) + ' ' + i18n.t('Investment total/ CAPEX')] : yearSum.totalInvestment,
        [String(yearSum.year) + ' ' + i18n.t('Maintenance total/ OPEX')] : yearSum.totalMaintenance,
        [String(yearSum.year) + ' ' + i18n.t('Energy investment suggestion')] : yearSum.energyInvestmentSuggestion,
        [String(yearSum.year) + ' ' + i18n.t('Investment suggestion/ CAPEX')] : yearSum.investmentSuggestion,
        [String(yearSum.year) + ' ' + i18n.t('Maintenance suggestion/ OPEX')] : yearSum.maintenanceSuggestion,
        [String(yearSum.year) + ' ' + i18n.t('Energy investment in process')] : yearSum.energyInvestmentInProcess,
        [String(yearSum.year) + ' ' + i18n.t('Investment in process/ CAPEX')] : yearSum.investmentInProcess,
        [String(yearSum.year) + ' ' + i18n.t('Maintenance in process/ OPEX')] : yearSum.maintenanceInProcess,
        [String(yearSum.year) + ' ' + i18n.t('Energy investment completed')] : yearSum.energyInvestmentCompleted,
        [String(yearSum.year) + ' ' + i18n.t('Investment completed/ CAPEX')] : yearSum.investmentCompleted,
        [String(yearSum.year) + ' ' + i18n.t('Maintenance completed/ OPEX')] : yearSum.maintenanceCompleted
      }
    }, { [i18n.t('Total (for period)')]: 0, [i18n.t('Total suggestion (for period)')]: 0, [i18n.t('Total in process (for period)')]: 0, [i18n.t('Total completed (for period)')]: 0 })

    let siteOverview = {

      site_identifier: siteData.site_identifier,
      id_site: siteData.id_site,
      site_name: siteData.name,
      net_floor_area: siteData.net_floor_area,
      ...siteYearDatas,
    }
    let siteOverviewArray = Object.entries(siteOverview)
    let siteOverviewStart = siteOverviewArray.splice(0, 3)
    siteOverviewStart.push([ [i18n.t('Total for period per net floor area')], Math.round(siteYearDatas[i18n.t('Total (for period)')] / siteData.net_floor_area).toFixed(2) ])
    siteOverviewStart.push([ [i18n.t('Total suggestion for period per net floor area')], Math.round(siteYearDatas[i18n.t('Total suggestion (for period)')] / siteData.net_floor_area).toFixed(2) ])
    siteOverviewStart.push([ [i18n.t('Total in process for period per net floor area')], Math.round(siteYearDatas[i18n.t('Total in process (for period)')] / siteData.net_floor_area).toFixed(2) ])
    siteOverviewStart.push([ [i18n.t('Total completed for period per net floor area')], Math.round(siteYearDatas[i18n.t('Total completed (for period)')] / siteData.net_floor_area).toFixed(2) ])
    
    const sitePerYearOverview = Object.fromEntries([...siteOverviewStart, ...siteOverviewArray])
    return sitePerYearOverview
  })
  return data
}
function PurposeOfUseHeaders (buildingUsages) {
  const preferredHeaders = [
    'TSTOH',
    'AVOTOIMISTO',
    'NEUV.H',
    'KOKOUSTILA',
    'VARASTO',
    'TYÖTILA',
    'PUKUH',
    'ÄÄNISTUDIO',
    'KUVAUSTILA'
  ]
  const headers = []
  headers.push({ text: 'Building', value: 'building_name' })
  headers.push({ text: 'Total area', value: 'area' })
  headers.push({ text: 'Space use', value: 'space_use' })

  const usages = [...new Set(buildingUsages.map(b => b.huoneKaytto))]

  let orderedUsages = usages
    .map(usage => {
      return {
        usage: usage,
        area: buildingUsages
          .filter(b => b.huoneKaytto === usage)
          .map(b => b.ala)
          .filter(a => a !== null)
          .reduce((acc, cur) => acc + cur, 0)
      }
    })
    .sort((a, b) => {
      return a.area < b.area ? 1 : -1
    })
    .map(u => u.usage)

  orderedUsages = [
    ...orderedUsages.filter(u => preferredHeaders.includes(u)),
    ...orderedUsages.filter(u => !preferredHeaders.includes(u))
  ]

  orderedUsages.forEach(usage => {
    headers.push({ text: usage, value: usage + '_area' })
  })
  return headers
}
function getVacantWorkstationsByPurposeOfUse (workstations) {
  const ws = workstations.reduce((acc, cur) => {
    if (typeof acc[cur.kaytto] === 'undefined' || acc[cur.kaytto] === null) {
      acc[cur.kaytto] = (cur.tyoPLkm - cur.varattuLkm)
    } else {
      acc[cur.kaytto] += (cur.tyoPLkm - cur.varattuLkm)
    }
    return acc
  }, {})
  return ws
}

function getWorkstationsForBuildings (buildings, workstations) {
  const buildingsWithWorkstations = buildings.map(b => {
    workstations.filter(w => w.rnro === b.building_code).map(w => {
      if (b.workstation_count) {
        b.workstation_count += w.tyoPLkm
        b.personnel_count += w.varattuLkm
        b.net_room_area += w.huonala
      } else {
        b.workstation_count = w.tyoPLkm
        b.personnel_count = w.varattuLkm
        b.net_room_area = w.huonala
      }
    })
    b.space_utilization = b.personnel_count > 0 ? b.net_room_area / b.personnel_count : '-'
    return b
  })
  return buildingsWithWorkstations.filter(b => b.workstation_count)
}

function getWorkstationsForRoomShares (workstations) {
  const spacesWithWorkstations = workstations.map(w => {
    w.vacant_workstations = w.tyoPLkm - w.varattuLkm
    w.net_room_area = w.huonala
    w.building_name = w.rakennusNimi
    w.workstation_count = w.tyoPLkm
    w.use = w.kaytto
    w.space_name = w.hnro
    return w
  })
  return spacesWithWorkstations.filter(b => b.tyoPLkm)
}


function getAuditsReportForDashboard (getAuditsReportForDashboard) {
  const auditsForDashboard = getAuditsReportForDashboard
    .map(afd => {
      return {
        site: afd.site,
        site_identifier: afd.site_identifier,
        general_rating: afd.general_rating,
        report_date: afd.report_date,
        siteIdentifier : afd.site_id
      }
    })

  return auditsForDashboard
}

function getContractRows (contractRows) {
  const contracts = contractRows
    .map(c => {
      return {
        organization: c.organization,
        site_name: c.site_name,
        area: c.area,
        unit_area: c.unit_area,
        leasable_area: c.leasable_area,
        contract_area: c.contract_area,
        start_date: c.start_date,
        end_date: c.end_date,
        notice_period: c.notice_period,
        landlord_notice_period: c.landlord_notice_period,
        earliest_notice_date: c.earliest_notice_date
      }
    })

  return contracts
}

function getZonePercentages (purposeZones) {
  if(purposeZones && purposeZones.length > 0) {

    const dataItems = [...purposeZones]

    dataItems.sort((a, b) => {
      const nameA = a.purpose_zone_name.toUpperCase()
      const nameB = b.purpose_zone_name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    return dataItems 
    
    
  }
  return []
}

function SpaceUtilizationByGovernment (utilizations)
{
  var res = utilizations.reduce((acc,cur) => {
    const key = cur.government + '_' + cur.organization + '_' + cur.site_name
    if (typeof acc[key] === 'undefined') {
      acc[key] = {
        government: cur.government,
        organization: cur.organization,
        site_name: cur.site_name,
        office_area_by_HTV: 0,
        office_area_per_person: 0,
        total_area_by_HTV: 0,
        total_area_per_person: 0,
        total_area: 0,
        office_area: 0,
        total_HTV_count: cur.HTV_total_count.toFixed(2),
        office_HTV_count: 0,
        total_person_count: 0,
        office_person_count: 0
      }
    }

    acc[key].total_area += cur.unit_area
    acc[key].office_area += cur.purpose_zone === 'Toimisto' ? cur.unit_area / cur.percentage : 0

    // If usage of locked person count is enabled, we use it. Otherwisely, use the normal person count value
    const permit = cur.use_locked_person_count

    if (permit === true) {
      if (cur.person_count < cur.locked_person_count){
        acc[key].total_person_count = cur.person_count + cur.external_person_count
        acc[key].total_area_per_person += cur.unit_area / cur.person_count
      }
      else {
        acc[key].total_person_count = cur.locked_person_count + cur.external_person_count
        acc[key].total_area_per_person += cur.unit_area / cur.locked_person_count
      }      
    } else {
      acc[key].total_person_count = cur.person_count + cur.external_person_count
      acc[key].total_area_per_person += cur.unit_area / cur.person_count
    }

    acc[key].total_area_by_HTV += cur.unit_area / cur.HTV
    
    //Ensure we only receive the value once
    if (acc[key].office_HTV_count === 0) {
      acc[key].office_HTV_count = cur.purpose_zone === 'Toimisto' ? ((cur.percentage / 100)*acc[key].total_HTV_count).toFixed(2) : 0
    }
    
    if (acc[key].office_person_count === 0) {
      acc[key].office_person_count = cur.purpose_zone === 'Toimisto' ? ((cur.percentage / 100)*acc[key].total_person_count).toFixed() : 0
    }

    acc[key].office_area_by_HTV = acc[key].office_area / acc[key].office_HTV_count
    acc[key].office_area_per_person = acc[key].office_area / acc[key].office_person_count

    return acc
  }, {})

  return Object.keys(res).map(g => {
    return res[g]
  })
}

function getPurposeZonesByOrganizations (purposeZones) {

    // We make subitems for lower tier purpose zone levels
    var result = purposeZones.reduce((acc, cur) => {
      const key = cur.organization + '_' + cur.site_name + '_' + cur.ktv1
      if (typeof acc[key] === 'undefined') {
        acc[key] = {
        organization: cur.organization,
        site_name: cur.site_name,
        id_site: cur.id_site,
        purpose_zone_level: 'KTV1',
        purpose_zone_1: cur.ktv1 !== null ? cur.ktv1 : 'Ei määritelty',
        description: cur.ktv1 !== null ? cur.ktv1 : 'Ei määritelty',
        net_floor_area: 0,
        net_floor_area_by_htv: 0,
        external_person_count: 0,
        htv: 0 
        }
      }
      acc[key].external_person_count = cur.external_person_count.toFixed(2)
      acc[key].htv = cur.htv
      acc[key].net_floor_area += cur.net_floor_area
      acc[key].net_floor_area_by_htv =  cur.htv > 0 ? acc[key].net_floor_area / cur.htv : null
      acc[key].showToggle = true
      
      if (typeof acc[key].subItems === 'undefined') {
        acc[key].subItems = {}
      }
      if (typeof acc[key].subItems[cur.ktv2] === 'undefined') {
        acc[key].subItems[cur.ktv2] = {
          organization: cur.organization,
          site_name: cur.site_name,
          purpose_zone_level: 'KTV2',
          purpose_zone_1: cur.ktv1 !== null ? cur.ktv1 : 'Ei määritelty',
          purpose_zone_2: cur.ktv2 !== null ? cur.ktv2 : 'Ei määritelty',
          description: cur.ktv2 !== null ? cur.ktv2 : 'Ei määritelty',
          net_floor_area: 0,
          net_floor_area_by_htv: 0,
          external_person_count: 0,
          htv: 0
        }
      }
      acc[key].subItems[cur.ktv2].external_person_count = cur.external_person_count.toFixed(2) 
      acc[key].subItems[cur.ktv2].htv = cur.htv 
      acc[key].subItems[cur.ktv2].net_floor_area += cur.net_floor_area  
      acc[key].subItems[cur.ktv2].net_floor_area_by_htv = cur.htv > 0 ? acc[key].subItems[cur.ktv2].net_floor_area / cur.htv : null
      acc[key].subItems[cur.ktv2].showToggle = true
      if (typeof acc[key].subItems[cur.ktv2].subItems === 'undefined') {
        acc[key].subItems[cur.ktv2].subItems = {}
      }
      if (typeof acc[key].subItems[cur.ktv2].subItems[cur.ktv3] === 'undefined') {
        acc[key].subItems[cur.ktv2].subItems[cur.ktv3] = {
          organization: cur.organization,
          site_name: cur.site_name,
          purpose_zone_level: 'KTV3',
          purpose_zone_1: cur.ktv1 !== null ? cur.ktv1 : 'Ei määritelty',
          purpose_zone_2: cur.ktv2 !== null ? cur.ktv2 : 'Ei määritelty',
          purpose_zone_3: cur.ktv3 !== null ? cur.ktv3 : 'Ei määritelty',
          description: cur.ktv3 !== null ? cur.ktv3 : 'Ei määritelty',
          net_floor_area: 0,
          net_floor_area_by_htv: 0,
          external_person_count: 0,
          htv: 0
        }
      }
      acc[key].subItems[cur.ktv2].subItems[cur.ktv3].external_person_count = cur.external_person_count.toFixed(2)
      acc[key].subItems[cur.ktv2].subItems[cur.ktv3].htv = cur.htv
      acc[key].subItems[cur.ktv2].subItems[cur.ktv3].net_floor_area += cur.net_floor_area
      acc[key].subItems[cur.ktv2].subItems[cur.ktv3].net_floor_area_by_htv = cur.htv > 0 ? acc[key].subItems[cur.ktv2].subItems[cur.ktv3].net_floor_area / cur.htv : null
      
      return acc
    }, {})
  
    // Convert items and subitems to arrays
    return Object.keys(result).map(pz1 => {
      if (typeof result[pz1].subItems !== 'undefined') {
        result[pz1].subItems = Object.keys(result[pz1].subItems).map(pz2 => {
          if (typeof result[pz1].subItems[pz2].subItems !== 'undefined') {
            result[pz1].subItems[pz2].subItems = Object.keys(result[pz1].subItems[pz2].subItems).map(pz3 => {
              const pz3Val = result[pz1].subItems[pz2].subItems[pz3]
              return pz3Val
            })
          }
          const pz2Val = result[pz1].subItems[pz2]
          return pz2Val
        })
      }
      return result[pz1]
    })
  }

  function getGetUseOfSpaceHistory (report) {
    const format = "StringToNumber"

    if (report === undefined || report === null) {
      return []
    }

    return report.map(item => {  
      return {
        reportingPeriod: item['RaportointiJakso'],
        administration: item['Hallinnon ala'],
        organization: item['Kirjanpitoyksikkö'],
        agreedArea: helpers.format.formatData(item['Sovittu ala, ka.'], format),
        officeUnitArea: helpers.format.formatData(item['Toimistoala, hum2'], format),
        personnelcount: helpers.format.formatData(item['Henk lkm'], format),
        htvcount: helpers.format.formatData(item['htv'], format),
        officeHtvcount: helpers.format.formatData(item['Tsto-HTV '], format),
        overallEfficiencycount: helpers.format.formatData(item['Kokonaistilatehokkuus (Sovittu ala / htv)'], format),
        officeEfficiencycount: helpers.format.formatData(item['Toimistotilatehokkuus (Toimistoala / tsto-htv)'], format),
      }
    })
  }

  function getGetPurposeZoneHistory (report) {
    const format = "StringToNumber"

    if (report === undefined || report === null) {
      return []
    }
    
    return report.map(item => {
      return {
        reportingPeriod: item['RaportointiJakso'],
        administration: item['Hallinnonala'],
        organization: item['KIRJANPITOYKSIKKÖ'],
        purposeOfUseZone: item['Käyttötarkoitusvyöhyke'],
        spaceArea: helpers.format.formatData(item['Huoneala'], format)
      }
    })
  }

  function getGetContractsHistory (report) {
    const format = "StringToNumber"

    if (report === undefined || report === null) {
      return []
    }

    return report.map(item => {
      return {
        reportingPeriod: item['RaportointiJakso'],
        administration: item['Raportointinimi'],
        organization: item['Kirjanpitoyksikkö Nimi'],
        typeOfAsset: item['Omaisuuslaji'],
        contractscount: helpers.format.formatData(item['Sopimusten lukumäärä'], format),
        annualRent: helpers.format.formatData(item['Vuosivuokra'], format),
        agreedArea: helpers.format.formatData(item['Sovittu ala ka'], format)
      }
    })
  }

  function getGetExpensesHistory (report) {
    const format = "StringToNumber"

    if (report === undefined || report === null) {
      return []
    }

    return report.map(item => {
      return {
        reportingPeriod: item['RaportointiJakso'],
        administration: item['Hallinnon ala'],
        organization: item['Kirjanpitoyksikkö'],
        expenseVariety: item['Kustannuslaji'],
        expensePlace: item['Tili'],
        euroCost: helpers.format.formatData(item['€'], format),
        euroHtvCost: helpers.format.formatData(item['€/htv'], format),
        euroHtmCost: helpers.format.formatData(item['€ / htm2'], format)
      }
    })
  }

  function getEnergyConsumptionsAndEmissionsHistory (report) {
    const format = "StringToNumber"

    if (report === undefined || report === null) {
      return []
    }

    return report.map(item => {
      return {
        reportingPeriod: item['RaportointiJakso'],
        administration: item['Hallinnonala'],
        organization: item['Kirjanpitoyksikkö'],
        energyConsumptionType: item['Energiankulutuslaji'],
        energyConsumptionMwh: helpers.format.formatData(item['Energiankulutus - MWh'], format),
        emissionsKgco2: helpers.format.formatData(item['Päästöt - kgCO2'], format)
      }
    })
  }

  function getRentalContractsByOrganization (rootGetters,report) {
    return report.map(item => {
      item.contract_type = GetDefinitionLabelById(rootGetters, item.contract_type)
      item.agreement_end_date = item.end_date
      return item
    })
  }
  // convert and combine capacities with same organization and site to a single row
  function getSitesCapacities (report) {
    let unitList = []
    let combined = []
    // convert capacity to a column
    for (let i = 0 ; i < report.length ; i++)
    {
      let unit = report[i].capacity_unit
      let quantity = report[i].capacity_quantity
      delete report[i].capacity_unit
      report[i][unit] = quantity
      unitList.indexOf(unit) === -1 ? unitList.push(unit) : null
      delete report[i].capacity_quantity
    }
    // combine site capacities to single row
    combined [0] = report [0]
    for (let i = 0 ; i < report.length ; i++)
    {
      var key = Object.keys(report[i])[Object.keys(report[i]).length-1]
      var value = report[i][key]
      if (report[i].organization == combined.at(-1).organization && report[i].siteIdentifier == combined.at(-1).siteIdentifier)
      {
        combined.at(-1)[key] = value
      }
      else
      {
        combined.push(report[i])
      }
    }
    return combined
  }

  function getBreeamInUseCertifications (report) {
    return report.map(siteData => {
      return {
        site_name: siteData.site_name,
        id_site: siteData.id_site,
        site_identifier: siteData.site_identifier,
        site_commercial_name: siteData.site_commercial_name,
        site_classification: siteData.site_classification,
        rental_contract_type: siteData.rental_contract_type,
        rentable_floor_area: siteData.rentable_floor_area,
        municipality: siteData.municipality,
        part1_rating: siteData.part1_star_rating?.grade,
        part2_rating: siteData.part2_star_rating?.grade,
        certification_expires: siteData.certification_expires,
        certification_version: siteData.certification_version,
        part1_score: siteData.part1_overall_score,
        part2_score: siteData.part2_overall_score,
        certification_date: siteData.certification_date,
        diploma_document_id: siteData.id_document
      }
    })
  }

  function getAllGreenBuildingCertificates (rootState, rootGetters, report) {

    return report.filter(data => rootState.app.sites.some(s => s.id_site === data.id_site))
    .map(data => {
      const selectedScheme = rootState.esg.schemeList.find(s => s.value === data.scheme).text
      const selectedSite = rootState.app.sites.find(s => s.id_site === data.id_site).name

      let selectedLevel = null
      if (data.level && rootState.esg.levelList.find(l => l.value === data.level)) {
        selectedLevel = rootState.esg.levelList.find(l => l.value === data.level).text
      }

      let selectedStatus = null
      if (data.status && rootState.esg.statusList.find(s => s.value === data.status)) {
        selectedStatus = rootState.esg.statusList.find(s => s.value === data.status).text
      }

      let selectedGrade = null
      if (data.grade) {
        const selectedGradeList = rootGetters['esg/getSelectedGradeList'](data.scheme)
        if (selectedGradeList !== null && selectedGradeList.find(g => g.value === data.grade)) {
          selectedGrade = selectedGradeList.find(g => g.value === data.grade).text
        }
      }

      let selectedEstate = null
      if (data.id_estate && rootState.app.estates.find(e => e.estate_id === data.id_estate)) {
        selectedEstate = rootState.app.estates.find(e => e.estate_id === data.id_estate).name
      }

      let selectedBuilding = null
      if (data.id_building && rootState.app.buildings.find(b => b.id_building === data.id_building)) {
        selectedBuilding = rootState.app.buildings.find(b => b.id_building === data.id_building).building_name
      }

      return {
        site_name: selectedSite,
        id_site: data.id_site,
        estate_name: selectedEstate,
        id_estate: data.id_estate,
        building_name: selectedBuilding,
        id_building: data.id_building,
        level_name: selectedLevel,
        level: data.level,
        status_name: selectedStatus,
        status: data.status,
        scheme_name: selectedScheme,
        scheme: data.scheme,
        scheme_specification: data.scheme_specification,
        grade_name: selectedGrade,
        grade: data.grade,
        grade_value: data.grade_value,
        issued: data.issued,
        expires: data.expires,
        notes: data.notes,
        area: data.area
      }
    })
  }

  function getHandoversAndTakeovers (data) {
    if(!data)
      return null
    let dataToReturn = []
    let dataWithRentalProcesses = []

    //This makes each prospect within a contact have its own row
    for(var d of data)
    {
      if(d.rentalProcesses.length > 0) {
        for(var p of d.rentalProcesses) {
          //If there are multiple rentalProcesses and each needs their own object the object is deep copied
          const dataToPush = d.rentalProcesses.length > 1 ? JSON.parse(JSON.stringify(d)) : d
          dataToPush.process_status = i18n.t(p.process_status)
          dataToPush.process_title = p.process_title
          dataToPush.id_process = p.id_process
          dataWithRentalProcesses.push(dataToPush)
        }
      }
      else {
        let dataToPush = d
        dataToPush.process_status = ""
        dataToPush.process_title = ""
        dataToPush.id_process = 0
        dataWithRentalProcesses.push(dataToPush)
      }
    }
    
    dataToReturn = dataWithRentalProcesses.map(x => {
      x.auditDirection = i18n.t(x.type)
      x.auditStatus = i18n.t(x.status)
      x.id_site = x.site.id
      x.site_identifier = x.site.identifier
      x.site = x.site.name
      x.id_tenant = x.tenant.id
      x.tenant = x.tenant.name
      x.units = x.units.map(x => x.name)
      x.joinedUnits = x.units.join(", ")

      x.subItems = x.units.map(u => {
        return  { units: u }
      })
      
      x.showToggle = x.subItems.length > 1

      return x
    })
    return dataToReturn
  }

  function getRealStudentCount (report) {

    if (report === undefined || report === null) {
      return []
    }

    return report.map(data => {
      return {
        school_name: data.school_name,
        building_identifier: data.building_identifier,
        address: data.address,
        real_student_count: data.real_student_count
    }})
  }
  function GetRatesByUnitStatusAndTargetWithCustomFooter (report) {
    if (report === undefined || report === null) {
      return []
    }
    return report
  }

  function CheckPrivateBuyerNames (report,rootGetters) {
    if (report === undefined || report === null) {
      return [];
    }
    
    let hideBuyerName = false
    if (rootGetters['app/hasApplicationPermissionByName']('KOHDEHIERARKIA_POISTO') == false) {
      hideBuyerName = true
    }
  
    return report.map(data => {
      return {
        ...data,
        Buyer: data.IsBuyerPrivatePerson == true && hideBuyerName == true ? i18n.t('PrivatePerson') : data.Buyer
      };
    });
  }

  function getAlerts (report, rootState, rootGetters) {
    const alertUsers = rootState.app.alertUsers
    return report.map(a => ({
      ...a,
      createdByUserName: alertUsers.find(user => user.id === a.createdBy)?.name,
      alertReceiverNames: a.alertReceivers.map(id => alertUsers.find(user => user.id === id)?.name),
      alertCompletedName: alertUsers.find(user => user.id === a.completedBy)?.name,
      alertStatus: {
        id: a.alertStatus,
        label: GetDefinitionLabelById(rootGetters, a.alertStatus),
        color: getStatusColor(a, rootState.app.currentDate, rootGetters)
      }
    }))
  }

function getStatusColor (alert, currentDate, rootGetters) {
  const notCompleted = GetDefinitionLabelById(rootGetters, alert.alertStatus) === 'alert.not_completed'
  const ready = GetDefinitionLabelById(rootGetters, alert.alertStatus) === 'alert.completed'

  if (alert.taskDate < moment(currentDate).format('YYYY-MM-DD') && !ready) {
    return 'danger'
  } else if (notCompleted) {
    return 'primary'
  } else if (ready) {
    return 'success'
  }
  
  return ''
}

function getRequiredReports () {
  return {
    'dashboard.strategic_statuses': [], // sites
    'dashboard.ownership_type': [], // sites,
    'dashboard.sites_by_class': [], // sites,
    'dashboard.buildings_by_type': ['GetBuildingsByBuildingList'],
    'dashboard.buildings_by_classification': ['GetBuildingsByBuildingList'],
    'dashboard.parties_without_links': ['GetPersonnelAssignmentsBySiteList'],
    'dashboard.rental_contract_type': [], // sites
    'dashboard.rentable_area': ['RentalStatus', 'GetBuildingsByBuildingList'],
    'dashboard.space_utilization_trend': [], // sites
    'dashboard.unit_vacancies_by_unit_usage': ['RentalStatus'],
    'dashboard.change_selected_sites': ['RentalStatus'],
    'dashboard.vat_responsibility': ['RentalStatus'],
    'dashboard.units_selected_sites': ['RentalStatus'],
    'dashboard.empty_units': ['RentalStatus'],
    'dashboard.vacant_spaces': ['Workstations'],
    'dashboard.tenants': ['RentalStatus'],
    'dashboard.sites': [], // sites
    'dashboard.space_utilization': ['GetSpaceUtilizationBySiteList', 'Workstations'],
    'dashboard.spaces_by_purpose_of_use': ['GetBuildingsByBuildingList'], // loaded always
    'dashboard.latest_party_changes': ['GetPersonnelAssignmentsBySiteList'],
    'dashboard.buildings': ['GetBuildingsByBuildingList'],
    'dashboard.map': ['GetBuildingsByBuildingList'],
    'dashboard.tasks': [], // ignored
    'dashboard.free_spaces_selected_sites': ['RentalStatus'], 
    'dashboard.rental_units': ['RentalStatus'],
    'dashboard.selected_sites_by_unit_status': [], // sites
    'dashboard.spaces_selected_sites': [],
    'dashboard.vacant_workstations_by_purpose_of_use': ['Workstations'],
    'dashboard.vacant_spaces_from_costcenters': ['GetVacantSpacesByCostcenters'],
    'dashboard.purpose_zone_status': ['GetPurposeZoneStatus'],
    'dashboard.purpose_zone_percentages': ['GetPurposeZonePercentages'],
    'dashboard.unit_dwg_changes': ['GetUnitDwgChanges'],
    'dashboard.falcony': ['GetAuditsReportForDashboard'],
    'dashboard.purpose_of_use_zones_by_organizations': ['GetPurposeZonesByOrganizations'],
    // 'dashboard.organization_selected_sites': ['GetOrganizationSites'], // LAZY !!
    'dashboard.apartments': ['GetApartmentsStatuses'],
    'dashboard.property_tax': ['GetPropertyTaxesList'],
    'dashboard.space_utilization_by_government': ['GetSpaceUtilizationByGovernment'],
    'dashboard.contract_rows': ['GetContractRows'],
    'dashboard.rental_contracts_by_organization': ['GetRentalContractsByOrganizations'], // TODO: LAZY
    'dashboard.capacities' : ['GetOrganizationCapacities'],
    'dashboard.breeam_in_use_certifications' : ['BreeamInUseCertifications'],
    'dashboard.carparks': ['GetCarParksAndParkingZonesWithRent'],
    'dashboard.carparks_arearent': ['GetSiteCarparksAreas'],
    'dashboard.carparks_privileges': ['GetSiteCarparksPrivileges'],
    'dashboard.real_student_count': ['GetRealStudentCount'],
    'dashboard.rates_by_unit_status_and_target': ['RatesByUnitStatusAndTarget'],
    'dashboard.alerts': ['GetAlerts'],
    'dashboard.sale_reporting': ['GetSales'],
    'dashboard.disassembled_and_removed_reporting': ['GetDisassembledAndRemoved'],
    'dashboard.sites_and_responsibilities': ['GetSiteParties'],
    'dashboard.green_building_certificates': ['GetAllGreenBuildingCertificates'],
    'dashboard.building_permission_validity': ['GetBuildingPermissionValidity'],
    'dashboard.unit_permission_validity': ['GetUnitPermissionValidity']
  }
}

export default {
  namespaced: true,
  state: {
    loadedWidgetData: [],
    apiEndpoint: null,
    apiToken: null,
    // Used for stopping ongoing requests if user has 
    // initiated multiple simultanious fetches on dashboard. 
    // This can occur for example if user uses filters too soon. 
    widgetDataAbortController: null,
    widgetFilters: { fimx: 0 },
    latestLoadWidgetDataCall: null,
    currentRecursionId: 0,
    widgetDataRecursion: 0,
    widgetDataLimit: 5000000
  },
  getters: {
    /**
     * dataForWidget retrieves data instance for widget
     */
    dataForWidget: (state) => (widgetName) => {
      const found = state.loadedWidgetData.find(row => row.name === widgetName)
      if (found) {
        return found.data
      }
      return null
    },
    headersForWidget: (state) => (widgetName) => {
      const found = state.loadedWidgetData.find(row => row.name === widgetName)

      if (found) {
        return found.headers
      }
      return null
    }
  },
  mutations: {
    setWidgetData (state, { name, data, headers }) {
      let names = name
      if (!Array.isArray(name)) {
        names = [name]
      }

      names.forEach(name => {
        const existing = state.loadedWidgetData.findIndex(r => r.name === name)
        if (existing >= 0) {
          state.loadedWidgetData.splice(existing, 1)
        }
        state.loadedWidgetData.push({ name: name, data: data, headers: headers })
      })
    },
    pushWidgetData (state, { name, data, headers }) {
      if (state.loadedWidgetData.find(x => x.name == name && x.data)) {
        state.loadedWidgetData.find(x => x.name == name).data.push(...data)                           
      } else {
        let names = name
        if (!Array.isArray(name)) {
          names = [name]
        }
        names.forEach(name => {
          const existing = state.loadedWidgetData.findIndex(r => r.name === name)
          if (existing >= 0) {
            state.loadedWidgetData.splice(existing, 1)
          }
  
          state.loadedWidgetData.push({ name: name, data: data, headers: headers })
        })
      }
    },
    clearWidgetData (state) {
      state.loadedWidgetData = []
    },
    setWidgetDataFilter (state, { widget, value }) {
      switch (widget) {
        case 'fimx':
          state.widgetFilters[widget] = value
          break
        default:
          break 
      }
    },
    setLatestLoadWidgetDataCall (state, value) {
      state.latestLoadWidgetDataCall = value
    },
    setCurrentRecursionId (state, id) {
      state.currentRecursionId = id
    },
    setWidgetDataRecursion (state, recursion) {
      state.widgetDataRecursion = recursion
    },
    updateBreeamCertificationWidgetData (state, { documentId, siteId, certificationDate }) {
      const widget = state.loadedWidgetData.find(widget => widget.name === 'dashboard.breeam_in_use_certifications')
      const dataRow = widget.data.find(row => row.id_site === siteId && row.certification_date === certificationDate)
      dataRow.diploma_document_id = documentId
    },
    setWidgetDataAbortController (state, controller){
      state.widgetDataAbortController = controller
    },
  },
  actions: {
    async loadRecursionWidgetData ({ commit, rootState, rootGetters, state }) {
      commit('clearWidgetData')
      commit('setCurrentRecursionId', rootState.app.idRecursion)
      commit('setWidgetDataRecursion', 0)

      // If there is ongoing fetchings, abort all.
      if(state.widgetDataAbortController){
        state.widgetDataAbortController.abort()
      }
      // Initialize new controller. 
      commit('setWidgetDataAbortController', new AbortController())

      do {
        if (state.currentRecursionId !== rootState.app.idRecursion) {
          commit('clearWidgetData')
          commit('setCurrentRecursionId', rootState.app.idRecursion)
          commit('setWidgetDataRecursion', 0)
        }
        const recursion = state.widgetDataRecursion
        commit('setWidgetDataRecursion', recursion + 1)

        if (rootState.app.idRecursion !== rootState.app.buildingIds.idRecursion) {
          break
        }

        const perfStart = performance.now()

        var sites = rootState.app.sites

        const start = recursion * state.widgetDataLimit
        const end = (recursion + 1) * state.widgetDataLimit

        var  buildings = rootState.app.buildingIds.buildings.slice(start, (end < rootState.app.buildingIds.buildings.length ? end : rootState.app.buildingIds.buildings.length))
        var  cemeteries = rootState.app.cemeteries.filter(g => sites.find(s => s.id_site === g.idSite))

        // We need to know which report is used for which widget
        const requiredReports = getRequiredReports()

        const requiredGraveyardReports = {
          'dashboard.graveyard.reserve_situation_graves' : ['ReserveSituationGraves'],
          'dashboard.graveyard.free_graves_per_graveyards' : ['VacantGraves'],
          'dashboard.map': ['GetCemeteries']
        }

        const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
        const currentDate = rootState.app.currentDate
        const buildingIds = buildings // .map(b => b.id_building) 12.34.17
        const cemeteryIds = cemeteries.map(g => g.id)

        // Add strings to the site list
        sites = sites.map(s => {
          s.ownership_type_string = GetDefinitionLabelById(rootGetters, s.ownership_type)
          s.site_status_string = GetDefinitionLabelById(rootGetters, s.site_status)
          s.status_string = GetDefinitionLabelById(rootGetters, s.site_status)
          s.classification_string = GetDefinitionLabelById(rootGetters, s.site_classification)
          s.portfolio_string = GetDefinitionLabelById(rootGetters, s.portfolio)
          s.rental_contract_type_string = GetDefinitionLabelById(rootGetters, s.rental_contract_type)
          s.property_company_type_string = GetDefinitionLabelById(rootGetters, s.property_company_type)
          s.customer_service_unit_string = GetDefinitionLabelById(rootGetters, s.customer_service_unit)
          s.hosting_team_string = GetDefinitionLabelById(rootGetters, s.hosting_team)
          s.major_district_string = GetDefinitionLabelById(rootGetters, s.major_district)
          s.district_string = GetDefinitionLabelById(rootGetters, s.district)
          s.neighbourhoods_string = GetDefinitionLabelById(rootGetters, s.neighbourhoods)
          s.siteIdentifier =  s.id_site
          s.unitIdentifier = s.unit_id

          return s
        })

        const fimxWidgets = [
          'dashboard.ltmp_year_overview_fimx',
          'dashboard.ltmp_tasks_year_overview_per_site_fimx'
        ]
        const siteDataWidgets = [
          'dashboard.strategic_statuses',
          'dashboard.ownership_type',
          'dashboard.sites_by_class',
          'dashboard.sites',
          'dashboard.rental_contract_type',
          'dashboard.space_utilization_trend',
          'dashboard.spaces_selected_sites',
          'dashboard.selected_sites_by_unit_status'
        ]
        const fileDataWidgets = {
          'dashboard.use_of_space_history': ['GetUseOfSpaceHistory'],
          'dashboard.purpose_zone_history': ['GetPurposeZoneHistory'],
          'dashboard.contracts_history': ['GetContractsHistory'],
          'dashboard.expenses_history': ['GetExpensesHistory'],
          'dashboard.energy_consumption_and_emissions_history': ['GetEnergyConsumptionAndEmissionsHistory']
        }
        const auditDataWidgets = [
          'dashboard.dashboard.contract_handovers_and_takeovers_within_three_months'
        ]

        // TODO All this data retrieval can be done at backend. The most of the code in this file could be ported over to C# for better performance
        const userWidgets = rootState.app.userWidgets
          .filter(w => w.name.startsWith('dashboard'))
          .filter(w => !siteDataWidgets.some(sw => sw === w.name))
          .filter(w => !fimxWidgets.some(fw => fw === w.name))
          .filter(w => !auditDataWidgets.some(aw => aw === w.name))

        // Sites. No need to retrieve anything!

        commit('setWidgetData', {
          name: siteDataWidgets,
          data: sites
        })

        let time = Date.now()
        commit('setLatestLoadWidgetDataCall', time)

        const allReports = userWidgets.map(w => w.name).reduce((acc, cur) => {
          if (requiredReports[cur]) {
            acc.push(...requiredReports[cur])
          }
          return acc
        }, []).filter((val, idx, arr) => arr.indexOf(val) === idx)

        let signal = null

        if(state.widgetDataAbortController){
          signal = state.widgetDataAbortController.signal
        }
        
        const reportPromises = allReports.map(async (reportName) => {
          const request =  api.reports.post(reportName, buildingIds, currentDate, signal)
            .then((data) => {
              if (data == null || data.isAxiosError) {
                loglevel.error('Report with name: ' + reportName + ' received no data from the API.')
                return { reportName, data: [] }
              } else if (data && data.message === "canceled") {
                throw Error("REQUEST_CANCELED")
              }
              return { reportName, data }
            })
          return request
        })

        const allGraveyardReports = userWidgets.map(w => w.name).reduce((acc, cur) => {
          if (requiredGraveyardReports[cur]) {
            acc.push(...requiredGraveyardReports[cur])
          }
          return acc
        }, []).filter((val, idx, arr) => arr.indexOf(val) === idx)

        var graveyardReports = null
        const graveyardReportsPromise = api.graveyards.reports.list(allGraveyardReports, cemeteryIds, currentDate)
          .then(val => {
            graveyardReports = val
          })
        reportPromises.push(graveyardReportsPromise)

        const reportResponses = await Promise.all(reportPromises)
        const reports = reportResponses.reduce((acc, cur) => {
          if (cur?.reportName) {
            return {
              ...acc,
              [cur.reportName]: cur.data,
            }
          }
          return acc
        }, {})

        const fileDataReports = await userWidgets.map(w => w.name).reduce(async (acc, cur) => {
          const res = await acc
          if (fileDataWidgets[cur]) {
            if (!state.loadedWidgetData.find(w => w.name == cur)) {
              res[fileDataWidgets[cur]] = await api.reports.post(fileDataWidgets[cur], buildingIds, currentDate)
            }
          }
          return res
        }, {})

        // If a report has null value then there is some problem with the data received frm the API.
        Object.keys(reports).forEach(key => {
          if (reports[key] === null && typeof api === 'undefined') { // TODO REMOVE && --> 
            loglevel.error('Report with name: ' + key + ' received no data from the API.')
            store.dispatch('error/addError', 'err.report_no_data')
            reports[key] = []
          }
        })

        let fimx = null
        const userFimxWidgets = rootState.app.userWidgets.filter(w => fimxWidgets.includes(w.name))
        // Fimx data should only be fetched if the user has a specific application right,
        // since it always brings data for a single, specific, customer.
        // Also only fetch fimx data if it's actually needed so only if user has fimx widgets
        if (this.getters['app/hasApplicationPermissionByName']('FIMX_PTS') && userFimxWidgets.length > 0) {
          const siteIdentifiers = sites.map(site => site.site_identifier)
          if (siteIdentifiers.length > 0) {
            fimx = await api.reports.fimx(siteIdentifiers, currentDate)
          }
        }
        userFimxWidgets.forEach(w => {
          let data = null
          if (w.name === 'dashboard.ltmp_year_overview_fimx') {
            data = LtmpYearOverview(fimx, sites, currentDate)
          } else if (w.name === 'dashboard.ltmp_tasks_year_overview_per_site_fimx') {
            data = LtmpTasksPerSiteYearOverview(fimx, sites, currentDate)
          }
          if (data !== null) {
            commit('setWidgetData', {
              name: w.name,
              data: data
            })
          }
        })

        if (userWidgets.map(w => w.name).includes('dashboard.contract_handovers_and_takeovers_within_three_months')) {
          if (this.getters['app/hasApplicationPermissionByName']('AUDIT_INTEGRAATIO') && 
            this.getters['app/hasApplicationPermissionByName']('LEASING')
          ) {
            const siteIds = sites.map(site => site.id_site)
            const today = new Date()
              try {
                let auditData = await api.reports.audit(currentDate)
                auditData = auditData?.filter(a => siteIds.includes(a.site.id))
                let data = getHandoversAndTakeovers(auditData, sites)
                if (data !== null && moment(currentDate, 'day').isSame(today, 'day')) {
                  commit('setWidgetData', {
                    name: 'dashboard.contract_handovers_and_takeovers_within_three_months',
                    data: data
                  })
                } else {
                  commit('setWidgetData', {
                    name: 'dashboard.contract_handovers_and_takeovers_within_three_months',
                    data: [],
                  })
                }
              } catch (err) {
                loglevel.error('Audit data fetch failed. Please check server Audit configuration')
                store.dispatch('error/addError', 'err.report_no_data')
                commit('setWidgetData', {
                  name: 'dashboard.contract_handovers_and_takeovers_within_three_months',
                  data: [],
                })
              }
          }
          else {
            commit('setWidgetData', {
              name: 'dashboard.contract_handovers_and_takeovers_within_three_months',
              data: [],
            })
          }
        }

        const today = new Date()
        const rentalDefs = GetDefinitionGroup(rootGetters, 'unit.notrentable')
        // report data loaded. commit it to the store
        for (const w of userWidgets) {
          let data = null
          let headers = null

          try {
            const report = lodash.cloneDeep(reports)
            // We do special handling of data here
            if (w.name === 'dashboard.tenants') {
              const { RentalStatus } = report
              data = Contracts(RentalStatus, sites, currentDate)
            } else if (['dashboard.change_selected_sites', 'dashboard.free_spaces_selected_sites'].includes(w.name)) {
              const { RentalStatus } = report
              data = Utilization(RentalStatus, sites, rentalDefs, currentDate)
            } else if (w.name === 'dashboard.units_selected_sites') {
              const { RentalStatus } = report
              data = UtilizationForUnits(RentalStatus, sites, rentalDefs)
            } else if (w.name === 'dashboard.unit_vacancies_by_unit_usage') {
              const { RentalStatus } = report
              data = UnitVacanciesByUsage(RentalStatus, rentalDefs, currentDate)
            } else if (w.name === 'dashboard.empty_units' || w.name === 'dashboard.empty_units_from_contract') {
              const { RentalStatus } = report
              const rentableDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') === -1).map(x => x.id)
              const mapRentalStatusObject = (rs) => {
                return {
                  site_name: rs.siteName,
                  building_name: rs.buildingName,
                  unit_name: rs.unitName,
                  unit_area: rs.area,
                  unit_class: rs.unitUsage,
                  unit_id: rs.unitId,
                  tenant: w.name === 'dashboard.empty_units' ? rs.curPartyName : rs.current_party_name_from_contract,
                  start_date: w.name === 'dashboard.empty_units' ? new Date(rs.curPartyStartDate) : rs.current_party_start_date_from_contract,
                  next_tenant_name: w.name === 'dashboard.empty_units' ? rs.nextPartyName : rs.next_party_name_from_contract,
                  next_tenant_start_date: w.name === 'dashboard.empty_units' ? rs.nextPartyStartDate : rs.next_party_start_date_from_contract,
                  previous_tenant_name: w.name === 'dashboard.empty_units' ? rs.prevPartyName : rs.previous_party_name_from_contract,
                  previous_tenant_end_date: w.name === 'dashboard.empty_units' ? rs.prevPartyEndDate : rs.previous_party_end_date_from_contract,
                  siteIdentifier : rs.siteId,
                  unitIdentifier : rs.unitId
                }
              }

              if (w.name === 'dashboard.empty_units') {
                data = RentalStatus
                  .filter(rs => rs.curPartyId === 0 && rentableDefs
                  .includes(rs.unitStatus))
                  .map(rs => mapRentalStatusObject(rs))
              } else if (w.name === 'dashboard.empty_units_from_contract') {
                data = RentalStatus
                  .filter(rs => rs.current_party_id_from_contract === null && rentableDefs
                  .includes(rs.unitStatus))
                  .map(rs =>  mapRentalStatusObject(rs))
              }
            } else if (w.name === 'dashboard.vat_responsibility') {
              const { RentalStatus } = report
              data = VatResponsibility(RentalStatus, sites, rentalDefs)
            } else if (w.name === 'dashboard.parties_without_links') {
              const { GetPersonnelAssignmentsBySiteList } = report
              data = PersonnelWithoutAssignment(GetPersonnelAssignmentsBySiteList)
            } else if (w.name === 'dashboard.rentable_area') {
              const { RentalStatus, GetBuildingsByBuildingList } = report
              data = RentableArea(rootGetters, RentalStatus, sites, GetBuildingsByBuildingList)
            } else if (w.name === 'dashboard.spaces_by_purpose_of_use') {
              const { GetBuildingsByBuildingList } = report
              const buildingCodes = GetBuildingsByBuildingList.map(b => b.building_code)
              const buildingUsages = await api.reports.buildingusages(buildingCodes)
              data = SpacesByPurposeOfUse(buildingUsages)
              headers = PurposeOfUseHeaders(buildingUsages)
            } else if (w.name === 'dashboard.map') {
              const { GetBuildingsByBuildingList } = report
              data = GetMapLayerFromBuildings(GetBuildingsByBuildingList, sites)
              const { GetCemeteries } = graveyardReports
              data.push(GetMapLayerFromCemeteries(GetCemeteries, sites))
            } else if (w.name === 'dashboard.rental_units') {
              const { RentalStatus } = report
              data = RentalUnits(RentalStatus, rentalDefs, sites)
            } else if (w.name === 'dashboard.buildings_by_type') {
              const { GetBuildingsByBuildingList } = report
              data = buildingsByUsage(rootGetters, GetBuildingsByBuildingList)
            } else if (w.name === 'dashboard.buildings_by_classification') {
              const { GetBuildingsByBuildingList } = report
              await store.dispatch('app/fetchBuildingClassifications')
              data = buildingsByClassification(rootGetters, GetBuildingsByBuildingList)
            } else if (w.name === 'dashboard.vacant_workstations_by_purpose_of_use') {
              const { Workstations } = report
              data = getVacantWorkstationsByPurposeOfUse(Workstations)
            } else if (w.name === 'dashboard.space_utilization') {
              const { GetSpaceUtilizationBySiteList, Workstations } = report
              data = getWorkstationsForBuildings(GetSpaceUtilizationBySiteList, Workstations)
            } else if (w.name === 'dashboard.vacant_spaces') {
              const { Workstations } = report
              data = getWorkstationsForRoomShares(Workstations).filter(w => w.vacant_workstations > 0)
            } else if (w.name === 'dashboard.latest_party_changes') {
              const { GetPersonnelAssignmentsBySiteList } = report
              var d = new Date(currentDate.getTime())
              d.setMonth(d.getMonth() - 6)
              data = GetPersonnelAssignmentsBySiteList.filter(data => data.start_date > (moment(d).format('YYYY-MM-DD') + 'T00:00:00.000Z'))
            } else if (w.name === 'dashboard.property_tax') {
              if (this.getters['app/hasApplicationPermissionByName']('KIINTEISTOVERO')) {
                const { GetPropertyTaxesList } = report
                data = PropertyTaxesList(GetPropertyTaxesList)
              } else {
                data = []
              }
            } else if (w.name === 'dashboard.space_utilization_by_government') {
              const { GetSpaceUtilizationByGovernment } = report
              data = SpaceUtilizationByGovernment(GetSpaceUtilizationByGovernment)
            } else if (w.name === 'dashboard.falcony') {
              const { GetAuditsReportForDashboard } = report
              if (getAuditsReportForDashboard === null) {
                return []
              } else if (!moment(currentDate, 'day').isSame(today, 'day')) {
                return []
              } else {
                data = getAuditsReportForDashboard(GetAuditsReportForDashboard)
              }
            } else if (w.name === 'dashboard.graveyard.reserve_situation_graves') {
              const { ReserveSituationGraves } = graveyardReports
              data = getReserveSituationGraves(ReserveSituationGraves)
            } else if (w.name === 'dashboard.purpose_zone_percentages') {
              const { GetPurposeZonePercentages } = report
              data = getZonePercentages(GetPurposeZonePercentages)
            } else if (w.name === 'dashboard.purpose_of_use_zones_by_organizations') {
              const { GetPurposeZonesByOrganizations } = report
              data = getPurposeZonesByOrganizations(GetPurposeZonesByOrganizations)
            } else if (w.name === 'dashboard.apartments') {
              const { GetApartmentsStatuses } = report
              data = getApartmentsForSites(GetApartmentsStatuses)
            } else if (w.name === 'dashboard.use_of_space_history') {
              const { GetUseOfSpaceHistory } = fileDataReports
              data = getGetUseOfSpaceHistory(GetUseOfSpaceHistory)
            } else if (w.name === 'dashboard.purpose_zone_history') {
              const { GetPurposeZoneHistory } = fileDataReports
              data =  getGetPurposeZoneHistory(GetPurposeZoneHistory)
            } else if (w.name === 'dashboard.contracts_history') {
              const { GetContractsHistory } = fileDataReports
              data =  getGetContractsHistory(GetContractsHistory)
            } else if (w.name === 'dashboard.expenses_history') {
              const { GetExpensesHistory } = fileDataReports
              data =  getGetExpensesHistory(GetExpensesHistory)
            } else if (w.name === 'dashboard.energy_consumption_and_emissions_history') {
              const { GetEnergyConsumptionAndEmissionsHistory } = fileDataReports
              data = getEnergyConsumptionsAndEmissionsHistory(GetEnergyConsumptionAndEmissionsHistory)
            } else if (w.name === 'dashboard.contract_rows') {
              const { GetContractRows } = report
              data = getContractRows(GetContractRows)
            } else if (w.name === 'dashboard.rental_contracts_by_organization') {
              const { GetRentalContractsByOrganizations } = report
              data = getRentalContractsByOrganization(rootGetters, GetRentalContractsByOrganizations)
            } else if (w.name === 'dashboard.capacities') {
              const { GetOrganizationCapacities } = report
              data = getSitesCapacities(GetOrganizationCapacities)
            } else if (w.name === 'dashboard.breeam_in_use_certifications') {
              const { BreeamInUseCertifications } = report
              data = getBreeamInUseCertifications(BreeamInUseCertifications)
            } else if (w.name === 'dashboard.carparks') {
              const { GetCarParksAndParkingZonesWithRent } = report
              data = carparks(GetCarParksAndParkingZonesWithRent, sites)
            } else if (w.name === 'dashboard.carparks_arearent') {
              const { GetSiteCarparksAreas } = report
              data = siteCarparks(GetSiteCarparksAreas, 'Area') 
            } else if (w.name === 'dashboard.carparks_privileges') {
              const { GetSiteCarparksPrivileges } = report
              data = siteCarparks(GetSiteCarparksPrivileges, 'Privilege')
            } else if (w.name === 'dashboard.real_student_count') {
              const { GetRealStudentCount } = report
              data = getRealStudentCount(GetRealStudentCount)
            } else if (w.name === 'dashboard.alerts'){
              const { GetAlerts } = report
              data = getAlerts(GetAlerts, rootState, rootGetters)
            } else if (w.name === 'dashboard.rates_by_unit_status_and_target'){
              const { RatesByUnitStatusAndTarget } = report
              data = GetRatesByUnitStatusAndTargetWithCustomFooter(RatesByUnitStatusAndTarget)
            } else if (w.name === 'dashboard.sites_and_responsibilities') {
              const {GetSiteParties} = report
              const PartyDefs = SetupPartyResponsibilityDefinitions(rootGetters)
              sites = sites.map(s => {
                
                const buildingsForSite = reports.GetBuildingsByBuildingList.filter(building => building.id_site === s.id_site);
                const PartiesForSite = GetPartiesForSite(s,GetSiteParties)
      
                s.addresses_string = GetAddresses(buildingsForSite);
                s.owners_string = GetOwners(buildingsForSite);
                s.rental_rate = GetRentalRate(s);
                s.maintenance_string = GetMaintenance(PartiesForSite,PartyDefs)
                s.administrationContact_string = GetAdministrationContact(PartiesForSite,PartyDefs)
                s.marketingContact_string = GetMarketingContact(PartiesForSite,PartyDefs)
      
                return s
              })
              data = sites
            } else if (w.name === 'dashboard.green_building_certificates') {
              const { GetAllGreenBuildingCertificates } = report        
              data = getAllGreenBuildingCertificates(rootState, rootGetters, GetAllGreenBuildingCertificates)
            } else if (w.name === 'dashboard.sale_reporting') {
              const { GetSales } = report  
              data = CheckPrivateBuyerNames(GetSales,rootGetters)
            } else if (w.name === 'dashboard.building_permission_validity'){
              const { GetBuildingPermissionValidity } = report
              data = getBuildingPermissionValidity(GetBuildingPermissionValidity)
            } else if (w.name === 'dashboard.unit_permission_validity'){
              const { GetUnitPermissionValidity } = report
              data = getUnitPermissionValidity(GetUnitPermissionValidity)
            } else {
              // See if the widget gets it's data straight from the report and requires no special handling.
              const requiredReportForWidget = requiredReports[w.name]
              if (requiredReportForWidget && requiredReportForWidget.length > 0) {
                data = report[requiredReportForWidget[0]]
              } else {
                const requiredGraveyardReportForWidget = requiredGraveyardReports[w.name]
                if (requiredGraveyardReportForWidget && requiredGraveyardReportForWidget.length > 0) {
                  data = graveyardReports[requiredGraveyardReportForWidget[0]]
                }
              }
            }
          } catch (error) {
            // Thrown if fetching is cancelled due to refetch... Terminate rest of the execution as useless.
            if(error && error.message === "REQUEST_CANCELLED"){
              return
            }

            loglevel.error('Error trying to process data for widget: "' + w.name + '"', error)
            store.dispatch('error/addError', 'err.report_data_handling_failed')
          }
          
          if (data !== null) {
            commit('pushWidgetData', {
              name: w.name,
              data: data,
              headers: headers
            })
          }
        }
        await loglevel.debug('Widget data loading took ' + (performance.now() - perfStart) + ' ms.')
      } while (rootState.app.buildingIds.buildings.length > (state.widgetDataLimit * state.widgetDataRecursion) && rootState.app.buildingRecursionLoadingState === false) 
      // Finally clear the abort controller so we don't call abort unnecessarily.
      commit('setWidgetDataAbortController', null)

    },
    async updateUnitInformation ({ state }, data) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const { unitId, time, patch } = data
      await api.units.patch(unitId, time, patch)
    },
    async updateCertificationDocumentId ({ commit, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      await api.sites.certificationDocument(params)
      commit('updateBreeamCertificationWidgetData', params)
    },
    async updateSingleWidgetData ({ commit, rootState, state, getters, rootGetters }, widgetName) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = rootState.app.currentDate
      const buildings = rootState.app.buildingIds.buildings
      const headers = getters.headersForWidget(widgetName)
      // reset data to set loading status
      commit('setWidgetData', {
        name: widgetName,
        data: null,
        headers
      })
      const reports = getRequiredReports()      
      let data =  await api.reports.post(reports[widgetName], buildings, currentDate, null)
      if (widgetName === 'dashboard.building_permission_validity'){
        data = getBuildingPermissionValidity(data)
      } 
      else if (widgetName === 'dashboard.unit_permission_validity'){
        data = getUnitPermissionValidity(data)
      }
      else if (widgetName === 'dashboard.alerts'){
        data = getAlerts(data, rootState, rootGetters)
      }
      commit('setWidgetData', {
        name: widgetName,
        data: data,
        headers
      })
    }
  }
}
