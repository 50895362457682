<template>
  <v-tab-item
    :key="2"
  >
    <v-row>
      <v-col cols="7">
        <MetadataForm
          :metadata="buildingRatingFields"
          :value="buildingRating"
          :with-status-icon="true"
          :building-rating-classes="buildingRatingClasses"
          class="bordered mt-4"
        />
      </v-col>
      <v-col cols="5">
        <BuildingRatingsChart
          :items="[buildingRating]"
          @requiredField="isRequired"
        />
      </v-col>
    </v-row>
  </v-tab-item>
</template>

<script>
import BuildingRatingsChart from './BuildingRatingsChart.vue'
import MetadataForm from '../../components/MetadataForm.vue'

export default {
  name: "SummaryTab",
  components: {
    BuildingRatingsChart,
    MetadataForm,
  },
  props: {
    buildingRating: {
      type: Object,
      default: () => undefined
    },
    buildingRatingClasses: {
      type: Array,
      default: () => []
    },
    savingDisabled: {
      type: Boolean,
      default: false
    },
  },
  emits: ['requiredField'],
  data () {
    return {
      buildingRatingFields: {
        average: { isShown: true, category: 'None', format: 'Double' },
        characteristics: { isShown: true, category: 'None', format: 'Double' },
        performance_level: { isShown: true, category: 'None', format: 'Double' },
        answered_questions_count: { isShown: true, category: 'None', max: 11 },
        unanswered_questions: { isShown: true, category: 'None' },
        justification: { isShown: true, category: 'None', isRequired: false, editType: 'TextArea' },
        rating_class_proposal_name: { isShown: true, category: 'None', format: 'Translation' },
        rating_class: { isShown: true, category: 'None', format: 'Translation', editType: 'DropDown' },
      },
    }
  },
  watch: {
    savingDisabled: {
      handler: function (savingDisabled) {
        // Remove editType if saving is disabled
        Object.keys(this.buildingRatingFields).forEach(key => {
          if (savingDisabled && this.buildingRatingFields[key].editType) {
            this.buildingRatingFields[key].editType = 'None';
          }
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    isRequired (val) {
      this.buildingRatingFields.justification.isRequired = val
      this.$emit('requiredField', val)
    }
  }
}
</script>

<style scoped>
.bordered {
  border: 1px solid var(--r-color-mountain-10);
  border-radius: var(--c-radius-m);
}
</style>