<template>
  <div id="reports">
    <BaseView>
      <template #selections>
        <v-row>
          <v-col>
            <v-select
              v-model="contractLevel"
              :label="$t('Contract level')"
              :items="contractLevels"
              item-text="text"
              item-value="value"
              class="mb-2"
            />
          </v-col>
          <v-col
            align-self="center"
          >
            <InfoBall :info="infoText" />
          </v-col>
        </v-row>
      </template>
    </BaseView>
    <div
      :class="{'contract-level': !isMobile}"
      class="mx-4"
    >
      <AllContractsLevel
        v-if="contractLevel === 'fairy_including_future'"
        :show-future-contracts="true"
        :site-id="siteId"
        :corporations="corporations"
      />
      <AllContractsLevel
        v-else-if="contractLevel === 'fairy_selected_date'"
        :show-future-contracts="false"
        :site-id="siteId"
        :corporations="corporations"
      />
      <FairyContractLevel
        v-if="contractLevel === 'fairy'"
        :site-id="siteId"
        :importing="importing"
        :corporations="corporations"
      />
      <MarketRentContractLevel
        v-else-if="contractLevel === 'market_rent' || contractLevel === 'carpark_market_rent'"
        :loading="loading"
        :rental-statuses="rentalStatuses"
        :future-rental-statuses="futureRentalStatuses"
        :carparks="carparks"
        :future-carpark-contracts="futureCarparkContracts"
        :importing="importing"
        :contract-level="contractLevel"
        @refreshRentalData="refreshRentalData"
        @refreshCarparkData="refreshCarparkData"
      />
      <EsrCarparksLevel
        v-else-if="contractLevel === 'esr_carparks'"
        :loading="loading"
        :importing="importing"
        :carparks="carparkContracts.concat(futureCarparkContracts)"
        @refreshData="refreshCarparkContractData"
      />
      <RentRollLevel
        v-else-if="contractLevel === 'unit_rent_roll'"
        :loading="loading"
        :data="unitRentRolls"
        :corporations="corporations"
        contract-level="rentroll"
      />
      <FullRentRollLevel
        v-else-if="contractLevel === 'unit_and_carpark_rent_roll'"
        :loading="loading"
        :data="unitCarparkRentRolls"
        :corporations="corporations"
        contract-level="unit_carpark_rent_roll"
      />
      <RentRollLevel
        v-else-if="contractLevel === 'future_unit_rent_roll'"
        :loading="loading"
        :data="futureUnitRentRolls"
        :corporations="corporations"
        contract-level="future_unit_rent_roll"
      />
      <CarparkRentrollLevel
        v-else-if="contractLevel === 'carpark_rent_roll'"
        :loading="loading"
        :data="carparkRentRollData"
        :corporations="corporations"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import BaseView from '../components/general/BaseView.vue'
import FairyContractLevel from '../components/Contracts/FairyContractLevel.vue'
import MarketRentContractLevel from '../components/Contracts/MarketRentContractLevel.vue'
import EsrCarparksLevel from '../components/Contracts/EsrCarparksLevel.vue'
import RentRollLevel from '../components/Contracts/RentRollLevel.vue'
import FullRentRollLevel from '../components/Contracts/FullRentRollLevel.vue'
import AllContractsLevel from '../components/Contracts/AllContractsLevel.vue'
import InfoBall from '../components/InfoBall.vue'
import CarparkRentrollLevel from '../components/Contracts/CarparkRentRollLevel.vue'
import { getContractDurationLeft, getDaysBetweenDates } from '../helpers/contract.js'

export default {
  name: 'RentActions',
  components: {
    BaseView,
    FairyContractLevel,
    MarketRentContractLevel,
    EsrCarparksLevel,
    RentRollLevel,
    AllContractsLevel,
    InfoBall,
    CarparkRentrollLevel,
    FullRentRollLevel
  },
  props: {
    siteId: { type: Number, default: undefined }
  },
  metaInfo () {
    return {
      title: `${this.$t('Contracts')} ·`,
    }
  },
  data () {
    return {
      contractLevels: [],
      contractLevel: '',
      rentalStatuses: [],
      futureRentalStatuses: [],
      carparks: [],
      esrCarparks: [],
      unitRentRolls: [],
      unitCarparkRentRolls: [],
      futureUnitRentRolls: [],
      carparkRentRollData: [],

      // Loading
      rentalDataLoading: false,
      carparkDataLoading: false,
      carparkContractDataLoading: false,
      rentRollDataLoading: false,
      unitCarparkRentRollDataLoading: false,
      futureUnitRentRollDataLoading: false,
      carparkRentRollLoading: false
    }
  },
  computed: {
    ...mapState('app', ['sites', 'currentDate', 'buildings']),
    ...mapState('leasing', ['corporations', 'corporationsLoading']),
    ...mapState('carpark', ['carparkContracts', 'futureCarparkContracts']),
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapGetters('sites', ['buildingsForCurrentSite']),
    importing () {
      switch (this.contractLevel) {
        case 'market_rent':
        case 'carpark_market_rent':
          return this.hasApplicationPermissionByName('MARKKINAVUOKRAT') && this.siteId == undefined
        case 'fairy':
        case 'esr_carparks':
          return this.hasApplicationPermissionByName('VSOP_IMPORT') && this.siteId == undefined
        default:
          return false
      }
    },
    isMobile () {
      return visualViewport.width <= 760
    },
    loading () {

      const loading = [
        this.rentalDataLoading,
        this.carparkDataLoading,
        this.carparkContractDataLoading,
        this.rentRollDataLoading,
        this.unitCarparkRentRollDataLoading,
        this.futureUnitRentRollDataLoading,
        this.corporationsLoading,
        this.carparkRentRollLoading
      ]

      return loading.reduce((acc, cur) => acc || cur)
    },
    siteIds () {
      return this.siteId === undefined ? this.sites.map(site => site.id_site) : [ this.siteId ]
    },
    buildingIds () {

      // As per request on site view we should ignore global filters. Let's use sites.buildingsForCurrentSite instead of global buildings list.
      if(this.siteId){
        return this.buildingsForCurrentSite.map(building => building.id_building)
      }

      return  this.buildings.filter(b => this.siteIds.includes(b.id_site)).map(b => b.id_building)
    },
    infoText () {
      const text = this.$t('ContractsInfo')

      if(this.contractLevel === "unit_rent_roll"){
        return `${this.$t("ContractsInfoRentrollAddendum")} ${text}`
      }

      if(this.contractLevel === "carpark_rent_roll"){
        return `${this.$t("CarparkRentrollAddendum")} ${text}`
      }

      return text
    }
  },
  watch: {
    buildings: async function () {
      if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA') && this.contractLevel !== 'fairy') {
        await this.getLevelData(this.contractLevel);
      }
    },
    contractLevel: async function (level) {
      await this.getLevelData(level)
    },
  },
  mounted: async function () {

    this.getCorporations()

    if (!this.hasApplicationPermissionByName('MUUT_SOPIMUKSET')) {
      if(this.siteId === undefined)
      {
        this.$router.push({ name: 'dashboard' })
      }
      else
      {
        this.$router.push({ name: 'sites.info' })
      }
    } else {
      this.formContractLevels()
    }
  },
  methods: {
    ...mapActions('leasing', ['getRentalStatuses', 'getCorporations']),
    ...mapActions('dashboard', ['updateUnitInformation']),
    ...mapActions('carpark', ['fetchCarparkContracts', 'fetchFutureCarparkContracts']),
    async getLevelData () {
      switch (this.contractLevel) {
        case 'carpark_market_rent': {
          // Only get new data if it hasn't been loaded yet
          if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA'))  {
            await this.getCarparkData()
          }
          break
        }
        case 'market_rent':
          await this.getRentalData()
          break
        case 'esr_carparks': {
          // Only get new data if it hasn't been loaded yet
          if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA'))  {
            await this.getCarparkContractData()
          }
          break
        }
        case 'unit_rent_roll':
          await this.getRentRollData()
          break
        case 'unit_and_carpark_rent_roll':
          await this.getUnitCarparkRentRollData()
          break
        case 'future_unit_rent_roll':
          await this.getFutureUnitRentRollData()
          break
        case 'carpark_rent_roll':
          await this.getCarparkRentRollData()
          break
        default:
          break
      }
    },
    async getRentalData () {
      this.rentalDataLoading = true

      this.futureRentalStatuses = await this.$rambollfmapi.reports.post('allfuturerentalstatusversions', this.buildingIds, this.currentDate)
      this.rentalStatuses = await this.$rambollfmapi.reports.post('rentalstatus', this.buildingIds, this.currentDate)

      this.rentalDataLoading = false
    },
    async getCarparkData () {
      this.carparkDataLoading = true

      this.carparks = await this.$rambollfmapi.reports.post('getcarparksandparkingzoneswithrent', this.buildingIds, this.currentDate)

      this.carparkDataLoading = false
    },
    async getCarparkContractData () {
      this.carparkContractDataLoading = true

      await this.fetchCarparkContracts(this.siteId)
      await this.fetchFutureCarparkContracts(this.siteId)

      this.carparkContractDataLoading = false
    },
    async getRentRollData () {
      this.rentRollDataLoading = true

      this.unitRentRolls = await this.$rambollfmapi.reports.post('rentrolls', this.buildingIds, this.currentDate)

      this.rentRollDataLoading = false
    },
    calculatedFields (item) {
      const {
        carparkArea,
        contractTotalArea,
        contractCapitalRent,
        contractUpkeepRent,
        contractChangeoverRent,
        contractSeperateAllowances,
        contractDiscounts,
        carparkMarketRent,
        maintenanceRentEqualization,
        contractRentalResbonsibility,
        contractTurnoverRent,
        contractOpenBills,
        contractBasicRent,
        carparkCount,
        contractCarparkCount,
      } = item

      let carparkAreaItems = {}
      if (carparkArea && contractTotalArea) {
        const carparkAreaRatio = carparkArea / contractTotalArea
        carparkAreaItems = {
          capital_rent_per_square: carparkAreaRatio * contractCapitalRent / carparkArea,
          maintenance_rent_per_square: carparkAreaRatio * contractUpkeepRent / carparkArea,
          market_rent_per_square: carparkMarketRent / carparkArea,
        }
      }

      if (!carparkCount || !contractCarparkCount) {
        return carparkAreaItems
      }

      const carparkCountRatio = carparkCount / contractCarparkCount
      const carparkCapitalRent = carparkCountRatio * contractCapitalRent
      const carparkUpkeepRent = carparkCountRatio * contractUpkeepRent

      return {
        capital_rent_contract: contractCapitalRent,
        capital_rent_unit: carparkCapitalRent,
        maintenance_rent_unit: carparkUpkeepRent,
        contractMaintenanceRent: contractUpkeepRent,
        capital_rent_yearly: carparkCapitalRent * 12,
        maintenance_rent_yearly: carparkUpkeepRent * 12,
        changeover_rent_unit: carparkCountRatio * contractChangeoverRent,
        separate_allowances_unit:
          carparkCountRatio * contractSeperateAllowances,
        discounts: carparkCountRatio * contractDiscounts,
        maintenance_rent_equalization_unit: carparkCountRatio * maintenanceRentEqualization,
        rental_responsibility_unit: carparkCountRatio * contractRentalResbonsibility,
        rental_responsibility: contractRentalResbonsibility,
        turnover_rent_unit: carparkCountRatio * contractTurnoverRent,
        open_bills_unit: carparkCountRatio * contractOpenBills,
        basic_rent_unit: carparkCountRatio * contractBasicRent,
        capital_rent_per_pcs: carparkCapitalRent / carparkCount,
        maintenance_rent_per_pcs: carparkUpkeepRent / carparkCount,
        ...carparkAreaItems
      }
    },
    async getUnitCarparkRentRollData () {
      const today = new Date()

      this.unitCarparkRentRollDataLoading = true

      if (this.currentDate > today) {
        this.unitCarparkRentRolls = []
      } else {

        try {
          const [carparkRentRollData, unitRentRollData, futureUnitRentRollData, futureRentRollData] = await Promise.all([
            this.$rambollfmapi.reports.post('carparkrentroll', this.buildingIds, this.currentDate),
            this.$rambollfmapi.reports.post('rentrolls', this.buildingIds, this.currentDate),
            this.$rambollfmapi.reports.post('futureunitrentrolls', this.buildingIds, this.currentDate),
            this.$rambollfmapi.reports.post('futurerentrolls', this.buildingIds, this.currentDate)
          ]);
        const carparkData = carparkRentRollData.map(item => {
          const durationLeft = getContractDurationLeft(item.contractEndDate, item.contractFirstPossibleEndDate, this.currentDate)
          const calculatedFields = this.calculatedFields(item)
          const empty_days = getDaysBetweenDates(item.lastContractEndDate, this.currentDate)

          let unitVersionStatus = 'undefined'
          if (item.carparkStartDate) {
            const date = new Date(item.carparkStartDate)
            if (date > this.currentDate) {
              unitVersionStatus = 'unit starting in future'
            } else if (item.carparkEndDate) {
              unitVersionStatus = 'unit with end date'
            } else if (carparkRentRollData.some(otherItem => otherItem !== item && otherItem.carparkIdentifier === item.carparkIdentifier)) {
              unitVersionStatus = 'unit with multiple rows'
            } else {
              unitVersionStatus = 'unit active and one row'
            }
          }

          return {
            ...item,
            site_name: item.site,
            unit_status: item.rentalType,
            unit_version_status: unitVersionStatus,
            facility_code: item.carpark,
            facility_code_long: item.carparkIdentifier,
            area: item.carparkArea,
            site_identifier: item.siteIdentifier,
            site_commercial_name: item.siteCommercialName,
            usage_type: item.usageType,
            building_code: item.buildingCode,
            rental_situation: item.rentalSituation,
            unit_start_date: item.carparkStartDate,
            unit_end_date: item.carparkEndDate,
            tenant_corporation: item.tenantCorporation,
            business_id: item.tenantBusinessId,
            contract_area: item.contractArea,
            contract_area_calculated: item.contractTotalArea,
            contract_duration_left_mm: durationLeft,
            contract_duration_left_yy: durationLeft !== null ? durationLeft / 12 : null,
            contract_start_date: item.contractStartDate,
            contract_end_date: item.contractEndDate,
            contract_details: item.contractDetails,
            contract_first_possible_end_date: item.contractFirstPossibleEndDate,
            tenant_first_possible_end_date: item.tenantFirstPossibleEndDate,
            landlord_first_possible_end_date: item.landlordFirstPossibleEndDate,
            estate_identifier: item.estateIdentifier,
            rental_processes_stage1: 0,
            rental_processes_stage2_5: 0,
            rental_processes_stage6_7: 0,
            last_contract_end_date: item.lastContractEndDate,
            last_tenant: item.lastTenant,
            empty_days,
            commercial_info_date: item.carparkCommercialInfoUpdated,
            lease_plan: item.carparkRentPlan,
            lease_plan_date: item.carparkRentPlanDate,
            rent_target: item.carparkRentTarget,
            market_rent_unit: item.carparkMarketRent,
            completed_year: item.completedYear,
            last_improvement_year: item.lastImprovementYear,
            facility_extra_information: item.carparkDetails,
            fixed_increase: item.fixedIncrease,
            review_basis: item.reviewBasis,
            rent_review_start_date: item.rentReviewStartDate,
            last_index_month: item.lastIndexMonth,
            last_index_year: item.lastIndexYear,
            last_index_value: item.lastIndexValue,
            review_months: item.contractReviewMonths,
            contract_type: item.contractType,
            tenant_notice: item.tenantNoticePeriod,
            landlord_notice: item.landlordNoticePeriod,
            changeover_rent_contract: item.contractChangeoverRent,
            separate_allowances_contract: item.contractSeperateAllowances,
            rent_review_type: item.contractReviewType,
            index_type: item.contractIndexType,
            minimum_increase: item.contractMinimumRise,
            vat_responsibility: item.contractVatResponsibility,
            vacancy_rate_assumption: item.vacancyRateAssumption,
            vacancy_rate_assumption_date: item.vacancyRateAssumptionDate,
            basic_index_point_number: item.basicIndexPointNumber,
            ...calculatedFields,
          }
        })

        const unitRentRollDataWithFuture = [...unitRentRollData, ...futureUnitRentRollData, ...futureRentRollData]
        const groupedData = unitRentRollDataWithFuture.reduce((acc, item) => {
          const key = item.unit_code_long
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(item)

          const hasNullContractNumber = acc[key].some(i => i.contract_number === null);
          const hasFutureContract = acc[key].some(i => i.contract_number !== null && new Date(i.contract_start_date) > this.currentDate)

          if (hasNullContractNumber && hasFutureContract) {
            acc[key] = acc[key].filter(i => i.contract_number !== null)
          }

          return acc
        }, {})

        const unitData = Object.keys(groupedData).reduce((acc, key) => {
          const items = groupedData[key]
          items.forEach(item => {
            const empty_days = getDaysBetweenDates(item.last_contract_end_date, this.currentDate)
            let unitVersionStatus = 'undefined'
            if (item.unit_start_date) {
              const date = new Date(item.unit_start_date)
              if (date > this.currentDate) {
                unitVersionStatus = 'unit starting in future'
              } else if (item.unit_end_date) {
                unitVersionStatus = 'unit with end date'
              } else if (items.length > 1) {
                unitVersionStatus = 'unit with multiple rows'
              } else {
                unitVersionStatus = 'unit active and one row'
              }
            }

            acc.push({
              ...item,
              contractNumber: item.contract_number,
              unit_version_status: unitVersionStatus,
              facility_code: item.unit_code,
              facility_code_long: item.unit_code_long,
              facility_extra_information: item.unit_extra_information,
              contract_duration_left_mm: item.contract_duration_left,
              contract_duration_left_yy: item.contract_duration_left !== null ? item.contract_duration_left / 12 : null,
              empty_days
            })
          })
          return acc
        }, [])

        this.unitCarparkRentRolls = [...unitData, ...carparkData]

        } catch (error) {
          this.unitCarparkRentRolls = [];
          this.$log.error("Error while fetching unit and carpark rent roll data: ", error)
        }
      }

      this.unitCarparkRentRollDataLoading = false
    },
    async getFutureUnitRentRollData () {
      this.futureUnitRentRollDataLoading = true

      this.futureUnitRentRolls = await this.$rambollfmapi.reports.post('futureunitrentrolls', this.buildingIds, this.currentDate)

      this.futureUnitRentRollDataLoading = false
    },
    async getCarparkRentRollData () {
      this.carparkRentRollLoading = true

      this.carparkRentRollData = await this.$rambollfmapi.reports.post('carparkrentroll', this.buildingIds, this.currentDate)

      this.carparkRentRollLoading = false
    },
    async refreshRentalData () {
      // update rental status
      if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA') && this.contractLevel !== 'fairy') {
        await this.getRentalData()
      }
    },
    async refreshCarparkData () {
      // update rental status
      if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA')) {
        await this.getCarparkData()
      }
    },
    async refreshCarparkContractData () {
      if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA')) {
        await this.getCarparkContractData()
      }
    },
    formContractLevels () {
      if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA')) {
        this.contractLevels.push({
          text: this.$t('contracts.all_including_future'),
          value: 'fairy_including_future'
        })

        this.contractLevels.push({
          text: this.$t('contracts.all_selected_date'),
          value: 'fairy_selected_date'
        })

        this.contractLevels.push({
          text: this.$t('contracts.unit_rent_roll'),
          value: 'unit_rent_roll'
        })

        this.contractLevels.push({
          text: this.$t('contracts.unit_and_carpark_rent_roll'),
          value: 'unit_and_carpark_rent_roll'
        })

        this.contractLevels.push({
          text: this.$t('contracts.carpark_rent_roll'),
          value: 'carpark_rent_roll'
        })

        this.contractLevels.push({
          text: this.$t('contracts.market_rent_and_ti_expenses'),
          value: 'market_rent'
        })

        this.contractLevels.push({
          text: this.$t('contracts.carpark_market_rent'),
          value: 'carpark_market_rent'
        })

        this.contractLevels.push({
          text: this.$t('Import: Huoneistovuokrasopimukset'),
          value: 'fairy'
        })

        this.contractLevels.push({
          text: this.$t('contracts.import_carpark_contracts'),
          value: 'esr_carparks'
        })

        this.contractLevels.push({
          text: this.$t('contracts.future_units'),
          value: 'future_unit_rent_roll'
        })

        this.contractLevel = 'fairy_including_future'
      }
    },
  }
}
</script>
<style scoped>
.contract-level {
  position: "relative";
  margin-top: -50px;
}
</style>